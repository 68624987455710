//default library
import React, { Component } from 'react'

//custom component
import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';
import InputComponentNumber from '../../../components/InputComponent/InputComponentNumber';
import SuccessComponent from '../../../components/ErrorComponent/SuccessComponent';
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import RightInformation from '../../../components/InformationComponent/RightInformation';

//api call
import * as PhoneNumberVerification from '../../../middleware/PhoneNumberVerification';

// auth0 library
import { withAuth0 } from '@auth0/auth0-react';

import * as CryptoJS from "crypto-js";
import * as Config from '../../../config/Config'

// local message
import IntlMessages from '../../../helper/IntlMessages';
export class PersonalDetailsPhoneVerification extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            phoneNumber: '',
            verificationCode: '',
            disabled: true,
            errorFlag: false,
            previousRoute: false,
            nextRoute: false,
            successFlag: false,
            showContinue: true,
        }
    }

    //set phone number 
    componentDidMount = async () => {
        let phoneNumber;
        const { getAccessTokenSilently } = this.props.auth0

        await this.setState({
            isLoading: true
        })

        //get phone number from localStorage
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null && localStorageData?.phoneNumber !== undefined) {
            phoneNumber = localStorageData?.phoneNumber
            let phoneNumber1 = phoneNumber.substring(0, 3)
            let phoneNumber2 = phoneNumber.substring(3, 6)
            let phoneNumber3 = phoneNumber.substring(6, 10)
            phoneNumber = `(${phoneNumber1}) ${phoneNumber2} - ${phoneNumber3}` ?? '';
        }

        // send otp to that number
        if (localStorageData?.phoneNumber) {
            const accessToken = await getAccessTokenSilently()
            await PhoneNumberVerification.sendOTP(accessToken)
        }

        await this.setState({
            phoneNumber: phoneNumber,
            isLoading: false
        })
    }

    //add event listener when component update and add phone number
    componentDidUpdate = async () => {
        if (this.state.disabled) {
            window.addEventListener("keydown", this.eventListener)
        }
    }

    //remove event listener when component change
    componentWillUnmount = async () => {
        window.removeEventListener("keydown", this.eventListener)
        this.setState = () => {
            return;
        };
    }

    //resend verification code
    resendCode = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true,
            disabled: true,
            verificationCode: '',
            errorFlag: false,
        })
        const accessToken = await getAccessTokenSilently() //get access token
        await PhoneNumberVerification.sendOTP(accessToken)
        await this.setState({
            isLoading: false
        })
    }

    //set verification code
    setVerificationCode = async (value) => {
        await this.setState({
            disabled: true,
            errorFlag: false,
            successFlag: false,
            showContinue: true
        })
        if (value.length === 6) {
            await this.setState({
                disabled: false,
            })
        }
        await this.setState({
            verificationCode: value
        })
    }

    //check verification code
    onCheckVerify = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        let verifyOtp = await PhoneNumberVerification.verifyOTP(accessToken, this.state.verificationCode)
        if (verifyOtp) {
            await this.setState({
                successFlag: true,
                showContinue: false,
                isLoading: false
            })
        }
        else {
            await this.setState({
                errorFlag: true,
                successFlag: false,
                showContinue: true,
                isLoading: false
            })
        }
    }

    //enter click listener
    eventListener = (e) => {
        if (e.key === 'Enter') {
            this.onCheckVerify()
        }
    }

    setDetails = () => {
        this.details = {
            // verifyPhoneNumber: this.state.successFlag
            verifyPhoneNumber: CryptoJS.AES.encrypt(JSON.stringify(this.state.successFlag), Config.SECRET_KEY).toString()
        }
    }

    render() {
        const { isLoading, disabled, errorFlag, successFlag, showContinue, phoneNumber, verificationCode } = this.state
        this.setDetails()

        return (
            <>
                {isLoading ? <LoadingComponent /> : ''}
                <div className="gi-from-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="py-3">
                                            <div className="gi-from-left">
                                                <PageCount count={1} pageName={'Personal details'} textLine={true} text={`personal.verification`} />
                                                <div className="gi-input mb-40">
                                                    <LabelComponent name={'verificationCode'} labelName={'personal_verify_label1'} labelName2={`personal_verify_label2`} phoneNumber={phoneNumber} />
                                                    <InputComponentNumber name={'verificationCode'} placeholder={'placeholder_zip_code'} maxLength={6} value={verificationCode} inputValue={this.setVerificationCode} />
                                                    <ErrorComponent message={'error_invalid_verification_code'} display={errorFlag ? 'block' : 'none'} />
                                                    <SuccessComponent display={successFlag ? 'block' : 'none'} />
                                                </div>
                                                {showContinue
                                                    ? <div className="gi-input mb-40">
                                                        <a href="#!" onClick={this.resendCode}>
                                                            {IntlMessages('resend_code')}
                                                        </a>
                                                    </div>
                                                    : ''
                                                }
                                                <div className="gi-btn-group d-none d-sm-flex">
                                                    <ButtonComponent value={this.details} previousRouting={'page1'} nextRouting={'page2'} buttonName={'button_continue'} showButton={showContinue} />

                                                    {/* verify button  */}
                                                    {showContinue ? <button className="gi-next-btn" type="button" disabled={disabled} onClick={this.onCheckVerify}>
                                                        {IntlMessages('button_verify')}
                                                    </button> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="gi-from-right">
                                            <RightInformation info1={'verification_info'} />
                                            <div className="gi-btn-group d-flex d-sm-none">
                                                <ButtonComponent value={this.details} previousRouting={'page1'} nextRouting={'page2'} buttonName={'button_continue'} showButton={showContinue} />

                                                {/* verify button  */}
                                                {showContinue
                                                    ? <button className="gi-next-btn" type="button" disabled={disabled} onClick={this.onCheckVerify}>
                                                        {IntlMessages('button_verify')}
                                                    </button>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withAuth0(PersonalDetailsPhoneVerification)