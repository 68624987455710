module.exports = {
    'privacy_policy': 'Privacy Policy',

    'privacy_policy_t1': 'Giraffe Invest, Inc.',
    'privacy_policy_t2': 'Effective Date: April 1st, 2022.',
    'privacy_policy_t3': 'Your Privacy Is Important to Us',
    'privacy_policy_t4': `Giraffe Invest, Inc. ("Giraffe") is committed to safeguarding the use of personal information that we obtain as your Registered Investment Advisor ("RIA"), as described here in our Privacy Policy (the "Policy"). Giraffe does not sell your non-public personal information to anyone. Nor do we provide such information to others except for discrete and reasonable business purposes in connection with the servicing and management of our relationship with you, as discussed below. Giraffe does not share non-public personal information with non affiliated third parties unless a Client specifically consents to the disclosure. Any such sharing would require an "opt-in" by signing a statement making an affirmative declaration of permission to disclose such information.`,
    'privacy_policy_t5': `For the purposes of this Policy, the term "Giraffe" includes Giraffe's affiliates, partners, agents, and employees. The term "Client" refers to an individual who allows Giraffe to serve as your RIA by signing the Giraffe Client Agreement.`,
    'privacy_policy_t6': `RIAs must share some of your personal information in the course of servicing your account. Federal and State laws give you the right to limit some of this sharing and require RIAs to disclose how we collect, share, and protect your personal information. Details of our approach to privacy and how your personal non-public information is collected and used are set forth in this Policy.`,
    'privacy_policy_t7': `Information We Collect`,
    'privacy_policy_t8': `Giraffe collects non-public personal information from Clients in order to ensure high-quality service.`,
    'privacy_policy_t9': `Client Information.`,
    'privacy_policy_t10': `When opening up an account with Giraffe, you must provide Giraffe with the following information so we can ensure high-quality service:`,
    'privacy_policy_t11': `your full legal name, address(es), email address(es),phone number(s), date of birth, Social Security or taxpayer identification number;`,
    'privacy_policy_t12': `your investment experience, goals, and activity; and`,
    'privacy_policy_t13': `your account information (including other institutions), income and expenses, assets and liabilities.`,
    'privacy_policy_t14': `Information from Other Sources.`,
    'privacy_policy_t15': `Giraffe also collects the following Client information from other sources:`,
    'privacy_policy_t16': `any custody, brokerage, and advisory agreements, other advisory agreements and legal documents;`,
    'privacy_policy_t17': `any transactional information with us or others, account applications and forms, investment questionnaires and suitability documents; and`,
    'privacy_policy_t18': `any other information we may need to adequately service your account.`,
    'privacy_policy_t19': `Cookies and Pixels.`,
    'privacy_policy_t20': `Whenever you visit our website or use our services, Giraffe may use cookies or pixels to store some personal information such as your IP address or browsing history. The collection of this information is intended to optimize website performance and user experience. You have the option of not accepting the use of cookies or pixels on Giraffe's website, but doing so could impact website performance.`,
    'privacy_policy_t21': `How We Use Your Information`,
    'privacy_policy_t22': `Business Use.`,
    'privacy_policy_t23': `Giraffe uses your non-public personal information for a variety of business purposes such as to deliver financial advice and services, offer customer support, optimize our products and features, maximize customer satisfaction with our services, and ensure compliance with the rules and regulations of federal bodies such as the Securities and Exchange Commission and Financial Industry Regulatory Authority.`,
    'privacy_policy_t24': `Other Uses.`,
    'privacy_policy_t25': `We may also use your non-public personal information to contact you regarding services or resources in which you may be interested. If you no longer wish to receive these communications, please follow the opt-out instructions below.`,
    'privacy_policy_t26': `How We Share Your Information`,
    'privacy_policy_t27': `An RIA shares Client personal information to effectively implement its services. In the section below, we list some reasons why we may share your personal information.`,
    'privacy_policy_t28': `Servicing Our Clients.`,
    'privacy_policy_t29': `We may share non-public personal information with non-affiliated third parties (such as administrators, brokers, custodians, regulators, credit agencies, consultants, and other financial institutions) as necessary for us to provide agreed upon services to you, consistent with applicable law, including but not limited to: processing transactions; general account maintenance; responding to regulators or legal investigations; and credit reporting. Our agreements with these third parties require them to keep your information confidential.`,
    'privacy_policy_t30': `Legal Purposes.`,
    'privacy_policy_t31': `We may share your non-public personal information when doing so is required by law such as when we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, front running or scaping, situations involving potential threats to the physical safety of any person, or suspected violations of our Terms of Use or customer agreements, in response to the service of legal process, or as part of a report to a credit bureau.`,
    'privacy_policy_t32': `Marketing Purposes.`,
    'privacy_policy_t33': `Giraffe does not disclose and does not intend to disclose personal information with non-affiliated third parties to offer you services. Certain laws may give us the right to share your personal information with financial institutions where you are a customer and where Giraffe or the Client has a formal agreement with the financial institution. We will only share information for purposes of servicing your accounts, not for marketing purposes.`,
    'privacy_policy_t34': `Authorized Users.`,
    'privacy_policy_t35': `Your non-public personal information may be disclosed to you and persons that we believe to be your authorized agent(s) or representative(s). Clients have the right to limit the disclosure of information to authorized users.`,
    'privacy_policy_t36': `Information About Former Clients.`,
    'privacy_policy_t37': `Giraffe does not disclose and does not intend to disclose non-public personal information to non-affiliated third parties with respect to persons who are no longer our Clients.`,
    'privacy_policy_t38': `Opt-Out Information`,
    'privacy_policy_t39': `Pursuant to federal law, Giraffe provides Clients the choice of opting out of certain uses and disclosures of your non-public personal information.`,
    'privacy_policy_t40': `Declining to Provide.`,
    'privacy_policy_t41': `You may always decline to provide Giraffe with non-public personal information, but doing so may disqualify you from the use of Giraffe services that require such information.`,
    'privacy_policy_t42': `Withdrawing Consent.`,
    'privacy_policy_t43': `Clients who have consented to`,
    'privacy_policy_t44': `Giraffe's use of your non-public personal information may withdraw that consent at any time by contacting us by phone or email. If a Client opts out of providing non-public personal information, they will be unable to continue using Giraffe's services. Before using any non-public personal information for new purposes, we will provide you information about that new opportunity.`,
    'privacy_policy_t45': `Email and Telephone Communications.`,
    'privacy_policy_t46': `Giraffe may use your non-public personal information to communicate with you about certain opportunities or services. Clients have the option of opting out of promotional or marketing emails by following the "Unsubscribe" instructions in those emails. But you cannot opt out of administrative communications, including emails that contain account statements, updates to Terms of Use or this Policy, or notifications regarding changes to your account.`,
    'privacy_policy_t47': `How to Access and Change of Your Information`,
    'privacy_policy_t48': `Once you become a Client, you can access your account profile, view any stored information, and change that information. If you have any problems with accessing or changing your information, you can contact us at`,
    'privacy_policy_t49': `(860) 878-7180 or`,
    'privacy_policy_t50': `legal@giraffeinvest.com`,
    'privacy_policy_t51': `How to Request Deletion of Your Information`,
    'privacy_policy_t52': `Clients may choose to close their Giraffe account by following the instructions on our website or contacting us directly. Please note that Giraffe retains information from deleted accounts as required for legal, regulatory, and security purposes.`,
    'privacy_policy_t53': `How We Protect Your Information`,
    'privacy_policy_t54': `Safeguards.`,
    'privacy_policy_t55': `To safeguard your personal information from unauthorized access and use, we maintain physical, procedural, and electronic security measures. These include such safeguards as secure passwords, encrypted file storage, and a secure office environment. Our technology vendors provide security and access control over personal information and have policies over the transmission of data. Our associates are trained on their responsibilities to protect our Clients' personal information.`,
    'privacy_policy_t56': `Third Parties.`,
    'privacy_policy_t57': `We require third parties that assist in providing our services to you to protect the personal information they receive from us.`,
    'privacy_policy_t58': `Closed Accounts.`,
    'privacy_policy_t59': `If you choose to close your account or your account is terminated with us, Giraffe will continue to adhere to the practices outlined in this Policy.`,
    'privacy_policy_t60': `Changes to Our Privacy Policy`,
    'privacy_policy_t61': `We will send you a copy of this Policy annually for as long as you maintain an ongoing relationship with us. Periodically we may revise this Policy and will provide you with a revised policy if the changes materially alter the previous Privacy Policy. We will not, however, revise our Privacy Policy to permit the sharing of non-public personal information other than as described in this notice unless we first notify you and provide you with an opportunity to prevent the information sharing.`,
    'privacy_policy_t62': `Privacy Rights of California Residents`,
    'privacy_policy_t63': `Under the California Consumer Privacy Act, Clients who are California residents may request and obtain from Giraffe information concerning your non-public personal information that we have collected in the past twelve months. You have the right to request that we permanently delete this non-public personal information. Please note that Giraffe may deny this deletion request for information required for legal, regulatory, and security purposes.`,
    'privacy_policy_t64': `Clients who are California residents can request and obtain from us twice a year, free of charge, information related to Personal Information we have collected in the twelve months preceding the request. The California Consumer Privacy Act ("Act") also gives Clients the right to request the permanent deletion of Personal Information. Clients can request information related to Personal Information we have collected about them and/or request deletion of their Personal Information by following the "Opt-Out" procedure above.`,
    'privacy_policy_t65': `The Act also permits Giraffe Clients who are California residents to request and obtain from Giraffe a list of third parties to whom we have disclosed non-public personal information for that third party's marketing purposes and the type of information disclosed to those parties. As stated above, Giraffe does not sell non-public personal information to third parties.`,
    'privacy_policy_t66': `Questions`,
    'privacy_policy_t67': `You may ask questions or voice any concerns, as well as obtain a copy of our current Privacy Policy by contacting us at`,
}