//default library
import React, { Component, Fragment } from 'react'
import Modal from "react-bootstrap/Modal";
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//api call
import { transferFund } from '../../middleware/TransferFundCall';

//custom function
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';
import { removeUndefined } from '../../service/utils/RemoveUndefineValue';

//custom component
import InputComponentCurrency from '../InputComponent/InputComponentCurrency';
import SelectModal from '../SelectComponent/SelectModal';
import TextError from '../ErrorComponent/TextError';
import LoadingModal from './LoadingModal';
import SuccessModal from './SuccessModal';

//image
import { ConsoleLog } from '../../service/utils/ConsoleLog';
import { MAX_NUMBER_100 } from '../../config/Config';
import IntlMessages from '../../helper/IntlMessages';
import IMAGES from '../../config/Images';
export class SellModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openSellModal: false, //open or close modal
            sellAmount: '', //sell amount
            fromAccount: [], //dropdown to portfolio
            portfolioValue: '', //dropdown value

            toAccountValue: '', //cash account value
            toAccountLabel: '', //cash account label

            errorMessage: '', //error message comes from backend
            errorFlag100: false, //error flag when amount less then MAX_NUMBER_100
            openLoadingModal: false, //loading modal
            openSuccessModal: false, //success modal
            openConfirmModal: false, //confirm modal
            errorFlag: false, //error flag when error message comes from backend 

            successMessage: 'bank_transfer', //successful transfer message
        }
    }

    UNSAFE_componentWillReceiveProps = async (props) => {
        await this.setState({
            openSellModal: props.openSellModal
        })
    }

    componentDidMount = async () => {

        let getFundLocation = this.props.getFundLocation
        let fromAccount = await removeUndefined(getFundLocation.map((e) => {
            if (e.type === 'Basket') {
                return {
                    value: `${e.id}|${e.type}`,
                    label: e.name
                }
            }
            else {
                return null
            }
        }))
        let toAccount = await removeUndefined(getFundLocation.map((e) => {
            if (e.type === 'Cash Account') {
                return {
                    value: `${e.id}|${e.type}`,
                    label: e.name
                }
            }
            else {
                return null
            }
        }))

        await this.setState({
            fromAccount: fromAccount,

            toAccountLabel: toAccount[0].label,
            toAccountValue: toAccount[0].value,
        })
    }

    //close modal
    onHandleClose = async () => {
        await this.setState({
            openSellModal: false, //close sell modal
            openConfirmModal: false, //close confirm modal
            openLoadingModal: false, //close loading modal
            openSuccessModal: false, //close success modal
            sellAmount: '', //clear sell amount
            portfolioValue: '', //clear dropdown value
            errorFlag: false, //set error flag to false
            errorMessage: '', //clear error message
        })
        await this.props.closeModal(false)
    }

    //close confirm modal
    onHandleCloseSuccess = async () => {
        await this.setState({
            openSuccessModal: false, //close confirm modal
            sellAmount: '', //clear sell amount
            portfolioValue: '', //clear dropdown value
            errorFlag: false, //set error flag to false
            errorMessage: '', //clear error message
        })
        await this.props.closeModal(false)
        await this.props.componentDidMount() //load total card component
    }

    //set amount
    setInputValue = async (value) => {
        await this.setState({
            sellAmount: value,
            errorMessage: '',
            errorFlag: false,
            errorFlag100: false,
        })
    }

    //portfolio dropdown value
    setPortfolioValue = async (value) => {
        await this.setState({
            portfolioValue: value
        })
    }

    //enable and disable transfer button
    checkValue = (value) => {
        const { sellAmount, portfolioValue } = value
        if (sellAmount && portfolioValue) {
            return false
        }
        else {
            return true
        }
    }

    //open confirm modal
    openConfirmModal = async () => {
        const { sellAmount } = this.state
        if (sellAmount < MAX_NUMBER_100) {
            await this.setState({
                errorFlag100: true,
                errorFlag: false,
            })
        }
        else {
            await this.setState({
                openConfirmModal: true,
                openSellModal: false
            })
        }
    }

    //sell transfer
    sellTransfer = async () => {
        const { sellAmount, portfolioValue, toAccountValue } = this.state
        const { getAccessTokenSilently } = this.props.auth0

        //if true set loading modal
        await this.setState({
            openLoadingModal: true, //loading modal
            openConfirmModal: false //confirm modal
        })

        // request modal 
        const data = {
            "amount": sellAmount,
            "fromAccount": {
                "id": portfolioValue.split('|')[0],
                "type": portfolioValue.split('|')[1]
            },
            "toAccount": {
                "id": toAccountValue.split('|')[0],
                "type": toAccountValue.split('|')[1]
            }
        }
        const accessToken = await getAccessTokenSilently() //get access token
        transferFund(accessToken, data)
            .then(async (res) => {
                let successMessage = ''

                if (res.data?.transferType === 'BASKET_BUY' || res.data?.transferType === 'BASKET_SELL') {
                    successMessage = res.data?.isOnHold ? 'fund_transfer_market_close' : 'fund_transfer_market_open'
                }
                else {
                    successMessage = 'bank_transfer'
                }

                await this.setState({
                    openLoadingModal: false, //loading modal
                    openSuccessModal: res.data?.isSuccessful, //success modal
                    successMessage: successMessage, //success message
                    openSellModal: res.error, //fund modal
                    errorMessage: res.message, //error message
                    errorFlag: res.error //error flag
                })
                await this.props.closeModal(res.error) //close fund modal from parent component
            })
            .catch(err => {
                ConsoleLog(' ', err)
            })
    }

    render() {
        const { openSellModal, fromAccount, errorFlag, sellAmount, portfolioValue,
            openLoadingModal, openSuccessModal, openConfirmModal, errorMessage, errorFlag100, successMessage } = this.state

        let isDisabled = this.checkValue(this.state)

        return (
            <Fragment>
                {/* Fund Modal */}
                <Modal
                    centered
                    show={openSellModal}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-g-new-fund-modal">
                    <Modal.Body>
                        <button className="btn-close" type="button" onClick={this.onHandleClose} aria-label="Close"></button>
                        <div className="gi-g-new-fund-modal-card">
                            {/* left image */}
                            <div className="gi-g-new-fund-modal-card-left">
                                <div>
                                    <img src={IMAGES.GIRAFFE_MODAL_ICON} alt="" />
                                    <h5 className="d-none d-md-block">
                                        {IntlMessages('sell_stock')}
                                    </h5>
                                    <h5 className="d-block d-md-none">
                                        {IntlMessages('sell_stock')}
                                    </h5>
                                    <div className="gi-g-p-tag">
                                        <p className="text-start text-justify">
                                            {IntlMessages('sell_info_text1', { currency: currencyFormatter(5) })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* right content */}
                            <div className="gi-g-new-fund-modal-card-right">
                                {/* input component */}
                                <div className="row">
                                    <div className="col-12">
                                        {/* amount */}
                                        <div className={`gi-g-new-input mb-30 ${errorFlag || errorFlag100 ? 'gi-input-error' : ''}`}>
                                            <label className="form-label">
                                                {IntlMessages('fund_select_amount')}
                                            </label>
                                            <div className="gi-currency-input">
                                                <InputComponentCurrency placeholder={'placeholder_currency_100'} name={'sellAmount'} inputValue={this.setInputValue} />
                                            </div>
                                            <TextError message2={errorMessage} display={errorFlag ? 'block' : 'none'} />
                                            <TextError message={'error_minimum_amount_sell'} display={errorFlag100 ? 'block' : 'none'} />
                                        </div>

                                        {/* from */}
                                        <div className="gi-g-new-input gi-select mb-30">
                                            <div>
                                                <label className="form-label">
                                                    {IntlMessages('sell_portfolio')}
                                                </label>
                                                <SelectModal option={fromAccount} selectValue={this.setPortfolioValue} placeholder={'placeholder_select_value'} value={portfolioValue} />
                                            </div>
                                        </div>

                                        {/* to */}
                                        <div className="gi-g-new-input mb-30">
                                            <div>
                                                <label className="form-label">
                                                    {IntlMessages('withdraw_account')}
                                                </label>
                                                <div className="form-control-text">
                                                    {IntlMessages('giraffe_cash_account')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* button */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-g-new-btn-groupe mt-30">
                                            <button className="gi-g-new-cancel" type="button" onClick={this.onHandleClose}>
                                                {IntlMessages('button_cancel')}
                                            </button>
                                            <button className="gi-g-new-transfer" type="button" disabled={isDisabled || errorFlag || errorFlag100} onClick={this.openConfirmModal}>
                                                {IntlMessages('button_next')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* confirm Modal */}
                <Modal
                    centered
                    show={openConfirmModal}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-g-new-fund-modal">
                    <Modal.Body>
                        <button className="btn-close" type="button" aria-label="Close" onClick={this.onHandleClose}></button>
                        <div className="gi-g-new-fund-modal-card">
                            {/* left image */}
                            <div className="gi-g-new-fund-modal-card-left">
                                <div>
                                    <img src={IMAGES.GIRAFFE_MODAL_ICON} alt="" />
                                    <h5 className="d-none d-md-block">
                                        {IntlMessages('sell_stock')}
                                    </h5>
                                    <h5 className="d-block d-md-none">
                                        {IntlMessages('sell_stock')}
                                    </h5>
                                    <div className="gi-g-p-tag">
                                        <p className="text-start text-justify">
                                            {IntlMessages('sell_info_text1', { currency: currencyFormatter(5) })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* right content */}
                            <div className="gi-g-new-fund-modal-card-right">
                                {/* input */}
                                <div className="row">
                                    <div className="col-12">
                                        {/* header */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <h5>
                                                {IntlMessages('fund_confirm_transaction')}
                                            </h5>
                                        </div>
                                        {/* amount */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <label>
                                                {IntlMessages('transfer_amount')}
                                            </label>
                                            <h5>
                                                {`$ ` + currencyFormatter(sellAmount).replace('$', '')}
                                            </h5>
                                        </div>
                                        {/* from */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <label>
                                                {IntlMessages('sell_portfolio')}
                                            </label>
                                            <h5>
                                                {fromAccount.length > 0
                                                    ? fromAccount.map((e) => {
                                                        if (e.value === portfolioValue) {
                                                            return e.label
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    })
                                                    : ''}
                                            </h5>
                                        </div>
                                        {/* to */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <label>
                                                {IntlMessages('withdraw_account')}
                                            </label>
                                            <h5>
                                                {IntlMessages('giraffe_cash_account')}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                {/* button */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-g-new-btn-groupe mt-30">
                                            <button className="gi-g-new-cancel" type="button" onClick={this.onHandleClose}>
                                                {IntlMessages('button_cancel')}
                                            </button>
                                            <button className="gi-g-new-transfer" type="button" onClick={this.sellTransfer}>
                                                {IntlMessages('button_confirm')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Loading Modal */}
                <LoadingModal openLoadingModal={openLoadingModal} title={'fund_preparing'} onHandleClose={this.onHandleClose} />

                {/* SuccessModal */}
                <SuccessModal openSuccessModal={openSuccessModal} successMessage={successMessage} onHandleClose={this.onHandleClose} onHandleCloseSuccess={this.onHandleCloseSuccess} />
            </Fragment>
        )
    }
}

export default withAuth0(SellModal)