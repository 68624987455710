//default library
import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import DashboardLogoComponent from '../../components/LogoComponent/DashboardLogoComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

//image import
import IMAGES from '../../config/Images';

//api call
import { getBasketCompanyCount } from '../../middleware/CreateBasket';
import IntlMessages from '../../helper/IntlMessages';


export class CompanyValueCount extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cancelButton: false, //click cancel button
            viewPortfolio: false, //click cancel button
            totalCompanyCount: 0, //total company count 
            excludedCompanyCount: 0, //exclude company count 
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        let companyCount = await getBasketCompanyCount(accessToken)
        await this.setState({
            totalCompanyCount: companyCount.totalCompanies,
            excludedCompanyCount: companyCount.excludedCompanies,
            isLoading: false,
        })
    }

    //click cancel button
    cancelButton = async () => {
        await this.setState({
            cancelButton: true
        })
    }

    //click view portfolio button
    viewPortfolio = async () => {
        await this.setState({
            viewPortfolio: true
        })
    }

    render() {
        const { cancelButton, viewPortfolio, totalCompanyCount, excludedCompanyCount, isLoading } = this.state
        return (
            <Fragment>
                {isLoading ? <LoadingComponent /> : ''}

                {/* click cancel button */}
                {cancelButton ? <Redirect push to='/dashboard' /> : ''}

                {/* click cancel button */}
                {viewPortfolio ? <Redirect push to='/create-basket/basket-summary' /> : ''}

                {/* page content start */}
                <section className="gi-c-main-section">
                    <header className="gi-c-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-c-header-main">
                                        <div className="gi-c-header-left">
                                            <DashboardLogoComponent mobileView={true} />
                                        </div>
                                        <div className="gi-c-header-right">
                                            <div className="gi-sell-btn-group d-none d-md-flex">
                                                <button className="gi-sell-cancel-btn" type="button" onClick={this.cancelButton}>
                                                    {IntlMessages('button_cancel')}
                                                </button>
                                                <button className="gi-sell-next-btn" type="button" onClick={this.viewPortfolio}>
                                                    {IntlMessages('view_portfolio')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section>
                        <div className="container">
                            <div className="gi-sell-btn-group d-flex flex-wrap justify-content-end d-md-none mt-20 mb-20">
                                <button className="gi-sell-cancel-btn gi-sell-cancel-btn-header" type="button" onClick={this.cancelButton}>
                                    {IntlMessages('button_cancel')}
                                </button>
                                <button className="gi-sell-next-btn" type="button" onClick={this.viewPortfolio}>
                                    {IntlMessages('view_portfolio')}
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-c-heading">
                                        <h3>
                                            {IntlMessages('create_basket_text3')}
                                        </h3>
                                        <p>
                                            {IntlMessages('create_basket_text4')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9 mx-auto">
                                    <div className="gi-c-companies-card">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="gi-c-line-text">
                                                    <h5>-{excludedCompanyCount}</h5>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="gi-c-companies-card-top">
                                                    <h5>{totalCompanyCount}</h5>
                                                    <span>
                                                        {IntlMessages('basket_companies')}
                                                    </span>
                                                </div>
                                                <div className="gi-c-companies-card-bottom">
                                                    <img src={IMAGES.MARKET_LOGO} className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-4"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Fragment>
        )
    }
}

export default withAuth0(CompanyValueCount)