//default library
import React, { Component, Fragment } from 'react'
import Modal from "react-bootstrap/Modal";
import { Redirect } from 'react-router-dom';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//config import
import { LAST_NAME_REGEX, MAX_NUMBER_36, NAME_REGEX, SSN_REGEX } from '../../config/Config';
import { actionValue } from '../../config/DefaultValue';
import IntlMessages from '../../helper/IntlMessages';

//api call
import { updateUserData } from '../../middleware/UpdateCall';

//custom function
import AgeCalculator from '../../service/utils/AgeCalculator';
import { ConsoleLog } from '../../service/utils/ConsoleLog';
import { removeUndefined } from '../../service/utils/RemoveUndefineValue';

//custom component
import AutoComplete from '../AutoComplete/AutoComplete';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import InputComponentRadio from '../InputComponent/InputComponentRadio';
import InputComponentText from '../InputComponent/InputComponentText';
import MaskInput from '../InputComponent/MaskInput';
// import LabelComponent from '../LabelComponent/LabelComponent';
// import LoadingComponent2 from '../LoadingComponent/LoadingComponent2';
import SelectModal from '../SelectComponent/SelectModal';
import LoadingModal from './LoadingModal';

export class KYCErrorModal extends Component {



    disabled = true; // submit button disabled

    validationFlagArray = {} //validation flag array each field

    constructor(props) {
        super(props)
        this.state = {
            openKYCErrorModal: props.openKYCErrorModal, //open kyc error modal
            kycError: props.kycError, //kyc error in array
            countryList: props.countryList, //list of country

            redirectTo: false, //redirect to kycPending Page
            openLoadingModal: false, //loading modal open
            errorFlag: false, //address invalid
            ssnNumber: '', //ssn number edit
            dob: '', //dob edit
            addressAutoFill: '', //auto complete address
            postalCode: '', //postal code
            apartmentUnit: '', //apartment unit
            firstName: '', //first name edit
            lastName: '', //last name edit
            phoneNumber: '', //phone number edit
            citizenship: '', //citizenship edit
            taxPayer: '', //tax payer edit

            ssnNumber1: '', //ssn number edit
            dob1: '', //dob edit
            addressAutoFill1: '', //auto complete address
            phoneNumber1: '', //phone number edit
            countryLabel: '', //counter label

            list: [
                {
                    label: 'Yes',
                    value: 'true'
                },
                {
                    label: 'No',
                    value: 'false'
                }
            ],
            errorCodeArray: ['K101', 'K102', 'K103', 'K104', 'K108', 'K110', 'K111'], //list of all error code of KYC
            handleKYC: true //check kyc error is handle or not
        }
    }

    componentDidMount = async () => {
        const { errorCodeArray, kycError } = this.state

        let handleKYC = await removeUndefined(errorCodeArray.map((e) => {
            return kycError.map((v) => {
                if (e === v) {
                    return true
                }
                else {
                    return null
                }
            })[0]
        }))

        await this.setState({
            handleKYC: handleKYC[0] ?? false
        })
    }

    //close modal
    onHandleClose = () => {
        this.setState({
            openKYCErrorModal: false
        })
    }

    //submit verify data
    submitData = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            openLoadingModal: true,
            openKYCErrorModal: false
        })
        const accessToken = await getAccessTokenSilently() //get access token
        await updateUserData(accessToken, this.state)
            .then(async (res) => {
                if (res.data.isKycProcessing) {
                    await this.setState({
                        redirectTo: true
                    })
                }
                // if (res.data) {
                await this.props.componentDidMount()
                await this.setState({
                    openLoadingModal: false
                })
                // }
            })
            .catch(err => {
                ConsoleLog(' ', err)
            })
    }

    //contact us
    contactUs = async () => {
        window.location = 'mailto:giraffeimpact@support.com'
    }

    //check field validation
    checkValidation = async () => {
        this.disabled = true;
        const flagArray = Object.values(this.validationFlagArray) // set value in flagArray
        // if any value in flagArray is true then submit button is disabled else enabled
        for (let i = 0; i < flagArray.length; i++) {
            if (flagArray[i]) {
                return this.disabled = true
            }
            else {
                this.disabled = false
            }
        }
    }

    //set ssn number
    setSsnNumberValue = async (value) => {
        this.errorFlagSSN = false
        this.validationFlagArray['disabledSSN'] = true
        await this.setState({
            ssnNumber1: value
        })
        let ssn = value.split('-')
        let ssn1 = ssn[0] ?? ''
        let ssn2 = ssn[1] ?? ''
        let ssn3 = ssn[2] ?? ''
        if (!isNaN(ssn1) && !isNaN(ssn2) && !isNaN(ssn3)) {
            if (value.length === 11) {
                if (!value.match(SSN_REGEX)) {
                    this.errorFlag = true
                } else {
                    this.validationFlagArray['disabledSSN'] = false
                }
                await this.setState({
                    ssnNumber: `${ssn1}${ssn2}${ssn3}`,
                })
            }
        }
    }

    //set dob
    setDobValue = async (value) => {
        this.errorFlagDOB = false
        this.validAgeFlag = false
        this.validationFlagArray['disabledDOB'] = true
        await this.setState({
            dob1: value
        })
        let date = value.split('/')
        let mm = date[0] ?? ''
        let dd = date[1] ?? ''
        let yyyy = date[2] ?? ''
        if (mm.length === 2 && dd.length === 2 && yyyy.length === 4 && !isNaN(mm) && !isNaN(dd) && !isNaN(yyyy)) {
            const date = `${mm}/${dd}/${yyyy}`
            if ((new Date(date) < new Date('1/1/1901')) || (new Date(date) > new Date(Date.now()))) {
                this.errorFlagDOB = true
            }
            else if (AgeCalculator(new Date(date)) < 18 && (new Date(date) < new Date(Date.now()))) {
                this.validAgeFlag = true
            }
            else {
                this.validationFlagArray['disabledDOB'] = false
            }
            await this.setState({
                dob: `${yyyy}-${mm}-${dd}`,
            })
        }
    }

    //auto complete address
    setAutoCompleteValue = async (autoCompleteValue, loading) => {
        const { addressAutoFill, errorFlag } = this.state
        this.validationFlagArray['disabledAddress'] = true;
        if (!errorFlag && !!addressAutoFill) {
            this.validationFlagArray['disabledAddress'] = false
        }
        await this.setState({
            addressAutoFill: autoCompleteValue.addressAutoFill,
            postalCode: autoCompleteValue.postalCode,
            errorFlag: autoCompleteValue.errorFlag,
        });

    }

    setApartmentUnit = async (value) => {
        this.validationFlagArray['disabledApartment'] = true;
        if (value.length > MAX_NUMBER_36) {
            this.validationFlagArray['disabledApartment'] = true
        }
        else {
            this.validationFlagArray['disabledApartment'] = false
        }
        await this.setState({
            apartmentUnit: value
        });
    }

    //set full name
    setFirstName = async (value) => {
        const { lastName } = this.state

        this.validationFlagArray['disabledFirstName'] = true; //set flag true

        if (lastName.length > MAX_NUMBER_36 || lastName.length === 0) {
            this.validationFlagArray['disabledLastName'] = true //check last name field length
        }

        //check first name length set flag according to
        if (value.length > MAX_NUMBER_36 || value.length === 0) {
            this.validationFlagArray['disabledFirstName'] = true
        }
        else {
            this.validationFlagArray['disabledFirstName'] = false
        }

        await this.setState({
            firstName: value
        });
    }

    setLastName = async (value) => {
        const { firstName } = this.state

        this.validationFlagArray['disabledLastName'] = true; //set flag true

        if (firstName.length > MAX_NUMBER_36 || firstName.length === 0) {
            this.validationFlagArray['disabledFirstName'] = true //check first name field length
        }
        //check first name length set flag according to

        if (value.length > MAX_NUMBER_36 || value.length === 0) {
            this.validationFlagArray['disabledLastName'] = true
        }
        else {
            this.validationFlagArray['disabledLastName'] = false
        }

        await this.setState({
            lastName: value
        });
    }

    //set phone number
    setPhoneNumber = async (value) => {
        this.validationFlagArray['disabledPhoneNumber'] = true

        await this.setState({
            phoneNumber1: value
        })
        let part1 = value.split('(')
        if (part1.length === 2) {
            let part2 = part1[1].split(')')
            let part3 = part2[1].split('-')
            let num = `${part2[0]}${part3[0].trim()}${part3[1].trim()}`
            if (!isNaN(num)) {
                this.validationFlagArray['disabledPhoneNumber'] = false

                await this.setState({
                    phoneNumber: num
                })
            }
        }
    }

    //set citizenship
    setCitizenship = async (value) => {
        const { countryList } = this.state
        //validation flag
        this.validationFlagArray['disabledCitizenship'] = false
        this.validationFlagArray['disabledTaxPayer'] = false
        //remove undefined
        let countryLabel = await removeUndefined(countryList.map(e => {
            if (e.value === value) {
                return e.label
            }
            else {
                return null
            }
        }))

        if (countryLabel[0] !== 'United States') {
            this.validationFlagArray['disabledTaxPayer'] = true
        }
        await this.setState({
            citizenship: value,
            countryLabel: countryLabel[0]
        })
    }

    setTaxPayerValue = async (value) => {
        this.validationFlagArray['disabledTaxPayer'] = false
        await this.setState({
            taxPayer: value.fieldValue
        })
    }

    render() {
        const { openKYCErrorModal, openLoadingModal, kycError, postalCode, errorFlag, apartmentUnit, redirectTo,
            firstName, lastName, countryList, countryLabel, taxPayer, handleKYC } = this.state
        this.checkValidation()

        return (
            <Fragment>
                {redirectTo ? <Redirect push to={`/kycPending`} /> : ''}
                {/* Single Error Modal */}
                <Modal
                    // centered
                    show={openKYCErrorModal}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="error-modal">
                    <Modal.Body>
                        <div className="error-modal-body">
                            <div className="error-modal-text">
                                {handleKYC
                                    ? <Fragment>
                                        {kycError.length > 1 ?
                                            <h5>
                                                {IntlMessages('kyc_trouble_data')}
                                            </h5>
                                            : ''
                                        }

                                        {kycError.length > 0 ? kycError.map((e, i) => {
                                            //for address not verify
                                            if (e === 'K101') {
                                                return (
                                                    <div key={i}>
                                                        {kycError.length === 1
                                                            ? <h5>
                                                                {IntlMessages('kyc_address')}
                                                            </h5>
                                                            : ''
                                                        }
                                                        <p className={`${kycError.length > 1 ? 'error-modal-text2' : ''}`}>{IntlMessages('kyc_address_label')}</p>
                                                        <div className="row">
                                                            <div className="col-10 mx-auto">
                                                                <div className="gi-g-new-input mb-20">
                                                                    <AutoComplete inputValue={this.setAutoCompleteValue} textCenter={true} />
                                                                    <ErrorComponent message={'error_invalid_address'} dashboard={true} display={errorFlag ? 'block' : 'none'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-md-n2">
                                                            <div className="col-md-6 offset-1">
                                                                <p className='error-modal-text2'>{IntlMessages('kyc_apartment_label')}</p>
                                                                <div className="gi-g-new-input mb-20">
                                                                    <InputComponentText name={'apartmentUnit'} placeholder={'placeholder_apartment_unit'} inputValue={this.setApartmentUnit} textCenter={true} />
                                                                    <ErrorComponent message={['error_max_length', { number: 36 }]} dashboard={true} display={apartmentUnit.length > MAX_NUMBER_36 ? 'block' : 'none'} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <p className='error-modal-text2'>{IntlMessages('personal_page4_label3')}</p>
                                                                <div className={`gi-g-new-input mb-20 ${postalCode ? 'valid-field' : ''}`}>
                                                                    <InputComponentText value={postalCode} name={'postalCode'} placeholder={'placeholder_zip_code'} readOnly={true} textCenter={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            //for ssn number not verify
                                            if (e === 'K102') {
                                                return (
                                                    <div key={i}>
                                                        {kycError.length === 1
                                                            ? <h5>
                                                                {IntlMessages('kyc_ssn')}
                                                            </h5>
                                                            : ''
                                                        }
                                                        <p className={`${kycError.length > 1 ? 'error-modal-text2' : ''}`}>{IntlMessages('kyc_ssn')}</p>
                                                        <div className="row">
                                                            <div className="col-6 mx-auto">
                                                                <div className="gi-g-new-input mb-20">
                                                                    <div className="gi-password">
                                                                        <MaskInput name={'ssnNumber'} placeholder={'placeholder_ssn'} inputValue={this.setSsnNumberValue} textCenter={true} />
                                                                    </div>
                                                                    <ErrorComponent message={'error_invalid_ssn_number'} dashboard={true} display={this.errorFlagSSN ? 'block' : 'none'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            //for dob not verify
                                            if (e === 'K103') {
                                                return (
                                                    <div key={i}>
                                                        {kycError.length === 1
                                                            ? <h5 >
                                                                {IntlMessages('kyc_dob')}
                                                            </h5>
                                                            : ''
                                                        }
                                                        <p className={`${kycError.length > 1 ? 'error-modal-text2' : ''}`}>{IntlMessages('kyc_dob_label')}</p>
                                                        <div className="row">
                                                            <div className="col-6 mx-auto">
                                                                <div className="gi-g-new-input mb-20">
                                                                    <MaskInput name={'dateOfBirth'} placeholder={'placeholder_dob'} inputValue={this.setDobValue} textCenter={true} />
                                                                    <ErrorComponent message={'error_not_valid_dob'} dashboard={true} display={this.errorFlagDOB ? 'block' : 'none'} />
                                                                    <ErrorComponent message={'error_dob_under_18'} dashboard={true} display={this.validAgeFlag ? 'block' : 'none'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            //for name not verify
                                            if (e === 'K104' || e === 'K110') {
                                                return (
                                                    <div key={i}>
                                                        {kycError.length === 1
                                                            ? <h5 >
                                                                {IntlMessages('kyc_name')}
                                                            </h5>
                                                            : ''
                                                        }
                                                        <p className={`${kycError.length > 1 ? 'error-modal-text2' : ''}`}>{IntlMessages('personal_page2_label1')}</p>
                                                        <div className="row">
                                                            <div className="col-6 mx-auto">
                                                                <div className="gi-g-new-input mb-20">
                                                                    <InputComponentText name={'firstName'} placeholder={'placeholder_type_here'} inputValue={this.setFirstName} textCenter={true} />
                                                                    <ErrorComponent message={'error_max_length_36_name'} dashboard={true} display={firstName.length > MAX_NUMBER_36 ? 'block' : 'none'} />
                                                                    <ErrorComponent message={'error_first_name'} dashboard={true} display={firstName && !firstName.match(NAME_REGEX) ? 'block' : 'none'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className='error-modal-text2'>{IntlMessages('personal_page2_label1')}</p>
                                                        <div className="row">
                                                            <div className="col-6 mx-auto">
                                                                <div className="gi-g-new-input mb-20">
                                                                    <InputComponentText name={'lastName'} placeholder={'placeholder_type_here'} inputValue={this.setLastName} textCenter={true} />
                                                                    <ErrorComponent message={'error_max_length_36_name'} dashboard={true} display={lastName.length > MAX_NUMBER_36 ? 'block' : 'none'} />
                                                                    <ErrorComponent message={'error_last_name'} dashboard={true} display={lastName && !lastName.match(LAST_NAME_REGEX) ? 'block' : 'none'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            //for phone number not verify
                                            if (e === 'K108') {
                                                return (
                                                    <div key={i}>
                                                        {kycError.length === 1
                                                            ? <h5 >
                                                                {IntlMessages('kyc_number')}
                                                            </h5>
                                                            : ''
                                                        }
                                                        <p className={`${kycError.length > 1 ? 'error-modal-text2' : ''}`}>{IntlMessages('kyc_number_label')}</p>
                                                        <div className="row">
                                                            <div className="col-6 mx-auto">
                                                                <div className="gi-g-new-input mb-20">
                                                                    <div className="gi-country-input">
                                                                        <MaskInput name={'phoneNumber'} placeholder={'placeholder_phone'} inputValue={this.setPhoneNumber} textCenter={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            //for citizenship not verify
                                            if (e === 'K111') {
                                                return (
                                                    <div key={i}>
                                                        {kycError.length === 1
                                                            ? <h5>
                                                                {IntlMessages('kyc_citizenship')}
                                                            </h5>
                                                            : ''
                                                        }
                                                        <p className={`${kycError.length > 1 ? 'error-modal-text2' : ''}`}>{IntlMessages('account_setting_citizenship')}</p>
                                                        <div className="row">
                                                            <div className="col-6 mx-auto">
                                                                <div className={`gi-g-new-input mb-20 `}>
                                                                    <SelectModal option={countryList} name={'bankName'} placeholder={'placeholder_country'} selectValue={this.setCitizenship} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {countryLabel !== 'United States' && countryLabel
                                                            ?
                                                            <Fragment>
                                                                <p className='error-modal-text2'>{IntlMessages('personal_page6_label')}</p>
                                                                {/* <LabelComponent name={'address'} labelName={'personal_page6_label'} /> */}
                                                                {actionValue.map((value, i) => {
                                                                    return (
                                                                        <div className="customize-radio form-check mb-20" key={i}>
                                                                            <label className={`form-check-label ${taxPayer === value.value ? 'active' : ''}`} htmlFor={value.label.props.id}>
                                                                                <InputComponentRadio id={value.label} name={'taxPayer'} value={value.value} title={value.label.props.id} text={value.label} inputValue={this.setTaxPayerValue} />
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Fragment>
                                                            : ''
                                                        }
                                                    </div>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }) : ''}

                                        <div className="text-center">
                                            <button className="contact-btn" type="button" onClick={this.submitData} disabled={this.disabled}>
                                                {IntlMessages('button_submit')}
                                            </button>
                                        </div>
                                    </Fragment>
                                    : <Fragment>
                                        {kycError.length > 0 ? kycError.map((e, i) => {

                                            if (e === 'K802') {
                                                return (
                                                    <Fragment key={i}>
                                                        <h5 >
                                                            {IntlMessages('error_multiple_account_holder')}
                                                        </h5>
                                                        <p>
                                                            {IntlMessages('error_contact_support2')}
                                                        </p>
                                                    </Fragment>
                                                )
                                            }

                                            else {
                                                return (
                                                    <Fragment key={i}>
                                                        <h5>
                                                            {IntlMessages('error_something_wrong')}
                                                        </h5>
                                                        <p>
                                                            {IntlMessages('error_contact_support')}
                                                        </p>
                                                    </Fragment>
                                                )
                                            }
                                        }) : ''}

                                        <div className="text-center">
                                            <button className="contact-btn" type="button" onClick={this.contactUs}>
                                                {IntlMessages('button_contact_us')}
                                            </button>
                                        </div>
                                    </Fragment>}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* loading modal */}
                <LoadingModal openLoadingModal={openLoadingModal} title={'modal_submit'} onHandleClose={this.onHandleClose} />
            </Fragment>
        )
    }
}

export default withAuth0(KYCErrorModal)