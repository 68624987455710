import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";
import IntlMessages from '../../helper/IntlMessages';

export class ErrorPendingModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openModelError: ''
        }
    }

    //close model
    onHandleClose = () => {
        this.setState({
            openModelError: false
        })
        this.props.closeErrorModal(false)
    }

    UNSAFE_componentWillReceiveProps = (props) => {
        this.setState({
            openModelError: props.openModelError
        })
    }

    render() {
        const { openModelError } = this.state
        const { h3, label, image } = this.props
        return (
            <Modal
                centered
                show={openModelError}
                onHide={this.onHandleClose}
                backdrop="static"
                keyboard={false} className="gi-d-error-modal gi-g-new-fund-modal"
            >
                <Modal.Body>
                    <button className="btn-close-white" type="button" onClick={this.onHandleClose}></button>

                    <div className="gi-g-new-transaction-card">
                        <div className="gi-g-new-transaction-text">
                            {image ?
                                <img src={image} className="img-fluid" alt="" />
                                : ''
                            }
                            <h5>
                                {typeof (h3) === 'object'
                                    ? IntlMessages(...h3)
                                    : IntlMessages(h3)
                                }
                            </h5>
                            <a href="#!" onClick={this.onHandleClose}>
                                {IntlMessages(label)}
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ErrorPendingModal