//default library
import React, { Component, Fragment } from 'react'

//custom component
import SelectActivity from '../SelectComponent/SelectActivity';
import PaginationNew from '../PaginationComponent/PaginationNew';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

//image import
import IMAGES from '../../config/Images';

//custom component
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';
import { DateFormatter } from '../../service/utils/DateFormatter';
import { DateRange } from '../../service/utils/DateFilter';

import { getTransaction } from '../../middleware/ActivityDetails';

import IntlMessages from '../../helper/IntlMessages';

import { taxesDropdown } from '../../config/DefaultValue';

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

export class TransactionsCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transactionData: props.transactionData, //all transaction data
            currentTransactionData: [], //current transaction data 
            totalTransactionData: [], //transaction data date wise
            currentPage: null,
            totalPages: null,
            dataLoad: false

        }
    }

    componentDidMount = async () => {
        const { totalTransactionData, transactionData } = this.state
        if (totalTransactionData?.length === 0) {
            await this.setState({
                totalTransactionData: transactionData,
                dataLoad: true
            })
        }
    }

    onPageChanged = async (data) => {
        const { totalTransactionData } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentTransactionData = await totalTransactionData?.slice(offset, offset + pageLimit);
        await this.setState({
            currentTransactionData: currentTransactionData,
            currentPage: currentPage,
            totalPages: totalPages
        });
    }

    onSelectValue = async (value) => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            dataLoad: false
        })
        let dateRange = await DateRange(value)//set default value date range

        const accessToken = await getAccessTokenSilently() //get access token
        let transactionData = await getTransaction(accessToken, dateRange)

        await this.setState({
            totalTransactionData: transactionData,
            dataLoad: true
        })
    }


    render() {
        const { currentTransactionData, totalTransactionData, dataLoad } = this.state
        return (
            <Fragment>
                {dataLoad ? '' : <LoadingComponent />}
                <div className="row gx-20">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="gi-d-ac-select-dropdown mb-30">
                                    <div>
                                        <label>
                                            {IntlMessages('table_range')}:
                                        </label>
                                    </div>
                                    <SelectActivity name='currentTransactionData' option={taxesDropdown} value={'past-week'} selectValue={this.onSelectValue} />
                                </div>
                            </div>
                            {/* <div className="col-sm-8 col-md-9">
                                pagination
                                {dataLoad
                                    ? <Pagination totalRecords={totalTransactionData?.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                                    : ''
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table gi-d-table-striped-02 mb-0" role="presentation">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <span>
                                                {IntlMessages('table_date')}
                                            </span>
                                        </th>
                                        <th scope="col">
                                            <span>
                                                {IntlMessages('table_description')}
                                            </span>
                                        </th>
                                        <th scope="col" className="amount-th">
                                            <span>
                                                {IntlMessages('table_amount')}
                                            </span>
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentTransactionData?.length > 0
                                            ? currentTransactionData.map((e, i) => {
                                                if (e.details) {
                                                    return (
                                                        <Fragment key={i}>
                                                            <tr className="bg-danger" >
                                                                <td>
                                                                    <span className="text-gi-white">{DateFormatter(e.date)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className="text-gi-white">{e.description}</span>
                                                                </td>
                                                                <td className="amount-td">
                                                                    <span className="text-gi-white-grey">{`+${currencyFormatter(e.amount)}`}</span>
                                                                </td>
                                                                <td>
                                                                    <a className="gi-d-down-btn collapsed" data-bs-toggle="collapse" href={`#collapseHistory-${i}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                                                        <img src={IMAGES.DOWN_ARROW_WHITE} alt="" className="down-collapsed" />
                                                                        <img src={IMAGES.UP_ARROW_WHITE} alt="" className="up-collapsed" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr className="collapse" id={`collapseHistory-${i}`}>
                                                                <td colSpan="12" className="px-0 pt-20 pb-20">
                                                                    <div className="gi-d-table-text">
                                                                        <p>{e.details}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Fragment key={i}>
                                                            <tr className={e.amount > 0 ? i % 2 === 0 ? 'bg-striped' : '' : 'bg-light'}>
                                                                <td>
                                                                    <span className="text-gi-bright-black">{DateFormatter(e.date)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className="text-gi-black fst-italic">{e.description}</span>
                                                                </td>
                                                                <td className="amount-td">
                                                                    <span className={e.amount > 0 ? 'text-gi-green' : 'text-gi-clay'}>{e.amount > 0 ? `+${currencyFormatter(e.amount)}` : currencyFormatter(e.amount)}</span>
                                                                </td>
                                                                <td>

                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                }

                                            })
                                            : <tr className="bg-striped">
                                                <td colSpan="12">
                                                    <span>
                                                        {/* <span className="text-gi-bright-black"> */}
                                                        {IntlMessages('table_not_range')}
                                                    </span>
                                                </td>
                                            </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                        {dataLoad
                            ? <PaginationNew totalRecords={totalTransactionData?.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                            : ''
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withAuth0(TransactionsCard)