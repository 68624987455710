//default library
import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import CurrentWeekSection from '../../components/SectionComponent/CurrentWeekSection'
import ScheduleSection from '../../components/SectionComponent/ScheduleSection'
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'

//custom function
import { removeUndefined } from '../../service/utils/RemoveUndefineValue'

// api call
import { getUserContent } from '../../middleware/GiraffeLearn'

export class GiraffeLearnPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            userName: `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`, //user name
            videoModal: false, //video modal
            isLoading: true,
            scheduleSection: {}, //week data
            userVideoList: {}, // user video content
            currentVideoIdentifier: '', //current video identifier
            identifier: '', //content identifier
        }
    }

    componentDidMount = async () => {
        window.scroll(0, 0)
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        const userContent = await getUserContent(accessToken)//user content
        let scheduleSection = {
            forWeek: userContent?.forWeek,
            nextWeek: userContent?.nextWeek,
            previousWeek: userContent?.previousWeek,
        }//change week

        const featuredVideo = await removeUndefined(userContent?.userContentVideoResponseList?.map(e => {
            if (e.featured) {
                return e
            }
            else {
                return null
            }
        }))//featured video for current week
        const thumbnailVideo = await removeUndefined(userContent?.userContentVideoResponseList
            ?.filter(e => {
                if (!e.featured) {
                    return e
                }
                else {
                    return null
                }
            })
            .sort((a, b) => a.priority - b.priority))//thumbnail video with sort priority

        const userVideoList = {
            forWeek: userContent?.forWeek,
            featuredVideo: featuredVideo ? featuredVideo[0] : [],
            thumbnailVideo: thumbnailVideo
        }//create object for video section
        await this.setState({
            isLoading: false,
            scheduleSection: scheduleSection,
            userVideoList: userVideoList,
            identifier: userContent.identifier,
        })
    }

    playVideo = async (identifier) => {
        await this.setState({
            videoModal: true,
            currentVideoIdentifier: identifier
        })
    }

    forWeek = async (forWeek) => {
        window.scroll(0, 0);
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true,
        })
        const accessToken = await getAccessTokenSilently() //get access token
        const userContent = await getUserContent(accessToken, forWeek) //user content
        let scheduleSection = {
            forWeek: userContent?.forWeek,
            nextWeek: userContent?.nextWeek,
            previousWeek: userContent?.previousWeek,
        }//change week

        const featuredVideo = await removeUndefined(userContent?.userContentVideoResponseList?.map(e => {
            if (e.featured) {
                return e
            }
            else {
                return null
            }
        }))//featured video for current week
        const thumbnailVideo = await removeUndefined(userContent?.userContentVideoResponseList
            ?.filter(e => {
                if (!e.featured) {
                    return e
                }
                else {
                    return null
                }
            })
            .sort((a, b) => a.priority - b.priority))//thumbnail video with sort priority

        const userVideoList = {
            forWeek: userContent?.forWeek,
            featuredVideo: featuredVideo ? featuredVideo[0] : [],
            thumbnailVideo: thumbnailVideo
        }//create object for video section
        await this.setState({
            isLoading: false,
            scheduleSection: scheduleSection,
            userVideoList: userVideoList,
            identifier: userContent.identifier,
        })
    }

    render() {
        const { isLoading, userName, videoModal, scheduleSection, userVideoList, currentVideoIdentifier, identifier } = this.state

        return (
            <Fragment>
                {videoModal
                    ? <Redirect push to={`/videos?cid=${identifier}&id=${currentVideoIdentifier}`} />
                    : ''
                }

                {isLoading
                    ? <LoadingComponent />
                    : <Fragment>
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-gift-heading">
                                    <PageHeader userName={userName} strong={`giraffe_learn_welcome`} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-right-sidebar-main">
                                    {/* <!-- Main content --> */}
                                    <div className="gi-g-right-sidebar-left">
                                        <div className="gi-g-details-card-left">
                                            {/* giraffe view week */}
                                            <ScheduleSection learnList={true} scheduleSection={scheduleSection}
                                                forWeek={this.forWeek} />

                                            {/* current week card */}
                                            {userVideoList ? <CurrentWeekSection userVideoList={userVideoList} playVideo={this.playVideo} scheduleSection={scheduleSection} forWeek={this.forWeek} /> : ''}

                                            {/* week change */}
                                            {/* <WeekChangeSection scheduleSection={scheduleSection} forWeek={this.forWeek} /> */}
                                        </div>
                                    </div>
                                    {/* Banner section */}
                                    <div className="gi-g-right-sidebar-right">
                                        <GiraffeLearnBannerSection />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withAuth0(GiraffeLearnPage)