const IMAGES = {
    /* SVG */
    LOCK_ICON: require('../assets/images/lock-icon.svg').default,
    LOADING_CAT: require('../assets/images/loading-cat.svg').default,
    HOME_ACTIVE: require('../assets/images/home-icons-active.svg').default,
    HOME_INACTIVE: require('../assets/images/home-icons-inactive.svg').default,
    BASKET_ACTIVE: require('../assets/images/line-chart-icons-active.svg').default,
    BASKET_INACTIVE: require('../assets/images/line-chart-icons-inactive.svg').default,
    ADD_PORTFOLIO_ACTIVE: require('../assets/images/add-portfolio-active.svg').default,
    ADD_PORTFOLIO_INACTIVE: require('../assets/images/add-portfolio-active.svg').default,
    ACTIVITY_ACTIVE: require('../assets/images/activity-active.svg').default,
    ACTIVITY_INACTIVE: require('../assets/images/activity-inactive.svg').default,
    ACCOUNT_SETTING_ACTIVE: require('../assets/images/account-settings-active.svg').default,
    ACCOUNT_SETTING_INACTIVE: require('../assets/images/account-settings-inactive.svg').default,
    TAXES_ACTIVE: require('../assets/images/taxes-documentation-active.svg').default,
    TAXES_INACTIVE: require('../assets/images/taxes-documentation-inactive.svg').default,
    MY_GIFT_ACTIVE: require('../assets/images/my-gifts-active.svg').default,
    MY_GIFT_INACTIVE: require('../assets/images/my-gifts-inactive.svg').default,
    BUY_GIFT_ACTIVE: require('../assets/images/buy-a-gift-active.svg').default,
    BUY_GIFT_INACTIVE: require('../assets/images/buy-a-gift-inactive.svg').default,
    GIRAFFE_VIEW_ACTIVE: require('../assets/images/giraffe-view-active.svg').default,
    GIRAFFE_VIEW_INACTIVE: require('../assets/images/giraffe-view-inactive.svg').default,
    BANK_CONNECT_ICON: require('../assets/images/bank-connect-icon.svg').default,
    CONFIRM_CHECK_ICON: require('../assets/images/confirm-check-img.svg').default,
    DASHBOARD_LOGO: require('../assets/images/dashboard-logo.svg').default,
    LOGO_DASHBOARD: require('../assets/images/logo.svg').default,
    VALUE_SEARCH_IMG: require('../assets/images/value-search-img.svg').default,
    WHITE_PLUS: require('../assets/images/white-plus.svg').default,
    BRIGHT_RIGHT_ICON: require('../assets/images/bright-right-icon.svg').default,
    RIGHT_LONG_ICON: require('../assets/images/right-long-arrow.svg').default,
    WHITE_RIGHT_ICON: require('../assets/images/white-right-icon.svg').default,
    WHITE_PLAY_ICON: require('../assets/images/white-play-icon.svg').default,
    // CLIMATE_CHANGE: require('../assets/images/climate-change.svg').default,
    WHITE_PREV_ICON: require('../assets/images/white-prev-icon.svg').default,
    WHITE_NEXT_ICON: require('../assets/images/white-next-icon.svg').default,
    BRIGHT_PLAY_ICON: require('../assets/images/bright-play-icon.svg').default,
    CALENDER_ICON: require('../assets/images/calendar-icon.svg').default,
    POLYGON_LEFT: require('../assets/images/polygon-left.svg').default,
    POLYGON_RIGHT: require('../assets/images/polygon-right.svg').default,
    GREEN_POLYGON_LEFT: require('../assets/images/green-polygon-left.svg').default,
    GREEN_POLYGON_RIGHT: require('../assets/images/green-polygon-right.svg').default,
    CLOSE_ICON: require('../assets/images/close-icon.svg').default,
    EXPERT_ACHIEVEMENT: require('../assets/images/expert-achievements.svg').default,
    NEW_GIRAFFE_LOGO: require('../assets/images/new-giraffe-logo.svg').default,
    DELETE_ICON: require('../assets/images/delete-icon.svg').default,
    PDF_ICON: require('../assets/images/pdf-icon.svg').default,
    PROCESSING_BAR: require('../assets/images/processing-bar.svg').default,
    COMPANY_LOGO: require('../assets/images/company-logo.svg').default,
    BRIGHT_REFRESH_ICON: require('../assets/images/bright-refresh-icon.svg').default,
    BRIGHT_PLUS_ICON: require('../assets/images/bright-plus-icon.svg').default,
    DOWN_ARROW_BLACK: require('../assets/images/down-arrow-black.svg').default,
    UP_ARROW_BLACK: require('../assets/images/up-arrow-black.svg').default,
    DOWN_ARROW_WHITE: require('../assets/images/down-arrow-white.svg').default,
    UP_ARROW_WHITE: require('../assets/images/up-arrow-white.svg').default,
    WHITE_CHECK_ICON: require('../assets/images/white-check-icon.svg').default,
    SUN_ICON: require('../assets/images/sun-icon.svg').default,
    VISIBILITY_ON: require('../assets/images/visibility-on.svg').default,
    VISIBILITY_OFF: require('../assets/images/visibility-off.svg').default,
    QUOTE_ICON: require('../assets/images/quote-icon.svg').default,
    GIRAFFE_SMALL_LOGO: require('../assets/images/girafee-small-img.svg').default,
    CHECK_ICON: require('../assets/images/check-icon.svg').default,
    WHITE_CLOSE_ICON: require('../assets/images/white-close-icon.svg').default,
    MARKET_LOGO: require('../assets/images/market-logo.svg').default,
    BACKGROUND_404: require('../assets/images/bg-404.svg').default,
    BACKGROUND_404_MOB: require('../assets/images/bg-404-mob.svg').default,
    HOUR_GLASS: require('../assets/images/hourglass.svg').default,
    TIME_OUT_LOGO: require('../assets/images/time-out-logo.svg').default,
    NEW_GIRAFFE_LOGO2: require('../assets/images/logo-giraffe.svg').default,
    VERTICAL_ICON: require('../assets/images/vertical-ellipsis-icon.svg').default,
    LINE_CHART: require('../assets/images/line-chart.svg').default,
    UNLINK_BANK_SVG: require('../assets/images/transaction-animation.svg').default,
    BANK_CONNECT_SVG: require('../assets/images/bank-connect.svg').default,
    GIRAFFE_MODAL_ICON: require('../assets/images/modal-giraffe-icon.svg').default,
    PENDING_BANK_IMAGE: require('../assets/images/pending-bank-small.svg').default,
    PENDING_LINE_CHART_SVG: require('../assets/images/pending-line-chart-grey.svg').default,
    VERTICAL_WHILE_ICON: require('../assets/images/vertical-ellipsis-white-icon.svg').default,
    BOOST_UP: require('../assets/images/boost-up.svg').default,
    MINI_MBA_CAP: require('../assets/images/cap-green.svg').default,
    PORTFOLIO_ICON: require('../assets/images/portfolio-icon.svg').default,
    WHITE_GIRAFFE_ERROR: require('../assets/images/white-giraffe-error.svg').default,
    // new nav icon svg
    HOME_ICON: require('../assets/images/nav-icon/home.svg').default,
    LINE_CHART_ICON: require('../assets/images/nav-icon/line-chart.svg').default,
    HEART_ICON: require('../assets/images/nav-icon/heart.svg').default,
    LEARN_ICON: require('../assets/images/nav-icon/learn.svg').default,
    GIFT_ICON: require('../assets/images/nav-icon/gift.svg').default,
    HOME_ICON_ACTIVE: require('../assets/images/nav-icon/home-active.svg').default,
    LINE_CHART_ICON_ACTIVE: require('../assets/images/nav-icon/line-chart-active.svg').default,
    HEART_ICON_ACTIVE: require('../assets/images/nav-icon/heart-active.svg').default,
    LEARN_ICON_ACTIVE: require('../assets/images/nav-icon/learn-active.svg').default,
    GIFT_ICON_ACTIVE: require('../assets/images/nav-icon/gift-active.svg').default,

    /* JPG */
    HOME_RIGHT_BANNER: require('../assets/images/banner-right.jpg'),
    NATURE_RIGHT_BANNER: require('../assets/images/nature.jpg'),

    /* PNG */
    LEARN_CARD_BG: require('../assets/images/learn-card-bg.png'),
    HELP_CENTER: require('../assets/images/help-center-img.png'),
    PIE_CHART: require('../assets/images/pie-chart.png'),
    CLIMATE_CHANGE_PNG: require('../assets/images/climate-change.png'),
    INVESTOR_PNG: require('../assets/images/investor-pic.png'),
    GIRAFFE_FRAME: require('../assets/images/giraffe-frame.png'),
    CARD_WITH_BG: require('../assets/images/card-with-bg.png'),
    DRIVE_WEALTH_LOGO: require('../assets/images/drive-wealth-logo.png'),
    ACTIVITY_RIGHT_BANNER: require('../assets/images/right-bnr-activity.png'),
    RIGHT_IMAGE_BANNER: require('../assets/images/right-image-basket.png'),
    PENDING_LINE_CHART_PNG: require('../assets/images/pending-line-chart-grey.png'),

    /* GIF */
    SMILE_LOADER_ANIMATION: require('../assets/images/gif/smile-loader-animation.gif'),
    HOURGLASS_ANIMATION: require('../assets/images/gif/hourglass-animation.gif'),
    BANK_CONNECT_ANIMATION: require('../assets/images/gif/bank-connect-green-animation.gif'),
    KYC_PROCESSING_ANIMATION: require('../assets/images/gif/kyc-processed-animation.gif'),
}

export default IMAGES