import React, { Component, Fragment } from 'react'

// custom component
import B2CProcessBar from '../../components/ProcessBarComponent/B2CProcessBar'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'

// api call
import * as B2CGift from '../../middleware/B2CGift'

// intl message
import IntlMessages from '../../helper/IntlMessages'
import B2CGiftInfoModal from '../../components/ModalComponent/B2CGiftInfoModal'

export class SelectEsgcValuePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            esgcValueList: [], //list of esgc value
            totalCompanyCount: 0, //total company count
            excludedCompanyCount: 0, //excluded company count
            isLoading: true,
            selectEsgcValue: '', //selected ESG-C value identifier
            selectedEsgc: '', //esgc selected value 
            openInfoModal: false, //esgc info modal
            nextButtonDisabled: true, //redirect to next page if value is selected
        };
    };

    componentDidMount = async () => {
        window.scroll(0, 0)

        const esgcValueData = await B2CGift.getEsgcValue()

        const selectB2CValue = await JSON.parse(localStorage.getItem('b2cEsgcValue'))

        await this.setState({
            esgcValueList: esgcValueData?.esgcValueList,
            totalCompanyCount: esgcValueData?.companySummary?.totalCompanies,
            excludedCompanyCount: esgcValueData?.companySummary?.excludedCompanies,
            selectEsgcValue: selectB2CValue?.identifier,
            isLoading: false
        })
    }

    // select esgc value
    selectEsgcValue = async (e) => {
        const { esgcValueList } = this.state

        const selectedEsgc = await esgcValueList.filter((e1) => e1.identifier === e.target.id) //get object of selected value

        await this.setState({
            selectEsgcValue: e.target.id,
            nextButtonDisabled: false
        })

        await localStorage.setItem('b2cEsgcValue', JSON.stringify(selectedEsgc[0])) //store selected value
    }

    // info modal
    esgcInfo = async (e) => {
        const { esgcValueList } = this.state

        const selectedEsgc = await esgcValueList.filter((e1) => e1.identifier === e.target.id) //get object of selected value

        await this.setState({
            selectedEsgc: selectedEsgc[0],
            openInfoModal: true
        })
    }

    // close modal
    onHandleClose = async (e) => {
        await this.setState({
            openInfoModal: false
        })
    }

    render() {
        const { isLoading, esgcValueList, totalCompanyCount, excludedCompanyCount, selectEsgcValue, selectedEsgc,
            openInfoModal, nextButtonDisabled } = this.state
        return (
            <Fragment>
                {isLoading
                    ? <LoadingComponent />
                    :
                    <Fragment>
                        <section className="b2c-main">
                            {/* tabs */}
                            <B2CProcessBar
                                backButton={false}
                                activeTab={1}
                                nextButton={true}
                                nextButtonDisabled={nextButtonDisabled}
                                backURL={''}
                                nextURL={'/b2c-gift/select-value'}
                            />

                            {/* actual content */}
                            <div className="b2c-esgc-value">
                                <div className="container">
                                    {/* static text */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="b2c-esgc-heading">
                                                <h5>{IntlMessages('select_esgc_text1')}</h5>
                                                <p>{IntlMessages('select_esgc_text2')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* esgc value details */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="b2c-esgc-scrollbox">
                                                <div className="b2c-esgc-main">
                                                    {esgcValueList.length > 0
                                                        ? esgcValueList.map((e, i) => {
                                                            const textGradient = {
                                                                background: `linear-gradient(${e.titleLinearGradientDirectionDeg}, ${e.titleLinearGradientColorStop1} ${e.titleLinearGradientColorStopPercent1}, ${e.titleLinearGradientColorStop2} ${e.titleLinearGradientColorStopPercent2})`,
                                                                WebkitBackgroundClip: 'text',
                                                                backgroundClip: 'text',
                                                                WebkitTextFillColor: 'transparent',
                                                                textFillColor: 'transparent',
                                                            }
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className={`b2c-esgc-card-main ${e.identifier === selectEsgcValue ? 'active' : ''}`}>
                                                                        <div className="b2c-esgc-card-bg" onClick={this.selectEsgcValue} id={e.identifier}>
                                                                            <div className="b2c-esgc-card" id={e.identifier}>
                                                                                <div className="b2c-esgc-card-img" id={e.identifier}>
                                                                                    <img src={e.giftImage} className="img-fluid" alt="" id={e.identifier} />
                                                                                </div>
                                                                                <div className="b2c-esgc-card-text" id={e.identifier}>
                                                                                    <h5 style={textGradient} id={e.identifier}>{e.name}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="b2c-esgc-card-info" id={e.identifier} onClick={this.esgcInfo}>
                                                                            <a href="#!" id={e.identifier}> </a>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                        : null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* company details */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="b2c-total-companies">
                                                <div className="b2c-total-companies-main">
                                                    <div className="b2c-portfolio-text">
                                                        <h5>{totalCompanyCount}</h5>
                                                        <span>{IntlMessages('select_esgc_total_companies')}</span>
                                                    </div>
                                                    <div className="b2c-excluded-text">
                                                        <h5>{excludedCompanyCount}</h5>
                                                        <span>{IntlMessages('select_esgc_text_excluded_companies')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* information modal */}
                        <B2CGiftInfoModal openInfoModal={openInfoModal} esgcValue={selectedEsgc} onHandleClose={this.onHandleClose} />
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default SelectEsgcValuePage