/* eslint-disable array-callback-return */
//default library
import React, { Component } from 'react'
import { injectIntl } from 'react-intl';
//select library
import Select from 'react-select';

//remove undefined or null value
import { removeUndefined } from '../../service/utils/RemoveUndefineValue';
export class SelectComponent extends Component {
    //default constructor
    constructor(props) {
        super(props)
        this.setLocalStorageData(); //initial call for localStorage
        this.state = {
            fieldValue: '',
            localStorageValue: [],
            defaultValue: [],
            localStorageFlag: false
        }
    }

    //check localStorage is data is present or not if present then store
    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null) {
            let labelArray = await removeUndefined(this.props.option.map((value) => {
                //set label and value for fields
                if (value.value === localStorageData[`${this.props.name}`]) {
                    return {
                        label: value.label,
                        value: value.value
                    }
                }
                else {
                    return null
                }
            }))
            // let label = await labelArray.filter(function (element) {
            //     return element !== undefined;
            // });
            await this.setState({
                localStorageValue: labelArray[0],
                localStorageFlag: true
            })
        }
    }

    //handle onChange event
    handleOnChange = async (e) => {
        await this.setState({
            fieldValue: e.value,
            localStorageFlag: false,
            defaultValue: {
                label: e.label,
                value: e.value
            }
        })
        //send to parent component
        this.props.selectValue(this.state.fieldValue)
    }


    render() {
        const { option, placeholder, name, intl } = this.props
        return (
            <div className='custom-react-dropdown'>
                <Select
                    name={name}//set field name
                    classNamePrefix='react-select'
                    options={option} //dropdown value
                    onChange={this.handleOnChange} //invoke on every dropdown select value
                    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                    value={this.state.localStorageFlag ? this.state.localStorageValue : this.state.defaultValue} //set field value
                />
            </div>
        )
    }
}

export default injectIntl(SelectComponent)