import React, { Component, Fragment } from 'react'

// custom component
import B2CProcessBar from '../../components/ProcessBarComponent/B2CProcessBar'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import InputComponentNumber from '../../components/InputComponent/InputComponentNumber'


// intl message
import IntlMessages from '../../helper/IntlMessages'

export class SelectAmountPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      esgcValueList: [], //list of esgc value
      isLoading: true,
      selectAmountDefault: '', //select default amount
      defaultAmount: [25, 50, 100, 200, 500, 750], //default amount 
      isDefault: false, //select default amount
      isInput: false, //enter amount
      enterAmount: '', //enter amount manually
      selectB2CValue: [], //select b2c value
      nextButtonDisabled: true, //redirect to next page if value is selected
    };
  };

  componentDidMount = async () => {
    const selectB2CValue = await JSON.parse(localStorage.getItem('b2cEsgcValue'))

    await this.setState({
      selectB2CValue: selectB2CValue,
      isLoading: false
    })
  }

  selectAmount = async (e) => {
    await this.setState({
      selectAmountDefault: e.target.value,
      isDefault: true,
      isInput: false,
      nextButtonDisabled: false,
    })
  }

  inputAmount = async (value) => {
    await this.setState({
      enterAmount: value,
      isDefault: false,
      isInput: true,
      nextButtonDisabled: false,
    })
  }

  render() {
    const { isLoading, defaultAmount, enterAmount, selectB2CValue, nextButtonDisabled, selectAmountDefault, isDefault } = this.state

    const textGradient = {
      background: `linear-gradient(${selectB2CValue.titleLinearGradientDirectionDeg}, ${selectB2CValue.titleLinearGradientColorStop1} ${selectB2CValue.titleLinearGradientColorStopPercent1}, ${selectB2CValue.titleLinearGradientColorStop2} ${selectB2CValue.titleLinearGradientColorStopPercent2})`,
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textFillColor: 'transparent',
    }

    return (
      <Fragment>
        {isLoading
          ? <LoadingComponent />
          : <section className="b2c-main">
            {/* tabs */}
            <B2CProcessBar
              backButton={true}
              activeTab={2}
              nextButton={true}
              nextButtonDisabled={nextButtonDisabled}
              backURL={'/b2c-gift'}
              nextURL={'/b2c-gift/select-value'}
            />

            {/* actual content */}
            <div className="b2c-gift">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="b2c-gift-main">
                      {/* static text */}
                      <div className="row">
                        <div className="col-12">
                          <div className="b2c-gift-heading">
                            <h5>{IntlMessages('select_amount_text1')}</h5>
                          </div>
                        </div>
                      </div>

                      {/* select amount */}
                      <div className="row">
                        <div className="col-12">
                          <div className="b2c-gift-card-main">
                            {/* gift card */}
                            <div className="b2c-gift-card">
                              <div className="b2c-esgc-gift-card-main">
                                <div className="b2c-esgc-gift-card-bg">
                                  <div className="b2c-esgc-gift-card">
                                    <div className="b2c-esgc-gift-card-img">
                                      <img src={selectB2CValue.giftImage} className="img-fluid" alt="" />
                                    </div>
                                    <div className="b2c-esgc-gift-card-text">
                                      <h5 style={textGradient}>{selectB2CValue.name}</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* select amount fields */}
                            <div className="b2c-gift-text-card">
                              {/* static text */}
                              <div className="b2c-Choose-text">
                                <h5>{IntlMessages('select_amount_text2')}</h5>
                                <p>{IntlMessages('select_amount_text3')}</p>
                              </div>
                              {/* select amount */}
                              <div className="b2c-select-amount-card">
                                <div className="b2c-select-label">
                                  <label>{IntlMessages('select_amount_text4')}</label>
                                </div>
                                <div className="b2c-select-group">
                                  {defaultAmount.length > 0
                                    ? defaultAmount.map((e, i) => {
                                      return (
                                        <Fragment key={i}>
                                          <button className={`b2c-amount-btn ${(e === Number(selectAmountDefault) && isDefault) ? 'active' : ''}`} type="button" value={e} onClick={this.selectAmount}>${e}</button>
                                        </Fragment>
                                      )
                                    })
                                    : null
                                  }
                                </div>
                              </div>
                              {/* input amount */}
                              <div className="b2c-select-amount-card">
                                <div className="b2c-select-label">
                                  <label>{IntlMessages('select_amount_text5')}</label>
                                </div>
                                <div className="b2c-gift-input">
                                  <InputComponentNumber placeholder={'select_amount_placeholder'} name={'b2cAmount'} value={enterAmount} inputValue={this.inputAmount} />
                                  <span>{IntlMessages('select_amount_text6', { min: `$25`, max: `$750` })}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </Fragment>
    )
  }
}

export default SelectAmountPage