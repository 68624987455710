//default library
import React, { Component, Fragment } from 'react'

//custom component
import SelectActivity from '../SelectComponent/SelectActivity';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import PaginationNew from '../PaginationComponent/PaginationNew';

// custom function
import { currencyFormatter } from '../../service/utils/CurrencyFormatter'
import { DateFormatter } from '../../service/utils/DateFormatter';
import { DateRange } from '../../service/utils/DateFilter';
// api call
import { getDividends } from '../../middleware/ActivityDetails';

import IntlMessages from '../../helper/IntlMessages';

import { taxesDropdown } from '../../config/DefaultValue';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

export class DividendsCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dividendData: props.dividendData,
            currentDividendData: [],
            totalDividendData: [],
            currentPage: null,
            totalPages: null,
        }
    }

    componentDidMount = async () => {
        const { totalDividendData, dividendData } = this.state
        if (totalDividendData?.length === 0) {
            await this.setState({
                totalDividendData: dividendData,
                dataLoad: true
            })
        }
    }

    onPageChanged = data => {
        const { totalDividendData } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentDividendData = totalDividendData?.slice(offset, offset + pageLimit);
        this.setState({
            currentDividendData: currentDividendData,
            currentPage: currentPage,
            totalPages: totalPages
        });
    }

    onSelectValue = async (value) => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            dataLoad: false
        })

        let dateRange = await DateRange(value)//set default value date range

        const accessToken = await getAccessTokenSilently() //get access token
        let dividendData = await getDividends(accessToken, dateRange)

        await this.setState({
            totalDividendData: dividendData,
            dataLoad: true
        })
    }


    render() {
        const { totalDividendData, currentDividendData, dataLoad } = this.state
        return (
            <Fragment>
                {dataLoad ? '' : <LoadingComponent />}
                <div className="row gx-20">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="gi-d-ac-select-dropdown mb-30">
                                    <div>
                                        <label>
                                            {IntlMessages('table_range')}:
                                        </label>
                                    </div>
                                    <SelectActivity name='transaction' option={taxesDropdown} value={'past-week'} selectValue={this.onSelectValue} />
                                </div>
                            </div>
                            {/* <div className="col-sm-8 col-md-9">
                                Pagination
                                {dataLoad
                                    ? <PaginationNew totalRecords={totalDividendData?.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                                    : ''
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table gi-d-table-striped-02 mb-0" role="presentation">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <span>
                                                {IntlMessages('table_date')}
                                            </span>
                                        </th>
                                        <th scope="col">
                                            <span>
                                                {IntlMessages('table_description')}
                                            </span>
                                        </th>
                                        <th scope="col">
                                            <span>
                                                {IntlMessages('table_amount')}
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentDividendData?.length > 0
                                        ? currentDividendData.map((e, i) => {
                                            return (
                                                <tr key={i} className={e.amount > 0 ? i % 2 === 0 ? 'bg-striped' : '' : 'bg-light'}>
                                                    <td>
                                                        <span>{DateFormatter(e.date)}</span>
                                                    </td>
                                                    <td>
                                                        <span>{e.description}</span>
                                                    </td>
                                                    <td className="amount-td">
                                                        <span className={e.amount > 0 ? 'text-gi-green' : 'text-gi-clay'}>{e.amount > 0 ? `+${currencyFormatter(e.amount)}` : currencyFormatter(e.amount)}</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : <tr className="bg-striped">
                                            <td colSpan="12">
                                                <span>
                                                    {IntlMessages('table_not_range')}
                                                </span>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        {dataLoad
                            ? <PaginationNew totalRecords={totalDividendData?.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                            : ''
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withAuth0(DividendsCard)