//default library
import React, { Component } from 'react'

//image import
import IMAGES from '../../config/Images'

import IntlMessages from '../../helper/IntlMessages'
//interweave library for html
import { Markup } from 'interweave';
export class ExchangeGiftCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            esgcValueList: props.esgcValueList
        }
    }

    openReadMoreModal = async (e) => {
        await this.props.openReadMoreModal(e.target.id, true)
    }

    render() {
        const { esgcValueList } = this.state
        return (
            <div className="row gx-50">
                {/* make loop */}
                {esgcValueList.length > 0
                    ? esgcValueList.map((e) => {
                        const titleTextStyle = {
                            background: `linear-gradient(${e.titleLinearGradientDirectionDeg}, ${e.titleLinearGradientColorStop1} ${e.titleLinearGradientColorStopPercent1}, ${e.titleLinearGradientColorStop2} ${e.titleLinearGradientColorStopPercent2})`,
                            WebkitBackgroundClip: 'text',
                            backgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        };
                        return (
                            <div className="col-md-6" key={e.identifier}>
                                <div className="gi-gift-sun-card">
                                    <div className="gi-gift-sun-img-new">
                                        <div className="gi-gift-sun-img">
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className="gi-gift-sun-icon">
                                                        <img src={e.giftImage} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className="gi-gift-sun-heading"
                                                        style={titleTextStyle}>
                                                        {e.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gi-gift-sun-text">
                                        <h5>{e.name}</h5>
                                        <p>
                                            <Markup content={`${e.description.slice(0, 100)}...`} />
                                        </p>
                                    </div>
                                    <div className="gi-gift-sun-footer">
                                        <button type="button" className="gi-gift-sun-btn" onClick={this.openReadMoreModal} id={e.esgcValueBoostIdentifier}>
                                            <img src={IMAGES.BRIGHT_REFRESH_ICON} alt="" />
                                            {IntlMessages('gift_switch_value')}
                                        </button>
                                        <button type="button" className="gi-gift-sun-btn" onClick={this.openReadMoreModal} id={e.esgcValueBoostIdentifier}>
                                            <img src={IMAGES.BRIGHT_PLUS_ICON} alt="" />
                                            {IntlMessages('gift_read_more')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : ''}
            </div>
        )
    }
}

export default ExchangeGiftCard