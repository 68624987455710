//default library
import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'

// import image
import IMAGES from '../../config/Images'

import IntlMessages from '../../helper/IntlMessages'
import WeekChangeSection from './WeekChangeSection'

export class CurrentWeekSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            viewListPage: false
        }
    }

    viewListPage = async () => {
        await this.setState({
            viewListPage: true
        })
    }

    playVideo = async (e) => {
        await this.props.playVideo(await (e.target.parentElement.id))
    }

    forWeek = async (forWeek) => {
        await this.props.forWeek(forWeek)
    }

    render() {
        const { viewListPage } = this.state
        const { userVideoList, scheduleSection } = this.props

        return (
            <Fragment>
                {/* goto giraffe learn list page */}
                {viewListPage ? <Redirect push to='giraffe-learn-list' /> : ''}
                {/* view schedule giraffe learn */}
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-full-view mb-10">
                                    <button type='button' onClick={this.viewListPage}>
                                        {IntlMessages('giraffe_learn_list_topics')}
                                        <img src={IMAGES.CALENDER_ICON} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* video section */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="gi-g-white-card mb-10">
                            {userVideoList.featuredVideo
                                ? <div className="row">
                                    <div className="col-md-12">
                                        <div className="gi-g-current-week">
                                            <div className="gi-g-current-week-img" id={userVideoList.featuredVideo.identifier} onClick={this.playVideo}>
                                                <img src={userVideoList.featuredVideo.bannerImage} className="img-fluid" alt="" />
                                                <button type='button' id={userVideoList.featuredVideo.identifier}>
                                                    <img src={IMAGES.BRIGHT_PLAY_ICON} alt="" />
                                                </button>
                                                <div className="gi-g-current-heading" id={userVideoList.featuredVideo.identifier}>
                                                    <h5>
                                                        {IntlMessages('week')} -
                                                        <span>
                                                            {userVideoList.forWeek < 10
                                                                ? `0${userVideoList.forWeek}`
                                                                : userVideoList.forWeek
                                                            }
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="gi-g-current-week-text">
                                                <h5>{userVideoList.featuredVideo.title}</h5>
                                                <p>{userVideoList.featuredVideo.longDescription}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            <div className="row gx-50">
                                {userVideoList.thumbnailVideo
                                    ? userVideoList.thumbnailVideo.map((e) => {
                                        return (
                                            <div className="col-sm-6" key={e.identifier}>
                                                <div className="gi-g-week-card">
                                                    <div className="gi-g-week-card-img" id={e.identifier} onClick={this.playVideo}>
                                                        <img src={e.thumbnailImage} className="img-fluid" alt="" />
                                                        <button type='button' id={e.identifier}>
                                                            <img src={IMAGES.BRIGHT_PLAY_ICON} alt="" />
                                                        </button>
                                                    </div>
                                                    <div className="gi-g-week-card-text">
                                                        <p className="mt-0">{e.shortDescription}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                                }

                                <WeekChangeSection scheduleSection={scheduleSection} forWeek={this.forWeek} />
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default CurrentWeekSection