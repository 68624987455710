//api call
import axios from "axios";

//api list
import { SEND_OTP, VERIFY_OTP } from "../config/UrlList";

//custom component
import { ConsoleLog } from "../service/utils/ConsoleLog";

//send otp
export const sendOTP = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    let param = {
        'phone': `+1${JSON.parse(localStorage.getItem('onBoardingDetails'))?.phoneNumber}`
    }

    return await axios.post(SEND_OTP, param, config)
        .then(res => {
            return res.data
        })
        .catch(error => {
            ConsoleLog('There was an error!', error);
        })
}

//send otp
export const verifyOTP = async (accessToken, otp) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    let param = {
        'phone': `+1${JSON.parse(localStorage.getItem('onBoardingDetails'))?.phoneNumber}`,
        'otp': otp
    }

    return await axios.post(VERIFY_OTP, param, config)
        .then(res => {
            return res.data
        })
        .catch(error => {
            ConsoleLog('There was an error!', error);
        })
}