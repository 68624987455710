//default library
import React, { Component } from 'react';

// custom component
import PageCount from '../../../components/PageCount/PageCount';
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
import RightInformation from '../../../components/InformationComponent/RightInformation';
import InputComponentRadio from '../../../components/InputComponent/InputComponentRadio';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';

// default values
import { actionValue } from '../../../config/DefaultValue';

export class PersonalDetailsPage6 extends Component {

    constructor(props) {
        super(props)
        this.setLocalStorageData()
        this.state = {
            taxPayer: '',
            localStorageData: '',
            localStorageDataFlag: false
        }
    }

    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null && localStorageData?.taxPayer !== undefined) {
            await this.setState({
                localStorageData: localStorageData?.taxPayer ?? '',
                localStorageDataFlag: true
            })
        }
    }

    setTaxPayerValue = async (taxPayer) => {
        await this.setState({
            taxPayer: taxPayer.fieldValue,
            localStorageDataFlag: false
        });
    }

    setDetails = (taxPayer) => {
        const { localStorageData, localStorageDataFlag } = this.state
        this.details = {
            taxPayer: localStorageDataFlag ? localStorageData : taxPayer
        }
    }

    render() {
        const { taxPayer, localStorageData, localStorageDataFlag } = this.state
        this.setDetails(taxPayer)
        return (
            <>
                <div className="gi-from-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="py-3">
                                            <div className="gi-from-left">
                                                <PageCount count={6} pageName={'Personal details'} />
                                                <div className="gi-input gi-select">
                                                    <LabelComponent name={'address'} labelName={'personal_page6_label'} />
                                                </div>
                                                <div className="mb-40">
                                                    {actionValue.length !== 0 ? actionValue.map((value, i) => {
                                                        const checked = localStorageDataFlag ? value.value === localStorageData : value.value === taxPayer
                                                        return (
                                                            <div className="customize-radio form-check mt-20" key={i}>
                                                                <label className={`form-check-label ${taxPayer === value.value ? 'active' : ''}`} htmlFor={value.label.props.id}>
                                                                    <InputComponentRadio id={value.label} name={'taxPayer'} value={value.value} title={value.label.props.id} text={value.label} checked={checked} inputValue={this.setTaxPayerValue} />
                                                                </label>
                                                            </div>
                                                        )
                                                    }) : <LoadingComponent />}
                                                    <div className="gi-input">
                                                        <ErrorComponent message={'error_non_us_taxpayer'} display={taxPayer === 'false' ? 'block' : 'none'} />
                                                    </div>
                                                </div>
                                                <div className="gi-btn-group d-none d-sm-flex">
                                                    <ButtonComponent value={this.details} previousRouting={'page5'} nextRouting={'page7'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="gi-from-right">
                                            <RightInformation info1={'personal_detail_info1'} info2={'personal_detail_info2'} />
                                            <div className="gi-btn-group d-flex d-sm-none">
                                                <ButtonComponent value={this.details} previousRouting={'page5'} nextRouting={'page7'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default PersonalDetailsPage6