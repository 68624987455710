//default library
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

//image import
import IMAGES from '../../config/Images';
import IntlMessages from '../../helper/IntlMessages';

export class GiraffeLearnSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            giraffeLearn: false
        }
    }

    giraffeLearn = async () => {
        await this.setState({
            giraffeLearn: true
        })
    }

    render() {
        const { giraffeLearn } = this.state
        return (
            <Fragment>
                {/* go to giraffe learn */}
                {giraffeLearn ? <Redirect push to='giraffe-learn' /> : ''}

                {/* main section */}
                <div className="row">
                    <div className="col-12">
                        <div className="gi-g-learn-card mb-10">
                            <div className="gi-g-care-about">
                                <div className="gi-g-care-about-img">
                                    <img src={IMAGES.GIRAFFE_VIEW_ACTIVE} alt="" />
                                </div>
                                <div className="gi-g-care-about-text">
                                    <p>
                                        {IntlMessages('giraffe_static_text1')}
                                    </p>
                                </div>
                            </div>
                            <div className="gi-g-learn-card-text">
                                <h1 className="mb-20">
                                    {IntlMessages('dashboard_giraffe_learn')}
                                </h1>
                                <p className="mb-30">
                                    {IntlMessages('giraffe_learn_text1')}
                                </p>
                                <button className="gi-g-start-btn" type="button" onClick={this.giraffeLearn}>
                                    <img src={IMAGES.WHITE_RIGHT_ICON} alt="" />
                                    {IntlMessages('giraffe_start_learning')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GiraffeLearnSection