//api call 
import axios from "axios";

//import Config for url
import { KYC_ERROR_HANDLING_FLAG } from "../config/Config";
import { USER_DATA_UPDATE } from "../config/UrlList";

//console logs
import { ConsoleLog } from "../service/utils/ConsoleLog";

//api call
import { giftUpdate } from "./GiftAPI";
import { getUserData } from "./User";

//onboarding update call
export const UpdateCall = async (accessToken) => {
    //check user has gift or not
    const giftIdentifier = await localStorage.getItem('giftIdentifier')

    if (giftIdentifier) {
        await giftUpdate(accessToken, giftIdentifier)
    }
    //get localStorage data
    let data = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    //construct user address modal
    let userAddress = {
        street1: data.street1,
        street2: data.apartmentUnit ?? null,
        city: data.city,
        province: data.province,
        postalCode: data.postalCode,
        countryIdentifier: data.country
    }

    //construct user employment modal
    let userEmployment = {
        statusIdentifier: data.employmentStatus,
        company: data.company ?? null,
        typeIdentifier: data.typeIdentifier ?? null,
        positionIdentifier: data.positionIdentifier ?? null,
        affiliatedWithBroker: data.brokerDealer,
        directorOf: data.directorOf ?? null
    }

    //construct user investor modal
    let userInvestor = {
        experienceIdentifier: data.experienceIdentifier,
        annualIncome: data.annualIncome,
        networthTotal: data.netWorth,
        riskToleranceIdentifier: data.riskTolerance
    }

    //construct user disclosure modal
    let userDisclosure = {
        termsOfUse: "true",
        customerAgreement: "true",
        marketDataAgreement: "true",
        rule14b: "true",
        findersFee: "false",
        privacyPolicy: "true",
        dataSharing: "true",
        extendedHoursAgreement: "false",
        giraffeTermsAndConditions: "true"
    }

    const name = `${data.firstName}${data.middleName ? ` ${data.middleName}` : ''} ${data.lastName}`

    //construct form data
    let formData = {
        name: name,
        firstName: data.firstName,
        middleName: data.middleName ? data.middleName : null,
        lastName: data.lastName,
        email: localStorage.getItem('userEmailId'),
        marketing: 'true',
        phone: `+1${data.phoneNumber}`,
        language: 'en_US',
        taxIdNumber: data.ssnNumber,
        citizenship: data.citizenship,
        dob: data.dateOfBirth,
        politicallyExposedNames: data.politicallyExposedNames,
        irsBackupWithholdings: 'false',
        identifier: localStorage.getItem('identifier'),
        showKycStatus: 'true',
        usTaxPayer: data.taxPayer === 'true' || data.countryLabel === 'United States' ? 'true' : 'false',
        userAddress: userAddress,
        userEmployment: userEmployment,
        userInvestor: userInvestor,
        userDisclosure: userDisclosure,
    }
    //update user
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.put(USER_DATA_UPDATE, formData, config)
        .then(response => {
            localStorage.setItem('username', response.data.name)
            localStorage.setItem('firstName', response.data.firstNameEncrypted)
            localStorage.setItem('lastName', response.data.lastNameEncrypted)
            localStorage.setItem('dwUserIdentifier', response.data.dwUserIdentifier)
            return {
                'data': response.data,
                'error': null
            }
        })
        .catch(error => {
            if (error.response !== 200) {
                return {
                    'data': null,
                    'error': true
                }
            }
            ConsoleLog('There was an error!', error);
        });
}

//kyc update call
export const updateUserData = async (accessToken, value) => {
    let lastName;
    const data = await getUserData(accessToken)
    const localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));

    //handle kyc error with lastName 
    if (KYC_ERROR_HANDLING_FLAG) {
        lastName = localStorage.getItem('lastName')
    } else {
        lastName = value.lastName ? value.lastName : data.lastNameEncrypted
    }

    let firstName = value.firstName ? value.firstName : data.firstNameEncrypted //set firstName

    let userAddress = {
        street1: localStorageData ? localStorageData.street1 : data.userAddress.street1,
        street2: localStorageData ? value.apartmentUnit : data.userAddress.street2,
        city: localStorageData ? localStorageData.city : data.userAddress.city,
        province: localStorageData ? localStorageData.province : data.userAddress.province,
        postalCode: localStorageData ? localStorageData.postalCode : data.userAddress.postalCode,
        countryIdentifier: localStorageData ? localStorageData.country : data.userAddress.countryIdentifier
    }

    //construct user employment modal
    let userEmployment = {
        statusIdentifier: data.userEmployment.statusIdentifier,
        company: data.userEmployment.company,
        typeIdentifier: data.userEmployment.typeIdentifier,
        positionIdentifier: data.userEmployment.positionIdentifier,
        affiliatedWithBroker: data.userEmployment.affiliatedWithBroker,
        directorOf: data.userEmployment.directorOf
    }

    //construct user investor modal
    let userInvestor = {
        experienceIdentifier: data.userInvestor.experienceIdentifier,
        annualIncome: data.userInvestor.annualIncome,
        networthTotal: data.userInvestor.networthTotal,
        riskToleranceIdentifier: data.userInvestor.riskToleranceIdentifier
    }

    //construct user disclosure modal
    let userDisclosure = {
        termsOfUse: "true",
        customerAgreement: "true",
        marketDataAgreement: "true",
        rule14b: "true",
        findersFee: "false",
        privacyPolicy: "true",
        dataSharing: "true",
        extendedHoursAgreement: "false",
        giraffeTermsAndConditions: "true"
    }

    const name = `${firstName}${data.middleNameEncrypted ? ` ${data.middleNameEncrypted}` : null} ${lastName}`

    //construct form data
    let formData = {
        name: name,
        firstName: firstName,
        middleName: data.middleNameEncrypted,
        lastName: lastName,
        email: localStorage.getItem('userEmailId'),
        marketing: 'true',
        phone: value.phoneNumber ? value.phoneNumber : data.phoneEncrypted,
        language: 'en_US',
        taxIdNumber: value.ssnNumber ? value.ssnNumber : data.ssnNumber,
        citizenship: value.citizenship ? value.citizenship : data.citizenship,
        dob: value.dob ? value.dob : data.dob,
        politicallyExposedNames: data.politicallyExposedNames,
        irsBackupWithholdings: 'false',
        identifier: data.identifier,
        showKycStatus: 'true',
        usTaxPayer: value.countryLabel ? value.taxPayer === 'true' || value.countryLabel === 'United States' ? 'true' : 'false' : data.usTaxPayer,
        userAddress: userAddress,
        userEmployment: userEmployment,
        userInvestor: userInvestor,
        userDisclosure: userDisclosure,
    }


    //update user
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };
    return await axios.put(USER_DATA_UPDATE, formData, config)
        .then(response => {
            localStorage.setItem('username', response.data.name)
            localStorage.setItem('firstName', response.data.firstNameEncrypted)
            localStorage.setItem('lastName', response.data.lastNameEncrypted)
            localStorage.setItem('dwUserIdentifier', response.data.dwUserIdentifier)
            localStorage.removeItem('onBoardingDetails')
            return {
                'data': response.data,
                'error': null
            }
        })
        .catch(error => {
            if (error.response !== 200) {
                return {
                    'data': null,
                    'error': true
                }
            }
            ConsoleLog('There was an error!', error);
        });
}

//account setting update call
export const updateSettingData = async (accessToken, value) => {
    //get all user data
    const data = await getUserData(accessToken)

    //get data from localStorage
    const localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    const addressAutoFillFlag = localStorageData && value.addressFlag
    let userAddress = {
        street1: addressAutoFillFlag ? localStorageData.street1 : data.userAddress.street1,
        street2: value.addressFlag ? value.apartmentUnit : data.userAddress.street2,
        city: addressAutoFillFlag ? localStorageData.city : data.userAddress.city,
        province: addressAutoFillFlag ? localStorageData.province : data.userAddress.province,
        postalCode: addressAutoFillFlag ? localStorageData.postalCode : data.userAddress.postalCode,
        countryIdentifier: addressAutoFillFlag ? localStorageData.country : data.userAddress.countryIdentifier
    }

    //construct user employment modal
    let userEmployment = {
        statusIdentifier: value.employmentStatusFlag ? value.employmentStatus : data.userEmployment.statusIdentifier,
        company: value.employmentStatusFlag ? value.employmentCompany : data.userEmployment.company,
        typeIdentifier: value.employmentStatusFlag ? value.employmentIndustry : data.userEmployment.typeIdentifier,
        positionIdentifier: value.employmentStatusFlag ? value.employmentPosition : data.userEmployment.positionIdentifier,
        affiliatedWithBroker: value.userBrokerDealerFlag ? value.affiliatedWithBroker : data.userEmployment.affiliatedWithBroker,
        directorOf: value.userBrokerDealerFlag && value.directorOf ? `${value.companyName} ${value.tickerSymbol}` : null
    }

    //construct user investor modal
    let userInvestor = {
        experienceIdentifier: value.investingInformationFlag ? value.investingExperience : data.userInvestor.experienceIdentifier,
        annualIncome: value.investingInformationFlag ? value.annualIncome : data.userInvestor.annualIncome,
        networthTotal: value.investingInformationFlag ? value.netWorth : data.userInvestor.networthTotal,
        riskToleranceIdentifier: value.investingInformationFlag ? value.riskTolerance : data.userInvestor.riskToleranceIdentifier
    }

    //construct user disclosure modal
    let userDisclosure = {
        termsOfUse: "true",
        customerAgreement: "true",
        marketDataAgreement: "true",
        rule14b: "true",
        findersFee: "false",
        privacyPolicy: "true",
        dataSharing: "true",
        extendedHoursAgreement: "false",
        giraffeTermsAndConditions: "true"
    }

    const middleName = value.personalDetails.personalDetailsFlag ? value.personalDetails.middleName ? value.personalDetails.middleName : null : data.middleNameEncrypted
    const name = `${data.firstNameEncrypted}${middleName ? ` ${middleName}` : ''} ${data.lastNameEncrypted}`

    //construct form data
    let formData = {
        name: name,
        firstName: data.firstNameEncrypted,
        middleName: middleName,
        lastName: data.lastNameEncrypted,
        email: localStorage.getItem('userEmailId'),
        marketing: 'true',
        phone: data.phoneEncrypted,
        language: 'en_US',
        taxIdNumber: value.ssnNumber !== '' ? value.ssnNumber : data.ssnNumber,
        citizenship: data.citizenship,
        dob: data.dob,
        politicallyExposedNames: value.userBrokerDealerFlag && value.politicallyExposedNames ? value.officialField : null,
        irsBackupWithholdings: 'false',
        identifier: data.identifier,
        showKycStatus: 'true',
        usTaxPayer: data.usTaxPayer,
        userAddress: userAddress,
        userEmployment: userEmployment,
        userInvestor: userInvestor,
        userDisclosure: userDisclosure,
    }

    //update user
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.put(USER_DATA_UPDATE, formData, config)
        .then(response => {
            localStorage.setItem('username', response.data.name)
            localStorage.setItem('firstName', response.data.firstNameEncrypted)
            localStorage.setItem('lastName', response.data.lastNameEncrypted)
            localStorage.setItem('dwUserIdentifier', response.data.dwUserIdentifier)
            localStorage.removeItem('onBoardingDetails')
            return {
                'data': response.data,
                'error': null
            }
        })
        .catch(error => {
            if (error.response !== 200) {
                return {
                    'data': null,
                    'error': true
                }
            }
            ConsoleLog('There was an error!', error);
        });
}