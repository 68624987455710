import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class AddPortfolioButton extends Component {

    onBackClick = async () => {
        await this.props.onBackClick()
    }

    onClick = async () => {
        await this.props.onClick()
    }

    render() {
        const { buttonHeader, buttonLabel, backButton, disabledNext } = this.props

        return (
            <div className="gi-p-card-header">
                <div className="gi-p-card-heading">
                    {IntlMessages(buttonHeader)}
                </div>
                <div className="gi-p-btns-group">
                    {backButton
                        ? <div>
                            <button type="button" className="gi-p-back-btn" onClick={this.onBackClick}>
                                {IntlMessages('button_back')}
                            </button>
                        </div>
                        : ''
                    }
                    <div>
                        <button type="button"
                            className={`${buttonLabel === 'button_next' ? 'gi-p-next-btn' : 'gi-p-complete-btn'}`}
                            onClick={this.onClick}
                            disabled={disabledNext}
                        >
                            <span className="gi-p-next-icon"></span>
                            {IntlMessages(buttonLabel)}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddPortfolioButton