import * as CryptoJS from "crypto-js";
import * as Config from '../../config/Config'

export const URLCheckFunction = () => {
    const localStorageData = JSON.parse(localStorage.getItem('onBoardingDetails'))

    //local storage is null
    if (localStorageData === null) {
        return '/onBoarding';
    }
    //personal details page1
    else if (!localStorageData?.phoneNumber) {
        return '/onBoarding/page1';
    }
    else if (localStorageData?.verifyPhoneNumber && !JSON.parse(CryptoJS.AES.decrypt(localStorageData?.verifyPhoneNumber, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8))) {
        return '/onBoarding/verifyCode';
    }
    //personal details page2
    else if (!localStorageData?.firstName) {
        return '/onBoarding/page2';
    }
    //personal details page3
    else if (!localStorageData?.dateOfBirth) {
        return '/onBoarding/page3';
    }
    //personal details page4
    else if (!localStorageData?.addressAutoFill) {
        return '/onBoarding/page4';
    }
    //personal details page5
    else if (!localStorageData?.citizenship) {
        return '/onBoarding/page5';
    }
    //personal details page6
    else if (!localStorageData?.taxPayer && localStorageData?.countryLabel !== 'United States') {
        return '/onBoarding/page6';
    }
    //personal details page7
    else if (!localStorageData?.ssnNumber) {
        return '/onBoarding/page7';
    }
    //employment details page1
    else if (!localStorageData?.employmentStatus) {
        return '/onBoarding/page8';
    }
    //employment details page2
    else if (!localStorageData?.positionIdentifier && (localStorageData?.employmentLabel === 'Currently working' || localStorageData?.employmentLabel === 'Self-employed')) {
        return '/onBoarding/page9';
    }
    //employment details page3
    else if (!localStorageData?.publicOfficial) {
        return '/onBoarding/page10';
    }
    //employment details page4
    else if (!localStorageData?.annualIncome) {
        return '/onBoarding/page11';
    }
    //investment details page1
    else if (!localStorageData?.netWorth) {
        return '/onBoarding/page12';
    }
    //investment details page2
    else if (!localStorageData?.experienceIdentifier) {
        return '/onBoarding/page13';
    }
    //investment details page3
    else if (!localStorageData?.riskTolerance && !localStorageData?.currentIndex) {
        return '/onBoarding/page14';
    }
    //completion details page
    else if (!localStorageData?.terms) {
        return '/onBoarding/page15';
    }
}