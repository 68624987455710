import React, { Component } from 'react'

import Slider from "rc-slider";
import Tooltip from "@material-ui/core/Tooltip";
import "rc-slider/assets/index.css";

const Handle = Slider.Handle;
class ProcessBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: JSON.parse(localStorage.getItem('onBoardingDetails'))?.currentIndex ?? 0,
      marks: Object.assign({}, props.labelName),
    };
    this.props.setProcessBar(0)

  }

  onHandleChange = value => {
    this.setState({
      value
    });
    this.props.setProcessBar(value)
  };


  handle = props => {
    props.dragging = props.dragging.toString()
    const { ...restProps } = props;
    const { marks, value } = this.state
    return (
      <Tooltip title={marks[value]} placement="top" arrow >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  render() {
    const { value, marks } = this.state
    const { labelName } = this.props

    return (
      <>
        {/* Horizontal */}
        <div className="col-12 mb-20">
          <div className='d-none d-sm-block'>
            <div className="horizontal-slider-custome mt-40">
              <Slider
                min={0}
                max={labelName.length - 1}
                step={1}
                marks={marks}
                onChange={this.onHandleChange}
                handle={this.handle}
                defaultValue={value}
                tipProps={{ visible: true }}
              />
            </div>
          </div>
        </div>

        {/* Vertical */}
        <div className='col-12 mr-20'>
          <div className='d-block d-sm-none'>
            <div className="vertical-slider-custome mr-20">
              <Slider
                min={0}
                max={3}
                step={1}
                marks={marks}
                onChange={this.onHandleChange}
                handle={this.handle}
                vertical={true}
                defaultValue={value}
                tipProps={{ visible: true }}
              />
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default ProcessBar


