//default library
import React, { Component } from 'react'

//image import
import IMAGES from '../../config/Images'

import IntlMessages from '../../helper/IntlMessages'

export class GiftFaq extends Component {
    constructor(props) {
        super(props)

        this.state = {
            faqs: [
                {
                    question: IntlMessages('question1'),
                    answer: IntlMessages('answer1')
                },
                {
                    question: IntlMessages('question2'),
                    answer: IntlMessages('answer2')
                },
                {
                    question: IntlMessages('question3'),
                    answer: IntlMessages('answer3')
                },
                
            ]
        }
    }
    render() {
        const { faqs } = this.state
        return (
            <section className="gi-g-lp-faq-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-g-lp-faq-top-bg"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11 mx-auto">
                            <div className="gi-g-lp-fag-heading">
                                {IntlMessages('faqs')}
                            </div>
                            <div className="gi-g-lp-fag-collapse-box mt-30">
                                {faqs.length > 0
                                    ? faqs.map((e, i) => {
                                        return (
                                            <div className="gi-g-lp-fag-card" key={i}>
                                                <button type="button" className="gi-g-lp-fag-card-header collapsed" data-bs-toggle="collapse"
                                                    data-bs-target={`#gi-g-faq-collapse-${i}`} aria-expanded="false"
                                                    aria-controls={`gi-g-faq-collapse-${i}`}>
                                                    {e.question}
                                                </button>
                                                <div className=" collapse" id={`gi-g-faq-collapse-${i}`}>
                                                    <div className="gi-g-lp-fag-card-body">
                                                        {e.answer}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                                }

                            </div>
                            <div className="gi-g-lp-question-card">
                                <div className="gi-g-lp-question-card-img">
                                    <img src={IMAGES.HELP_CENTER} alt="" />
                                </div>
                                <div className="gi-g-lp-question-card-text">
                                    <h5>
                                        {IntlMessages('gift_faq')}
                                    </h5>
                                    <p>
                                        {IntlMessages('gift_faq_please_see')} 
                                        {/* &nbsp;
                                        <a href="#!">
                                            {IntlMessages('gift_faq_help')}
                                        </a> */}
                                        &nbsp;
                                        {IntlMessages('gift_faq_article')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default GiftFaq