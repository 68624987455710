// default library
import React, { Component } from 'react'
// intl message provider
import IntlMessages from '../../helper/IntlMessages'

// moment library
import moment from 'moment'

export class LearnEventSection extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-green-portfolio mb-10">
                        <h6>
                            {moment('20230625', 'YYYYMMDD').format('MMMM Do, YYYY')}
                        </h6>
                        <h5>
                            {IntlMessages('mini_mba_text4')}
                        </h5>
                        <p>
                            {IntlMessages('mini_mba_text5')}
                        </p>
                        <span>
                            {IntlMessages('mini_mba_text6')}
                        </span>
                        <button type="button">
                            {IntlMessages('mini_mba_secure')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default LearnEventSection