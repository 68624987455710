import React, { Component, Fragment } from 'react'
import IntlMessages from '../../helper/IntlMessages';
import PaginationNew from '../PaginationComponent/PaginationNew';

export class TotalCompanyTable extends Component {
    sortTypesTicker = {
        up: {
            className: 'sort-up',
            fn: (a, b) => a.tickerSymbol.localeCompare(b.tickerSymbol)
        },
        down: {
            className: 'sort-down',
            fn: (a, b) => b.tickerSymbol.localeCompare(a.tickerSymbol)
        },
        default: {
            className: 'sort',
            fn: (a, b) => a
        }
    };

    sortTypesDollarValue = {
        up: {
            className: 'sort-up',
            fn: (a, b) => a.value - b.value
        },
        down: {
            className: 'sort-down',
            fn: (a, b) => b.value - a.value
        },
        default: {
            className: 'sort',
            fn: (a, b) => a
        }
    };

    sortTypesIndustry = {
        up: {
            className: 'sort-up',
            fn: (a, b) => a.industry.localeCompare(b.industry)
        },
        down: {
            className: 'sort-down',
            fn: (a, b) => b.industry.localeCompare(a.industry)
        },
        default: {
            className: 'sort',
            fn: (a, b) => a
        }
    };

    sortTypesPercentage = {
        up: {
            className: 'sort-up',
            fn: (a, b) => a.weight - b.weight
        },
        down: {
            className: 'sort-down',
            fn: (a, b) => b.weight - a.weight
        },
        default: {
            className: 'sort',
            fn: (a, b) => a
        }
    };

    sortTicker = async () => {
        const { currentSortTicker } = this.state;
        let nextSort;

        await this.setState({
            sortingFlag: false
        })

        if (currentSortTicker === 'default') nextSort = 'up';
        if (currentSortTicker === 'down') nextSort = 'up';
        else if (currentSortTicker === 'up') nextSort = 'down';

        await this.setState({
            currentSortTicker: nextSort,
            currentSortDollarValue: 'default',
            currentSortIndustry: 'default',
            currentSortPercentage: 'default',
            sortTypes: this.sortTypesTicker[nextSort],
            sortingFlag: true
        })
    }

    sortDollarValue = async () => {
        const { currentSortDollarValue } = this.state;
        let nextSort;

        await this.setState({
            sortingFlag: false
        })

        if (currentSortDollarValue === 'default') nextSort = 'up';
        if (currentSortDollarValue === 'down') nextSort = 'up';
        else if (currentSortDollarValue === 'up') nextSort = 'down';

        await this.setState({
            currentSortTicker: 'default',
            currentSortDollarValue: nextSort,
            currentSortIndustry: 'default',
            currentSortPercentage: 'default',
            sortTypes: this.sortTypesDollarValue[nextSort],
            sortingFlag: true
        })

    }

    sortIndustry = async () => {
        const { currentSortIndustry } = this.state;
        let nextSort;

        await this.setState({
            sortingFlag: false
        })

        if (currentSortIndustry === 'default') nextSort = 'up';
        if (currentSortIndustry === 'down') nextSort = 'up';
        else if (currentSortIndustry === 'up') nextSort = 'down';

        await this.setState({
            currentSortTicker: 'default',
            currentSortDollarValue: 'default',
            currentSortIndustry: nextSort,
            currentSortPercentage: 'default',
            sortTypes: this.sortTypesIndustry[nextSort],
            sortingFlag: true
        })

    }

    sortPercentage = async () => {

        const { currentSortPercentage } = this.state;
        let nextSort;

        await this.setState({
            sortingFlag: false
        })

        if (currentSortPercentage === 'default') nextSort = 'up';
        if (currentSortPercentage === 'down') nextSort = 'up';
        else if (currentSortPercentage === 'up') nextSort = 'down';

        await this.setState({
            currentSortTicker: 'default',
            currentSortDollarValue: 'default',
            currentSortIndustry: 'default',
            currentSortPercentage: nextSort,
            sortTypes: this.sortTypesPercentage[nextSort],
            sortingFlag: true
        })

    }

    constructor(props) {
        super(props)

        this.state = {
            totalCompanyList: [],//total record

            currentTotalCompanyList: [], //list company name after in pagination
            currentSortTicker: 'default', //
            currentSortDollarValue: 'default', //
            currentSortIndustry: 'default', //
            currentSortPercentage: 'down', //
            sortTypes: this.sortTypesPercentage['down'],
            sortTypesName: this.sortTypesPercentage,
            sortingFlag: false,
            dataLoadCompany: false

        }
    }

    componentDidMount = async () => {
        const { totalCompanies } = this.props;

        await this.setState({
            totalCompanyList: totalCompanies,
            sortingFlag: true,
            dataLoadCompany: true
        });
    }

    componentDidUpdate = async (previousProps, previousState) => {
        const { totalCompanies } = this.props;

        if (previousProps.totalCompanies !== totalCompanies) {
            this.componentDidMount()
        }
    }

    onPageChangedCompany = async (data) => {
        const { sortTypes } = this.state
        await this.setState({
            dataLoadCompany: false
        })
        const { totalCompanyList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentTotalCompanyList = await [...totalCompanyList].sort(sortTypes.fn).slice(offset, offset + pageLimit);
        await this.setState({
            currentTotalCompanyList: currentTotalCompanyList,
            currentPageCompany: currentPage,
            totalPagesCompany: totalPages,
            dataLoadCompany: true
        });

    }

    render() {
        const { currentTotalCompanyList, dataLoadCompany, totalCompanyList, sortingFlag,
            currentSortTicker, currentSortDollarValue, currentSortIndustry, currentSortPercentage, sortTypes, sortTypesName } = this.state

        return (
            <Fragment>
                <div className="table-responsive">
                    {dataLoadCompany && totalCompanyList.length > 0
                        ? <table className="gi-p-table table table-striped table-active mb-0" role="presentation">
                            <thead className='text-nowrap'>
                                <tr>
                                    <th scope="col" onClick={this.sortTicker}>
                                        <strong>
                                            {IntlMessages('table_ticker')}&nbsp;
                                            <i className={`fa fa-${sortTypesName[currentSortTicker].className}`} />
                                        </strong>
                                    </th>
                                    <th scope="col" onClick={this.sortDollarValue}>
                                        <strong>
                                            {IntlMessages('table_dollar_value')}&nbsp;
                                            <i className={`fa fa-${sortTypesName[currentSortDollarValue].className}`} />
                                        </strong>
                                    </th>
                                    <th scope="col" onClick={this.sortIndustry}>
                                        <strong>
                                            {IntlMessages('table_industry')}&nbsp;
                                            <i className={`fa fa-${sortTypesName[currentSortIndustry].className}`} />
                                        </strong>
                                    </th>
                                    <th scope="col" onClick={this.sortPercentage}>
                                        <strong>
                                            {IntlMessages('table_percentage')}&nbsp;
                                            <i className={`fa fa-${sortTypesName[currentSortPercentage].className}`} />
                                        </strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {[...currentTotalCompanyList].sort(sortTypes.fn).map((value, i) => {
                                    return (<tr key={i}>
                                        <th scope="row"><strong>{value.tickerSymbol}</strong></th>
                                        <td><span>${Math.floor(value.value * 100) / 100}</span></td>
                                        <td><span>{value.industry}</span></td>
                                        <td><span>{Math.floor(value.weight * 100) / 100}%</span></td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        : ''
                    }
                </div>
                {sortingFlag ? <PaginationNew totalRecords={totalCompanyList.length} pageLimit={10} pageNeighbors={1} onPageChanged={this.onPageChangedCompany} /> : ''}

            </Fragment>
        )
    }
}

export default TotalCompanyTable