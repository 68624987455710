//default library
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

class InputComponentText extends Component {
    //default constructor
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this) //bind this keyword to handleOnChange function
        this.setLocalStorageData() //initial call for localStorage
        this.state = {
            fieldValue: ''
        }
    }

    //check localStorage is data is present or not if present then store
    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null) {
            await this.setState({
                fieldValue: localStorageData[`${this.props.name}`] ?? '',
            })
        }
    }

    //handle onChange event
    handleOnChange = async (e) => {

        await this.setState({
            fieldValue: e.target.value
        })
        //send to parent component
        this.props.inputValue(this.state.fieldValue)
    }

    render() {
        //destructuring props
        const { placeholder, name, readOnly, value, intl, textCenter } = this.props
        const { fieldValue } = this.state
        return (
            <>
                <input
                    type='text'
                    className={`form-control ${textCenter ? 'text-center' : ''}`}
                    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                    name={name ?? ''} //set field name
                    value={fieldValue ? fieldValue : value ? value : ''} //set field value
                    onChange={this.handleOnChange} //invoke on every key press
                    readOnly={readOnly ?? false} //check field is readOnly or not
                />
            </>
        )
    }
}

export default injectIntl(InputComponentText)