//default library
import React, { Component } from 'react'

//image import
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'
export class SuccessComponent extends Component {
    render() {
        return (
            <>
                <div className="valid-msg" style={{ display: this.props.display }}>
                    <img src={IMAGES.WHITE_CHECK_ICON} alt="" />
                    {IntlMessages('common_verify')}
                </div>
            </>
        )
    }
}

export default SuccessComponent