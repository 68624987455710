/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class SellDropdown extends Component {

    handleClick = async () => {
        this.props.openSellModal(true)
    }

    render() {
        const { disabled } = this.props
        return (
            <button className="sell-btn" type="button" onClick={this.handleClick} disabled={disabled}>
                {IntlMessages('button_sell')}
            </button>
        )
    }
}

export default SellDropdown