import * as defaultValue from "../../config/DefaultValue";
import { ConsoleLog } from "./ConsoleLog";

export const getDirection = () => {
    let direction = defaultValue.defaultDirection;

    try {
        if (localStorage.getItem('direction')) {
            const localValue = localStorage.getItem('direction');
            if (localValue === 'rtl' || localValue === 'ltr') {
                direction = localValue;
            }
        }
    } catch (error) {
        ConsoleLog('getDirection -> error', error);
        direction = defaultValue.defaultDirection;
    }
    return {
        direction,
        isRtl: direction === 'rtl',
    };
};

export const setDirection = (localValue) => {
    let direction = 'ltr';
    if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
    }
    try {
        localStorage.setItem('direction', direction);
    } catch (error) {
        ConsoleLog('setDirection -> error', error);
    }
};


export const getCurrentLanguage = () => {
    let language = defaultValue.defaultLocale;
    try {
        language =
            localStorage.getItem('currentLanguage') &&
                defaultValue.localeOptions.filter(
                    (x) => x.id === localStorage.getItem('currentLanguage')
                ).length > 0
                ? localStorage.getItem('currentLanguage')
                : defaultValue.defaultLocale;
    } catch (error) {
        ConsoleLog(
            'getCurrentLanguage -> error',
            error
        );
        language = defaultValue.defaultLocale;
    }
    return language;
};
export const setCurrentLanguage = (locale) => {
    try {
        localStorage.setItem('currentLanguage', locale);
    } catch (error) {
        ConsoleLog(
            'setCurrentLanguage -> error',
            error
        );
    }
};