/* 
    Giraffe Language Texts
*/

module.exports = {

    /* Button Name */
    'button_next': 'הַבָּא',
    'button_ok': 'בסדר',
    'button_continue': 'לְהַמשִׁיך',
    'button_verify': 'תאשר',
    'button_cancel': 'לְבַטֵל',
    'button_update': 'עדכון',
    'button_confirm': 'לְאַשֵׁר',
    'button_transfer': 'לְהַעֲבִיר',
    'button_submit': 'שלח',
    'button_contact_us': 'צור קשר',
    'button_fund': `ממן את החשבון שלך`,
    'button_buy_fund': 'לְהַעֲבִיר',
    'button_buy_invest': 'להשקיע',
    'button_sell': 'מכירה',
    'button_view_details': `הצג פרטים`,
    'button_invest': `השקיעו בתיק`,
    'button_create': `לִיצוֹר`,
    'button_close': `סגור`,
    'button_connect': `לְחַבֵּר`,
    'button_back': `חזור`,
    'button_change': `שינוי`,

    /* Common text */
    'logout': 'להתנתק',
    'logout2': 'להתנתק',
    'click_here': 'לחץ כאן',
    'of': 'שֶׁל',
    'resend_code': 'שלח קוד שוב',
    'common_verify': 'מְאוּמָת!',
    'common_verify2': '(מְאוּמָת)',
    'thank_you': `תודה`,
    'unlink': `בטל את הקישור`,
    'edit': 'לַעֲרוֹך',
    'hello': `שלום`,
    'note': `הערה`,
    'minimum': `מִינִימוּם`,
    'from': `מ`,
    'page': `עמוד :`,
    'previous': `קודם`,
    'next': `הַבָּא`,
    'week': `שָׁבוּעַ`,
    'faqs': `שאלות נפוצות`,
    'loading': 'טוען...',
    'gift': `מתנה`,
    'total': `סך הכל`,
    'complete': `לְהַשְׁלִים`,
    'to': `ל`,
    'from2': `מ`,

    /* Symbol */
    '$': '$',
    '%': '%',

    /* Header and Footer */
    'header_login': `התחברות`,
    'header_register': `הירשם`,
    'header_rtl': `משמאל לימין`,
    'header_ltr': `מימין לשמאל`,
    'footer_giraffe': `מה זה ג'ירף 500?`,
    'footer_products': `מוצרים`,
    'footer_about': `על אודות`,
    'footer_blog': `בלוג`,
    'footer_business': `לעסקים`,
    'footer_education': `חינוך`,
    'footer_gifts': `מתנות`,

    /* Placeholder */
    'placeholder_type_here': 'הקלד פה',
    'placeholder_auto_select': 'כתובת רחוב, עיר, מדינה',
    'placeholder_apartment_unit': 'מספר דירה, סוויטה או יחידה',
    'placeholder_country': 'מדינה',
    'placeholder_email': 'כתובת דוא"ל',
    'placeholder_ticker': 'zzzxxx',
    'placeholder_investing_exp': 'ניסיון בהשקעות',
    'placeholder_industry': 'תַעֲשִׂיָה',
    'placeholder_position': 'עמדה',
    'placeholder_dob': 'MM/DD/YYYY',
    'placeholder_phone': '(123) 456 - 7890',
    'placeholder_zip_code': '123456',
    'placeholder_ssn': '111-11-1111',
    'placeholder_currency_1': '1.00',
    'placeholder_currency_10': '10.00',
    'placeholder_currency_100': '100.00',
    'placeholder_select_value': 'בחר ערך...',
    'placeholder_change_name': 'הפרישה שלי',

    /* verify email page */
    'thank_you1': `תודה`,
    'verify_email_t1': `אנא לחץ על הקישור בדוא"ל האימות כדי להמשיך`,
    'resend_email': `שלח אימייל מחדש`,
    'wrong_email': `מייל שגוי?`,
    'sign_out': `התנתק`,

    /* Session time out modal */
    'session_timeout1': `זמן קצוב להפעלה`,
    'session_timeout2': `פסק זמן של הפגישה`,
    'session_timeout_t1': `ההפעלה הנוכחית עומדת לפוג בעוד`,
    'session_timeout_t2': `שניות.`,
    'session_timeout_t3': `הוצאנו אותך מהחשבון כדי שהמידע הפיננסי שלך יישאר בטוח. כל עסקאות שאושרו נשמרות!`,
    'sign_in': `להתחבר`,

    /* 1. Onboarding Journey */
    /* Journey Name */
    'personal_details': 'פרטים אישיים',
    'employment_details': 'פרטי העסקה',
    'investment_details': 'פרטי השקעה',
    'complete_application': 'השלם יישום',
    'personal': 'אישי',
    'employment': 'תעסוקה',
    'investment': 'הַשׁקָעָה',
    'details': ' פרטים',
    'information': 'מֵידָע',

    /* Journey Last Page Text */
    'personal_details_last': 'אחרון כאן!',
    'employment_details_last': 'להתקרב...',
    'investment_details_last': `עוד אחד וסיימנו`,
    'complete_application_last': `כן! זה קו הסיום`,

    /* Start onBoarding page */
    'onboarding_start_t1': `בוא נפתח את החשבון שלך`,
    'onboarding_start_t2': `כיועץ השקעות רשום ב-SEC, אנו נדרשים לשאול מספר שאלות מזהות.`,
    'onboarding_start_t3': `תהליך ההצטרפות אורך כ-5 דקות וכולל את השלבים הבאים:`,
    'onboarding_start_t4': `השותף שלנו הוא Drivewealth, סוחר ברוקר רשום ב-FINRA ומבוטח ב-SIPC.`,
    'onboarding_start_t5': `כדי לקרוא עוד עליהם`,
    'onboarding_start_t6': `המתנה שלך מ`,

    /* 1.1 Personal Detail */
    'personal_detail_info1': 'אנו מצפינים את כל המידע שאתה מזין בסטנדרטים הגבוהים ביותר.',
    'personal_detail_info2': 'כמוסד פיננסי אנו נדרשים לבקש מידע מזהה כדי למנוע הלבנת הון',
    'personal_detail_info3': 'כמוסד פיננסי, אנו נדרשים על פי חוק הפטריוט לאמת את זהותך באמצעות מספר תעודת זהות שלך.',
    'verification_info': 'אנו נשלח סיסמה חד פעמית כ-SMS למספר הטלפון שסופק על מנת לאמת את המידע שלך ולאפשר אימות רב-גורמי',
    'personal_page1_label': `מספר טלפון נייד בארה"ב`,
    'personal_page2_label1': `אנא הזן את שמך הפרטי החוקי`,
    'personal_page2_label2': `אנא הזן את שם המשפחה החוקי שלך`,
    'personal_page2_label3': `אנא הזן את שמך האמצעי החוקי (אופציונלי)`,
    'personal_page3_label': `מה תאריך הלידה שלך?`,
    'personal_page4_label1': `מה הכתובת החוקית שלך?`,
    'personal_page4_label2': `מספר דירה / יחידה`,
    'personal_page4_label3': `מיקוד`,
    'personal_page5_label': `מהי האזרחות העיקרית שלך?`,
    'personal_page6_label': `האם אתה משלם מס בארה"ב?`,
    'personal_page7_label': `מהו מספר תעודת זהות או ITIN שלך?`,
    'personal_verify_label1': `קוד שש ספרות -`,
    'personal_verify_label2': `נשלח ל-+1`,

    /* 1.2 Employment Details */
    'employment_detail_info1': 'על מנת לפתוח עבורך חשבון השקעה, אנו צריכים לקבל כמה פרטים קלים על המעסיק שלך',
    'employment_detail_info2': 'שאלות לגבי הגבלת חשבון',
    'employment_detail_info3': `מצבים אלו אינם חלים על רוב הלקוחות שלנו.`,
    'employment_detail_info4': 'אבל אם אתה יודע, אתה יודע!',
    'employment_detail_info5': 'הכנסה שנתית משוערת',
    'employment_detail_info6': 'אנו נדרשים לשאול זאת כדי שנדע את השפעות המס המשוערות של תיק ההשקעות שלך ונוכל לבנות את ההשקעות שלך כך שיתאימו לצרכים שלך :)',
    'employment_page1_label': 'מה מצב התעסוקה הנוכחי שלך?',
    'employment_page2_label': `מה שם החברה שלך?`,
    'employment_page2_label2': 'באיזו תעשייה אתה עובד?',
    'employment_page2_label3': 'מה תפקידך בחברה?',
    'employment_page3_label1': 'האם אתה קשור או מועסק בבורסה, חברה בבורסה או FINRA, או סוחר בניירות ערך עירוני?',
    'employment_page3_label2': 'האם אתה איש שליטה בחברה ציבורית? דירקטור, נושא משרה או בעל מניות של 10%?',
    'employment_page3_label2_1': 'שם החברה',
    'employment_page3_label2_2': 'סמל טיקר',
    'employment_page3_label3': 'האם אתה אדם חשוף פוליטית בהווה או לשעבר או פקיד ציבור (כולל ארה"ב וחוץ)?',
    'employment_page3_label3_1': 'שם הפקיד ובני המשפחה',
    'employment_page4_label': 'הכנסה שנתית',
    'employment_static_t1': 'בסדר אין בעיה!',
    'employment_static_t2': 'עם זאת אתה מחויב להודיע ​​בכתב למעסיק שלך על כוונתך לפתוח חשבון',
    'employment_static_t3': `אנא ספק את שמותיהם של בני משפחתו הקרובים של אותו פקיד ובני פקיד (כולל בני זוג לשעבר)`,
    'employment_static_t4': `נא לרשום את שם החברה וסמל הטיקר שלה`,

    /* 1.3 Investment Details */
    'investment_detail_info1': 'שווי נקי משוער',
    'investment_detail_info2': `זה כדי לעזור לנו להבין אילו השקעות הכי מתאימות לך ולוודא שאתה לא משקיע עם יותר מדי סיכון`,
    'investment_detail_info3': `ניסיון בהשקעות`,
    'investment_detail_info4': `זהו רעיון משוער כך שנדע כיצד לוודא שיש לך את כל הידע שאתה צריך כדי לבצע את ההשקעות הנכונות`,
    'investment_detail_info5': `סובלנות לסיכון`,
    'investment_detail_info6': `אנחנו רוצים לוודא שאתה לא לוקח על עצמך יותר מדי סיכונים, אז ספר לנו כמה אתה מוכן לקחת על עצמך! שימו לב שזה לא משפיע על התיק שאנחנו בונים עבורכם כרגע`,
    'investment_page1_label': 'שווי נקי הכולל',
    'investment_page2_label': 'מהי חווית ההשקעה שלך?',
    'investment_page3_label': 'כמה סיכון אתה מוכן לקחת? גרור את הדגל כדי ליידע אותנו:',

    /* 1.4 complete application */
    'complete_journey_info1': `סיימתם את ההטמעה שלנו - לאחר שנאמת את הפרטים שסיפקתם, תוכלו לגשת לחוויית הג'ירף המלאה ולהשקיע בהתאם לערכים שלכם, מבלי לוותר על תמורה.`,
    'complete_application_t1': `אני מסכים גם לג'ירף`,
    'complete_application_t2': `מדיניות פרטיות`,
    'complete_application_t3': `תנאים והגבלות,`,
    'complete_application_t4': `להניע עושרשל`,
    'complete_application_t5': `גילויים`,
    'complete_application_t6': `הֶסכֵּם.`,
    'complete_application_t7': `.הֶסכֵּם`,
    'complete_application_t8': `גילויים`,

    /* 1.5 waiting page */
    'waiting_page_t1': 'הפרטים שלך',
    'waiting_page_t2': 'נמצא בעיבוד',
    'waiting_page_t3': 'זה יכול לקחת עד דקה אחת.',
    'waiting_page_t4': `נשלח לך אימייל לאחר שתאושר.`,

    /* 2. Dashboard */
    /* 2.1 Route Name */
    'dashboard_home': 'בית',
    'dashboard_add_portfolio': 'הוסף תיק עבודות',
    'dashboard_activity': 'פעילות',
    'dashboard_account_setting': 'הגדרות חשבון',
    'dashboard_taxes': 'מיסים ותיעוד',
    'dashboard_my_gift': 'המתנות שלי',
    'dashboard_buy_gift': 'תקנה מתנה',
    'dashboard_giraffe_view': `נוף של ג'ירף`,
    'dashboard_giraffe_learn': `ג'ירף למד`,
    'dashboard_mini_mba': 'מיני-MBA',
    'dashboard_current_investment': 'השקעות שוטפות',
    'dashboard_create_investment': 'צור השקעה',
    'dashboard_learn': 'לִלמוֹד',
    'dashboard_gift_given': `מתנות שנתתי`,

    /* KYC error */
    'kyc_trouble_data': `אנו נתקלים בבעיות באימות הנתונים שלך`,
    'kyc_address': `אנו נתקלים בבעיות באימות הכתובת`,
    'kyc_address_label': `אנא אמת או שנה את הכתובת החוקית שלך`,
    'kyc_apartment_label': `דירה/יחידה`,
    'kyc_ssn': `אנו מתקשים לאמת את מספר תעודת זהות שלךאנו מתקשים לאמת את מספר תעודת זהות שלך`,
    'kyc_dob': `אנו מתקשים לאמת את תאריך הלידה שלך`,
    'kyc_dob_label': `אנא אמת או שנה את תאריך הלידה שלך`,
    'kyc_name': `אנו מתקשים לאמת את שמך`,
    'kyc_number': `אנו מתקשים לאמת את מספר הטלפון שלך`,
    'kyc_number_label': `אנא אמת או שנה את מספר הטלפון שלך`,
    'kyc_citizenship': `אנו מתקשים לאמת את האזרחות שלך`,

    /* Home Page */
    'home_heading': `הנה סקירה כללית של ההשקעה שלך`,
    'home_today': `השקיעו היום {br} למחר טוב יותר...`,
    'home_tomorrow': `למחר טוב יותר...`,
    'home_processing': `גדול! מעבד את תיק ההשקעות שלך ב-{basketName} כעת.`,
    'home_portfolio': `תיק עכשיו.`,
    'home_fund': `מממן את העתיד שלך {br} היום`,
    'home_future': `עתיד היום`,
    'home_withdraw': `למשוך כסף`,
    'home_tooltip': `משך כסף מחשבון הג'ירפה שלך לחשבון הבנק שלך`,
    'home_banner_t1': `שני דברים עיקריים לעשות`,
    'home_banner_t2': `בנה את האינדקס שלך {br} תן מתנה`,
    'home_gift_date': `תאריך מימוש`,
    'home_static_t1': `{span} זוהי תחזית היפותטית המבוססת על התשואות ההיסטוריות של S&P 500`,
    'home_bank_connect': `כדי להשקיע, {br} התחבר לחשבון הבנק שלך`,

    /* Transaction card heading */
    'transaction_total_account': `סך החשבון`,
    'transaction_total_current': `סך ההשקעה הנוכחי`,
    'transaction_total_cash': `סך הכל מזומן`,
    'transaction_earning': `רווחים`,
    'transaction_current': `ערך נוכחי:`,
    'transaction_projection': `הקרנה של 20 שנה`,
    'transaction_total_value': `ערך מוחלט`,
    'transaction_redeemed_value': `ערך פדיון`,

    /* Basket Details Page */
    'basket_historical': `תשואות היסטוריות`,
    'basket_future': `תחזית עתידית`,
    'basket_not_enough': `אין מספיק נתונים כדי להציג`,
    'basket_chart_text1': `תרשים הוא השוואה היפותטית. השקעה כרוכה בסיכון להפסד והביצועים אינם מובטחים.`,
    'basket_chart_text2': `השונות החזויה של תיק ההשקעות שלך לעומת S&P 500 באותו זמן`,
    'basket_your_value': 'הערכים שלך',
    'basket_companies': `חברות`,
    'basket_industry': `תעשיות`,
    'basket_excluded_companies': `חברות שלא נכללו`,
    'basket_view_list': `רשימת צפיה`,
    'basket_companies_list': `{totalCompany} סה"כ חברות`,
    'basket_excluded': `לא נכלל`,
    'basket_excluded1': `{excludedCompany} לא נכלל`,
    'basket_expense': `יחס הוצאות`,
    'basket_text1': `החברות הבאות עמדו בקריטריונים לתשואה גבוהה מבלי להתפשר על הערכים שלך.`,
    'basket_text2': `החברות הבאות אינן נכללות מהפורטפוליו שלך בגלל הערכים שבחרת`,
    'basket_expense_text2': `ראה להלן פירוט של הוצאות תיק זה:`,
    'basket_expense_breakdown': `פירוט הוצאות:`,
    'basket_boosted': `לְהַגבִּיר`,
    'basket_boosted2': `מוגברת`,
    'basket_expense_ratio': `יחס הוצאות כולל`,
    'basket_change_name': 'שנה שם תיק:',
    'basket_excluded_text': `בחר את הערכים שלך למעלה כדי להתחיל לא לכלול חברות ולהתאים אישית את S&P 500`,

    /* Bank connect */
    'bank_text1': `כדי להשקיע {br}, התחבר לחשבון הבנק שלך`,
    'bank_text2': `אנא התחבר לחשבון הבנק שלך`,
    'bank_text3': `כדי למשוך מזומן, {br} אנא התחבר לחשבון הבנק שלך`,

    /* Fund Modal */
    'fund_select_amount': `בחר כמות`,
    'fund_account': `מתוך חשבון:`,
    'fund_portfolio': 'לתיק:',
    'giraffe_cash_account': `חשבון הג'ירף שלך במזומן`,
    'fund_confirm_transaction': 'אנא אשר את העסקה שלך',
    'fund_preparing': 'הכנת העברה',
    'fund_success': 'העברה הצלחה!',
    'fund_your_portfolio': 'ממן את תיק ה-{br} שלך',
    'bank_transfer': 'עיבוד העברות בנקאיות נמשך 2-3 ימים.',
    'fund_transfer_market_close': 'אנו נשלים את העסקה בזמן שהבורסה פתוחה.',
    'fund_transfer_market_open': 'העסקה שלך תושלם תוך 5-10 דקות.',

    /* Buy Modal */
    'buy_head1': `הוסף כסף לשלך`,
    'buy_head2': `תיק עבודות עשה טוב`,

    /* Sell Modal */
    'sell_head': 'מכירה',
    'sell_portfolio': 'מתוך תיק העבודות:',
    'sell_amount': `כמות`,
    'sell_stock': `למכור מניות`,

    /* Withdraw Modal */
    'withdraw_head1': `משיכת כסף לחשבון הבנק שלך`,
    'withdraw_text1': `אתה לא יכול להעביר יותר ממה שיש לך במזומן`,
    'withdraw_account': `לקחת בחשבון:`,
    'withdraw': `לָסֶגֶת`,
    'withdraw_charge': `השותפים שלנו גובים $0.25 עבור העברות כספים יוצאות`,
    'withdraw_bank_account': 'משך לחשבון הבנק שלך {br}',
    'withdraw_text2': `* לג'ירף ול- Drivewealth יש תקופת תיבול שמונעת חיובים מיותרים מהמשתמשים שלנו.אתה יכול למשוך מזומן מחשבון הג'ירפה שלך לפחות חמישה ימים לאחר הפקדתו.`,
    'withdraw_text3': 'המזומן שלך זמין למשיכה: {amount}*',

    /* Create Basket */
    'create_basket_text1': `בחר את הערכים הדרושים עבורם`,
    'create_basket_text2': `ליצור עתיד מזהיר`,
    'create_basket_text3': 'יצירת תיק העבודות שלך',
    'create_basket_text4': 'כולל חברות מ-S&P 500 שמתיישרות עם הערכים שלך ולמעט חברות המובילות שלא',
    'create_basket_text5': `תיק ה"עשה-טוב" שלך`,
    'create_basket_text6': `להתחיל עם:`,
    'create_basket_text7': `המתן בסבלנות ל:`,
    'create_basket_text8': `ויהיה לך:`,
    'view_portfolio': 'הצג תיק עבודות',
    'create_basket_boost': `לְהַגבִּיר!`,
    'create_basket_unboost': `ביטול חיזוק`,
    'create_basket_remove': `לְהַסִיר`,
    'create_basket_trending': `ערכים מגמתיים`,
    'create_basket_more': `עוד ערכים`,
    'create_basket_head_t1': `אנא בחר מבין הערכים הבאים כדי למקד את ההשקעה שלך`,
    'create_basket_card_t1': `התאם אישית את S&P 500 על ידי אי הכללת חברות`,
    'create_basket_text9': `בחר את הערכים הדרושים ליצירת עתיד מזהיר.`,
    'create_basket_text10': `רוצה להגביר את ההשפעה שלך?`,
    'create_basket_text11': `אם תבחר להגביר, תסיר עוד חברות שאינן תואמות את הערכים שלך`,
    'create_basket_text12': `איך בחרנו את מי להדיר?`,

    /* 2.5 Account Setting */
    'account_setting_my_account': 'החשבון שלי',
    'account_setting_bank': 'חשבון בנק',
    'account_setting_status': 'מצב תעסוקה נוכחי',
    'account_setting_broker': 'סוחר ברוקר וחשיפה פוליטית',
    'account_setting_investing': 'מידע על השקעות',
    'account_setting_delete': 'מחק חשבון',
    'account_delete': 'מחק חשבון',
    'account_delete_text': 'על מנת למחוק את חשבונך, בשלב זה עליך לפנות תחילה לתמיכת הלקוחות. סליחה על אי הנוחות.',

    /* 2.4 Activity Page */
    'activity_tab1': 'עסקאות',
    'activity_tab2': 'היסטוריית השקעות',
    'activity_tab3': 'דיבידנדים',
    'activity_tab4': 'דמי ייעוץ',

    /* 2.5.1 Account Setting Modal */
    'account_setting_email': 'אימייל',
    'account_setting_phone': 'מספר טלפון בארה"ב',
    'account_setting_first': 'שם פרטי חוקי',
    'account_setting_middle': 'שם אמצעי חוקי (אופציונלי)',
    'account_setting_last': 'שם משפחה חוקי',
    'account_setting_address': 'כתובת חוקית',
    'account_setting_citizenship': 'אזרחות ראשית',
    'account_setting_ssn': 'מספר SSN',
    'account_setting_dob': 'תאריך לידה',
    'account_setting_status1': 'סטָטוּס',
    'account_setting_company_name': 'שם החברה',
    'account_setting_industry': 'תַעֲשִׂיָה',
    'account_setting_position': 'עמדה',
    'account_setting_broker_dealer': 'שיוך מתווך/עוסק',
    'account_setting_public_company': 'איש שליטה בחברה ציבורית',
    'account_setting_political': 'איש חשיפות פוליטיות',
    'account_setting_investing_exp': 'ניסיון בהשקעות',
    'account_setting_net_worth': 'שווי נקי',
    'account_unlink_modal': 'אינך יכול לבטל את הקישור של חשבון הבנק שלך בזמן שהכסף מועבר. המתן עד להשלמת ה-{type} שלך ואז נסה שוב!',

    /* Taxes and Documentation */
    'taxes_head_text': `מיסים ותיעוד`,

    /* Gift Page */
    'gift_head_text': 'מתנת {giftGiver} שלך מוכנה',
    'gift_head_text2': `הנה סקירה כללית של ההשקעה שלך`,
    'gift_static_text1': 'תוכל גם לשנות {br} את ערך ההשפעה של כרטיס המתנה שלך!',
    'gift_static_text2': 'ערך ההשפעה של כרטיס המתנה שלך!',
    'gift_static_text3': 'ראה את האפשרויות שלך למטה:',
    'gift_static_text4': `ג'ירף למד`,
    'gift_static_text5': 'למד כיצד להשקיע בהתאם לערכים שלך ולקבל החלטות פיננסיות אחראיות עבורך ועבור כדור הארץ.',
    'gift_static_text6': 'צפה בקורס',
    'gift_switch_value': 'החלף ערכים',
    'gift_read_more': 'קרא עוד',
    'gift_show_less': 'הראי פחות',
    'gift_change_value': 'כן! שנה את הערך ל',
    'gift_accept_gift': 'קבלו את המתנה',
    'gift_cash_out': 'לפדות את',
    'gift_redeemed_ready': `המתנה שלך מוכנה לצאת!`,
    'gift_redeemed_text1': `והעולם יהיה טוב יותר בשביל זה.`,
    'gift_redeemed_button1': `לממש את סל המניות שלך`,
    'gift_redeemed_button2': `קרא עוד`,
    'gift_cash_out_text1': 'בטוחים שאתם רוצים לפדות את כל הסל שלכם?',

    /* Gift accept modal */
    'gift_accept_text1': `מתנה מומשה!`,
    'gift_accept_text2': `ההשקעה שלך נמצאת בשלב סופי`,
    'gift_accept_text3': `עיבוד העברת הכסף וההשקעה יידרשו 2-3 ימי עסקים. לאחר מכן תוכל להתחיל לעקוב אחר ההשקעה הטובה שלך!`,

    /* Giraffe Learn */
    'giraffe_learn_welcome': `ברוכים הבאים!`,
    'giraffe_static_text1': `קיבלת את מתנת הידע ממישהו שאכפת לו ממך.`,
    'giraffe_start_learning': `בואו נתחיל ללמוד`,
    'giraffe_mini_mba': `מיני-MBA`,
    'giraffe_mini_mba_text1': `כלול גם במתנה שלך:`,
    'giraffe_mini_mba_text2': `סדנה בלעדית בת יומיים.`,
    'giraffe_mini_mba_text3': `התוכנית תוכננה ללכוד את נקודות השיא של בתי ספר ל-MBA מהליגה הבכירה של קיסוס ופותחה במקור עבור האקדמיה של Microsoft Venture.`,
    'giraffe_mini_mba_register': `הירשמו עוד היום`,
    'giraffe_work': `איך ג'ירף {br} לומד עבודה?`,
    'giraffe_work_learn': `ללמוד עבודה?`,
    'giraffe_work_text1': `האם אי פעם תהיתם איך חברות {br} והמשקיעים משתמשים בשוק היום? {br} איך זה בכלל הומצא?`,
    'giraffe_work_text2': `והמשקיעים משתמשים בשוק היום?`,
    'giraffe_work_text3': `איקס`,
    'giraffe_watch': `צפה עכשיו`,
    'giraffe_learn_list_topics': `צפה ברשימה המלאה של הנושאים`,
    'giraffe_learn_back': `חזרה ללוח זמנים`,
    'giraffe_learn_path': `מסלול לימוד הג'ירפה שלך`,
    'giraffe_learn_of_week': `{title} של השבוע {forWeek}`,
    'giraffe_learn_achievement_text1': `בכל שלב של הדרך, תלמד ותשיג הישגים`,
    'giraffe_learn_achievement_text2': `עבור כל הישג, תוכל לשתף עם חברים ולהפוך למומחה בעולם ההשפעה`,

    /* Giraffe Mini-MBA */
    'mini_mba': 'מיני-MBA',
    'mini_mba_text1': 'קיבלת את מתנת הידע {br} ממישהו שאכפת לו ממך.',
    'mini_mba_text2': `סדנה בלעדית בת יומיים.`,
    'mini_mba_text3': `התוכנית תוכננה ללכוד את נקודות השיא של בתי ספר ל-MBA מהליגה הבכירה של קיסוס ופותחה במקור עבור האקדמיה של Microsoft Venture.`,
    'mini_mba_register': `הירשמו עוד היום`,
    'mini_mba_text4': `אנחנו צריכים כאן כותרת על האירוע`,
    'mini_mba_text5': `אנחנו צריכים תיאור על האירוע כאן`,
    'mini_mba_text6': `בהובלת פרופסורים מובילים בבתי ספר לעסקים.`,
    'mini_mba_secure': `הבטיחו את מקומכם עוד היום.`,

    /* Giraffe Learn Banner */
    'giraffe_banner_10': `10%`,
    'giraffe_banner_75': `$75`,
    'giraffe_banner_text1': `הגדרת תיק העבודות שלך נמצאת ב:`,
    'giraffe_banner_text2': `השלם את רישום החשבון שלך כדי לממש את כרטיס המתנה שלך.`,
    'giraffe_banner_text3': `חבילת מניות שינויי אקלים`,
    'giraffe_banner_text4': `פורטפוליו בעל השפעה גבוהה של חברות התומכות בשינויי אקלים.`,
    'giraffe_banner_text5': `הזן מידע משפטי`,
    'giraffe_banner_text6': `מימוש כרטיס המתנה שלך`,
    'giraffe_banner_text7': `הגדל את עץ ההשפעה שלך.`,
    'giraffe_banner_text8': `מימוש מתנה`,

    /* Modal Header */
    'modal_submit': `מגיש לך נתונים`,
    'modal_connect_bank': `מחבר בנק...`,
    'modal_fund_account': `נא לממן את חשבונך`,

    /* Table Fields */
    'table_not_range': `אין עסקאות קיימות בטווח התאריכים שצוין`,
    'table_date': 'תַאֲרִיך',
    'table_description': 'תיאור',
    'table_amount': 'כמות',
    'table_range': 'טווח תאריכים',
    'table_ticker': 'טֵלֵפּרִינטֶר',
    'table_dollar_value': 'ערך דולר',
    'table_industry': 'תַעֲשִׂיָה',
    'table_percentage': 'אחוז מהסל',
    'table_document': 'שם המסמך',
    'table_account_name': 'שם החשבון',
    'table_form_type': 'סוג טופס',
    'table_status': 'סטָטוּס',
    'table_trade': 'אישור מסחר',
    'table_no_data': 'אין נתונים בטווח תאריכים ספציפי',

    /* Gift Landing Page */
    'gift_landing_impact': 'מתנת ההשפעה',
    'gift_landing_impact_button': 'מתנת ההשפעה שלך',
    'gift_include': 'מתנת ההשפעה שלך כוללת:',
    'gift_include_basket': '1. סל מניות',
    'gift_include_basket_description': 'תיק מניות בעל השפעה רבה שתומכת בערכת הערך לבחירתך.',
    'gift_include_tree': 'עץ ההשפעה',
    'gift_include_tree_description': 'מזרע צומח עץ. צפו בהשפעה שלכם גדלה עם כל מתנה שאתם נותנים. אחרת ההשפעה מתחזקת ומתעצמת.',
    'gift_include_start': 'אנו מקווים שהמתנה הזו תעזור לכם להתחיל להשקיע בעתיד, גם שלכם וגם בעולם.',
    'gift_include_start_button': 'התחל את המסע שלך',
    'gift_faq': 'יש לך שאלות לגבי מימוש המתנה שלך?',
    'gift_faq_please_see': 'אנא ראה שלנו',
    'gift_faq_help': 'מרכז עזרה',
    'gift_faq_article': 'כדי לראות כמה מאמרים שעשויים לעזור לך',
    'gift_footer_text1': 'שנה ללא דמי ניהול',
    'gift_footer_text2': `כרטיסי מתנה של ג'ירף אינם ניתנים להחזר ולתושבי ארה"ב ולאנשים בלבד. ארבעה קווים+`,
    'question1': `מה אני צריך לעשות כדי לממש את המתנה שלי?`,
    'answer1': `על מנת לממש את המתנה שלך, תצטרך לפתוח חשבון תיווך עם השותפים שלנו ב-FINRA ועם מבוטחי SIPC, Drivewealth, וכן להפוך ללקוח של יועץ ההשקעות הרשום ב-SEC Giraffe Invest, Inc. על מנת לעשות זאת , תצטרך לענות על כמה שאלות אישיות כדי שנוכל לאמת את זהותך.`,
    'question2': `כיצד אוכל לסמוך על כך שהמידע שלי בטוח?`,
    'answer2': `ג'ירף משתמש בהצפנה מקצה לקצה והנתונים שלך נשמרים בסטנדרטים הגבוהים ביותר. אנו עובדים עם שותפים כמו Stripe ו-Wells Fargo ואחרים המפעילים את אותן אפליקציות כמו Sofi, Betterment, Robinhood ו-Venmo`,
    'question3': `איפה המתנה שלי עכשיו?`,
    'answer3': `הערך במזומן של המתנה שלך מוחזק כעת אצל השותף הבנקאי שלנו, וולס פארגו. לאחר שתסיימו את תהליך ההצטרפות, תוכלו לממש את המתנה שלכם, בלחיצת כפתור! אתה גם תוכל להשקיע יותר ויותר, הכל בערכים החשובים לך...`,
    'redeemed_error_modal': 'אופס!',
    'redeemed_error_modal_text1': 'נראה שהמתנה הזו כבר מומשה.',
    'redeemed_error_modal_text2': 'אנא צור קשר',
    'redeemed_error_modal_text3': 'support@giraffe.us',
    'redeemed_error_modal_text4': 'אם אתה חושב שנפלה טעות.',

    /* Error page*/
    'error_404': 'אופס! 404',
    'error_log_in': 'התחברות',
    'error_register': 'הרשמה מראש',
    'error_notFound': 'הדף שביקשת לא נמצא.',
    'error_validUrl': 'נא להזין דף חוקי או',
    'error_returnHome': 'חזור לדף הבית שלנו :)',
    'error_fixURL': 'אנחנו כאן כדי להישאר, אנחנו לא הולכים לשום מקום. אתה רק צריך לתקן את כתובת האתר!',

    /* Error Message */
    'error_max_length': `מצטערים, אתה יכול להזין עד {number} תווים בלבד`,
    'error_max_length_36_name': `מצטערים, אתה יכול להזין רק עד 36 תווים עבור השם שלך.`,
    'error_max_length_255': `מצטערים, אתה יכול להזין רק עד 255 תווים עבור השם שלך.`,
    'error_max_length_64': `מצטערים, אתה יכול להזין רק עד 64 תווים עבור השם שלך.`,
    'error_max_length_1000': `משתמשים אינם יכולים להזין יותר מ-1,000 תווים בשדה.`,
    'error_maximum_amount': `מקסימום סכום $9,999,999,999,999,998`,
    'error_minimum_amount_fund': `סכום המימון המינימלי הוא $100`,
    'error_minimum_amount_withdraw': `סכום המשיכה המינימלי הוא $10`,
    'error_minimum_amount_invest': `סכום ההשקעה המינימלי הוא $100`,
    'error_minimum_amount_sell': `סכום המכירה המינימלי הוא $100`,
    'error_first_name': `מצטערים, השם הפרטי שלך עשוי להכיל אותיות, נקודות, אפוסתרופים ורווחים אך מתחיל באות בלבד`,
    'error_middle_name': `מצטערים, השם האמצעי שלך עשוי להכיל אותיות, נקודות, אפוסתרופים ורווחים אך מתחיל באות בלבד`,
    'error_last_name': `מצטערים, שם המשפחה שלך עשוי להכיל אותיות, נקודות, אפוסתרופים ורווחים אך מתחיל באות בלבד`,
    'error_not_valid_dob': `אופס! נראה שלא הזנת תאריך חוקי`,
    'error_dob_under_18': `מצטער! אנחנו לא יכולים כרגע לאפשר לאנשים מתחת לגיל 18 להירשם! בדוק עם ההורים שלך ותראה מה אתה יכול לעשות :)`,
    'error_invalid_address': `מצטערים, כתובת זו היא כתובת לא חוקית`,
    'error_invalid_citizenship': `מצטערים, למרבה הצער איננו יכולים לקבל אזרחים בעלי אזרחות זו`,
    'error_invalid_ssn_number': `אופס! נראה שלא הזנת מספר SSN חוקי`,
    'error_invalid_annual_income': `לא ניתן להעלות הכנסה שנתית לסכום של 9,223,372,036,854,775,295`,
    'error_invalid_net_worth': `לא ניתן להעלות את השווי הנקי לסכום של 9,223,372,036,854,775,295`,
    'error_invalid_verification_code': `הקוד הזה שגוי, אנא נסה שוב`,
    'error_ticker_max_length': `מצטערים, אתה יכול להזין רק עד 10 תווים עבור סמל הטיקר.`,
    'error_ticker_message': `מצטערים, אתה יכול להזין רק סמל טיקר חוקי`,
    'error_update_call_error': `אופס! משהו השתבש. לא יכולנו לשלוח את המידע שלך בשלב זה. אנא נסה שוב בעוד זמן מה.`,
    'error_try_again': `בבקשה נסה שוב מאוחר יותר`,
    'error_not_enough_balance': `מצטערים, אינך יכול להשקיע בתיק שלך מכיוון שאין לך מספיק יתרה בחשבון המזומנים שלך. נא לממן את חשבונך.`,
    'error_something_wrong': `אה הו, משהו השתבש בעיבוד המידע שלך.`,
    'error_multiple_account_holder': `נראה שהמידע שלך עשוי להיות בשימוש על ידי מספר בעלי חשבונות.`,
    'error_contact_support': `אנא צור קשר עם התמיכה שלנו.`,
    'error_contact_support2': `אנא צור קשר עם תמיכת הלקוחות אם זה לא תואם את הפעילות שלך.`,
    'error_connecting_account': `אנא ודא שהשם שלך במערכת שלנו נכון ואם לא, תקן זאת מדף הגדרות החשבון או צור קשר עם תמיכת הלקוחות.`,
    'error_connecting_account1': `אירעה שגיאה בעת ההתחברות לחשבון הבנק שלך דרך Plaid.`,
    'error_money_route': `מצטערים, אינך יכול לבצע עסקה נוספת בזמן שהכסף שלך עדיין במסלול`,
    'error_transaction_process': `עסקת התיק שלך בעיבוד. אנא המתן עד לסיום העסקה.`,
    'error_please_try': `בבקשה נסה שוב.`,
    'error_unlink_bank': 'מצטערים, אינך יכול לבטל את הקישור של חשבון הבנק שלך בזמן שעסקה מתקיימת',
    'error_basket_name': 'מצטערים, שם הסל שלך לא מתחיל ברווח.',
    'error_non_us_taxpayer': 'אויי! מצטערים, אנחנו יכולים לקבל רק משלמי מס בארה"ב בשלב זה. אנא צור קשר עם support@giraffe.us אם אתה חושב שאירעה שגיאה',

    /* customer notes */
    'customer_note1': `אם תרצה לשנות את מספר הטלפון או הדוא"ל שלך, אנא פנה תחילה לתמיכת הלקוחות.`,
    'customer_note2': `אם תרצה לשנות את המידע הנעול כאן, אנא פנה תחילה לתמיכת הלקוחות.`,

    /* Static message */
    'buy_modal_static_message': `בשל אילוצים סביב אחזקות מינימום במניות, אנו מגבילים את החלק הנמוך ביותר של מניה שתוכל לקנות ל-$0.05. ככזה, ייתכן שתראה מספר נמוך יותר של מניות שנרכשו בתיק שלך מאשר בדף המציג את התיק האידיאלי.`,
    'expense_ratio_text1': `ג'ירף משתמש במונח "יחס הוצאות" כדי לתאר את אחוז התיק שגובה ג'ירף על בסיס שנתי. כמו שקרן נסחרת בבורסה גובה יחס הוצאות על סך הנכסים בקרן, לכל תיק שג'ירף מציעה יש עמלה שנתית ספציפית משלה הנגבית כאחוז משווי הנכסים בתיק.`,
    'expense_ratio_text2': `העמלה המוצגת היא על בסיס שנתי, אולם העמלה תיגבה על בסיס חודשי בראשון לחודש.`,
    'sell_info_text1': `בגלל עמלות הכרוכות במכירת מאות מניות שונות, ג'ירף תצטרך לגבות עמלת פירוק תיק של`,
    'sell_info_text2': `על מנת לכסות את העלויות שלנו`,
    'create_basket_static_text1': `הוציאו את רוב החברות ב-S&P 500 שעומדות בניגוד לערך הזה`,
    'create_basket_static_text2': `חברות`,
    'create_basket_static_text3': `ניתן להגביר רק ערך אחד`,
    'create_basket_static_text4': `השונות החזויה של תיק ההשקעות שלך לעומת S&P 500 באותו זמן`,
    'taxes_static_text1': `הצהרת מס משלימה`,
    'taxes_static_text2': `הכנסה ממקורות זרים, ריבית ממשלת ארה"ב, פירוט עירוני של מדינה למדינה`,
    'giraffe_learn_text1': `עם Giraffe learn אנו רוצים לעורר בך השראה ולספק לך ידע על השפעת ההשקעות שלך. אנו מקווים שזה יעזור לך לקבל החלטות אחראיות יותר עבור עצמך ועבור כדור הארץ.`,

    /* Transaction Pending message */
    'funding_deposit_message': 'עסקאות מימון אינן זמינות עד לסיום הסכום הסופי של {type} בסך {amount}.',
    'funding_withdraw_message': 'עסקאות משיכה אינן זמינות עד שהסכום שלך ב {type} בסך {amount} יושלם.',
    'funding_total_card_message': 'ההעברה שלך בסך {amount} נמצאת בעיבוד. סך החשבון ויתרת המזומנים יעודכנו לאחר סיום ה-{type}.',

    'buy_deposit_message': 'עסקאות מימון אינן זמינות עד לסיום העסקה {type} שלך ב-{basketName}.',
    'buy_withdraw_message': 'עסקאות משיכה אינן זמינות עד לסיום העסקה {type} שלך ב-{basketName}.',
    'buy_total_card_message': 'העסקה שלך בעיבוד. סך המזומנים וההשקעות יעודכנו ברגע שהעסקה {type} ב-{basketName} תושלם.',

    'sell_deposit_message': `עסקאות תיקים אינן זמינות עד לסיום סופי של סכום ה-{type} שלך בסך {amount}.`,
    'sell_withdraw_message': `עסקאות פורטפוליו אינן זמינות עד לסיום העסקה {type} שלך ב-{basketName}.`,

    'total_card_message': 'עסקאות תיק ומשיכה אינן זמינות מכיוון שהיתרה הכוללת שלך במזומן היא $0. נא לממן את חשבונך.',

    'transfer_pending': 'ממתין ל',
    'transfer_amount': 'כמות',
    'transfer_business_day_deposit': 'עיבוד עסקה זו ייקח 2-3 ימי עסקים. לאחר מכן, תראה את המזומנים בחשבונך ותוכל להשקיע!',
    'transfer_business_day_withdrawal': 'עיבוד עסקה זו ייקח 2-3 ימי עסקים. לאחר מכן, תראה את המזומן בחשבון הבנק שלך.',

    'bank_transfer_text1': 'העברת מזומן',

    'direct_bank': `העסקה שלך תושלם תוך 2-3 ימי עסקים`,
    'market_open': `העסקה שלך תושלם בשעות הפתיחה של השוק`,
    'simple_basket_transfer': `העסקה שלך תושלם תוך 5-10 דקות`,

    /* B2C Gift */
    /* Tabs */
    'b2c_tab_text1': 'בחר את הסיבה שלך',
    'b2c_tab_text2': 'כמה לתת',
    'b2c_tab_text3': 'מידע על שולח/מקבל',
    'b2c_tab_text4': 'לבדוק',

    /* Select ESG-C Value */
    'select_esgc_text1': 'התאם אישית את ההשקעה שלהם',
    'select_esgc_text2': `בחר ערך אחד כדי להתאים אישית את ה-S&P 500 עבור מתנת הג'ירפה :)`,
    'select_esgc_total_companies': 'חברות בפורטפוליו',
    'select_esgc_text_excluded_companies': 'חברות שלא נכללו',
    'select_esgc_info': `כֵּן! זה הערך שאני רוצה במתנה`,

    /* Select Amount */
    'select_amount_text1': `המתנה שלך בהתהוות`,
    'select_amount_text2': `בחר כמה לתת:`,
    'select_amount_text3': `תן לחברים ולמשפחה שלך השקעה כדי לגרום להם להתחיל בהשקעה לתמיד`,
    'select_amount_text4': `בחר סכום:`,
    'select_amount_text5': `או הזן את הסכום שברצונך לתת:`,
    'select_amount_text6': `מ-{min} עד {max}`,
    'select_amount_placeholder': `הזן סכום של $ כאן...`,

    /* Default Dropdown Value */
    'yes': 'כן',
    'no': 'לא',
    'past_week': 'שבוע שעבר',
    'past_three_week': 'שלושת החודשים האחרונים',
    'past_six_week': 'ששת החודשים האחרונים',
    'past_year': 'שנה שעברה',
    'all_time': 'כל הזמן',

    'one_month': 'חודש אחד',
    'three_month': 'שלושה חודשים',
    'year_to_date': 'YTD',
    'one_year': 'שנה אחת',
    'two_year': 'שנתיים',

    'year_number': '{number} שנה',
    'years_number': '{number} שנים',
    'number': '{number}',
}