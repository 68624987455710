//default library
import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Redirect } from 'react-router-dom'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//message import
import IntlMessages from '../../helper/IntlMessages'
// api import
import { redeemedGift } from '../../middleware/GiftAPI'

// custom component
import LoadingModal from './LoadingModal'

export class GiftAcceptModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingModalOpen: props.acceptModalOpen,
            acceptModalOpen: false,
            redirect: false
        }
    }

    componentDidMount = async () => {
        const { redeemedGiftDetails } = this.props
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        const isRedeemed = await redeemedGift(accessToken, redeemedGiftDetails)
        await this.setState({
            loadingModalOpen: false,
            acceptModalOpen: isRedeemed,
        })
    }

    onHandleClose = async () => {
        await this.setState({
            loadingModalOpen: false,
            acceptModalOpen: false,
            redirect: true
        })
        await this.props.closeAcceptModal()
    }

    render() {
        const { acceptModelESGC } = this.props
        const { loadingModalOpen, acceptModalOpen, redirect } = this.state
        return (
            <Fragment>
                {/* redirect to dashboard */}
                {redirect ? <Redirect to='/dashboard' /> : ''}

                {/* Loading Modal */}
                <LoadingModal openLoadingModal={loadingModalOpen} title={'loading'} />

                {/* Redeemed Modal */}
                <Modal
                    centered
                    show={acceptModalOpen}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className='gift-redeemed-modal'>
                    <Modal.Body>
                        <button type="button" className="read-more-btn-close" onClick={this.onHandleClose}></button>
                        <div className="gi-gift-redeemed-card">
                            <div className="gi-gift-redeemed-img">
                                <div className="gi-g-r-card">
                                    <div className="gi-g-r-price">${acceptModelESGC.amount}</div>
                                    <div className="gi-g-r-img">
                                        <img src={acceptModelESGC.image} className="img-fluid" alt="" />
                                    </div>
                                    <div className="gi-g-r-text">
                                        <h5>{acceptModelESGC.name}</h5>
                                    </div>
                                </div>
                                {/* <img src={IMAGES.CARD_WITH_BG} className="img-fluid" alt="" /> */}
                            </div>
                            <div className="gi-g-r-shadow">
                                <div className="gi-gift-redeemed-text">
                                    <h3>
                                        {IntlMessages('gift_accept_text1')}
                                    </h3>
                                    <h5>
                                        {IntlMessages('gift_accept_text2')}
                                    </h5>
                                    <p>
                                        {IntlMessages('gift_accept_text3')}
                                    </p>
                                    <div className="text-center mt-20">
                                        <button type="button" onClick={this.onHandleClose}>
                                            {IntlMessages('button_ok')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </Fragment>
        )
    }
}

export default withAuth0(GiftAcceptModal)