import axios from "axios";

import * as UrlList from "../config/UrlList";
import { ConsoleLog } from "../service/utils/ConsoleLog";

export const portfolioSummary = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    const params = {
        'locale': 'en_US'
    }

    return await axios.post(UrlList.PORTFOLIO_SUMMARY_URL, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })

}

export const getBasketList = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(UrlList.BASKET_LIST, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
            return []
        })

}