//default library
import React, { Component } from 'react'

import IntlMessages from '../../helper/IntlMessages'

export class WeekChangeSection extends Component {

    forWeek = async (e) => {
        await this.props.forWeek(e.target.id)
    }

    render() {
        const { scheduleSection } = this.props

        return (
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-week-change mt-40 mb-20">
                        <div className="gi-g-weekbox">
                            {scheduleSection.previousWeek
                                ? <a href="#!" id={scheduleSection.previousWeek} className="gi-g-left-icon" onClick={this.forWeek}>
                                </a>
                                : ''
                            }
                            <span>{IntlMessages('week')} {scheduleSection.forWeek}</span>
                            {scheduleSection.nextWeek
                                ? <a href="#!" id={scheduleSection.nextWeek} className="gi-g-right-icon" onClick={this.forWeek}>
                                </a>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WeekChangeSection