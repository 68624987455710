import React, { Component, Fragment } from 'react'
import { Switch, Redirect } from 'react-router-dom';


//ProtectedRoute for dashboard
import ProtectedRouteOnBoarding from './ProtectedRouteOnBoarding';

//import pages
import * as PersonalDetails from '../pages/OnBoardingPages/PersonalDetailsPage';
import * as EmploymentDetails from '../pages/OnBoardingPages/EmploymentDetailsPage';
import * as InvestmentDetails from '../pages/OnBoardingPages/InvestmentDetailsPage';
import * as OtherOnboarding from '../pages/OnBoardingPages/OtherOnboardingPage';

import { URLCheckFunction } from '../service/utils/URLCheckFunction';
import Header from '../components/HeaderComponent/Header';
export class OnBoardingRoutes extends Component {

    constructor(props) {
        super(props)

        require('../assets/styles/oldCSS/main.scss') //import css

        this.state = {
            checkURL: '',
            routerSlash: false,
            size: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            check: 0,
            kycProcessing: false
        };
    };

    componentDidMount = async () => {
        let routerSlash = false
        if (window.location.pathname === `/onBoarding/`) {
            routerSlash = true
            this.props.check(0)
        }

        const checkURL = await URLCheckFunction()
        await this.setState({
            routerSlash: routerSlash,
            checkURL: checkURL
        })

        window.addEventListener("resize", this.handleWindowResize);

    }

    componentWillUnmount = async () => {
        await this.setState(() => {
            return;
        })
        window.removeEventListener("resize", this.handleWindowResize);
    }

    componentDidUpdate = async (previousProps, previousState) => {
        // const { checkURL, check } = this.state
        // if (previousState.checkURL !== checkURL || previousState.check !== check) {
        if ((window.location.pathname === '/onBoarding/page16') || (window.location.pathname === '/onBoarding/page1')) {
            const detectHistory = () => {
                window.removeEventListener('popstate', detectHistory)
            }
            window.history.pushState(
                { id: window.history.state?.key ?? '1' },
                null,
                window.location.pathname
            )
            window.addEventListener('popstate', detectHistory)
        }
        // }
    }

    handleWindowResize = () => {
        const size = {
            height: window.innerHeight,
            width: window.innerWidth
        }
        this.setState({
            size: size
        });
    }

    onStepHandler = async (step) => {
        let check = 0
        let kycProcessing = false
        if (step === `/onBoarding`) {
            check = 0
        }
        if (step === `/onBoarding/verifyCode`) {
            check = 1
        }
        for (let i = 1; i <= 7; i++) {
            if (step === `/onBoarding/page${i}`) {
                check = 1
            }
        }
        for (let i = 8; i <= 11; i++) {
            if (step === `/onBoarding/page${i}`) {
                check = 2
            }
        }
        for (let i = 12; i <= 14; i++) {
            if (step === `/onBoarding/page${i}`) {
                check = 3
            }
        }
        if (step === `/onBoarding/page15`) {
            check = 4
        }
        if (step === `/onBoarding/page16`) {
            check = 0
            kycProcessing = true
        }

        const textHeight = document.getElementsByClassName("gi-from-right")[0];
        const formSection = document.getElementsByClassName("gi-from-section")[0];
        if (this.state.size && textHeight && formSection) {
            if (this.state.size.width < 576) {
                formSection.style.paddingBottom = textHeight.offsetHeight + 'px';
            } else {
                formSection.style.paddingBottom = '20px';
            }
        }

        await this.setState({
            check: check,
            kycProcessing: kycProcessing
        })

        document.body.style.fontFamily = `Lato`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1125px'
        }

    }

    render() {
        const { routerSlash, checkURL, check, kycProcessing } = this.state
        return (
            <Fragment>
                {checkURL ? <Redirect push to={checkURL} /> : ''}
                {routerSlash ? <Redirect push to={'/onBoarding'} /> : ''}
                <Header step={check} kycProcessing={kycProcessing} />
                <Switch >
                    <ProtectedRouteOnBoarding exact path="/onBoarding" component={OtherOnboarding.StartPageOnBoarding} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page1" component={PersonalDetails.PersonalDetailsPage1} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/verifyCode" component={PersonalDetails.PersonalDetailsPhoneVerification} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page2" component={PersonalDetails.PersonalDetailsPage2} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page3" component={PersonalDetails.PersonalDetailsPage3} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page4" component={PersonalDetails.PersonalDetailsPage4} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page5" component={PersonalDetails.PersonalDetailsPage5} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page6" component={PersonalDetails.PersonalDetailsPage6} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page7" component={PersonalDetails.PersonalDetailsPage7} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page8" component={EmploymentDetails.EmploymentDetailsPage1} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page9" component={EmploymentDetails.EmploymentDetailsPage2} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page10" component={EmploymentDetails.EmploymentDetailsPage3} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page11" component={EmploymentDetails.EmploymentDetailsPage4} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page12" component={InvestmentDetails.InvestmentDetailsPage1} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page13" component={InvestmentDetails.InvestmentDetailsPage2} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page14" component={InvestmentDetails.InvestmentDetailsPage3} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path="/onBoarding/page15" component={OtherOnboarding.CompleteDetailsPage} step={this.onStepHandler} />
                    <ProtectedRouteOnBoarding path='/onBoarding/page16' component={OtherOnboarding.LastDetailsPages} step={this.onStepHandler} />
                </Switch>
            </Fragment>
        )
    }
}

export default OnBoardingRoutes