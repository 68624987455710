//default library
import React, { Component } from 'react'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

//custom component
import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import InputComponentRadio from '../../../components/InputComponent/InputComponentRadio';
//information component
//get list of employment status 
import { getEmploymentStatus } from '../../../middleware/DropdownList'
//button component
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
//loading component
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import RightInformation from '../../../components/InformationComponent/RightInformation';
export class EmploymentDetailsPage1 extends Component {

    //default constructor
    constructor(props) {
        super(props)
        this.setLocalStorageData()//initial call for localStorage
        this.state = {
            statusList: [],
            employmentStatus: '',
            employmentLabel: '',
            localStorageData: '',
            localStorageDataFlag: false
        }
    }

    //check localStorage is data is present or not if present then store
    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null && localStorageData?.employmentStatus !== undefined) {
            await this.setState({
                localStorageData: localStorageData?.employmentStatus ?? '',
                localStorageDataFlag: true,
                employmentLabel: localStorageData?.employmentLabel ?? '',
            })
        }
    }

    //get list of employment list and store into statusList field
    componentDidMount = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        let list = await getEmploymentStatus(accessToken)
        this.setState({
            statusList: list
        })
    }

    //set component value to field value
    setEmploymentStatus = async (employmentStatus) => {
        await this.setState({
            employmentStatus: employmentStatus.fieldValue,
            employmentLabel: employmentStatus.fieldName,
            localStorageDataFlag: false
        });
    }

    //set details for store data
    setDetails = (state) => {
        //destructuring state
        const { localStorageData, localStorageDataFlag, employmentLabel } = this.state
        //change previous page based on employment label
        if (state.employmentLabel === 'Currently working' || state.employmentLabel === 'Self-employed') {
            this.page = 'page9'
        }
        else {
            this.page = 'page10'
        }
        this.details = {
            employmentStatus: localStorageDataFlag ? localStorageData : state.employmentStatus,
            employmentLabel: localStorageDataFlag ? employmentLabel : state.employmentLabel
        }
    }

    //call when component remove
    componentWillUnmount = () => {
        this.setState = () => {
            return;
        };
    }

    render() {
        //destructuring state
        const { statusList, localStorageDataFlag, localStorageData, employmentStatus } = this.state;
        this.setDetails(this.state) //call every time when user change value
        return (
            <div className="gi-from-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="py-3">
                                        <div className="gi-from-left">
                                            {/* page count */}
                                            <PageCount count={1} pageName={'Employment details'} />
                                            {/* label component */}
                                            <LabelComponent name={'employmentStatus'} labelName={'employment_page1_label'} />
                                            <div className="mb-40">
                                                {/* loop for every radio button */}
                                                {statusList.length !== 0 ? statusList.map((value, i) => {
                                                    const checked = localStorageDataFlag ? value.value === localStorageData : value.value === employmentStatus
                                                    return (
                                                        <div className="customize-radio form-check mt-20" key={i}>
                                                            <label className="form-check-label" htmlFor={value.label}>
                                                                <InputComponentRadio name={'employmentStatus'} value={value.value} title={value.label} text={value.label} checked={checked} inputValue={this.setEmploymentStatus} />
                                                            </label>
                                                        </div>
                                                    )
                                                }) : <LoadingComponent />}
                                            </div>
                                            <div className="gi-btn-group d-none d-sm-flex">
                                                {/* button component */}
                                                <ButtonComponent value={this.details} previousRouting={'page7'} nextRouting={this.page} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="gi-from-right">
                                        {/* employment details information component */}
                                        <RightInformation info1={'employment_detail_info1'} />
                                        <div className="gi-btn-group d-flex d-sm-none">
                                            {/* button component */}
                                            <ButtonComponent value={this.details} previousRouting={'page7'} nextRouting={this.page} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default withAuth0(EmploymentDetailsPage1)