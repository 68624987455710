//default library
import React, { Component, Fragment } from 'react'

//image imtl messing
import IntlMessages from '../../helper/IntlMessages';

//custom function
import { currencyFormatter } from '../../service/utils/CurrencyFormatter'
import { DateFormatter2 } from '../../service/utils/DateFormatter';

export class GiftRedeemCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redeemedValue: '',
            currentValue: '',
            earnings: ''
        };
    };

    componentDidMount = async () => {
        const { detailRedeemed } = this.props
        await this.setState({
            redeemedValue: currencyFormatter(detailRedeemed.redeemedValue),
            currentValue: currencyFormatter(detailRedeemed.currentValue),
            earnings: currencyFormatter(detailRedeemed.totalGain)
        })
    }

    render() {
        const { redeemedValue, currentValue, earnings } = this.state
        const { detailRedeemed } = this.props
        const esgcValue = detailRedeemed?.esgcValueList[0]
        const titleTextStyle = {
            background: `linear-gradient(${esgcValue?.titleLinearGradientDirectionDeg}, ${esgcValue?.titleLinearGradientColorStop1} ${esgcValue?.titleLinearGradientColorStopPercent1}, ${esgcValue?.titleLinearGradientColorStop2} ${esgcValue?.titleLinearGradientColorStopPercent2})`,
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        };
        
        return (
            <Fragment>
                <div className="gi-d-b-gift-body">
                    <div className="gi-d-b-gift-esgc-value">
                        <div>
                            <img src={esgcValue.giftImage} className="img-fluid" alt="" />
                            <h5 style={titleTextStyle}>
                                {esgcValue.name}
                            </h5>
                        </div>
                    </div>
                    <div className="gi-d-b-gift-details">
                        <div className="gi-d-b-gift-details-left">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-d-b-gift-earnings">
                                        <span>
                                            {IntlMessages('from')}
                                        </span>
                                        <h5>
                                            {detailRedeemed?.giftGiverName}
                                        </h5>
                                    </div>
                                    <div className="gi-d-b-gift-earnings">
                                        <span>
                                            {IntlMessages('home_gift_date')}
                                        </span>
                                        <h5>
                                            {DateFormatter2(detailRedeemed?.giftRedemptionDate)}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gi-d-b-gift-details-right">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-d-b-gift-earnings">
                                        <span>
                                            {IntlMessages('transaction_redeemed_value')}
                                        </span>
                                        <h5>
                                            {redeemedValue}
                                        </h5>
                                    </div>
                                    <div className="gi-d-b-gift-earnings">
                                        <span>
                                            {IntlMessages('transaction_current')}
                                        </span>
                                        <h5>
                                            {currentValue}
                                        </h5>
                                    </div>
                                    <div className="gi-d-b-gift-earnings">
                                        <span>
                                            {IntlMessages('transaction_earning')}:
                                        </span>
                                        <h5>
                                            {earnings}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GiftRedeemCard