//default library
import React, { Component } from 'react'

//regex from number
import { ONLY_NUMBER } from '../../config/Config'

import { injectIntl } from 'react-intl';

export class InputComponentCurrency extends Component {
    //default constructor
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this) //bind this keyword to handleOnChange function
        this.setLocalStorageData() //initial call for localStorage
        this.state = {
            fieldValue: ''
        }
    }

    //check localStorage is data is present or not if present then store
    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null) {
            await this.setState({
                fieldValue: localStorageData[`${this.props.name}`] ?? '',
            })
        }
    }

    //handle onChange event
    handleOnChange = async (e) => {
        //check for only number and set to the field
        let value = e.target.value.replace(ONLY_NUMBER, '')
        await this.setState({
            fieldValue: value
        })

        //send to parent component
        this.props.inputValue(this.state.fieldValue)
    }

    render() {
        //destructuring props
        const { placeholder, name, readOnly, value, intl } = this.props
        const { fieldValue } = this.state

        return (
            <>
                <input
                    type='text'
                    className='form-control form-control-lg'
                    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                    name={name ?? ''} //set field name
                    value={fieldValue ? fieldValue : value ? value : ''} //set field value
                    onChange={this.handleOnChange} //invoke on every key press
                    readOnly={readOnly ?? false} //check field is readOnly or not
                />
                {/* if field name is annualIncome or netWorth then add $ symbol to prefix */}
                <span className="gi-symbol">$</span>
                {/* {name === 'annualIncome' || name === 'netWorth' || name === 'netWorth' ? <span className="gi-symbol">$</span> : ''} */}

            </>
        )
    }
}

export default injectIntl(InputComponentCurrency)