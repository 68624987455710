import React, { Component, Fragment } from 'react'
import IntlMessages from '../../helper/IntlMessages'
export class PageHeader extends Component {

    render() {
        const { h1, h5, strong, userName, basketName } = this.props
        return (
            <Fragment>
                <h1>
                    {h1
                        ? IntlMessages(h1)
                        : ''
                    }
                    {basketName
                        ? basketName
                        : ''
                    }
                </h1>
                <h5>
                    {h5
                        ? strong
                            ? <Fragment>
                                <strong>
                                    {IntlMessages(strong)}
                                    &nbsp;
                                    {userName ? <Fragment>{userName}!&nbsp;</Fragment> : ''}
                                </strong>
                                {/* {IntlMessages(h5)} */}
                                {typeof (h5) === 'object'
                                    ? IntlMessages(...h5)
                                    : IntlMessages(h5)
                                }
                            </Fragment>
                            // : IntlMessages(h5)
                            : typeof (h5) === 'object'
                                ? IntlMessages(...h5)
                                : IntlMessages(h5)
                        : strong
                            ? <Fragment>
                                <strong>
                                    {IntlMessages(strong)}
                                    &nbsp;
                                </strong>
                                {userName ? <Fragment>{userName}&nbsp;</Fragment> : ''}
                            </Fragment>
                            : ''
                    }
                </h5>
            </Fragment>

        )
    }
}

export default PageHeader