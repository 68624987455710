//default library
import React, { Component, Fragment } from 'react'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
// custom component
import LearnWeekCard from '../../components/CardComponent/LearnWeekCard'
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import AchievementSection from '../../components/SectionComponent/AchievementSection'
import ScheduleSection from '../../components/SectionComponent/ScheduleSection'
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'

// api call
import { getUserCalendar } from '../../middleware/GiraffeLearn'

export class GiraffeLearnListPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userName: `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`,
            isLoading: true,
            userCalendar: {}//load user calender data
        }
    }

    componentDidMount = async () => {
        window.scroll(0, 0)
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        const userCalendar = await getUserCalendar(accessToken)
        const currentCalendar = userCalendar.userCalendarSectionResponseList.filter((e) => {
            if (e.current) {
                return e
            }
            else {
                return null
            }
        })
        const userCalendarData = {
            currentCalendar: currentCalendar[0],
            userCalendar: userCalendar.userCalendarSectionResponseList
        }
        await this.setState({
            isLoading: false,
            userCalendar: userCalendarData
        })
    }

    render() {
        const { userName, isLoading, userCalendar } = this.state
        return (
            <Fragment>
                {isLoading
                    ? <LoadingComponent />
                    : <Fragment>
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-gift-heading">
                                    <PageHeader userName={userName} strong={`giraffe_learn_welcome`} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-right-sidebar-main">
                                    <div className="gi-g-right-sidebar-left">
                                        <div className="gi-g-details-card-left">
                                            {/* schedule section */}
                                            <ScheduleSection learnList={false} />

                                            {/* LearnWeekCard */}
                                            <LearnWeekCard userCalendar={userCalendar} />

                                            {/* achievement section */}
                                            <AchievementSection />
                                        </div>
                                    </div>
                                    <div className="gi-g-right-sidebar-right">
                                        <GiraffeLearnBannerSection />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }

            </Fragment>
        )
    }
}

export default withAuth0(GiraffeLearnListPage)