/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'

export class BuyButton extends Component {


    onHandleClick = async () => {
        this.props.openBuyModel(true)
        this.props.buyModalOpenId(this.props.id)
    }

    render() {
        const { disabled, basketPage } = this.props
        return (
            <>
                <button className={basketPage ? 'portfolio-btn' : 'invest-btn'} type="button" onClick={this.onHandleClick} disabled={disabled}>
                    {basketPage
                        ? <img src={IMAGES.PORTFOLIO_ICON} alt="" />
                        : ''
                    }

                    {basketPage
                        ? IntlMessages('button_invest')
                        : IntlMessages('button_buy_invest')
                    }

                </button>
            </>
        )
    }
}

export default BuyButton