//default library
import React, { Component, Fragment } from "react";

//router library
import { Route, Switch } from "react-router-dom";

//custom component
import Routes from "./routes/Routes";
import GiftLandingPage from "./pages/LandingPage/GiftLandingPage";
import PageNotFound from "./pages/NotFoundPage/PageNotFound";
import GiraffeLearnVideoModal from "./components/ModalComponent/GiraffeLearnVideoModal";
import B2CGiftRoutes from "./routes/B2CGiftRoutes";

//local file
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import * as LanguageConfig from "./service/utils/LanguageConfig";

//import config
import * as defaultValue from './config/DefaultValue'

let count = 0
export class App extends Component {

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');

    // using query params
    if (lang) {
      defaultValue.localeOptions.map(e => {
        if (e.id === lang) {
          LanguageConfig.setCurrentLanguage(lang)
          LanguageConfig.setDirection(e.direction);
        }
        return null
      })
    }

    const direction = LanguageConfig.getDirection(); //get default direction
    const language = LanguageConfig.getCurrentLanguage() //get default language

    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
      require('bootstrap/dist/css/bootstrap.rtl.min.css');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
      require('bootstrap/dist/css/bootstrap.min.css');
    }
    require('bootstrap/dist/js/bootstrap.bundle.min')
    require('./assets/styles/loading.scss') //import loader css
    // require('./assets/styles/newCSS/new-main.scss')

    this.state = {
      language: language
    }
  }

  componentDidMount = () => {
    // if email is verified
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('success') === 'true' && count === 0) {
      localStorage.clear()
      count++;
      window.history.pushState(
        {},
        '',
        window.location.pathname
      )
    }
  }

  render() {
    const currentAppLocale = AppLocale[this.state.language];
    const b2cGift = process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'local'
    return (
      <IntlProvider
        textComponent={Fragment}
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          {/* Gift Landing page */}
          {JSON.parse(process.env.REACT_APP_SHOW_NAVIGATION_GIFT)
            ? <Route exact path={'/gift/:id'} component={GiftLandingPage} />
            : ''
          }

          {/* Giraffe Learn */}
          <Route exact path={'/videos'} component={GiraffeLearnVideoModal} />

          {/* B2c gift page */}
          {b2cGift
            ? <Route path={'/b2c-gift'} component={B2CGiftRoutes} />
            : null
          }

          {/* Error page */}
          <Route exact path={'/error'} component={PageNotFound} />

          {/* Other Routes */}
          <Route path={'*'} component={Routes} />

        </Switch>
      </IntlProvider>
    )
  }
}

export default App