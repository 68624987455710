//default library
import React, { Component } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import InputComponentText from '../../../components/InputComponent/InputComponentText';
import SelectComponent from '../../../components/SelectComponent/SelectComponent';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';

//information component
import RightInformation from '../../../components/InformationComponent/RightInformation';

//button component
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';

//get industry and position industry list
import * as DropdownList from '../../../middleware/DropdownList';

//error message
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import { MAX_NUMBER_64 } from '../../../config/Config';
export class EmploymentDetailsPage2 extends Component {
  //default constructor
  constructor(props) {
    super(props)
    this.setLocalStorageData()//initial call for localStorage
    this.state = {
      company: '',
      optionIndustry: [],
      optionPosition: [],
      typeIdentifier: '',
      positionIdentifier: ''
    }
  }

  //check localStorage is data is present or not if present then store
  setLocalStorageData = async () => {
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    if (localStorageData !== null) {
      await this.setState({
        company: localStorageData?.company ?? '',
        positionIdentifier: localStorageData?.positionIdentifier ?? '',
        typeIdentifier: localStorageData?.typeIdentifier ?? '',
      })
    }
  }

  //get list of industry list and store into optionIndustry field
  componentDidMount = async () => {
    const { getAccessTokenSilently } = this.props.auth0
    const accessToken = await getAccessTokenSilently() //get access token
    let industryList = await DropdownList.getIndustryList(accessToken)
    let positionIndustryList = await DropdownList.getPositionIndustryList(accessToken)

    this.setState({
      optionIndustry: industryList,
      optionPosition: positionIndustryList,
    })
  }

  //set component value to field value
  setIndustryValue = async (industry) => {
    await this.setState({
      typeIdentifier: industry
    });
  }

  //set component value to field value
  setPositionValue = async (position) => {
    await this.setState({
      positionIdentifier: position
    });
  }

  //set component value to field value
  setCompanyName = async (company) => {
    await this.setState({
      company: company
    })
  }

  //set details for store data
  setDetails = (state) => {
    this.details = {
      company: state.company,
      position: state.positionIdentifier,
      industry: state.typeIdentifier
    }
  }

  componentWillUnmount = () => {
    this.setState = () => {
      return;
    };
  }

  render() {
    //destructuring state
    const { company, optionIndustry, optionPosition } = this.state
    const optionLength = optionIndustry.length !== 0 && optionPosition.length !== 0
    this.setDetails(this.state) //call every time when user change value
    return (
      <div className="gi-from-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-8">
                  <div className="py-3">
                    <div className="gi-from-left">
                      {/* page count */}
                      <PageCount count={2} pageName={'Employment details'} />
                      <div className="gi-input mb-40">
                        <LabelComponent name={'company'} labelName={`employment_page2_label`} />
                        <InputComponentText name={'company'} placeholder={'placeholder_type_here'} inputValue={this.setCompanyName} />
                        <ErrorComponent message={'error_max_length_64'} display={company.length > MAX_NUMBER_64 ? 'block' : 'none'} />
                      </div>
                      <div>
                        <div className="gi-input gi-select mb-40">
                          <div>
                            <LabelComponent name={'industry'} labelName={`employment_page2_label2`} />
                            {optionLength
                              ? <SelectComponent name={'typeIdentifier'} option={optionIndustry} placeholder={'placeholder_industry'} selectValue={this.setIndustryValue} />
                              : <LoadingComponent />}
                          </div>
                        </div>
                        <div className="gi-input gi-select mb-40">
                          <div>
                            <LabelComponent name={'position'} labelName={`employment_page2_label3`} />
                            {optionLength
                              ? <SelectComponent name={'positionIdentifier'} option={optionPosition} placeholder={'placeholder_position'} selectValue={this.setPositionValue} />
                              : <LoadingComponent />}
                          </div>
                        </div>
                      </div>
                      <div className="gi-btn-group d-none d-sm-flex">
                        {/* button component */}
                        <ButtonComponent value={this.details} previousRouting={'page8'} nextRouting={'page10'} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="gi-from-right">
                    {/* employment details information component */}
                    <RightInformation info1={'employment_detail_info1'} />

                    <div className="gi-btn-group d-flex d-sm-none">
                      {/* button component */}
                      <ButtonComponent value={this.details} previousRouting={'page8'} nextRouting={'page10'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withAuth0(EmploymentDetailsPage2)