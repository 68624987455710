//default library
import React, { Component, Fragment } from 'react'
import Select from 'react-select';

// jquery library
import $ from 'jquery';

// custom component
import ExcludedCompanyTable from '../TableComponent/ExcludedCompanyTable.js'
import TotalCompanyTable from '../TableComponent/TotalCompanyTable.js'
import ExpenseSection from './ExpenseSection.js'
import IntlMessages from '../../helper/IntlMessages';

//interweave library for html
import { Markup } from 'interweave';

//prefect scroll bar react
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export class CompanyDetailSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            option: [
                { value: '0', label: IntlMessages('basket_companies_list', { totalCompany: props.chartValue?.totalCompanies }) },
                { value: '1', label: IntlMessages('basket_excluded1', { excludedCompany: props.chartValue?.excludedCompanies }) },
                { value: '2', label: IntlMessages('basket_expense') },
            ],
            activeTab: 0,
            count: 0
        };
    };


    componentDidMount = async () => {
        $(".gi-p-red-info, .gi-p-close-icon").click(function () {
            $(".gi-p-excluded-info-card").toggleClass("active");
        });
    }

    componentDidUpdate = async (previousProps, previousState) => {
        const { chartValue } = this.props
        if (previousProps.chartValue !== chartValue) {
            await this.setState({
                option: [
                    { value: '0', label: IntlMessages('basket_companies_list', { totalCompany: chartValue?.totalCompanies }) },
                    { value: '1', label: IntlMessages('basket_excluded1', { excludedCompany: chartValue?.excludedCompanies }) },
                    { value: '2', label: IntlMessages('basket_expense') },
                ]
            })
        }

        const tabActive = this.checkActiveTab()
        const { activeTab, count } = this.state
        if (previousState.activeTab === activeTab && previousState.count === count) {
            await this.setState({
                activeTab: tabActive,
                count: count + 1
            })

        }
    }

    // select value change
    handleOnChange = async (e) => {
        $('#pills-tab li a').removeClass('active show');
        $('#pills-tab li a').eq(e.value).addClass('active show');

        $('#pills-tabContent .tab-pane').removeClass('active show');
        $('#pills-tabContent .tab-pane').eq(e.value).addClass('active show');

        let tabs = document.querySelectorAll('#pills-tab li a') //get all tab
        const activeTab = Array.from(tabs).findIndex((e, i) => {
            if (e.className.search('active') > 0) {
                return e
            }
            else {
                return null
            }
        }) //get index of active tab
        await this.setState({
            activeTab: activeTab
        })
    }

    // tab change
    tabClick = async () => {
        const activeTab = await this.checkActiveTab()

        await this.setState({
            activeTab: activeTab
        })
    }

    //check active tab in pill tabs
    checkActiveTab = () => {
        let tabs = document.querySelectorAll('#pills-tab li a') //get all tab
        const activeTab = Array.from(tabs).findIndex((e, i) => {
            if (e.className.search('active') > 0) {
                return e
            }
            else {
                return null
            }
        }) //get index of active tab
        return activeTab
    }

    render() {
        const { option, activeTab } = this.state
        const { companyDetail, chartValue, esgcValueList, esgcValueExcluded, basketPage } = this.props
        const excludedCompaniesList = companyDetail?.excludedCompanyList
        const esgcValueIdentifier = JSON.parse(localStorage.getItem('esgcValueIdentifierList'))
        const excludedCompany = []

        esgcValueList?.forEach((e) => {
            esgcValueIdentifier?.forEach((e1) => {
                if (e.identifier === e1.esgcValueIdentifier) {
                    excludedCompany.push(e)
                }
            })
        })

        esgcValueExcluded?.forEach((e) => {
            excludedCompany.push(e)
        })

        const checkTextDisplayed = esgcValueIdentifier?.length > 0 || basketPage

        return (
            <div className="gi-p-tabs">
                <div className="gi-p-select-tabs custom-react-two">
                    <Select
                        classNamePrefix='react-select-chart'
                        options={option} //dropdown value
                        onChange={this.handleOnChange}
                        value={option[activeTab]}
                    />
                </div>
                <div className="gi-p-tabs-btns">
                    <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist" onClick={this.tabClick}>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-home" href="#pills-home" role="tab" aria-controls="pills-home"
                                aria-selected="true">
                                {/* {chartValue?.totalCompanies} */}
                                {IntlMessages('basket_companies_list', { totalCompany: chartValue?.totalCompanies })}
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link excluded" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-profile" href="#pills-profile" role="tab" aria-controls="pills-profile"
                                aria-selected="false">
                                {/* {chartValue?.excludedCompanies} */}
                                {IntlMessages('basket_excluded1', { excludedCompany: chartValue?.excludedCompanies })}
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-contact" href="#pills-contact" role="tab" aria-controls="pills-contact"
                                aria-selected="false">
                                {IntlMessages('basket_expense')}
                            </a>
                        </li>
                        {basketPage
                            ? ''
                            : <li className="nav-item" role="presentation">
                                <a className="nav-link" href='#!'> </a>
                            </li>
                        }
                    </ul>
                </div>

                <div className="tab-content" id="pills-tabContent">
                    {/* table component */}
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-p-tab-text">
                                    <p className="text-green">
                                        {checkTextDisplayed
                                            ? IntlMessages('basket_text1')
                                            : '.....'
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                {companyDetail?.totalCompanies.length > 0
                                    ? <TotalCompanyTable totalCompanies={companyDetail?.totalCompanies} />
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                    {/* excluded component */}
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-p-tab-text">
                                    <p className="text-bright-black d-flex align-items-center flex-wrap">
                                        {checkTextDisplayed
                                            ? <Fragment>
                                                {IntlMessages('basket_text2')}&nbsp;
                                            </Fragment>
                                            : <Fragment>
                                                {IntlMessages('basket_excluded_text')}
                                            </Fragment>
                                        }
                                        <button type="button" className="gi-p-red-info" style={{ display: checkTextDisplayed ? 'block' : 'none' }}></button>
                                    </p>
                                    {/* data come from backend */}
                                    <div className="gi-p-excluded-info-card">
                                        <h3>{IntlMessages('create_basket_text12')}</h3>
                                        <button type="button" className="gi-p-close-icon"></button>
                                        {/* <div className="gi-p-excluded-info-scroll"> */}
                                        <ScrollBar component="div" className="gi-p-excluded-info-scroll">
                                            <div className="gi-p-excluded-info-text">
                                                {excludedCompany?.map((e, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <h6 className={i > 0 ? 'mt-4' : ''}> {e.name}</h6>
                                                            <Markup content={e.boosted ? e?.boostedDescription : e?.description} />
                                                        </Fragment>
                                                    )
                                                })}
                                            </div>
                                        </ScrollBar>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                {excludedCompaniesList && excludedCompaniesList.length > 0
                                    ? <ExcludedCompanyTable excludedCompanies={excludedCompaniesList} />
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                    {/* expense ration */}
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="row">
                            <div className='col-12'>
                                <div className='row'>
                                    <div className={`${basketPage ? 'col-12' : 'col-md-9'}`}>
                                        <div className="gi-expense-ratio-card">
                                            <div className="gi-expense-ratio-card-header">
                                                {IntlMessages('basket_expense_text2')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='gi-expense-ratio-card'></div>
                                </div>
                                <ExpenseSection expenseRatio={companyDetail?.expenseRatio} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default CompanyDetailSection