//default library
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import DashboardLogoComponent from '../../components/LogoComponent/DashboardLogoComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'

//api call
import { getESGCValue } from '../../service/ApiService/EsgcValueListService';
import InformationModal from '../../components/ModalComponent/InformationModal';
import IntlMessages from '../../helper/IntlMessages';


export class SelectValueBasket extends Component {
    constructor(props) {
        super(props)

        this.state = {
            esgcValue: [], //esgc value data array
            disabled: true, //next button disabled
            companyValueCountPage: false, //when click next button
            cancelButton: false, //when click cancel button
            informationId: '', //store modal id
            openInformationModal: false, //store modal id
        }

        this.intervalId = setInterval(this.checkNextButton, 200) //call check next button
    }

    componentDidMount = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        const esgcValue = await getESGCValue(accessToken)
        await this.setState({
            esgcValue: esgcValue
        })
        //add jquery class
        await this.toggleClass()

    }

    //clear interval when component change
    componentWillUnmount = () => {
        clearInterval(this.intervalId)
    }

    //add and remove active class
    toggleClass = () => {
        $(".gi-c-card").click(function () {
            $(this).toggleClass("gi-c-active");
        });
    }

    //next button click
    onNextClick = async () => {
        let esgcValueIdentifierList = [] //empty array to store value of select company
        const data = document.querySelectorAll('.gi-c-active') //get data that the class name is gi-c-active
        for (let i = 0; i < data.length; i++) {
            let data2 = {
                "esgcValueIdentifier": data[i].id,
                "boosted": false
            }
            esgcValueIdentifierList.push(data2)
        }
        if (data.length > 0) {
            clearInterval(this.intervalId) //clear interval
            this.setState({
                companyValueCountPage: true //company value count page
            })
        }
        localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList))

    }

    //check next button active or not
    checkNextButton = async () => {
        if (document.querySelectorAll('.gi-c-active').length > 0) {
            await this.setState({
                disabled: false
            })
        }
        else {
            await this.setState({
                disabled: true
            })
        }
    }

    //click cancel button
    cancelButton = async () => {
        await this.setState({
            cancelButton: true
        })
    }

    //open information modal
    openInformationModal = async (e) => {
        await this.setState({
            informationId: e.target.id,
            openInformationModal: true
        })
    }

    closeInformationModal = async () => {
        await this.setState({
            openInformationModal: false
        })
    }

    render() {
        const { esgcValue, disabled, companyValueCountPage, cancelButton, informationId, openInformationModal } = this.state
        return (
            <Fragment>
                {/* if click next button */}
                {companyValueCountPage ? <Redirect push to='/create-basket/company-count' /> : ''}

                {/* click cancel button */}
                {cancelButton ? <Redirect push to='/dashboard' /> : ''}

                {/* page content start */}
                <section className="gi-c-main-section">
                    <header className="gi-c-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-c-header-main">
                                        <div className="gi-c-header-left">
                                            <DashboardLogoComponent mobileView={true} />
                                        </div>
                                        <div className="gi-c-header-right">
                                            <div className="gi-sell-btn-group d-none d-md-flex">
                                                <button className="gi-sell-cancel-btn" type="button" onClick={this.cancelButton}>
                                                    {IntlMessages('button_cancel')}
                                                </button>
                                                <button className="gi-sell-next-btn" type="button" disabled={disabled} onClick={this.onNextClick}>
                                                    {IntlMessages('button_next')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section>
                        <div className="container">
                            <div className="gi-sell-btn-group d-flex flex-wrap justify-content-end d-md-none mt-20 mb-20">
                                <button className="gi-sell-cancel-btn gi-sell-cancel-btn-header" type="button" onClick={this.cancelButton}>
                                    {IntlMessages('button_cancel')}
                                </button>
                                <button className="gi-sell-next-btn" type="button" disabled={disabled} onClick={this.onNextClick}>
                                    {IntlMessages('button_next')}
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-c-heading">
                                        <h4>
                                            {IntlMessages('create_basket_text1')}
                                        </h4>
                                        <h5>
                                            {IntlMessages('create_basket_text2')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row gx-20">
                                <div className="col-md-9 mx-auto">
                                    <div className="row gx-20">
                                        {esgcValue.length > 0
                                            ? esgcValue.map((e, i) => {
                                                return (
                                                    <div className="col-lg-3 col-md-4 col-sm-6" key={e.identifier} >
                                                        <div className={`gi-c-card`} id={e.identifier}>
                                                            <div className="gi-c-card-img" >
                                                                <img className="gi-c-card-img-inactive" src={e.thumbnailSmall} alt="" />
                                                                <img className="gi-c-card-img-active" src={e.thumbnailMedium} alt="" />
                                                            </div>
                                                            <div className="gi-c-card-text">
                                                                <h6>
                                                                    {e.name} <a href="#!" onClick={this.openInformationModal} id={e.identifier}> (i)</a></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : <LoadingComponent />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </section >

                {openInformationModal
                    ? <InformationModal openInformationModal={openInformationModal} informationId={informationId} esgcValue={esgcValue} closeInformationModal={this.closeInformationModal} />
                    : ''
                }
            </Fragment>
        )
    }
}

export default withAuth0(SelectValueBasket)