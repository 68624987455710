//default library
import React, { Component } from 'react'

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
import IntlMessages from '../../helper/IntlMessages';


export class GiftGiverSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            giverLogo: props.giftGiver.giverLogo,
            giverName: props.giftGiver.giverName,
            giverMessage: props.giftGiver.giverMessage,
        }
    }

    login = async () => {
        const { loginWithRedirect } = this.props.auth0
        await loginWithRedirect();
        localStorage.setItem('triggerMethod', 'register')
    }

    render() {
        const { giverLogo, giverName, giverMessage } = this.state

        return (
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-lp-impact-box">
                        <h1>
                            {IntlMessages('gift_landing_impact')}
                        </h1>
                        <span>
                            {IntlMessages('from')}:
                            &nbsp;
                            {giverLogo ? <img src={giverLogo} alt="" /> : <strong>{giverName}</strong>}
                        </span>
                        <p>{giverMessage}</p>
                        <button type="button" className="gi-g-impact-btn" onClick={this.login}>
                            {IntlMessages('gift_landing_impact_button')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth0(GiftGiverSection)