//default library
import React, { Component } from 'react'
import { injectIntl } from 'react-intl';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export class MaskInput extends Component {

    //mask formate for dateOfBirth
    dobMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
    //mask formate for phoneNumber
    phoneNumberMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, " ", "-", " ", /\d/, /\d/, /\d/, /\d/]
    //mask formate for ssnNumber
    ssnNumberMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

    //default constructor
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this) //bind this keyword to handleOnChange function
        this.setLocalStorageData()         //initial call for localStorage
        this.state = {
            fieldValue: '',
            passwordType: props.name === 'ssnNumber' ? 'password' : 'text'
        }
        //implement mask properties to respective field
        if (props.name === 'dateOfBirth') {
            this.mask = this.dobMask
            this.pipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
        }
        if (props.name === 'phoneNumber') {
            this.mask = this.phoneNumberMask
            this.pipe = ''
        }
        if (props.name === 'ssnNumber') {
            this.mask = this.ssnNumberMask
            this.pipe = ''
        }
    }

    //check localStorage is data is present or not if present then store
    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        const { name, value } = this.props
        if (localStorageData !== null) {
            if ((name === 'dateOfBirth' && localStorageData?.dateOfBirth !== undefined)) {
                // set dateOfBirth according to formate
                let dob = localStorageData?.dateOfBirth.split('-')
                let dd = dob[2]
                let mm = dob[1]
                let yyyy = dob[0]
                await this.setState({
                    fieldValue: `${mm}/${dd}/${yyyy}` ?? '',
                })
            } else if ((name === 'phoneNumber' && localStorageData?.phoneNumber !== undefined)) {
                // set phoneNumber according to formate
                let phoneNumber = localStorageData?.phoneNumber
                let phoneNumber1 = phoneNumber.substring(0, 3)
                let phoneNumber2 = phoneNumber.substring(3, 6)
                let phoneNumber3 = phoneNumber.substring(6, 10)
                await this.setState({
                    fieldValue: `(${phoneNumber1}) ${phoneNumber2} - ${phoneNumber3}` ?? '',
                })
            } else if ((name === 'ssnNumber' && localStorageData?.ssnNumber !== undefined)) {
                // set ssnNumber according to formate
                let ssnNumber = localStorageData?.ssnNumber
                let ssnNumber1 = ssnNumber.substring(0, 3)
                let ssnNumber2 = ssnNumber.substring(3, 5)
                let ssnNumber3 = ssnNumber.substring(5, 9)
                await this.setState({
                    fieldValue: `${ssnNumber1}-${ssnNumber2}-${ssnNumber3}` ?? '',
                })
            }
        }
        if (value !== undefined) {
            if (name === 'dateOfBirth') {
                // set dateOfBirth according to formate
                await this.setState({
                    fieldValue: `${value}` ?? '',
                })
            } else if (name === 'phoneNumber') {
                // set phoneNumber according to formate
                await this.setState({
                    fieldValue: `${value}` ?? '',
                })
            } else if (name === 'ssnNumber') {
                // set ssnNumber according to formate
                await this.setState({
                    fieldValue: `${value}` ?? '',
                })
            }
        }
    }

    //handle onChange event
    handleOnChange = async (e) => {
        await this.setState({
            fieldValue: e.target.value
        })
        this.props.inputValue(this.state.fieldValue)
    }

    //change password type
    togglePasswordType = async () => {
        if (this.state.passwordType === "password") {
            await this.setState({
                passwordType: 'text'
            })
            return;
        }
        await this.setState({
            passwordType: 'password'
        })
    }

    render() {
        //destructuring props
        const { placeholder, name, readOnly, intl, textCenter } = this.props
        const { passwordType } = this.state
        return (
            <>
                <MaskedInput
                    type={passwordType}
                    className={`form-control ${textCenter ? 'text-center' : ''}`}
                    name={name}
                    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                    mask={this.mask} //mask field
                    pipe={this.pipe} //implement pipe to handle input value
                    keepCharPositions={true}
                    onChange={this.handleOnChange} //invoke on every key press
                    value={this.state.fieldValue} //set default value
                    readOnly={readOnly ?? false} //check field is readOnly or not
                    guide={name === 'ssnNumber' ? false : true} //show guid for field
                />
                {/* hide and unhide ssn number */}
                {name === 'ssnNumber' ?
                    <div className='gi-password-visibility' onClick={this.togglePasswordType}>
                        {passwordType === "password" ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                        {/* {passwordType === "password" ? <img src={eyeSlash} alt='eye-slash' /> : <img src={eyeVisible} alt='eye' />} */}
                    </div>
                    : ''
                }
                {/* if field name is phoneNumber then add +1 symbol to prefix */}
                {name === 'phoneNumber' ? <span className="gi-country-code">+1</span> : ''}
            </>
        )
    }
}

export default injectIntl(MaskInput)