//default library
import React, { Component, Fragment } from 'react'

//custom component
import SelectActivity from '../SelectComponent/SelectActivity'
import PaginationNew from '../PaginationComponent/PaginationNew';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

//image import
import IMAGES from '../../config/Images';

// custom function
import { DateFormatter } from '../../service/utils/DateFormatter'
import { DateRange } from '../../service/utils/DateFilter';

// api call
import { getStatement, downloadPdf } from '../../middleware/TaxDocumentation';

import IntlMessages from '../../helper/IntlMessages';

import { taxesDropdown } from '../../config/DefaultValue';

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

export class StatementCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            statementData: props.statementData,
            currentStatementData: [],
            totalStatementData: [],
            currentPage: null,
            totalPages: null,
            dataLoad: false
        }
    }

    componentDidMount = async () => {
        const { totalStatementData, statementData } = this.state
        if (totalStatementData.length === 0) {
            await this.setState({
                totalStatementData: statementData,
                dataLoad: true
            })
        }

    }

    // handle pagination data 
    onPageChanged = (data) => {
        const { totalStatementData } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentStatementData = totalStatementData.slice(offset, offset + pageLimit);
        this.setState({
            currentStatementData: currentStatementData,
            currentPage: currentPage,
            totalPages: totalPages
        });
    }

    // handle data filter data 
    onSelectValue = async (value) => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            dataLoad: false
        })

        let dateRange = await DateRange(value)//set default value date range
        const accessToken = await getAccessTokenSilently() //get access token
        let statementData = await getStatement(accessToken, dateRange)
        await this.setState({
            totalStatementData: statementData,
            dataLoad: true
        })
    }

    // downLoad pdf
    downLoadPDF = async (e) => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        const downLoadURL = await downloadPdf(accessToken, e.target.parentElement.id)
        if (downLoadURL) {
            window.open(downLoadURL)
        }
    }

    render() {
        const { dataLoad, totalStatementData, currentStatementData } = this.state
        return (
            <Fragment>
                {dataLoad ? '' : <LoadingComponent />}
                <div className="row">
                    <div className="col-12">
                        <div className="gi-d-ac-card-main mb-10">
                            <div className="gi-d-new-heading">
                                <h5>
                                    {IntlMessages('taxes_head_text')}
                                </h5>
                            </div>
                            <div className="gi-d-ac-new-card">
                                <div className="row gx-20">
                                    <div className="col-12">
                                        <div className="px-10">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="gi-d-ac-select-dropdown mb-30">
                                                        <div>
                                                            <label>
                                                                {IntlMessages('table_range')}:
                                                            </label>
                                                        </div>
                                                        <SelectActivity name='transaction' option={taxesDropdown} value={'past-week'} selectValue={this.onSelectValue} />
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-8 col-md-9">
                                                    PaginationNew
                                                    {dataLoad
                                                        ? <PaginationNew totalRecords={totalStatementData.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                                                        : ''
                                                    }
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table table-striped gi-d-table-striped mb-0" role="presentation">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <span>
                                                                {IntlMessages('table_date')}
                                                            </span>
                                                        </th>
                                                        <th scope="col">
                                                            <span>
                                                                {IntlMessages('table_document')}
                                                            </span>
                                                        </th>
                                                        <th scope="col">
                                                            <span> </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentStatementData.length > 0
                                                        ? currentStatementData.map((e, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <span>{DateFormatter(e.statementDate)}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="text-gi-black">{e.name}</span>
                                                                    </td>
                                                                    <td>
                                                                        {e.fileKey ? < a href="#!" onClick={this.downLoadPDF} id={e.fileKey}><img src={IMAGES.PDF_ICON} alt="" /></a> : ''}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : <tr className="bg-light">
                                                            <td colSpan="12">
                                                                <span>
                                                                    {IntlMessages('table_no_data')}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {dataLoad
                                            ? <PaginationNew totalRecords={totalStatementData.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Fragment>
        )
    }
}

export default withAuth0(StatementCard)