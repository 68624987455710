import React, { Component, Fragment } from 'react'
import PaginationNew from '../PaginationComponent/PaginationNew.js';

export class ExcludedCompanyTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            totalExcludedCompanies: [],
            currentExcludedCompanies: [], //list company name after in pagination
            sortingFlag: false,
            dataLoadCompany: false
        };
    };


    componentDidMount = async () => {
        const { excludedCompanies } = this.props;
        await this.setState({
            totalExcludedCompanies: excludedCompanies,
            sortingFlag: true,
            dataLoadCompany: true
        });
    }

    componentDidUpdate = async (previousProps, previousState) => {
        const { excludedCompanies } = this.props;

        if (previousProps.excludedCompanies !== excludedCompanies) {
            this.componentDidMount()
        }
    }

    onPageChangedCompany = async (data) => {
        await this.setState({
            dataLoadCompany: false
        })
        const { totalExcludedCompanies } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentExcludedCompanies = await [...totalExcludedCompanies].slice(offset, offset + pageLimit);
        await this.setState({
            currentExcludedCompanies: currentExcludedCompanies,
            currentPageCompany: currentPage,
            totalPagesCompany: totalPages,
            dataLoadCompany: true
        });

    }

    render() {
        const { totalExcludedCompanies, currentExcludedCompanies, sortingFlag } = this.state
        return (
            <Fragment>
                <div className="table-responsive">
                    <table className="gi-p-excluded-table table table-striped table-active mb-0" role="presentation">
                        <tbody>
                            {[...currentExcludedCompanies].map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <th scope="row">
                                            <strong>{e}</strong>
                                        </th>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {sortingFlag ? <PaginationNew totalRecords={totalExcludedCompanies.length} pageLimit={10} pageNeighbors={1} onPageChanged={this.onPageChangedCompany} /> : ''}

            </Fragment>
        )
    }
}

export default ExcludedCompanyTable