//default library
import React, { Component, Fragment } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
// routing library
import { Redirect } from 'react-router-dom';

// jquery library
import $ from 'jquery';

// custom component
import AddPortfolioButton from '../../components/ButtonComponent/AddPortfolioButton';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import ChartSection from '../../components/SectionComponent/ChartSection';
import CompanyDetailSection from '../../components/SectionComponent/CompanyDetailSection';


// API call
import * as CreateBasket from '../../middleware/CreateBasket';

// intl message
import IntlMessages from '../../helper/IntlMessages';
import { injectIntl } from 'react-intl';

export class CreatePortfolioPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isLoadingBasket: false, //loading when select ESG-C value
            userName: localStorage.getItem('firstName'), //user name
            createClick: false, //create button
            backButton: false, //back button
            selectedValue: [], //selected ESG-C value List
            chartValue: {}, //value for chart component
            companyDetail: {}, //value for company details component
            basketId: '', //basket ID after creating basket,
            esgcValueList: [], //esgc value
        };
    };

    componentDidMount = async () => {
        window.scroll(0, 0)
        const { intl } = this.props
        //check esgcValueIdentifierList present in localStorage
        if (JSON.parse(localStorage.getItem('esgcValueIdentifierList'))?.length > 0) {
            await this.basketSummary()
        }
        // if not redirect to add-portfolio page
        else {
            await this.setState({
                backButton: true
            })
        }


        $('.gi-p-boost-value-ul').on('click', async (e) => {
            $(e.currentTarget).toggleClass("active");
            $(e.currentTarget).children(".gi-p-boost-boost")
                .children(".gi-p-add-boost")
                .first()
                .text($(this).children(".gi-p-boost-boost")
                    .children(".gi-p-add-boost")
                    .first().text() === intl.formatMessage({ id: 'basket_boosted2' })
                    ? intl.formatMessage({ id: 'basket_boosted' })
                    : intl.formatMessage({ id: 'basket_boosted2' })
                );

            this.getBasketCreateSummary(e.currentTarget); //get basket summary by clicking ESG-C value
        });
    }

    componentWillUnmount = () => {
        this.setState = () => {
            return;
        };
    }

    // call basket summary api
    basketSummary = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoadingBasket: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        const basketSummary = await CreateBasket.getBasketCreateSummary(accessToken)

        const chartValue = {
            totalCompanies: basketSummary?.companySummary.totalCompanies,
            excludedCompanies: basketSummary?.companySummary.excludedCompanies,
            totalIndustry: basketSummary?.companySummary.totalIndustries,
        }

        const companyDetail = {
            totalCompanies: basketSummary?.companySummary.totalCompanyList,
            excludedCompanyList: basketSummary?.companySummary.excludedCompanyList,
            expenseRatio: basketSummary?.expenseRatio
        }

        await this.setState({
            esgcValueList: basketSummary?.esgcValueList,
            selectedValue: basketSummary.esgcValueList, //select ESG-C value
            chartValue: chartValue, //data for chart section
            companyDetail: companyDetail, //data for company details section
            isLoading: false,
            isLoadingBasket: false,
        })
    }

    // handle create button click
    createClick = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoadingBasket: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        const createBasket = await CreateBasket.createBasket(accessToken);
        await this.props.createBasketRoute()
        await this.setState({
            basketId: createBasket?.id, //get basket ID
            isLoadingBasket: false,
            createClick: true
        })
        await localStorage.removeItem('esgcValueIdentifierList')
    }

    // handle back button click
    backButtonCLick = async () => {
        await this.setState({
            backButton: true
        })
    }

    // create basket summary
    getBasketCreateSummary = async (boostIdentifier) => {
        const esgcValueIdentifierList = await JSON.parse(localStorage.getItem('esgcValueIdentifierList')) //get esgc value identifier from localStorage
        const { intl } = this.props
        for (let i = 0; i < esgcValueIdentifierList.length; i++) {
            //if boost true then remove for view
            const id = `#${esgcValueIdentifierList[i].esgcValueIdentifier}`
            if (esgcValueIdentifierList[i].boosted && esgcValueIdentifierList[i].esgcValueIdentifier === boostIdentifier.id) {
                esgcValueIdentifierList[i].boosted = false
                $(id).removeClass('gi-p-boost-value-ul active').addClass('gi-p-boost-value-ul'); //toggle active class 
                $(id).children(".gi-p-boost-boost")
                    .children(".gi-p-add-boost")
                    .first()
                    .text(intl.formatMessage({ id: 'basket_boosted' }));
            }
            // set only one boost for request payload
            else if (esgcValueIdentifierList[i].esgcValueIdentifier === boostIdentifier.id) {
                esgcValueIdentifierList[i].boosted = true
            }
            else {
                esgcValueIdentifierList[i].boosted = false
                $(id).removeClass('gi-p-boost-value-ul active').addClass('gi-p-boost-value-ul'); //toggle active class 
                $(id).children(".gi-p-boost-boost")
                    .children(".gi-p-add-boost")
                    .first()
                    .text(intl.formatMessage({ id: 'basket_boosted' }));
            }
        }

        await localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList))

        await this.basketSummary()
    }

    render() {
        const { userName, isLoading, createClick, backButton, isLoadingBasket,
            selectedValue, chartValue, companyDetail, basketId, esgcValueList } = this.state

        return (
            <Fragment>
                {createClick ? <Redirect push to={`basket/${basketId}`} /> : ''}
                {backButton ? <Redirect push to='add-portfolio' /> : ''}
                {isLoading
                    ? <LoadingComponent />
                    : <Fragment>
                        {isLoadingBasket ? <LoadingComponent /> : ''}

                        <div className="row gi-g-font">
                            <div className="col-12">
                                <div className="gi-gift-heading">
                                    <PageHeader h5={`create_basket_head_t1`} strong={`hello`} userName={`${userName}`} />
                                </div>
                            </div>
                        </div>
                        <div className="row gi-g-font">
                            <div className="col-12">
                                <div className="gi-p-card mb-10">
                                    <AddPortfolioButton
                                        buttonHeader={'create_basket_text10'}
                                        buttonLabel={'complete'}
                                        backButton={true}
                                        onClick={this.createClick}
                                        onBackClick={this.backButtonCLick} />

                                    {/* boost */}
                                    < div className="gi-p-card-body">
                                        <div className="gi-p-green-card">
                                            <div className="gi-p-green-card-header">
                                                {IntlMessages('create_basket_text11')}
                                            </div>
                                            <div className="gi-p-green-card-body">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-12">
                                                        {selectedValue.length > 0
                                                            ? selectedValue.map((e) => {
                                                                if (e.selected) {
                                                                    return (
                                                                        <div className="row" key={e.identifier}>
                                                                            <div className="col-12">
                                                                                <div className="gi-p-boost-value-ul" id={e.identifier}>
                                                                                    <div className="gi-p-boost-value-li">
                                                                                        <div className="gi-p-boost-value-card">
                                                                                            <div className="gi-p-boost-value-card-img">
                                                                                                <img src={e.thumbnailMedium} alt={e.name} />
                                                                                            </div>
                                                                                            <div className="gi-p-boost-value-card-text">
                                                                                                <h5>{e.name}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {e.canBeBoosted
                                                                                        ? <div className="gi-p-boost-boost">
                                                                                            <div className="gi-p-add-boost">
                                                                                                {IntlMessages('basket_boosted')}
                                                                                            </div>
                                                                                        </div>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            })
                                                            : ''
                                                        }
                                                    </div>
                                                    <div className="col-lg-5 col-md-6">
                                                        <ChartSection chartValue={chartValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* company details */}
                                        <CompanyDetailSection companyDetail={companyDetail} chartValue={chartValue} esgcValueList={esgcValueList} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }


            </Fragment >
        )
    }
}

export default withAuth0(injectIntl(CreatePortfolioPage))