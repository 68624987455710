import React, { Component } from 'react'

import PageCount from '../../../components/PageCount/PageCount';
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';
import RightInformation from '../../../components/InformationComponent/RightInformation';
import MaskInput from '../../../components/InputComponent/MaskInput';
import { SSN_REGEX } from '../../../config/Config';
import IMAGES from '../../../config/Images'
class PersonalDetailsPage7 extends Component {

    errorFlag = false;
    constructor(props) {
        super(props)
        this.setLocalStorageData()
        this.state = {
            ssnNumber: '',
        }
    }

    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null && localStorageData?.ssnNumber !== undefined) {
            let ssnNumber = localStorageData?.ssnNumber
            let ssnNumber1 = ssnNumber.substring(0, 3)
            let ssnNumber2 = ssnNumber.substring(3, 5)
            let ssnNumber3 = ssnNumber.substring(5, 9)
            await this.setState({
                ssnNumber: `${ssnNumber1}-${ssnNumber2}-${ssnNumber3}` ?? '',
            })
        }
    }

    setSSNNumber = async (ssnNumber) => {
        await this.setState({
            ssnNumber: ssnNumber
        });
    }

    setDetails = (ssnNumber) => {
        let localStorageData = JSON.parse(localStorage.getItem('onBoardingDetails'))

        this.errorFlag = false
        this.details = {
            ssnNumber: ''
        }
        let ssn = ssnNumber.split('-')
        let ssn1 = ssn[0] ?? ''
        let ssn2 = ssn[1] ?? ''
        let ssn3 = ssn[2] ?? ''
        if (!isNaN(ssn1) && !isNaN(ssn2) && !isNaN(ssn3)) {
            if (ssnNumber.length === 11) {
                if (!ssnNumber.match(SSN_REGEX)) {
                    this.errorFlag = true
                }
                this.details = {
                    ssnNumber: `${ssn1}${ssn2}${ssn3}`,
                    ssnNumberMask: ssnNumber
                }
            }
        }


        if (localStorageData?.countryLabel === 'United States') {
            this.page = 'page5'
        }
        else {
            this.page = 'page6'
        }
    }

    render() {
        const { ssnNumber } = this.state
        this.setDetails(ssnNumber)
        return (
            <div className="gi-from-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="py-3">
                                        <div className="gi-from-left">
                                            <PageCount count={7} pageName={'Personal details'} />

                                            <div className="gi-input mb-40">
                                                <LabelComponent name={'ssnNumber'} labelName={'personal_page7_label'} />
                                                <div className="gi-password">
                                                    <MaskInput name={'ssnNumber'} placeholder={'placeholder_ssn'} inputValue={this.setSSNNumber} />
                                                </div>
                                                <ErrorComponent message={'error_invalid_ssn_number'} display={this.errorFlag ? 'block' : 'none'} />
                                            </div>
                                            <div className="gi-btn-group d-none d-sm-flex">
                                                <ButtonComponent value={this.details} previousRouting={this.page} nextRouting={'page8'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="gi-from-right">
                                        <RightInformation info1={'personal_detail_info3'} img={IMAGES.LOCK_ICON} />
                                        <div className="gi-btn-group d-flex d-sm-none">
                                            <ButtonComponent value={this.details} previousRouting={this.page} nextRouting={'page8'} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PersonalDetailsPage7