// default library
import React, { Component } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
// plaid library
import { PlaidLink } from 'react-plaid-link';

// plaid api call
import { plaidLinkToken, plaidGenerateToken } from '../../service/ApiService/PlaidService';

// config
import * as Config from '../../config/Config'

// custom console function
import { ConsoleLog } from '../../service/utils/ConsoleLog';

// datadog log library 
import { datadogLogs } from '@datadog/browser-logs'
export class PlaidComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            token: '',
            userName: props.userName
        }
    }

    componentDidMount = async () => {
        const plaidToken = localStorage.getItem('plaidToken')
        const { getAccessTokenSilently } = this.props.auth0
        if (plaidToken === null) {
            this.props.tokenGenerate(false)
            const accessToken = await getAccessTokenSilently() //get access token
            await plaidLinkToken(accessToken)
                .then(async (res) => {
                    await this.setState({
                        token: res
                    })
                    await localStorage.setItem('plaidToken', res)
                    this.props.tokenGenerate(true)
                })
                .catch((err) => {
                    ConsoleLog('', err)
                })
        }
        else {
            await this.setState({
                token: plaidToken
            })
        }
    }

    onSuccess = async (publicToken, metadata) => {
        const { plaidData } = this.props
        const { getAccessTokenSilently } = this.props.auth0
        await this.props.isLoading()
        let processorTokenRequestData = {
            'customerIdentifier': localStorage.getItem('dwUserIdentifier'),
            "customerFirstName": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_FIRST_NAME : plaidData.customerFirstName,
            "customerMiddleName": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_LAST_NAME : plaidData.customerMiddleName,
            "customerLastName": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_MIDDLE_NAME : plaidData.customerLastName,
            "customerPhoneNumber": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_PHONE : plaidData.customerPhoneNumber,
            "customerEmail": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_EMAIL : plaidData.customerEmail,
            "customerAddress": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_ADDRESS : plaidData.customerAddress,
            // 'customerName': JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_FIRST_NAME : this.state.userName,
            'publicToken': publicToken,
            'institutionName': metadata.institution.name,
            'account': {
                'id': metadata.account.id,
                'mask': metadata.account.mask,
                'name': metadata.account.name,
                'subtype': metadata.account.subtype,
                'type': metadata.account.type
            }
        };
        const accessToken = await getAccessTokenSilently() //get access token
        let onSuccess = await plaidGenerateToken(accessToken, processorTokenRequestData);
        if (onSuccess === true) {
            await this.props.componentDidMount(); //if bank is connect
            await localStorage.removeItem('plaidToken') //remove plaid token when bank link successfully
        }
        else {
            await this.props.handleErrorModal(onSuccess); //if any error occurred
        }

    };

    onExit = async (error, metadata) => {
        await this.props.onHandleClose()
    };

    onEvent = async (eventName, metadata) => {
        datadogLogs.logger.info(`Plaid ${eventName}`, { plaidR: metadata, plaidToken: this.state.token })
        if (eventName === 'OPEN') {
            await this.props.openPlaid()
        }
        if (metadata.error_code !== null) {
            await this.props.handleErrorModal(); //if any error occurred
        }
    }


    render() {
        const { className, buttonName } = this.props
        return (

            <PlaidLink
                className={className}
                token={this.state.token}
                onSuccess={this.onSuccess}
                onExit={this.onExit}
                onEvent={this.onEvent}
            >
                {buttonName}
            </PlaidLink>
        )
    }
}

export default withAuth0(PlaidComponent)