//default library
import React, { Component, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

//error message
import TextError from '../ErrorComponent/TextError';

//custom component
import InputComponentCurrency from '../InputComponent/InputComponentCurrency';
import LoadingModal from './LoadingModal';
import SuccessModal from './SuccessModal';
import SelectModal from '../SelectComponent/SelectModal';

//api call
import { transferFund } from '../../middleware/TransferFundCall';
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';

//remove undefined value
import { removeUndefined } from '../../service/utils/RemoveUndefineValue';
import { ConsoleLog } from '../../service/utils/ConsoleLog';
import { MAX_NUMBER_100, MAX_NUMBER_AMOUNT } from '../../config/Config';
import IntlMessages from '../../helper/IntlMessages';
import IMAGES from '../../config/Images';
export class FundModal extends Component {

  portfolioListDropDown = []

  constructor(props) {
    super(props)

    this.state = {
      openFundModel: '', //open modal
      accountBalance: '', //account amount enter
      errorMessage: '', //error message comes from backend

      cashAccountValue: '', //cash account value
      cashAccountLabel: '', //cash account label
      bankAccountValue: '', //bank account value
      bankAccountLabel: '', //bank account label

      openLoadingModal: false, //loading modal
      openSuccessModal: false, //success modal
      openConfirmModal: false, //confirm modal
      errorFlag: false, //error flag when error message comes from backend 
      errorFlag100: false, //error flag when amount less then MAX_NUMBER_100
      errorFlagMax: false, //error flag when amount reached max limit

      fromAccount: [],
      toPortfolio: [],
      selectFromAccount: true,
      fromAccountValue: '',
      toPortfolioValue: '',

      successMessage: 'bank_transfer', //successful transfer message
    }
  }

  //received props
  UNSAFE_componentWillReceiveProps = async (props) => {
    await this.setState({
      openFundModel: props.openFundModel
    })
  }

  //load dropdown values
  componentDidMount = async () => {
    //new dropdown value
    let getFundLocation = this.props.getFundLocation
    let bankAccountDropDown = await removeUndefined(getFundLocation.map((e) => {
      if (e.type === 'Bank Account') {
        return {
          value: `${e.id}|${e.type}`,
          label: e.name
        }
      }
      else {
        return null
      }
    }))

    let cashAccountDroDown = await removeUndefined(getFundLocation.map((e) => {
      if (e.type === 'Cash Account') {
        return {
          value: `${e.id}|${e.type}`,
          label: e.name
        }
      }
      else {
        return null
      }
    }))

    const fromAccount = await removeUndefined(getFundLocation.map((e) => {
      if (e.type === 'Bank Account' || e.type === 'Cash Account') {
        return {
          value: `${e.id}|${e.type}`,
          label: e.name,
        }
      }
      else {
        return null
      }
    }))

    await this.setState({
      bankAccountLabel: bankAccountDropDown.length > 0 ? bankAccountDropDown[0].label : '',
      cashAccountLabel: cashAccountDroDown[0].label,

      bankAccountValue: bankAccountDropDown.length > 0 ? bankAccountDropDown[0].value : '',
      cashAccountValue: cashAccountDroDown[0].value,

      fromAccount: fromAccount
    })
  }

  //close model and clear input values
  onHandleClose = async () => {
    await this.setState({
      openFundModel: false,
      openLoadingModal: false,
      openSuccessModal: false,
      openConfirmModal: false,
      accountBalance: '',
      errorFlag: false,
      errorFlag100: false,
      errorFlagMax: false,
      fromAccountValue: '',
      toPortfolioValue: '',
    })
    await this.props.closeModal(false) //close fund transfer modal
    // await this.props.componentDidMount() //load total card component
  }

  //close confirm modal
  onHandleCloseSuccess = async () => {
    await this.setState({
      openSuccessModal: false,
      accountBalance: '',
      errorFlag: false,
      errorFlag100: false,
      errorFlagMax: false,
    })
    await this.props.closeModal(false) //close fund transfer modal
    await this.props.componentDidMount() //load total card component
  }

  //set input value
  setInputValue = async (value) => {
    await this.setState({
      accountBalance: value,
      errorFlag: false,
      errorFlag100: false,
      errorFlagMax: false,

    })
  }

  //set from account dropdown
  selectFromAccount = async (value) => {
    await this.setState({
      toPortfolio: [],
      selectFromAccount: false,
      toPortfolioValue: ''
    })

    let getFundLocation = this.props.getFundLocation
    let fromAccountType = value.split('|')[1];

    let toPortfolio = await removeUndefined(getFundLocation.map((e) => {
      if (fromAccountType === 'Bank Account' && (e.type === 'Cash Account' || e.type === 'Basket')) {
        return {
          value: `${e.id}|${e.type}`,
          label: e.name,
        }
      }
      else if (fromAccountType === 'Cash Account' && e.type === 'Basket') {
        return {
          value: `${e.id}|${e.type}`,
          label: e.name,
        }
      }
      else {
        return null
      }

    }))

    await this.setState({
      fromAccountValue: value,
      toPortfolio: toPortfolio,
      selectFromAccount: true
    })
  }

  //set to portfolio dropdown
  selectFromPortfolio = async (value) => {
    await this.setState({
      toPortfolioValue: value,
    })
  }

  //enable and disable transfer button
  checkValue = (value) => {
    const { accountBalance, fromAccountValue, toPortfolioValue } = value
    if (accountBalance && fromAccountValue && toPortfolioValue) {
      return false
    }
    else {
      return true
    }
  }

  //transfer fund
  fundTransfer = async () => {
    const { accountBalance, fromAccountValue, toPortfolioValue } = this.state
    const { getAccessTokenSilently } = this.props.auth0

    //if true set loading modal
    await this.setState({
      openLoadingModal: true, //loading modal
      openConfirmModal: false //confirm modal
    })
    // data
    let data = {
      "amount": accountBalance,
      "fromAccount": {
        "id": fromAccountValue.split('|')[0],
        "type": fromAccountValue.split('|')[1]
      },
      "toAccount": {
        "id": toPortfolioValue.split('|')[0],
        "type": toPortfolioValue.split('|')[1]
      }
    }

    const accessToken = await getAccessTokenSilently() //get access token
    //transfer fund api call
    transferFund(accessToken, data)
      .then(async (res) => {
        let successMessage = ''

        if (res.data?.transferType === 'BASKET_BUY' || res.data?.transferType === 'BASKET_SELL') {
          successMessage = res.data?.isOnHold ? 'fund_transfer_market_close' : 'fund_transfer_market_open'
        }
        else {
          successMessage = 'bank_transfer'
        }

        await this.setState({
          openLoadingModal: false, //loading modal
          openSuccessModal: res.data?.isSuccessful, //success modal
          successMessage: successMessage, //success message
          openFundModel: res.error, //fund modal
          errorMessage: res.message, //error message
          errorFlag: res.error //error flag
        })
        await this.props.closeModal(res.error) //close fund modal from parent component
      })
      .catch(err => {
        ConsoleLog(' ', err)
      })
  }

  //open confirm modal
  openConfirmModal = async () => {
    const { accountBalance } = this.state
    if (accountBalance < MAX_NUMBER_100) {
      await this.setState({
        errorFlag100: true,
        errorFlagMax: false,
        errorFlag: false,
      })
    }
    else if (accountBalance > MAX_NUMBER_AMOUNT) {
      await this.setState({
        errorFlag100: false,
        errorFlagMax: true,
        errorFlag: false
      })
    }
    else {
      await this.setState({
        openConfirmModal: true,
        openFundModel: false
      })
    }
  }

  render() {
    const { openFundModel, openLoadingModal, openSuccessModal, openConfirmModal, errorFlag, errorFlag100, errorFlagMax,
      accountBalance, errorMessage,
      fromAccount, toPortfolio, selectFromAccount, fromAccountValue, toPortfolioValue, successMessage } = this.state

    let isDisabled = this.checkValue(this.state)

    return (
      <Fragment>
        {/* Fund Modal */}
        <Modal
          centered
          show={openFundModel}
          onHide={this.onHandleClose}
          backdrop="static"
          keyboard={false} className="gi-g-new-fund-modal">
          <Modal.Body>
            <button className="btn-close" type="button" onClick={this.onHandleClose} aria-label="Close"></button>
            <div className='gi-g-new-fund-modal-card'>
              {/* left image */}
              <div className="gi-g-new-fund-modal-card-left">
                <div>
                  <img src={IMAGES.GIRAFFE_MODAL_ICON} alt="" />
                  <h5 className="d-none d-md-block">{IntlMessages('fund_your_portfolio', { br: <br /> })}</h5>
                  <h5 className="d-block d-md-none">{IntlMessages('fund_your_portfolio', { br: '' })}</h5>
                </div>
              </div>
              {/* right content */}
              <div className="gi-g-new-fund-modal-card-right">
                {/* input component */}
                <div className="row">
                  <div className="col-12">
                    {/* amount */}
                    <div className={`gi-g-new-input mb-30 ${errorFlag || errorFlag100 || errorFlagMax ? 'gi-input-error' : ''}`}>
                      <label className="form-label">
                        {IntlMessages('fund_select_amount')} |
                        <span className="gi-d-amount-error">
                          &nbsp;
                          <span className="gi-symbol">$</span>
                          {MAX_NUMBER_100}&nbsp;
                          {IntlMessages('minimum')}
                        </span>
                      </label>
                      <div className="gi-currency-input">
                        <InputComponentCurrency placeholder={'placeholder_currency_100'} name={'accountBalance'} inputValue={this.setInputValue} value={accountBalance} />
                        {/* <span className="gi-symbol">$</span> */}
                      </div>
                      <TextError message2={errorMessage} display={errorFlag ? 'block' : 'none'} />
                      <TextError message={'error_minimum_amount_fund'} display={errorFlag100 ? 'block' : 'none'} />
                      <TextError message={'error_maximum_amount'} display={errorFlagMax ? 'block' : 'none'} />
                    </div>

                    {/* from account */}
                    <div className="gi-g-new-input gi-select mb-30">
                      <div>
                        <label className="form-label">
                          {IntlMessages('fund_account')}
                        </label>
                        {/* <div className="gi-static-fetch row">
                        <div className="gi-static-fetch-text col-10">
                          {bankAccountLabel}
                        </div>
                      </div> */}
                        <div className="gi-d-bank-logo-select">
                          <SelectModal option={fromAccount} name={'fromAccount'} placeholder={'placeholder_select_value'} value={fromAccountValue} selectValue={this.selectFromAccount} disabled={false} />
                        </div>
                      </div>
                    </div>

                    {/* to portfolio */}
                    <div className="gi-g-new-input gi-select mb-30">
                      <div>
                        <label className="form-label">
                          {IntlMessages('fund_portfolio')}
                        </label>
                        {/* <div className="gi-static-fetch">
                        <div className="gi-static-fetch-text">
                          {IntlMessages('giraffe_cash_account')}
                        </div>
                      </div> */}
                        <div className="gi-d-bank-logo-select">
                          {selectFromAccount
                            ? <SelectModal option={toPortfolio} name={'toPortfolio'} placeholder={'placeholder_select_value'} value={toPortfolioValue} selectValue={this.selectFromPortfolio} disabled={toPortfolio.length > 0 ? false : true} />
                            : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* button */}
                <div className="row">
                  <div className="col-12">
                    <div className="gi-g-new-btn-groupe mt-30">
                      <button className="gi-g-new-cancel" type="button" onClick={this.onHandleClose}>
                        {IntlMessages('button_cancel')}
                      </button>
                      <button className="gi-g-new-transfer" type="button" onClick={this.openConfirmModal} disabled={isDisabled || errorFlag || errorFlag100 || errorFlagMax}>
                        {IntlMessages('button_buy_fund')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* confirm Modal */}
        <Modal
          centered
          show={openConfirmModal}
          onHide={this.onHandleClose}
          backdrop="static"
          keyboard={false} className='gi-g-new-fund-modal'>
          <Modal.Body>
            <button className="btn-close" type="button" aria-label="Close" onClick={this.onHandleClose}></button>
            <div className='gi-g-new-fund-modal-card'>
              {/* left image */}
              <div className="gi-g-new-fund-modal-card-left">
                <div>
                  <img src={IMAGES.GIRAFFE_MODAL_ICON} alt="" />
                  <h5 className="d-none d-md-block">{IntlMessages('fund_your_portfolio', { br: <br /> })}</h5>
                  <h5 className="d-block d-md-none">{IntlMessages('fund_your_portfolio', { br: '' })}</h5>
                </div>
              </div>
              {/* right content */}
              <div className="gi-g-new-fund-modal-card-right">
                {/* input */}
                <div className="row">
                  <div className="col-12">
                    {/* heading */}
                    <div className="gi-g-new-input-text mb-40">
                      <h5>
                        {IntlMessages('fund_confirm_transaction')}
                      </h5>
                    </div>
                    {/* input */}
                    <div className="gi-g-new-input-text mb-40">
                      <label>
                        {IntlMessages('table_amount')}
                      </label>
                      <h5>
                        {`$ ` + currencyFormatter(accountBalance).replace('$', '')}
                      </h5>
                    </div>

                    {/* from account */}
                    <div className="gi-g-new-input-text mb-40">
                      <label>
                        {IntlMessages('fund_account')}
                      </label>
                      <h5>
                        {fromAccount.length > 0
                          ? fromAccount.map((e) => {
                            if (e.value === fromAccountValue) {
                              return e.label
                            }
                            else {
                              return null
                            }
                          })
                          : ''}
                      </h5>
                    </div>

                    {/* to portfolio */}
                    <div className="gi-g-new-input-text mb-40">
                      <label>
                        {IntlMessages('fund_portfolio')}
                      </label>
                      <h5>
                        {toPortfolio.length > 0
                          ? toPortfolio.map((e) => {
                            if (e.value === toPortfolioValue) {
                              return e.label
                            }
                            else {
                              return null
                            }
                          })
                          : ''}
                      </h5>
                    </div>
                  </div>
                </div>
                {/* button */}
                <div className="row">
                  <div className="col-12">
                    <div className="gi-g-new-btn-groupe mt-30">
                      <button className="gi-g-new-cancel" type="button" onClick={this.onHandleClose}>
                        {IntlMessages('button_cancel')}
                      </button>
                      <button className="gi-g-new-transfer" type="button" onClick={this.fundTransfer}>
                        {IntlMessages('button_confirm')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Loading Modal */}
        <LoadingModal openLoadingModal={openLoadingModal} title={'fund_preparing'} onHandleClose={this.onHandleClose} />

        {/* SuccessModal */}
        <SuccessModal openSuccessModal={openSuccessModal} successMessage={successMessage} onHandleClose={this.onHandleClose} onHandleCloseSuccess={this.onHandleCloseSuccess} />
      </Fragment>
    )
  }
}

export default withAuth0(FundModal)