// default library
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'

//image import 
import IMAGES from '../../../config/Images';

import { DRIVE_HOME_PAGE } from '../../../config/UrlList';
import { getGiftReceived } from '../../../middleware/GiftAPI';
import { currencyFormatter } from '../../../service/utils/CurrencyFormatter'
import IntlMessages from '../../../helper/IntlMessages';

export class StartPageOnBoarding extends Component {
    constructor(props) {
        super(props)

        this.state = {
            nextRouting: false,
            giverName: '',
            amount: '',
            giftImage: '',
            giftName: '',
            shortDescription: '',
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        const giftIdentifier = (localStorage.getItem('giftIdentifier'))
        if (giftIdentifier) {
            const accessToken = await getAccessTokenSilently() //get access token
            const giftReceived = await getGiftReceived(accessToken, giftIdentifier)
            if (giftReceived?.status === 'AVAILABLE') {
                await this.setState({
                    giverName: giftReceived.name,
                    amount: giftReceived.amount,
                    giftImage: giftReceived.esgcValues[0].giftImage,
                    giftName: giftReceived.esgcValues[0].name,
                    shortDescription: giftReceived.esgcValues[0].shortDescription,
                    isLoading: false
                })
            }
            else {
                await this.setState({
                    isLoading: false
                })
            }
        }
        else {
            await this.setState({
                isLoading: false
            })
        }

        // css
        document.body.style.fontFamily = `Lato`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1125px'
        }
    }

    nextPage = () => {
        this.setState({
            nextRouting: true
        })
        // localStorage.setItem('onBoardingDetails', JSON.stringify('start'))
    }

    render() {
        const { nextRouting, giverName, amount, giftImage, giftName, shortDescription, isLoading } = this.state

        return (
            <>
                {nextRouting ? <Redirect push to='/onBoarding/page1' /> : ''}
                {isLoading
                    ? <LoadingComponent />
                    : <div className="gi-from-section gi-st-boarding">
                        <div className="container">
                            <div className="row gx-70">
                                <div className="col-md-6 order-md-1 order-2">
                                    <div className="gi-st-text">
                                        <h1 className="mb-20">
                                            {IntlMessages('onboarding_start_t1')}
                                        </h1>
                                        <p className="mb-30">
                                            <strong>
                                                {IntlMessages('onboarding_start_t2')}
                                            </strong>
                                        </p>
                                        <p className="mb-30">
                                            {IntlMessages('onboarding_start_t3')}
                                        </p>
                                        <ul className="mb-30">
                                            <li>
                                                <div className="gi-st-points">
                                                    <span>1</span>
                                                    {IntlMessages('personal')}
                                                    <br />
                                                    {IntlMessages('details')}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="gi-st-points">
                                                    <span>2</span>
                                                    {IntlMessages('employment')}
                                                    <br />
                                                    {IntlMessages('details')}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="gi-st-points">
                                                    <span>3</span>
                                                    {IntlMessages('investment')}
                                                    <br />
                                                    {IntlMessages('details')}
                                                </div>
                                            </li>
                                        </ul>
                                        <div>
                                            <button className="gi-st-next-btn" type="button" onClick={this.nextPage}>
                                                {IntlMessages('button_next')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {giverName && JSON.parse(process.env.REACT_APP_SHOW_NAVIGATION_GIFT)
                                    ? <div className="col-md-6 order-md-2 order-1 mb-30">
                                        <div className="gi-st-gift-heading">
                                            <h5>{IntlMessages('onboarding_start_t6')}: <strong> {giverName}</strong></h5>
                                        </div>
                                        <div className="gi-st-gift-card">
                                            <div className="gi-st-gift-price">
                                                <span>
                                                    {currencyFormatter(amount)}
                                                </span>
                                            </div>
                                            <div className='row'>
                                                <div className="col-6">
                                                    <div className="gi-st-gift-card-img">
                                                        <img src={giftImage} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="gi-st-gift-card-text">
                                                        <h5>
                                                            {giftName}
                                                        </h5>
                                                        <p>
                                                            {shortDescription}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-st-dw mt-40 pt-30">
                                        <img src={IMAGES.DRIVE_WEALTH_LOGO} alt="" />
                                        <p>
                                            {IntlMessages('onboarding_start_t4')}&nbsp;
                                            <br />
                                            <a href={DRIVE_HOME_PAGE} target={'_blank'} rel="noreferrer">
                                                {IntlMessages('click_here')}
                                            </a>
                                            &nbsp;{IntlMessages('onboarding_start_t5')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        )
    }
}

export default withAuth0(StartPageOnBoarding)