export const getBasketPerformance = async (startWith, waitPatiently) => {

    let startAmount = ''
    let endAmount = ''

    if (waitPatiently === 20) {
        if (startWith === 1000) {
            startAmount = 5000;
            endAmount = 7200;
        }
        else if (startWith === 2000) {
            startAmount = 10000;
            endAmount = 14000;
        }
        else if (startWith === 3000) {
            startAmount = 15000;
            endAmount = 21600;
        }
    }
    else if (waitPatiently === 30) {
        if (startWith === 1000) {
            startAmount = 11100;
            endAmount = 19300;
        }
        else if (startWith === 2000) {
            startAmount = 23000;
            endAmount = 38500;
        }
        else if (startWith === 3000) {
            startAmount = 33500;
            endAmount = 57900;
        }
    }
    else if (waitPatiently === 40) {
        if (startWith === 1000) {
            startAmount = 25000;
            endAmount = 52000;
        }
        else if (startWith === 2000) {
            startAmount = 50000;
            endAmount = 100000;
        }
        else if (startWith === 3000) {
            startAmount = 75000;
            endAmount = 155000;
        }
    }

    return {
        startAmount, endAmount
    }
}