//default library
import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//auth0 library
import { useAuth0 } from "@auth0/auth0-react";

//custom component
import LandingPage from "../pages/LandingPage/LandingPage";
import ThankYouPage from "../pages/Auth0Pages/ThankYouPage";

//loading component
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";

//route
import OnBoardingRoutes from "./OnBoardingRoutes";
import DashboardRoutes from "./DashboardRoutes";
import CreateBasketRoutes from "./CreateBasketRoutes";

//waiting page 
import { LastDetailsPages } from '../pages/OnBoardingPages/OtherOnboardingPage/index';
import PlaidRedirect from "../pages/Dashboard/PlaidRedirect";

//protected route
import ProtectedRoute from "./ProtectedRoute";

// session timeout component
import SessionTimeout from "../components/SessionTimeoutComponent/SessionTimeout";


//api call
import * as User from "../middleware/User";

import { SESSION_EXPIRED } from "../config/Config";

let count = 0;
let count2 = 0;
// let countUserDetail = 0;

const Routes = () => {

    const { isLoading, isAuthenticated, getAccessTokenSilently, user, loginWithRedirect } = useAuth0(); //get auth0 details

    const [kycPageRefresh, setKycPageRefresh] = useState(false) //for refresh screen

    const [cookieClear, setCookieClear] = useState(false)

    const baseURLList = ['onBoarding', 'kycPending', 'dashboard', 'create-basket', '', 'plaid-redirect']

    const [pageRedirect, setPageRedirect] = useState([false, false, false, false])


    // get user details and redirect
    const userDetails = async () => {
        const accessToken = await getAccessTokenSilently()
        const userDetail = await User.getUserDetails(accessToken)
        localStorage.setItem('hasKycErrors', userDetail.hasKycErrors)
        const data = [
            userDetail.isNewUser,
            userDetail.isKycProcessing,
            !(userDetail.isNewUser || userDetail.isKycProcessing),
            true
        ]
        await setPageRedirect(data)
    }

    useEffect(() => {
        let isMounted = true;
        const userDetails = async () => {
            if (isAuthenticated && user.email_verified) {
                const accessToken = await getAccessTokenSilently()
                const userDetail = await User.getUserDetails(accessToken)
                localStorage.setItem('hasKycErrors', userDetail.hasKycErrors)
                const data = [
                    userDetail.isNewUser,
                    userDetail.isKycProcessing,
                    !(userDetail.isNewUser || userDetail.isKycProcessing),
                    true
                ]
                await setPageRedirect(data)
            }
        }
        if (isMounted) {
            userDetails()
        }
        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently, isAuthenticated, user])
    //check auth0 loading part
    if (isLoading) {
        return <LoadingComponent />;
    }

    //if user email not verify
    if (isAuthenticated && !user.email_verified) {
        if (!(localStorage.getItem('isAuthenticate'))) {
            localStorage.setItem('expiredTime', (Date.now() + (SESSION_EXPIRED + 12 * 1000)))
            localStorage.setItem('isAuthenticate', isAuthenticated)
        }
        return (
            <Fragment>
                <SessionTimeout isAuthenticated={isAuthenticated} />
                <ThankYouPage />
            </Fragment>
        )
    }

    //if user is not authenticate from auth0
    if (!isAuthenticated) {
        loginWithRedirect() //send to login page
        localStorage.clear() //clear all data when user is not authenticate
    }

    //if user is authenticate from auth0
    if (isAuthenticated) {
        if (!(localStorage.getItem('isAuthenticate'))) {
            localStorage.setItem('expiredTime', (Date.now() + (SESSION_EXPIRED + 12 * 1000)))
            localStorage.setItem('isAuthenticate', isAuthenticated)
        }
        // if (countUserDetail === 0) {
        //     countUserDetail++;
        //     userDetails()
        // }
    }

    //if cookie is clear from local storage
    if (!window.document.cookie && count === 0) {
        count++;
        setCookieClear(true)
        localStorage.clear()
    }

    //check on load page
    if (((!pageRedirect[0] && window.location.pathname === '/onBoarding/page16') || (!pageRedirect[1] && window.location.pathname === '/kycPending')) && count === 0) {
        setKycPageRefresh(true)
        count++;
    }

    // if user in kyc page
    if (((window.location.pathname === '/onBoarding/page16') || (window.location.pathname === '/kycPending')) && count2 === 0) {
        setTimeout(() => {
            // countUserDetail = 0
            userDetails();
            clearTimeout();
        }, 1000 * 5);
        count2++;

        if (!pageRedirect[1]) {
            count2 = 0
        }
    }

    if (!baseURLList.includes(window.location.pathname.split('/')[1])) {
        return (
            <Redirect push to='/error' />
        )
    }

    return (
        <div>
            {/* set session timeout */}
            <SessionTimeout isAuthenticated={isAuthenticated} />
            <Switch>
                <Route exact path='/'>
                    {/* add check condition that user login or not */}
                    <LandingPage pageRedirect={pageRedirect} />
                </Route>

                <Route path={'/plaid-redirect'}>
                    <PlaidRedirect />
                </Route>

                <ProtectedRoute path={'/create-basket'}>
                    <CreateBasketRoutes />
                </ProtectedRoute>

                {/* onBoarding all routes */}
                {pageRedirect[0]
                    ? <ProtectedRoute path="/onBoarding">
                        <OnBoardingRoutes/>
                    </ProtectedRoute>
                    : ''
                }

                {/* kyc pending page */}
                {pageRedirect[1]
                    ? <ProtectedRoute path="/kycPending">
                        <LastDetailsPages header={true} />
                    </ProtectedRoute>
                    : ''
                }

                {/* dashboard routes */}
                {pageRedirect[2]
                    ? <ProtectedRoute path="/dashboard">
                        <DashboardRoutes />
                    </ProtectedRoute>
                    : ''}

                {/* handle page not found */}
                {pageRedirect[3]
                    //if new user true then redirect to onBoarding
                    ? pageRedirect[0]
                        ? <Redirect push to='/onBoarding' />
                        //if kyc is under process then redirect to kycPending
                        : pageRedirect[1]
                            ? <Redirect push to='/kycPending' />
                            //if kyc processing is done then redirect to dashboard
                            : pageRedirect[2]
                                ? <Redirect push to='/dashboard' />
                                : <Redirect push to='/error' />
                    : <LoadingComponent />
                }

            </Switch>

            {cookieClear ? <Redirect to='/' /> : ''}
            {kycPageRefresh ? <Redirect push to='/dashboard' /> : ''}
        </div>
    )
}

export default Routes
