//api call 
// import axios from "axios";

// //import Config for url
// import * as UrlList from "../config/UrlList";
// import { ConsoleLog } from "../service/utils/ConsoleLog";

export const getEsgcValue = async () => {
    //set params for post
    // const params = {
    //     'locale': 'en_US'
    // }

    // return await axios.post(UrlList.GET_ESGC_VALUE, params)
    //     .then(res => {
    //         return res.data
    //     })
    //     //handle error
    //     .catch(err => ConsoleLog(' ', err))

    return (
        {
            "esgcValueList": [
                {
                    "id": 1,
                    "identifier": "a4affa05-5df1-4a5a-b2a7-ec40acabba61",
                    "name": "Fighting Climate Change",
                    "description": "<p>Despite our cries for change, the oil and gas industry continues to pour CO2 into the atmosphere. These industries should be held accountable for the environmental devastation they have caused. The companies we divested from are still HUGE factors in the climate crisis today. For this reason, we have removed them for our client's that care to prevent climate change.</p>",
                    "boostedDescription": "<p>Some of the largest companies in the world have yet to make drastic changes to prevent a climate disaster. From oil and gas companies that are polluting and destroying our environment to companies with the highest carbon intensity in their industry…We have divested from the offenders according to the following specifications:</p><ul><li><p><strong>Fossil fuel companies and/or companies that own significant fossil fuel reserves.</strong></p></li><li><p><strong>All utility companies using fossil fuels.</strong></p></li><li><p><strong>The top 10% of companies in each sector with the highest carbon intensity (carbon emissions divided by sales = carbon intensity).</strong></p></li></ul><p>Sleep well knowing that you are making an impact and divesting from companies that are destroying our environment.</p>",
                    "esgcGroupIdentifier": "965dff02-6383-4468-9164-21afec9288b5",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#00467B",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "67.39deg",
                    "titleLinearGradientColorStop1": "#008300",
                    "titleLinearGradientColorStopPercent1": "9.12%",
                    "titleLinearGradientColorStop2": "#00C0CF",
                    "titleLinearGradientColorStopPercent2": "61.41%"
                },
                {
                    "id": 3,
                    "identifier": "da4053e9-2f35-452a-a12a-b0246ad25538",
                    "name": "Animal Compassion",
                    "description": "<p><strong>Every year</strong> Tysons Foods kills <strong>2.4 billion</strong> animals* and Americans alone consume <strong>one billion pounds of beef</strong> at McDonald's in a year (that's about five and a half million head of cattle). Support animal rights by divesting from some of the largest companies contributing to the suffering of millions of innocent animals.</p><p>*<a href='https://sentientmedia.org/tyson-foods-kills-2-4-billion-animals-every-year-can-it-do-good-too/' target='_blank'>https://sentientmedia.org/tyson-foods-kills-2-4-billion-animals-every-year-can-it-do-good-too/</a></p>",
                    "boostedDescription": "<p><strong>Every day</strong> around <strong>23.3 million</strong> land animals are killed in the United States for meat consumption.* The meat production industry, companies testing on animals…we don't have to support them and contribute to the suffering of millions of innocent creatures. Giraffe will divest from the offenders:</p><ul><li><p><strong>All companies exploiting animals according to <a href='http://crueltyfreeinvesting.org/' target='_blank'>CrulteyFreeInvesting.org</a>*</strong></p></li></ul><p>Out of the largest 500 companies in the US Stock indices, we have divested from those that have not met our standards in regards to animal cruelty.</p><p>*<a href='https://animalclock.org/#section-stats' target='_blank'>2022 U.S. Animal Kill Clock</a></p><p>**For specific evaluation criteria please see <a href='http://crueltyfreeinvesting.org/about-us/' target='_blank'>here</a>.</p>",
                    "esgcGroupIdentifier": "9e0b9245-2fad-42fd-98d3-de9df9042683",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#E4A01B",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "79.02deg",
                    "titleLinearGradientColorStop1": "#D57C00",
                    "titleLinearGradientColorStopPercent1": "7.11%",
                    "titleLinearGradientColorStop2": "#F0AF06",
                    "titleLinearGradientColorStopPercent2": "69.08%"
                },
                {
                    "id": 4,
                    "identifier": "11763691-f2ed-42cb-96a4-d604e83aa69a",
                    "name": "Gender Equality",
                    "description": "<p>As of 2021, there are 41 women CEOs on the Fortune 500 list…That's only about 8.1% of the entire list. Not to mention that women, on average, make <strong>24.4%</strong> less than men. The antiquated social phenomenon of gender inequality continues…but we don't have to support it. Choose this index and we will remove the companies that aren't supporting gender equality in the workplace.</p>",
                    "boostedDescription": "<p>As of 2021, there are 41 women CEOs on the Fortune 500 list…That's only about 8.1% of the entire list. Not to mention that women, on average, make <strong>24.4%</strong> less than men. The antiquated social phenomenon of gender inequality continues…but we don't have to support it. Divest from the companies that have hundreds of employees in management yet only one is a female. We removed the offenders based on the following standards:</p><ul><li><p><strong>Percentage of women on the board</strong></p></li><li><p><strong>Percentage of executives are women</strong></p></li><li><p><strong>Percentage of female leadership</strong></p></li><li><p><strong>CEO is a male/female</strong></p></li></ul><p>Out of the largest 500 companies in the US Stock indices, we have removed 20% of the worst offendingcompanies.Companies with hundreds of employees but only one woman in all of their management positions…</p>",
                    "esgcGroupIdentifier": "4957b284-218c-42f0-a54b-3a9cbd084006",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#02A6DF",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "71.41deg",
                    "titleLinearGradientColorStop1": "#0040BC",
                    "titleLinearGradientColorStopPercent1": "10.88%",
                    "titleLinearGradientColorStop2": "#00B5FC",
                    "titleLinearGradientColorStopPercent2": "84.71%"
                },
                {
                    "id": 5,
                    "identifier": "7cbd4ee0-e6aa-448a-b000-fb60a3eaec67",
                    "name": "Tobacco Free",
                    "description": "<p><strong>Tobacco kills up to half of its users.* You no longer have to support the corporate tobacco giants in the industry… </strong>Choose this value set and we will remove tobacco companies from your index.</p>",
                    "boostedDescription": null,
                    "esgcGroupIdentifier": "49fd76fa-4615-436e-9a4e-27d35303dfd7",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#315163",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "84.56deg",
                    "titleLinearGradientColorStop1": "#1F4655",
                    "titleLinearGradientColorStopPercent1": "6.95%",
                    "titleLinearGradientColorStop2": "#6E8294",
                    "titleLinearGradientColorStopPercent2": "107.23%"
                },
                {
                    "id": 6,
                    "identifier": "44206e3b-9518-47f5-abd0-0acbb3feccb1",
                    "name": "Alcohol Free",
                    "description": "<p><strong>Worldwide, 3 million deaths every year result from harmful use of alcohol. This represents 5.3% of all deaths.</strong> *Divest from the alcohol industry by choosing this value.</p>",
                    "boostedDescription": null,
                    "esgcGroupIdentifier": "49fd76fa-4615-436e-9a4e-27d35303dfd7",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#770160",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "84.69deg",
                    "titleLinearGradientColorStop1": "#70015A",
                    "titleLinearGradientColorStopPercent1": "19.66%",
                    "titleLinearGradientColorStop2": "#CE2C7A",
                    "titleLinearGradientColorStopPercent2": "90.27%"
                },
                {
                    "id": 7,
                    "identifier": "685fd7e1-8595-424e-a40f-915a2362a14a",
                    "name": "Gambling Free",
                    "description": "<p>Not comfortable investing in the gambling industry? Choose this value set and we will remove all the following types of companies:</p><ul><li><p><strong>Casinos</strong></p></li><li><p><strong>Casino Hotels and Racetracks</strong></p></li><li><p><strong>Cruise lines and hotel companies that have casinos</strong></p></li></ul>",
                    "boostedDescription": null,
                    "esgcGroupIdentifier": "6e626994-1734-4ff1-a463-3574ee2f08c3",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#E00000",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "86.35deg",
                    "titleLinearGradientColorStop1": "#000000",
                    "titleLinearGradientColorStopPercent1": "13.06%",
                    "titleLinearGradientColorStop2": "#C62222",
                    "titleLinearGradientColorStopPercent2": "95.78%"
                },
                {
                    "id": 8,
                    "identifier": "78476a5c-e48b-4ddc-a698-5fe2621e9f2d",
                    "name": "Healthy Nutrition",
                    "description": "<p>Don't want to support the junk food industry? Choose this value set and we will remove the top fast-food companies, sugary food companies, and soda companies from your index.</p>",
                    "boostedDescription": null,
                    "esgcGroupIdentifier": "49fd76fa-4615-436e-9a4e-27d35303dfd7",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#008678",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "84.67deg",
                    "titleLinearGradientColorStop1": "#11706B",
                    "titleLinearGradientColorStopPercent1": "6.68%",
                    "titleLinearGradientColorStop2": "#019E79",
                    "titleLinearGradientColorStopPercent2": "86.42%"
                },
                {
                    "id": 9,
                    "identifier": "63c4858e-febd-4557-9df6-3bb92469c4ba",
                    "name": "Weapon Free",
                    "description": "<p>Weapons companies have strong lobbies in Washington that allow them to continue mass producing dangerous weapons. If you choose this value, we will remove companies that make most of their money from gun and weapons sales</p>",
                    "boostedDescription": null,
                    "esgcGroupIdentifier": "6e626994-1734-4ff1-a463-3574ee2f08c3",
                    "selected": false,
                    "giftImage": "https://d2q9cm0auwnru9.cloudfront.net/images/gift/AnimalCompassion.png",
                    "footerBackgroundColor": "#707052",
                    "footerEsgcValueColor": "#FFE72D",
                    "titleLinearGradientDirectionDeg": "79.76deg",
                    "titleLinearGradientColorStop1": "#393937",
                    "titleLinearGradientColorStopPercent1": "13.14%",
                    "titleLinearGradientColorStop2": "#7C7C44",
                    "titleLinearGradientColorStopPercent2": "87.04%"
                }
            ],
            "companySummary": {
                "totalCompanies": 450,
                "excludedCompanies": 37
            },
            "blueprintIdentifier": "0c59518d-82e7-4d6c-9ba0-5a659fee00c7"
        }
    )
}