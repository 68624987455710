import moment from 'moment'

export const DateFormatter = (value) => {

    const date = moment(value).format('MMM Do, YYYY');
    return date
}

export const DateFormatter2 = (value) => {

    const date = moment(value).format('MMM DD YYYY');
    return date
}