//default library
import React from 'react'
import { Fragment } from 'react'
import IntlMessages from '../../helper/IntlMessages'

//common label component
function LabelComponent(props) {
    return (
        <>
            <label htmlFor={props.name} className="form-label">
                {props.labelName ? IntlMessages(props.labelName) : ''}
                <span className="gi-text-line">
                    {props.labelName2
                        ? <Fragment>
                            &nbsp;
                            {IntlMessages(props.labelName2)}
                            &nbsp;
                            {props.phoneNumber}
                        </Fragment>
                        : ''
                    }
                </span>
            </label>
        </>
    )
}

export default LabelComponent