import axios from "axios";

import { TRANSFER_FUND_URL, CHECK_FUND_TRANSFER_URL, GET_FUND_LOCATION, BASKET_ORDER_PENDING, GET_CASH_BALANCE, PLACE_BASKET_ORDER } from "../config/UrlList";
import { ConsoleLog } from "../service/utils/ConsoleLog";

//transfer fund
export const transferFund = async (accessToken, param) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.post(TRANSFER_FUND_URL, param, config)
        .then(response => {
            return {
                'data': response.data,
                'error': false,
                'message': null
            }
        })
        .catch(error => {
            if (error.response !== 200) {
                return {
                    'data': null,
                    'error': true,
                    'message': error.response.data.message
                }
            }
            ConsoleLog('There was an error!', error);
        })
}

//transfer fund
export const placeBasketOrder = async (accessToken, param) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.post(PLACE_BASKET_ORDER, param, config)
        .then(response => {
            return {
                'data': response.data,
                'error': false,
                'message': null
            }
        })
        .catch(error => {
            if (error.response !== 200) {
                return {
                    'data': null,
                    'error': true,
                    'message': error.response.data.message
                }
            }
            ConsoleLog('There was an error!', error);
        })
}

//check fund transfer or not
export const checkFundTransfer = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(CHECK_FUND_TRANSFER_URL, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

//get fund transfer modal dropdown value
export const getFundLocations = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(GET_FUND_LOCATION, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

//check buy order is pending or not
export const isOrderBuyPending = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(BASKET_ORDER_PENDING, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

//check cash balance 
export const getCashBalance = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(GET_CASH_BALANCE, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}