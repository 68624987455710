import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class InformationComponent extends Component {
  render() {
    const { strongFlag, message, message2 } = this.props
    return (
      <p>
        {strongFlag
          ? <strong>
            {IntlMessages(message)}
          </strong>
          : IntlMessages(message)
        }
        <br />
        {
          message2
            ? IntlMessages(message2)
            : ''
        }
      </p>
    )
  }
}

export default InformationComponent