//default library
import React, { Component, Fragment } from 'react'
//custom component
import GiftFooter from '../../components/FooterComponent/GiftFooter'
import GiftHeader from '../../components/HeaderComponent/GiftHeader'
import GiftRedeemedErrorModal from '../../components/ModalComponent/GiftRedeemedErrorModal'
import GiftFaq from '../../components/SectionComponent/GiftFaq'
import GiftGiverSection from '../../components/SectionComponent/GiftGiverSection'
import GiftMainSection from '../../components/SectionComponent/GiftMainSection'
import StartJourney from '../../components/SectionComponent/StartJourney'

//api call
import { getGiftDetail } from '../../middleware/GiftAPI'

export class GiftLandingPage extends Component {
    constructor(props) {
        super(props)
        require('../../assets/styles/newCSS/main.scss') //import css
        this.state = {
            giftHash: window.location.pathname.split('/')[2],
            giftGiver: '',
            giftImpact: '',
            openRedeemedErrorModal: false,
        }

    }

    componentDidMount = async () => {
        document.body.style.fontFamily = `'Roboto Slab', serif`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1390px'
        }

        const { giftHash } = this.state
        let giftDetail = await getGiftDetail(giftHash)
        let giftGiver = {
            giverLogo: giftDetail.image,
            giverName: giftDetail.giverName,
            giverMessage: giftDetail.message,
        }
        let giftImpact = {
            miniMba: giftDetail.miniMba,
            giraffeLearn: giftDetail.learn,
            treeOfImpact: giftDetail.treeOfImpact,
        }

        await this.setState({
            giftGiver: giftGiver,
            giftImpact: giftImpact,
            openRedeemedErrorModal: giftDetail.status !== 'AVAILABLE' ? true : false
        })

        if (giftDetail.status === 'AVAILABLE') {
            await localStorage.setItem('giftIdentifier', giftDetail?.identifier)
        }
    }

    render() {
        const { giftGiver, giftImpact, openRedeemedErrorModal } = this.state

        return (
            <Fragment>
                {/* Header */}
                <GiftHeader />

                {/* Gift main section */}
                <section className="gi-g-lp">
                    <div className="container">
                        {/* gift giver section */}
                        {giftGiver ? <GiftGiverSection giftGiver={giftGiver} /> : ''}

                        {/* gift main section */}
                        {giftImpact ? <GiftMainSection giftImpact={giftImpact} /> : ''}

                        {/* start journey section */}
                        <StartJourney />
                    </div>
                </section>

                {/* Gift FAQ Section */}
                <GiftFaq />

                {/* Footer */}
                <GiftFooter />

                {/* Gift Redeemed Error modal */}
                {openRedeemedErrorModal
                    ? <GiftRedeemedErrorModal openRedeemedErrorModal={openRedeemedErrorModal} />
                    : ''
                }
            </Fragment>
        )
    }
}

export default GiftLandingPage