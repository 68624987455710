//default library
import React from "react";
import { useHistory } from "react-router-dom";

//api call library
import axios from "axios";


//auth0 library
import { Auth0Provider } from "@auth0/auth0-react";

//auth0 Config import
import { RESEND_EMAIL } from "../config/UrlList";
import { ConsoleLog } from "../service/utils/ConsoleLog";

//auth0 with history
export const Auth0WithHistory = ({ children }) => {
    //routing history hook
    const history = useHistory();

    //redirect after loggedIn
    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || `${window.location.pathname}`);
    };

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_DOMAIN} //set domain
            clientId={process.env.REACT_APP_CLIENT_ID} //set client Id
            redirectUri={window.location.origin} //set origin call
            onRedirectCallback={onRedirectCallback} //redirect after logged in
            audience={process.env.REACT_APP_AUDIENCE} //set api call
            useRefreshTokens={true} //use refresh token
            cacheLocation={'localstorage'}
        >
            {children}
        </Auth0Provider>
    );
};

//resend email 
export const resendEmail = async (accessToken) => {

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    await axios.get(RESEND_EMAIL, config)
        .then((res) => {
            return res
        })
        .catch((err) => {
            ConsoleLog('', err)
        })

}
