//api call 
import axios from "axios";
//import Config for url
import { GET_STATEMENT, GET_TAX_STATEMENT, GET_TRADE_CONFIRMATION, PDF_DOWNLOAD } from "../config/UrlList";

import { ConsoleLog } from '../service/utils/ConsoleLog'


// get statement from driveWealth
export const getStatement = async (accessToken, dateRange) => {

    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "startDate": dateRange.startDate,
        "endDate": dateRange.endDate,
    }

    return await axios.post(GET_STATEMENT, params, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}

// get tax statement from driveWealth
export const getTaxStatement = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(GET_TAX_STATEMENT, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

// get trade conformation from driveWealth
export const getTradeConfirmation = async (accessToken, dateRange) => {

    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "startDate": dateRange.startDate,
        "endDate": dateRange.endDate,
    }

    return await axios.post(GET_TRADE_CONFIRMATION, params, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}

// generate file download url
export const downloadPdf = async (accessToken, fileKey) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(`${PDF_DOWNLOAD}/${fileKey}`, config)
        .then(res => {
            return res.data.url
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}