import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class TextError extends Component {
    render() {
        const { display, message, message2 } = this.props
        return (
            <div className="error-text" style={{ display: display }}>
                {message ? IntlMessages(message) : ''}
                {message2 ? message2 : ''}

            </div>
        )
    }
}

export default TextError