//default library
import React, { Component, Fragment } from 'react'

//auth0 library
import { withAuth0 } from '@auth0/auth0-react'

//custom component
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import LogoComponent from '../../components/LogoComponent/LogoComponent';

//api call
import { resendEmail } from '../../middleware/Auth0';

import IntlMessages from '../../helper/IntlMessages'

export class ThankYouPage extends Component {

    constructor(props) {
        super(props)
        require('../../assets/styles/oldCSS/main.scss') //import css
        this.state = {
            isLoading: false,
            user: [],
            userName: '',
        }
    }

    componentDidMount = async () => {
        const { user } = this.props.auth0

        await this.setState({
            userName: user.name,
            user: user,
        })

        document.body.style.fontFamily = `Lato`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1125px'
        }
    }

    //resend email
    resendEmail = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        await resendEmail(accessToken)
        await this.setState({
            isLoading: false
        })
    }

    logOut = () => {
        const { logout } = this.props.auth0

        logout({
            returnTo: window.location.origin,
        });
        localStorage.clear()
    }

    render() {

        const { userName, isLoading } = this.state
        return (
            <Fragment>
                {isLoading ? <LoadingComponent /> : ''}

                <div className="gi-thanks-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-thanks-modal">
                                    <div className="gi-thanks-modal-logo">
                                        <LogoComponent />
                                    </div>
                                    <div className="gi-thanks-modal-text">
                                        <h5>{IntlMessages('thank_you1')}</h5>
                                        <p>{IntlMessages('verify_email_t1')} </p>
                                        <span>{userName}</span>
                                        <a href="#!" onClick={this.resendEmail}>{IntlMessages('resend_email')}</a>
                                        <h6>
                                            {IntlMessages('wrong_email')}&nbsp;
                                            <a href="#!" onClick={this.logOut}> {IntlMessages('sign_out')}</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withAuth0(ThankYouPage)