import React, { Component } from 'react'

//chart
import ReactApexChart from 'react-apexcharts'

// jquery library
import $ from 'jquery';

//intl message
import IntlMessages from '../../helper/IntlMessages';

export class ChartSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            optionsPieChart: {
                chart: {
                    width: '83%',
                    type: "donut"
                },
                tooltip: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        // customScale: -1,
                        expandOnClick: false,
                        donut: {
                            size: '75%',
                            labels: {
                                show: true,
                                total: {
                                    verticalAlign: 'middle',
                                    show: true,
                                    showAlways: true,
                                    fontSize: '14px',
                                    fontFamily: 'Roboto Slab',
                                    fontWeight: 400,
                                    label: 'Industries',
                                    color: '#FFFFFF',
                                    formatter: function (w) {
                                        return Math.floor(w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0))
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '14px',
                                    fontFamily: 'Roboto Slab',
                                    color: '#FFFFFF',
                                },
                            }
                        }
                    }
                },
                stroke: {
                    show: false,
                },

                // colors: ['#48C35C', '#B5FB7D', '#95E588', '#A89554', '#8F643C', '#BC7E44'],
                colors: ['#48C35C', '#B5FB7D', '#95E588', '#CCC584', '#A89554', '#8F643C', '#BC7E44', '#151517', '#484848', '#48C35C'],
                dataLabels: {
                    enabled: false,
                    style: {
                        fontSize: "140px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "bold"
                    }
                },
                legend: {
                    show: false
                },
                grid: {
                    yaxis: {
                        lines: {
                            offsetX: 10
                        }
                    },
                    padding: {
                        left: -5 //left: -10
                    },

                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                }
            },
            seriesPieChart: [6.2, 17.8, 9.1, 22.5, 12.7, 12.7, 4.7, 4, 4.3, 6.2],


        };
    };

    componentDidMount = async () => {
        $('.gi-p-c-card-text a').click(function (e) {
            //get selected href
            var href = $(this).attr('href');
            //set all nav tabs to inactive
            $('.nav-pills li').removeClass('active');
            $('.nav-pills li a').removeClass('active');

            //get all nav tabs matching the href and set to active
            $('.nav-pills li a[href="' + href + '"]').closest('li').addClass('active');
            $('.nav-pills li a[href="' + href + '"]').addClass('active');

            //active tab
            $('#pills-tabContent .tab-pane').removeClass('active show');
            $('#pills-tabContent .tab-pane' + href).addClass('active show');
        })
    }

    render() {
        const { chartValue } = this.props
        const { optionsPieChart, seriesPieChart } = this.state

        const seriesData = seriesPieChart.map((e) => {
            return ((e * chartValue.totalIndustry) / 100)
        })

        return (
            <div className="gi-d-chartbox">
                <ReactApexChart options={optionsPieChart} series={seriesData} type='donut' width={'83%'} />

                <div className="gi-p-c-card">
                    <div className="gi-p-c-card-text">
                        <h5>{chartValue?.totalCompanies}</h5>
                        <span>{IntlMessages('total')}&nbsp;{IntlMessages('basket_companies')}</span>
                        <a id="viewList" href="#pills-home">{IntlMessages('basket_view_list')}</a>
                    </div>
                    <div className="gi-p-c-card-text excluded-price">
                        <h5>&nbsp;{chartValue?.excludedCompanies}&nbsp;</h5>
                        <span>{IntlMessages('basket_excluded')}&nbsp;{IntlMessages('basket_companies')}</span>
                        <a id="viewList" href="#pills-profile">{IntlMessages('basket_view_list')}</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChartSection