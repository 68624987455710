//default library
import React, { Component, Fragment } from 'react'
import Modal from "react-bootstrap/Modal";

// custom component
import LoadingModal from './LoadingModal';
import ErrorPendingModal from './ErrorPendingModal';
import PlaidComponent from '../PlaidComponent/Plaid';
import LoadingComponent2 from '../LoadingComponent/LoadingComponent2';

//import images
import IMAGES from '../../config/Images';
import IntlMessages from '../../helper/IntlMessages';

export class BankConnectModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openBankModal: false, //bank connect modal
            userName: props.userName, //user name
            openLoadingModal: false, //loading modal while bank connect
            display: 'block', //display modal
            openErrorModal: false, //open error modal when error occurred
            errorMessage: '',
            displayLoading: false, //show loader while token is not creating
        }
    }

    //close model
    onHandleClose = () => {
        this.setState({
            openBankModal: false,
            display: 'block',
            openErrorModal: false,
        })
        this.props.closeBankModal()
    }

    UNSAFE_componentWillReceiveProps = (props) => {
        this.setState({
            openBankModal: props.openBankModal,
        })
    }

    //after bank successful bank connect
    bankConnect = async () => {
        await this.setState({
            openLoadingModal: false
        })
        await this.onHandleClose() //close bank modal
        await this.props.componentDidMount()
    }

    //comes when data fetch from api 
    isLoading = async () => {
        await this.setState({
            openBankModal: false, //close connect modal
            openLoadingModal: true
        })
    }

    // if plaid modal open hide connect modal
    openPlaid = async () => {
        await this.setState({
            display: 'none'
        })
    }

    // error modal handle
    handleErrorModal = async (value) => {
        let errorMessage = ''
        if (value?.message === `Personal data doesn't match bank data`) {
            errorMessage = `error_connecting_account`
        }
        else {
            errorMessage = `error_connecting_account1`
        }
        await this.setState({
            openLoadingModal: false,
            openErrorModal: value?.error,
            errorMessage: errorMessage
        })
    }

    // plaid token generate
    tokenGenerate = async (value) => {
        await this.setState({
            displayLoading: !value
        })
    }

    render() {
        const { openBankModal, userName, openLoadingModal, display, openErrorModal, errorMessage, displayLoading } = this.state
        const { plaidData, message } = this.props

        return (
            <Fragment>
                {/* bank connect modal */}
                <Modal
                    centered
                    show={openBankModal}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-d-bank-connect-modal"
                    style={{ display: display }}
                >
                    <Modal.Body className="gi-d-bank-connect-modal-card">
                        <div style={{ display: displayLoading ? 'block' : 'none' }}>
                            <div className='loading'>
                                <LoadingComponent2 />
                            </div>
                        </div>
                        <div style={{ display: !displayLoading ? 'block' : 'none' }}>
                            <button type="button" className="btn-close" aria-label="Close" onClick={this.onHandleClose}></button>
                            <div className="gi-d-bank-connect-modal-body">
                                <img src={IMAGES.BANK_CONNECT_ANIMATION} className="img-fluid" alt="" />
                                <h5>
                                    {IntlMessages(message, { br: <br /> })}
                                </h5>
                            </div>
                            <div className="gi-d-bank-connect-modal-footer">
                                <PlaidComponent
                                    className={'gi-d-connect-btn'}
                                    isLoading={this.isLoading}
                                    onHandleClose={this.onHandleClose}
                                    componentDidMount={this.bankConnect}
                                    buttonName={IntlMessages('button_connect')}
                                    userName={userName}
                                    openPlaid={this.openPlaid}
                                    handleErrorModal={this.handleErrorModal}
                                    plaidData={plaidData}
                                    tokenGenerate={this.tokenGenerate}
                                />
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>

                {/* Loading modal */}
                <LoadingModal openLoadingModal={openLoadingModal} title={'modal_connect_bank'} onHandleClose={this.onHandleClose} />

                {/* Error Modal */}
                <ErrorPendingModal
                    openModelError={openErrorModal}
                    closeErrorModal={this.onHandleClose}
                    h3={errorMessage}
                    label={'error_please_try'}
                />
            </Fragment>
        )
    }
}

export default BankConnectModal