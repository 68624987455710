//api call 
import axios from "axios";
//import Config for url
import { DISCLOSURE_URL } from '../../config/UrlList';
import { ConsoleLog } from "../utils/ConsoleLog";

//get t&c modal from driveWealth
const getDisclosureUrl = async (accessToken) => {
    //get localStorage data
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'))

    //set params for post
    let data = {
        'citizenship': localStorageData?.citizenship ?? null,
        'country': localStorageData?.countryDisclosure ?? null,
        'language': 'en_US',
        'usTaxPayer': localStorageData?.taxPayer === 'true' || localStorageData?.countryLabel === 'United States' ? true : false,
    };

    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //fetch driveWealth t&c utl
    return await axios.post(DISCLOSURE_URL, data, config)
        .then(res => {
            return res.data
        })
        .catch(err => ConsoleLog(' ', err))

}

export default getDisclosureUrl
