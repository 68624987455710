/* eslint-disable jsx-a11y/anchor-is-valid */
//default library
import React from 'react'

//Auth0 library
import { useAuth0 } from "@auth0/auth0-react";

// local message
import IntlMessages from '../../helper/IntlMessages'

// import direct 

const LogoutComponent = (props) => {

    //Auth0 hook call
    const { logout, user } = useAuth0();
    const { name } = user;

    //onClick logout
    const logOut = () => {
        logout({
            returnTo: window.location.origin,
        });
        localStorage.clear()
    }

    // handle local change
    // const handleChangeLocale = (_locale, direction) => {
    //     LanguageConfig.setCurrentLanguage(_locale)
    //     const currentDirection = LanguageConfig.getDirection();

    //     if (direction !== currentDirection.direction) {
    //         LanguageConfig.setDirection(direction);
    //         setTimeout(() => {
    //             window.location.reload();
    //         }, 500);
    //     }
    // };

    return (
        <>
            <div className="gi-header-right">
                <div className="gi-profile-dropdown">
                    <div className="dropdown">
                        <button type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="material-icons-outlined">
                                more_vert
                            </span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><span className="dropdown-item">{name}</span></li>
                            <li>
                                <a className="dropdown-item" onClick={logOut}>
                                    {IntlMessages('logout')}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="gi-header-contact">
                    <span> {name} | </span><a onClick={logOut}>{IntlMessages('logout')}</a>
                    {/* <div className="dropdown">
                        <button type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="material-icons-outlined">
                                format_align_right
                            </span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            {localeOptions.map((l) => {
                                return (
                                    <li key={l.id}>
                                        <a className="dropdown-item" onClick={() => handleChangeLocale(l.id, l.direction)} >
                                            {l.name}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default LogoutComponent