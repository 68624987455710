//api call 
import axios from "axios";

//import Config for url
import * as UrlList from "../config/UrlList";
import { ConsoleLog } from "../service/utils/ConsoleLog";

//ESG-C value list
export const getESGCValue = async (accessToken) => {

    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params for post
    const params = {
        'locale': 'en_US'
    }

    return await axios.post(UrlList.GET_ESGC_VALUE, params, config)
        .then(res => {
            return res.data.esgcValueList
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}

//get basket company count 
export const getBasketCompanyCount = async (accessToken) => {

    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = await JSON.parse(localStorage.getItem('esgcValueIdentifierList'))

    return await axios.post(UrlList.GET_BASKET_COMPANY_COUNT, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })

}

//get basket create summary
export const getBasketCreateSummary = async (accessToken) => {

    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    const esgcValueIdentifierList = await JSON.parse(localStorage.getItem('esgcValueIdentifierList'))

    //set params
    const params = {
        'locale': 'en_US',
        "esgcValueBoostList": esgcValueIdentifierList,
    }

    return await axios.post(UrlList.GET_BASKET_CREATE_SUMMARY, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })

}

//create basket
export const createBasket = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');//get access token from localStorage

    //set header
    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    }

    //set params
    const params = await JSON.parse(localStorage.getItem('esgcValueIdentifierList'))

    return await axios.post(UrlList.CREATE_BASKET, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

//get basket details
export const getBasketDetails = async (accessToken, basketId) => {
    // const accessToken = await localStorage.getItem('accessToken');//get access token from localStorage
    //set header
    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        'locale': 'en_US',
        "basketId": basketId
    }

    return await axios.post(UrlList.GET_BASKET_DETAIL, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

export const getHistoricalProjection = async (accessToken, basketId, dateRange) => {
    // const accessToken = await localStorage.getItem('accessToken'); //get access token from localStorage
    //set header
    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "basketId": basketId,
        "startDate": dateRange.startDate,
        "endDate": dateRange.endDate,
    }

    return await axios.post(UrlList.GET_HISTORICAL_PROJECTION, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })


}

export const getFutureProjection = async (accessToken, basketId, yearRange) => {
    // const accessToken = await localStorage.getItem('accessToken'); //get access token from localStorage
    //set header
    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "basketId": basketId,
        "years": yearRange
    }

    return await axios.post(UrlList.GET_FUTURE_PROJECTION, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

// rename basket
export const renameBasket = async (accessToken, basket) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        identifier: basket.identifier,
        name: basket.name
    }

    return await axios.post(UrlList.RENAME_BASKET, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
            return []
        })
}