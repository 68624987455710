//default library
import React, { Component, Fragment } from 'react';
import IntlMessages from '../../helper/IntlMessages';

//currency formatter
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';

export class TotalCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalAccount: '0.00', //total account
            totalInvestment: '0.00',// total investment
            totalCash: '0.00', //total cash balance
            earning: '-', // total earning
            pendingValue: '', //pending value
            portfolioSummaryData: props.portfolioSummaryData, //portfolio summary api data
            note: props.note //messaging note 
        }
    }

    componentDidMount = async () => {
        const { portfolioSummaryData } = this.state
        //loading start
        await this.setState({
            totalAccount: currencyFormatter(portfolioSummaryData.totalAccountInformation.totalAccountValue),
            totalInvestment: currencyFormatter(portfolioSummaryData.totalInvestmentInformation.totalBasketValue),
            totalCash: currencyFormatter(portfolioSummaryData.totalCashBalance),
            earning: portfolioSummaryData.totalInvestmentInformation.totalBasketGain === 0 ? '-' : currencyFormatter(portfolioSummaryData.totalInvestmentInformation.totalBasketGain),
            pendingValue: portfolioSummaryData.totalPendingTransfer !== 0 ? currencyFormatter(portfolioSummaryData.totalPendingTransfer) : '',
        })
        // let togglePriorityFund = portfolioSummaryData.totalCashBalance === 0 ? true : false
        // await this.props.togglePriorityFund(togglePriorityFund)
    }

    render() {
        const { totalAccount, totalInvestment, totalCash, earning } = this.state

        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="gi-d-total-lg">
                            <span>
                                {IntlMessages('transaction_total_account')}
                            </span>
                            <h5>{totalAccount}</h5>
                        </div>
                    </div>
                </div>
                <div className="row gx-20">
                    <div className="col-sm-4">
                        <div className="gi-d-total-sm">
                            <span>{IntlMessages('transaction_total_current')}</span>
                            <h5>{totalInvestment}</h5>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="gi-d-total-sm">
                            <span>
                                {IntlMessages('transaction_total_cash')}
                            </span>
                            <h5>{totalCash}</h5>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="gi-d-total-sm">
                            <span>
                                {IntlMessages('transaction_earning')}
                            </span>
                            <h5>
                                {earning}
                            </h5>
                        </div>
                    </div>
                </div>
                {/* {note
                    ? <div className='row mt-3'>
                        <div className="col-12">
                            <div className="gi-d-g-green-note">
                                <p>
                                    <strong>
                                        {IntlMessages('note')}:
                                    </strong>
                                    &nbsp;
                                    {typeof (note) === 'object'
                                        ? IntlMessages(...note)
                                        : IntlMessages(note)
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    : ''
                } */}
            </Fragment>
        )
    }
}

export default TotalCard