import React, { Component } from 'react'

import RightInformation from '../../../components/InformationComponent/RightInformation';
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
import PageCount from '../../../components/PageCount/PageCount';
import InputComponentText from '../../../components/InputComponent/InputComponentText';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';
import { LAST_NAME_REGEX, MAX_NUMBER_36, NAME_REGEX } from '../../../config/Config';
export class PersonalDetailsPage2 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            middleName: '',
        }
    }

    componentDidMount = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null) {
            await this.setState({
                firstName: localStorageData?.firstName ?? '',
                lastName: localStorageData?.lastName ?? '',
                middleName: localStorageData?.middleName ?? '',
            })
        }
    }

    // set firstName
    setFirstName = async (firstName) => {
        await this.setState({
            firstName: firstName
        });
    }

    // set lastName
    setLastName = async (lastName) => {
        await this.setState({
            lastName: lastName
        });
    }

    // set middleName
    setMiddleName = async (middleName) => {
        await this.setState({
            middleName: middleName
        });
    }

    // set details
    setDetails = (value) => {
        this.details = {
            firstName: value.firstName,
            middleName: value.middleName,
            lastName: value.lastName
        }
    }


    render() {
        const { firstName, lastName, middleName } = this.state;
        this.setDetails(this.state)

        return (
            <>
                <div className="gi-from-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="py-3">
                                            <div className="gi-from-left">
                                                <PageCount count={2} pageName={'Personal details'} />
                                                {/* firstName */}
                                                <div className="gi-input mb-40">
                                                    <LabelComponent name={'firstName'} labelName={'personal_page2_label1'} />
                                                    <InputComponentText name={'firstName'} placeholder={'placeholder_type_here'} inputValue={this.setFirstName} />
                                                    <ErrorComponent message={'error_max_length_36_name'} display={firstName.length > MAX_NUMBER_36 ? 'block' : 'none'} />
                                                    <ErrorComponent message={'error_first_name'} display={firstName && !firstName.match(NAME_REGEX) ? 'block' : 'none'} />
                                                </div>

                                                {/* middleName */}
                                                <div className="gi-input mb-40">
                                                    <LabelComponent name={'middleName'} labelName={'personal_page2_label3'} />
                                                    <InputComponentText name={'middleName'} placeholder={'placeholder_type_here'} inputValue={this.setMiddleName} />
                                                    <ErrorComponent message={'error_max_length_36_name'} display={middleName.length > MAX_NUMBER_36 ? 'block' : 'none'} />
                                                    <ErrorComponent message={'error_middle_name'} display={middleName && !middleName.match(NAME_REGEX) ? 'block' : 'none'} />
                                                </div>

                                                {/* lastName */}
                                                <div className="gi-input mb-40">
                                                    <LabelComponent name={'firstName'} labelName={'personal_page2_label2'} />
                                                    <InputComponentText name={'lastName'} placeholder={'placeholder_type_here'} inputValue={this.setLastName} />
                                                    <ErrorComponent message={'error_max_length_36_name'} display={lastName.length > MAX_NUMBER_36 ? 'block' : 'none'} />
                                                    <ErrorComponent message={'error_last_name'} display={lastName && !lastName.match(LAST_NAME_REGEX) ? 'block' : 'none'} />
                                                </div>

                                                <div className="gi-btn-group d-none d-sm-flex">
                                                    <ButtonComponent value={this.details} previousRouting={'page1'} nextRouting={'page3'} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="gi-from-right">
                                            <RightInformation info1={'personal_detail_info1'} info2={'personal_detail_info2'} />
                                            <div className="gi-btn-group d-flex d-sm-none">
                                                <ButtonComponent value={this.details} previousRouting={'page1'} nextRouting={'page3'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PersonalDetailsPage2