//default library
import React from 'react'

//route library
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

//custom component
import CreateBasketPage from '../pages/CreateBasket/CreateBasketPage'
import CompanyValueCount from '../pages/CreateBasket/CompanyValueCount.js'
import SelectValueBasket from '../pages/CreateBasket/SelectValueBasket'
import ProtectedRoute from './ProtectedRoute'

function CreateBasketRoutes() {

    let { path } = useRouteMatch()

    return (
        <Switch>
            <ProtectedRoute exact path={`${path}`} component={SelectValueBasket} />
            <ProtectedRoute exact path={`${path}/company-count`} component={CompanyValueCount} />
            <ProtectedRoute exact path={`${path}/basket-summary`} component={CreateBasketPage} />
            <Route path={'*'}>
                <Redirect push to='/error' />
            </Route>
        </Switch>
    )
}

export default CreateBasketRoutes