import { Markup } from 'interweave';
import React, { Component, Fragment } from 'react'
import IntlMessages from '../../helper/IntlMessages';

export class ReadMoreSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isReadMore: true,
            readMore: props.children
        }
    }

    toggleReadMore = async () => {
        await this.setState({
            isReadMore: !this.state.isReadMore
        })
    };

    render() {
        const { isReadMore, readMore } = this.state
        return (
            <Fragment>
                <div className='gi-b-details-table-text'>
                    {isReadMore
                        ? <Markup content={readMore.slice(0, 150)} />
                        : <Markup content={readMore} />
                    }
                    <p>
                        <a href="#!" onClick={this.toggleReadMore}>
                            {isReadMore
                                ? <Fragment>
                                    ...{IntlMessages('gift_read_more')}
                                </Fragment>
                                :
                                <Fragment>
                                    &nbsp;{IntlMessages('gift_show_less')}
                                </Fragment>
                            }
                        </a>
                    </p>
                </div>
            </Fragment>
        )
    }
}

export default ReadMoreSection