//default library
import React, { Component, Fragment } from 'react'

//prefect scroll bar react
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// react you-tube
import YouTube from 'react-youtube';

//custom function
import { removeUndefined } from '../../service/utils/RemoveUndefineValue';

// import image
import { getUserAnyVideos, getUserVideo } from '../../middleware/GiraffeLearn';
import { withAuth0 } from '@auth0/auth0-react';
import DashboardHeader from '../HeaderComponent/DashboardHeader';


export class GiraffeLearnVideoModal extends Component {

    opts = {
        height: '390',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    constructor(props) {
        super(props)
        require('../../assets/styles/newCSS/main.scss') //import css
        this.state = {
            currentVideo: [],
            thumbnailVideo: [],
        };

        this.endTime = this.endTime.bind(this);
    };

    componentDidMount = async () => {
        // css
        document.body.style.fontFamily = `'Roboto Slab', serif`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1390px'
        }

        window.scroll(0, 0) //scroll to the top

        const urlParams = new URLSearchParams(window.location.search);
        const identifier = urlParams.get('cid');
        const getUserAnyVideo = await getUserAnyVideos(identifier); //no need access token
        const currentIdentifier = urlParams.get('id') ?? getUserAnyVideo[0].anyContentVideoResponseList[0].identifier;

        let currentVideo = await removeUndefined(getUserAnyVideo?.[0]?.anyContentVideoResponseList?.map((e) => {
            if (e.identifier === currentIdentifier) {
                return e
            }
            else {
                return null
            }
        }))

        await this.setState({
            currentVideo: currentVideo[0],
            thumbnailVideo: getUserAnyVideo?.[0]?.anyContentVideoResponseList,
        })
    }

    videoReady = async (event) => {
        const { currentVideo } = this.state
        event.target.loadVideoById({
            videoId: currentVideo.videoId,
            startSeconds: currentVideo.viewedTill,
        })
    }

    playVideo = async (event) => {
        const { thumbnailVideo } = this.state
        const currentVideo = await removeUndefined(thumbnailVideo.map((e) => {
            if (e.videoId === event.target.parentElement.id) {
                return e
            }
            else {
                return null
            }
        }))
        await this.setState({
            currentVideo: currentVideo[0]
        })
    }

    endTime = async (event) => {
        const { getAccessTokenSilently } = this.props.auth0
        const { isAuthenticated } = this.props.auth0

        const videoIdentifier = this.state.currentVideo.identifier
        const currentTime = event.target.getCurrentTime()
        const currentDate = new Date()

        const videoUpdate = {
            videoIdentifier: videoIdentifier,
            currentTime: parseInt(currentTime),
            currentDate: currentDate.toISOString().split('T')[0] + ' ' + currentDate.toTimeString().split(' ')[0],
        }
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently() //get access token
            await getUserVideo(accessToken, videoUpdate)
        }
    }

    onHandleClose = async () => {
        await this.props.closeVideoModal()
    }

    render() {
        const { currentVideo, thumbnailVideo } = this.state
        const { isAuthenticated } = this.props.auth0

        return (
            <Fragment>

                {/* Header  */}
                {isAuthenticated ? <DashboardHeader dashboard={false} /> : ''}

                {/* Actual Content */}
                <section className={`gi-g-main-section gi-g-video-section ${isAuthenticated ? '' : 'gi-g-header-learn'}`}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="gi-g-v-section">
                                    {/* <div className="gi-g-closebox">
                                        <button type='button' onClick={this.onHandleClose}>
                                            <img src={whiteCloseIcon} alt="" />
                                        </button>
                                    </div> */}
                                    <div className="gi-g-v-video-main">
                                        <div className="gi-g-v-video-player">
                                            <YouTube videoId={currentVideo.videoId} opts={this.opts} onReady={this.videoReady} onPause={this.endTime} onEnd={this.endTime} />;
                                        </div>
                                        <div className="gi-g-v-video-description d-block d-md-none pb-5">
                                            <h5> {currentVideo.title}</h5>
                                            <p>{currentVideo.longDescription} </p>
                                        </div>
                                    </div>
                                    <div className="gi-g-v-list-main">
                                        <ScrollBar component="div">
                                            <div className="gi-g-v-list">
                                                {thumbnailVideo.map((e) => {
                                                    return (
                                                        <div className="gi-g-v-list-point" key={e.identifier} id={e.videoId} onClick={this.playVideo}>
                                                            <div className="gi-g-v-list-thumbnail" id={e.videoId}>
                                                                <img src={e.thumbnailImage} className="img-fluid" alt="" />
                                                            </div>
                                                            <div className="gi-g-v-list-tittle" id={e.videoId}>
                                                                {e.title}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </ScrollBar>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="gi-g-v-video-description d-none d-md-block pb-5">
                                    <h5> {currentVideo.title}</h5>
                                    <p>{currentVideo.longDescription} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default withAuth0(GiraffeLearnVideoModal)