//default library
import React, { Component } from 'react'
import { Fragment } from 'react'
import IMAGES from '../../config/Images'

//Loading component
export class LoadingComponent2 extends Component {
  render() {
    return (
      <Fragment>
        {/* <div className="gi-spinner2">
          <div className={`spinner-border ${this.props.classFalse ? '' : 'mt-40 mb-20'}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> */}
        <img src={IMAGES.SMILE_LOADER_ANIMATION} className='loader-img' alt='loading' />
      </Fragment>
    )
  }
}

export default LoadingComponent2
