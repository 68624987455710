//default library
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//auth0 library
import { useAuth0 } from "@auth0/auth0-react";

//loading component
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

//API call 
import * as User from '../../middleware/User';


let count = 0

function LandingPage(props) {

    //auth0 hook
    const { isAuthenticated, getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

    // get trigger method
    let triggerMethod = localStorage.getItem('triggerMethod')

    //flag coming from backend
    const [pageRedirect, setPageRedirect] = useState([false, false, false])

    // get user create and redirect
    const createUser = async () => {
        const accessToken = await getAccessTokenSilently()
        const createUser = await User.createUser(accessToken)
        localStorage.setItem('hasKycErrors', createUser.hasKycErrors)
        if (window.location.pathname === '/') {
            const data = [
                createUser.isNewUser,
                createUser.isKycProcessing,
                !(createUser.isNewUser || createUser.isKycProcessing),
                true
            ]
            await setPageRedirect(data)
        }
    }

    //create user
    if (isAuthenticated && triggerMethod === 'register' && count === 0) {
        count++;
        createUser()
    }

    useEffect(() => {
        let isMounted = true;
        const getToken = async () => {
            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently()
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('userEmailId', user.name)
                const data = [
                    props.pageRedirect[0],
                    props.pageRedirect[1],
                    props.pageRedirect[2],
                    props.pageRedirect[3],
                ]
                await setPageRedirect(data)
            }
        }
        if (isMounted) {
            getToken()
        }

        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently, isAuthenticated, user, props]);

    //onClick login
    if (!isAuthenticated) {
        loginWithRedirect();
        localStorage.setItem('triggerMethod', 'register')
    }

    return (
        <>
            {pageRedirect[0] ? <Redirect push to='/onBoarding' /> : ''}
            {pageRedirect[1] ? <Redirect push to='/kycPending' /> : ''}
            {pageRedirect[2] ? <Redirect push to='/dashboard' /> : ''}
            <LoadingComponent />
            {/* <section className="gi-c-main-section gi-add-footer">

                header
                <LandingPageHeader />

                page start section
                <LandingPageSection1 />

                graph section
                <LandingPageSection2 />

                investor section
                <LandingPageSection3 />

                better section
                <LandingPageSection4 />

                gift card section
                <LandingPageSection5 />

                landing page courses section
                <LandingPageSection6 />

                footer section
                <LandingPageFooter />
            </section> */}
        </>
    )
}

export default LandingPage