//default library
import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
//image
import IMAGES from '../../config/Images';
import IntlMessages from '../../helper/IntlMessages';

export class SuccessModal extends Component {

    // onHandleClose = async () => {
    //     this.props.onHandleClose()
    // }

    onHandleCloseSuccess = async () => {
        this.props.onHandleCloseSuccess()
    }

    render() {
        const { openSuccessModal, sellConfirm, successMessage } = this.props
        return (
            <Modal
                centered
                show={openSuccessModal}
                backdrop="static"
                keyboard={false} className="gi-g-new-fund-modal"
            >
                <Modal.Body>
                    <button className="btn-close-white" type="button" onClick={this.onHandleCloseSuccess}></button>
                    <div className="gi-g-new-transfer-card">
                        <div className="gi-g-new-transfer-text">
                            <img src={IMAGES.GIRAFFE_MODAL_ICON} alt="" />
                            <h5>
                                {IntlMessages('fund_success')}
                            </h5>
                            {/* {sellConfirm
                                ? '' 
                                :*/}
                            <p>
                                {IntlMessages(successMessage)}
                            </p>
                            {/* } */}
                            <button className={`gi-d-ok-btn ${sellConfirm ? 'mt-10' : ''}`} type="button" onClick={this.onHandleCloseSuccess}>
                                {IntlMessages('button_ok')}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default SuccessModal