//default library
import React, { Component } from 'react';

//custom component
import TableComponent from '../TableComponent/TableComponent';
import ExpenseRatioCard from './ExpenseRatioCard';

//import static image
import IMAGES from '../../config/Images';
import IntlMessages from '../../helper/IntlMessages';

export class CompanyDetailsCard extends Component {

    count = 0 //count componentDidUpdate function

    constructor(props) {
        super(props)

        this.state = {
            totalCompanyCount: props.companySummary ? props.companySummary.totalCompanies : '', //
            excludedCompanyCount: props.companySummary ? props.companySummary.excludedCompanies : '', //
            totalCompanyList: props.companySummary ? props.companySummary.totalCompanyList : '', //total company list
            excludedCompanyList: props.companySummary ? props.companySummary.excludedCompanyList : '', //excluded company list
            expenseRatio: props.expenseRatio ? props.expenseRatio : ''// expense ratio summary
        }
    }

    checkURLTab = (value) => {
        if (value === 'expense-ratio') {
            return ([false, false, true])
        }
        else if (value === 'excluded-company') {
            return ([false, true, false])
        }
        else {
            return ([true, false, false])
        }
    }

    render() {
        const { totalCompanyList, excludedCompanyList, totalCompanyCount, excludedCompanyCount, expenseRatio } = this.state
        const checkURLTab = this.checkURLTab(window.location.href.split('#')[1])
        return (
            <div className="row">
                <div className="col-12 mt-20">
                    <div className="gi-d-tabs">
                        <ul className="nav nav-pills">
                            <li className="nav-item" >
                                <div id='total-company'>
                                    <a className={`nav-link ${checkURLTab[0] ? 'active' : ''}`} href="#total-company" >
                                        {/* {totalCompanyCount}&nbsp; */}
                                        {IntlMessages('basket_companies_list', { totalCompany: totalCompanyCount })}
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item" >
                                <div id='excluded-company'>
                                    <a className={`nav-link ${checkURLTab[1] ? 'active' : ''}`} href="#excluded-company" >
                                        {/* <span> {excludedCompanyCount}</span>&nbsp; */}
                                        {IntlMessages('basket_excluded1', { excludedCompany: excludedCompanyCount })}
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item" >
                                <div id='expense-ratio'>
                                    <a className={`nav-link ${checkURLTab[2] ? 'active' : ''}`} href="#expense-ratio" >
                                        {IntlMessages('basket_expense')}
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>

                    {checkURLTab[0]
                        ? <div className="gi-d-table-card">
                            <div className="gi-d-table-card-header">
                                <p>
                                    {IntlMessages('basket_text1')}
                                </p>
                            </div>
                            <div className="gi-d-table-card-body">
                                <div className="row">
                                    <TableComponent totalCompanyList={totalCompanyList} />
                                </div>
                            </div>
                        </div>
                        : ''
                    }

                    {checkURLTab[1]
                        ? <div className="gi-d-table-card">
                            <div className="gi-d-table-card-header">
                                <p>
                                    {IntlMessages('basket_text2')}
                                </p>
                                {/* <ul className="gi-yp-excluded-view">
                                    {excludedCompanyList
                                        ? excludedCompanyList.exploitingEsgcValueList.map((e, i) => {
                                            return (
                                                <li key={i}>
                                                    <a href="#!">{e.name} <span> ({e.companyCount})</span></a>
                                                </li>
                                            )
                                        })
                                        : ''}
                                </ul> */}

                            </div>
                            <div className='excluded-height'>
                                <div className="gi-yp-excluded-ul">
                                    {excludedCompanyList.length > 0
                                        ? excludedCompanyList.map((e, i) => {
                                            return (
                                                <div className="gi-yp-excluded-list" key={i}>
                                                    <div className="gi-yp-excluded-list-img">
                                                        <img src={IMAGES.COMPANY_LOGO} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="gi-yp-excluded-list-text">
                                                        <span>{e} <a href="#!"> (i)</a></span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ''
                                    }
                                </div>
                            </div>

                        </div>
                        : ''
                    }

                    {
                        checkURLTab[2]
                            ? <ExpenseRatioCard expenseRatio={expenseRatio} />
                            : ''
                    }

                </div>
            </div>
        )
    }
}

export default CompanyDetailsCard