//default library
import React, { Component, Fragment } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Redirect } from 'react-router-dom';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import CompanyCountCard from '../../components/CardComponent/CompanyCountCard'
import CompanyDetailsCard from '../../components/CardComponent/CompanyDetailsCard'
import DashboardLogoComponent from '../../components/LogoComponent/DashboardLogoComponent'
import SelectCreateBasket from '../../components/SelectComponent/SelectCreateBasket';
import InformationModal from '../../components/ModalComponent/InformationModal';

//footer import 
import LandingPageFooter from '../../components/FooterComponent/LandingPageFooter';

//api call
import { getBasketCreateSummary, createBasket } from '../../middleware/CreateBasket';

// custom function call
import { getBasketPerformance } from '../../service/Constant/BasketPerformance';
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';
import IntlMessages from '../../helper/IntlMessages';
import { startWithDropdown, waitPatientlyDropdown } from '../../config/DefaultValue';

export class CreateBasketPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            series: [{
                name: 'S&P500',
                data: [
                    {
                        x: "Today",
                        y: 20
                    },
                    {
                        x: "2042",
                        y: 200
                    },
                ]
            }], //pie chart data
            options: {
                chart: {
                    height: 200,
                    type: 'area'
                },
                colors: ['#4FAA7E'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.45,
                        opacityTo: 0.05,
                        stops: [20, 100, 100, 100]
                    },
                },
                xaxis: {
                    type: 'categories',
                    // categories: ["Today", "2042"],
                    labels: {
                        style: {
                            colors: ['#484848', '#484848'],
                            fontSize: '14px',
                            fontFamily: `'Lato', sans-serif`,
                            fontWeight: 400,
                        },
                        // offsetY: 0,
                        offsetX: 1,
                    },
                },
                yaxis: [{
                    tickAmount: 5,
                    floating: false,
                    opposite: true,
                    labels: {
                        style: {
                            colors: '#7f7f7f',
                            fontSize: '14px',
                            fontFamily: `'Lato', sans-serif`,
                            fontWeight: 400,
                        },
                        formatter: function (val) {
                            return val + '%';
                        },
                        offsetY: 0,
                        offsetX: 0,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false
                    }
                }],
                legend: {
                    horizontalAlign: 'left',
                    fontSize: '12px',
                    fontFamily: `'Lato', sans-serif`,
                    fontWeight: 400,
                    offsetX: -35,
                    offsetY: 0,
                    markers: {
                        width: 8,
                        height: 8,
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 0
                    },
                    labels: {
                        colors: '#484848',
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },//pie chart option

            isLoading: false, //set loader
            sideMenuOpen: false, //side menu open in mobile view
            cancelButton: false, //cancel create basket flow
            createButton: false, //create basket

            esgcValueList: '', //select company list
            trendingEsgcValueList: '', //trending company list
            moreEsgcValueList: '', //more company list
            companySummary: '', //basket summary data
            createBasketId: '', //when basket is create response get id
            expenseRatio: '', //expense ration tab data



            startWithValue: 1000, //default value of start with amount
            waitPatientlyValue: 20, // default value of wait year
            startAmount: '', //starting amount
            endAmount: '', //ending amount

            esgcValueDescription: [], //esgc description array
            openInformationModal: false, //info modal

            boostedId: null //store boost identifier
        }
    }

    componentDidMount = async () => {
        window.scroll(0, 0) //scroll to top
        const { getAccessTokenSilently } = this.props.auth0
        const esgcValueDescription = [];
        let boostedId;
        await this.setState({
            isLoading: true,
            esgcValueList: '', //select company list
            trendingEsgcValueList: '', //trending company list
            moreEsgcValueList: '', //more company list
            companySummary: '', //basket summary data
            expenseRatio: '', //expense ratio summary data
        })
        const accessToken = await getAccessTokenSilently() //get access token
        let basketSummary = await getBasketCreateSummary(accessToken)
        for (let i = 0; i < basketSummary?.moreEsgcValueList.length; i++) {
            esgcValueDescription.push(...basketSummary?.moreEsgcValueList[i].esgcValueList)
        }
        const esgcValueIdentifierList = await JSON.parse(localStorage.getItem('esgcValueIdentifierList')) //get esgc value identifier from localStorage
        for (let i = 0; i < esgcValueIdentifierList.length; i++) {
            if (esgcValueIdentifierList[i].boosted) {
                boostedId = esgcValueIdentifierList[i].esgcValueIdentifier
            }
        }

        await this.getBasketPerformance() //add static value from basket performance

        await this.setState({
            isLoading: false,
            esgcValueList: basketSummary?.esgcValueList,
            trendingEsgcValueList: basketSummary?.trendingEsgcValueList,
            moreEsgcValueList: basketSummary?.moreEsgcValueList,
            companySummary: basketSummary?.companySummary,
            esgcValueDescription: esgcValueDescription,
            boostedId: boostedId,
            expenseRatio: basketSummary?.expenseRatio ?? [],
        })
    }

    menuOpen = async () => {
        await this.setState({
            sideMenuOpen: !this.state.sideMenuOpen
        })
    }

    //click cancel button
    cancelButton = async () => {
        await this.setState({
            cancelButton: true
        })
    }

    //create basket
    createBasket = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        let data = await createBasket(accessToken)
        await this.setState({
            createBasketId: data.id,
            createButton: true,
            isLoading: false
        })
    }

    //add value
    addValue = async (e) => {
        const esgcValueIdentifierList = await JSON.parse(localStorage.getItem('esgcValueIdentifierList')) //get esgc value identifier from localStorage
        let data = {
            "esgcValueIdentifier": e.target.value,
            "boosted": false
        }
        esgcValueIdentifierList.push(data) //add value to esgc value identifier list array
        await localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList)) //set esgc value identifier in localStorage
        await this.componentDidMount() //load component again
    }

    //remove value
    removeValue = async (e) => {
        const esgcValueIdentifierList = await JSON.parse(localStorage.getItem('esgcValueIdentifierList')) //get esgc value identifier from localStorage
        for (let i = 0; i < esgcValueIdentifierList.length; i++) {
            if (esgcValueIdentifierList[i].esgcValueIdentifier === e.target.id) {
                let index = await esgcValueIdentifierList.indexOf(esgcValueIdentifierList[i]) //find index of remove element
                await esgcValueIdentifierList.splice(index, 1) //remove value from esgc identifier list array
            }
        }
        await localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList)) //set esgc value identifier in localStorage
        await this.componentDidMount() //load component again
    }

    //add boost value
    addBoost = async (e) => {
        const esgcValueIdentifierList = await JSON.parse(localStorage.getItem('esgcValueIdentifierList')) //get esgc value identifier from localStorage
        for (let i = 0; i < esgcValueIdentifierList.length; i++) {
            if (esgcValueIdentifierList[i].esgcValueIdentifier === e.target.value) {
                esgcValueIdentifierList[i].boosted = true
            }
            else {
                esgcValueIdentifierList[i].boosted = false
            }
        }
        await localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList)) //set esgc value identifier in localStorage
        await this.componentDidMount() //load component again

    }

    //remove boost value
    removeBoost = async (e) => {
        const esgcValueIdentifierList = await JSON.parse(localStorage.getItem('esgcValueIdentifierList')) //get esgc value identifier from localStorage
        for (let i = 0; i < esgcValueIdentifierList.length; i++) {
            if (esgcValueIdentifierList[i].esgcValueIdentifier === e.target.id) {
                esgcValueIdentifierList[i].boosted = false
            }
        }
        await localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList)) //set esgc value identifier in localStorage
        await this.componentDidMount() //load component again
    }

    //open information modal
    openInformationModal = async (e) => {
        await this.setState({
            informationId: e.target.id,
            openInformationModal: true
        })
    }

    //close information modal
    closeInformationModal = async () => {
        await this.setState({
            openInformationModal: false
        })
    }

    //handle start with amount
    handleStartWith = async (value) => {
        await this.setState({
            startWithValue: value
        })
        await this.getBasketPerformance()
    }

    //handle wait patiently year
    handleWaitPatiently = async (value) => {
        await this.setState({
            waitPatientlyValue: value
        })
        await this.getBasketPerformance()
    }

    //add static value from basket performance
    getBasketPerformance = async () => {
        const { startWithValue, waitPatientlyValue } = this.state
        const basketPerformance = await getBasketPerformance(startWithValue, waitPatientlyValue)

        const endYear = await new Date().getFullYear() + waitPatientlyValue

        const seriesData = await [{
            name: 'S&P500',
            data: [
                {
                    x: "Today",
                    y: 0
                },
                {
                    x: endYear,
                    y: (((basketPerformance?.startAmount + basketPerformance?.endAmount) / 2) * 100) / startWithValue
                },
            ]
        }]

        await this.setState({
            startAmount: currencyFormatter(basketPerformance?.startAmount),
            endAmount: currencyFormatter(basketPerformance?.endAmount),
            series: seriesData
        })
    }

    render() {
        const { series, options, sideMenuOpen, cancelButton, isLoading, createButton,
            esgcValueList, trendingEsgcValueList, moreEsgcValueList, companySummary, createBasketId,
            esgcValueDescription, informationId, openInformationModal, boostedId, expenseRatio,
            startWithValue, waitPatientlyValue, startAmount, endAmount } = this.state

        return (
            <Fragment>
                {isLoading ? <LoadingComponent /> : ''}

                {/* click cancel button */}
                {cancelButton ? <Redirect push to='/dashboard' /> : ''}

                {/* click cancel button */}
                {createButton ? <Redirect push to={`/dashboard/basket/${createBasketId}`} /> : ''}

                {/* page content */}
                <section className="gi-c-main-section gi-add-footer">
                    <header className="gi-c-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-c-header-main">
                                        <div className="gi-c-header-left">
                                            <DashboardLogoComponent mobileView={true} />
                                        </div>
                                        <div className="gi-c-header-right d-flex align-items-center">

                                            <div className="gi-sell-btn-group d-none d-md-flex">
                                                <button className="gi-sell-cancel-btn gi-sell-cancel-btn-header" type="button" onClick={this.cancelButton}>
                                                    {IntlMessages('button_cancel')}
                                                </button>
                                                <button className="gi-sell-next-btn" type="button" onClick={this.createBasket}>
                                                    {IntlMessages('button_create')}
                                                </button>
                                            </div>
                                            <div className="gi-header-right d-flex d-lg-none">
                                                <button className="gi-header-menu-btn" onClick={this.menuOpen}>
                                                    <span className="material-icons">
                                                        menu
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className="gi-yp-main-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-sell-btn-group d-flex flex-wrap justify-content-end d-md-none mt-20 mb-20">
                                        <button className="gi-sell-cancel-btn gi-sell-cancel-btn-header" type="button" onClick={this.cancelButton}>
                                            {IntlMessages('button_cancel')}
                                        </button>
                                        <button className="gi-sell-next-btn" type="button" onClick={this.createBasket}>
                                            {IntlMessages('button_create')}
                                        </button>
                                    </div>
                                    <div className="gi-yp-main">
                                        <div className={`gi-yp-main-left ${sideMenuOpen ? 'active' : ''}`}>
                                            <div className="gi-yp-nav">
                                                {/* your value list */}
                                                <div className="gi-yp-your">
                                                    <div className="gi-yp-heading">
                                                        <h6>
                                                            {IntlMessages('basket_your_value')}
                                                        </h6>
                                                    </div>

                                                    {esgcValueList.length > 0
                                                        ? esgcValueList.map((e) => {
                                                            return (
                                                                <div className="gi-yp-your-main" key={e.id}>
                                                                    <div>
                                                                        <button type="type" className={`gi-yp-your-btn ${boostedId === e.identifier ? 'boost-active' : ''}`} >{e.name}</button>
                                                                    </div>

                                                                    {esgcValueList.length !== 1 || e.boosted
                                                                        ? <div className="gi-yp-your-drop">
                                                                            <div className="dropdown">
                                                                                <button className="gi-yp-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <span className="gi-d-menu-ico"></span>
                                                                                </button>
                                                                                <ul className="dropdown-menu gi-yp-toggle-text" aria-labelledby="dropdownMenuButton2">
                                                                                    {e.boosted
                                                                                        ? <div className={`gi-yp-boostbox-main ${boostedId === e.identifier ? 'py-0' : ''}`}>
                                                                                            {boostedId === e.identifier
                                                                                                ? <div className="gi-yp-unboost">
                                                                                                    <a href="#!" id={e.identifier} onClick={this.removeBoost}>
                                                                                                        {IntlMessages('create_basket_unboost')}
                                                                                                    </a>
                                                                                                </div>
                                                                                                : <div className="gi-yp-boostbox">
                                                                                                    <button type="button" className="boost-btn" value={e.identifier} onClick={this.addBoost}>
                                                                                                        {IntlMessages('create_basket_boost')}
                                                                                                    </button>
                                                                                                    <p>
                                                                                                        {IntlMessages('create_basket_static_text1')}
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        {IntlMessages('create_basket_static_text3')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        : ''
                                                                                    }
                                                                                    {esgcValueList.length === 1
                                                                                        ? ''
                                                                                        : <div className="gi-yp-boostbox-main border-bottom-0 py-0">
                                                                                            <div className="gi-yp-remove">
                                                                                                <a href="#!" id={e.identifier} onClick={this.removeValue} >
                                                                                                    {IntlMessages('create_basket_remove')}
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        : ''}

                                                </div>

                                                {/* trending value */}
                                                <div className="gi-yp-trending">
                                                    <div className="gi-yp-heading">
                                                        <h6>
                                                            {IntlMessages('create_basket_trending')}
                                                        </h6>
                                                    </div>
                                                    {trendingEsgcValueList
                                                        ? trendingEsgcValueList.map((e) => {
                                                            return (
                                                                <div className="gi-yp-your-main" key={e.id}>
                                                                    <div>
                                                                        <button type="type" className="gi-yp-your-btn" value={e.identifier} onClick={this.addValue}>{e.name}</button>
                                                                    </div>
                                                                    <div className="gi-yp-your-drop">
                                                                        <a href="#!" id={e.identifier} onClick={this.openInformationModal}> (i)</a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ''}
                                                </div>

                                                {/* more value select */}
                                                <div className="gi-yp-more">
                                                    <div className="gi-yp-heading">
                                                        <h6 className="pb-0">
                                                            {IntlMessages('create_basket_more')}
                                                        </h6>
                                                    </div>
                                                    <div className="gi-yp-more-main">
                                                        {moreEsgcValueList
                                                            ? moreEsgcValueList.map((e, i) => {
                                                                return (
                                                                    <div className="gi-c-collapse" key={i}>
                                                                        <div>
                                                                            <a className="gi-c-collapse-btn collapsed" data-bs-toggle="collapse"
                                                                                href={`#gi-c-collapse-${i}`} role="button" aria-expanded="false"
                                                                                aria-controls="gi-c-collapse-01">
                                                                                {e.esgcGroupName} ({e.esgcValueList.length})
                                                                            </a>
                                                                        </div>
                                                                        {e.esgcValueList.map((v) => {
                                                                            return (
                                                                                <div className="collapse" id={`gi-c-collapse-${i}`} key={v.id}>
                                                                                    <div className="gi-yp-your-main" >
                                                                                        <div>
                                                                                            <button type="type" className="gi-yp-your-btn" value={v.identifier} onClick={this.addValue}>{v.name}</button>
                                                                                        </div>
                                                                                        <div className="gi-yp-your-drop">
                                                                                            <a href="#!" id={v.identifier} onClick={this.openInformationModal}>(i)</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )
                                                            })
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="gi-yp-main-right">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="gi-yp-heading">
                                                        <h6>
                                                            {IntlMessages('create_basket_text5')}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="gi-yp-graph-card">
                                                        <div className="row gx-40">
                                                            <div className="col-lg-7">
                                                                <div className="row gx-40">
                                                                    <div className="col-sm-6">
                                                                        <div className="gi-yp-select">
                                                                            <label>
                                                                                {IntlMessages('create_basket_text6')}
                                                                            </label>
                                                                            <SelectCreateBasket name={'startWith'} option={startWithDropdown} value={startWithValue} selectValue={this.handleStartWith} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="gi-yp-select">
                                                                            <label>
                                                                                {IntlMessages('create_basket_text7')}
                                                                            </label>
                                                                            <SelectCreateBasket name={'waitPatiently'} option={waitPatientlyDropdown} value={waitPatientlyValue} selectValue={this.handleWaitPatiently} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <div className="gi-yp-select">
                                                                    <label>
                                                                        {IntlMessages('create_basket_text8')}
                                                                    </label>
                                                                    <span>
                                                                        {startAmount
                                                                            ? <Fragment>
                                                                                <i className="gi-d-dollar" aria-hidden="true">
                                                                                    {IntlMessages('$')}
                                                                                </i>
                                                                                {startAmount.replace('$', '')}&nbsp;
                                                                            </Fragment>
                                                                            : ''
                                                                        }
                                                                        {endAmount
                                                                            ? <Fragment>
                                                                                -&nbsp;
                                                                                <i className="gi-d-dollar" aria-hidden="true">
                                                                                    {IntlMessages('$')}
                                                                                </i>
                                                                                {endAmount.replace('$', '')}
                                                                            </Fragment>
                                                                            : ''
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <ReactApexChart options={options} series={series} type='area' height={200} />
                                                                <div className="gi-d-compare-text mb-20">
                                                                    <p className='gi-compare'>
                                                                        {IntlMessages('create_basket_static_text4')}
                                                                    </p>
                                                                    <p className='gi-chart-compare'>
                                                                        {IntlMessages('basket_chart_text1')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* company count card */}
                                            {companySummary ? <CompanyCountCard companySummary={companySummary} /> : ''}

                                            {/* company list */}
                                            {companySummary ? <CompanyDetailsCard companySummary={companySummary} expenseRatio={expenseRatio} /> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <LandingPageFooter directionShow={false} />
                </section>

                {openInformationModal
                    ? <InformationModal openInformationModal={openInformationModal} informationId={informationId} esgcValue={esgcValueDescription} closeInformationModal={this.closeInformationModal} />
                    : ''
                }
            </Fragment>
        )
    }
}

export default withAuth0(CreateBasketPage)
