import * as enMessages from '../locales/he-IL';

const Language = {
  messages: {
    ...enMessages.heIL,
    ...enMessages.TandC,
    ...enMessages.privacy,
  },
  locale: 'he-IL',
};
export default Language;
