// default library
import React, { Component, Fragment } from 'react'
// custom component
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'
import LearnEventSection from '../../components/SectionComponent/LearnEventSection'
import MiniMBASection from '../../components/SectionComponent/MiniMBASection'

export class GiraffeMiniMBA extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userName: `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`
        }
    }

    componentDidMount = async () => {
        window.scroll(0, 0)
    }
    render() {
        const { userName } = this.state
        return (
            <Fragment>
                {/* header */}
                <div className="row">
                    <div className="col-12">
                        <div className="gi-gift-heading">
                            <PageHeader
                                strong={`giraffe_learn_welcome`}
                                userName={userName}
                            />
                        </div>
                    </div>
                </div>
                {/* main content */}
                <div className="row">
                    <div className="col-12">
                        <div className="gi-g-right-sidebar-main">
                            <div className="gi-g-right-sidebar-left">
                                <div className="gi-g-details-card-left">
                                    {/* mini mba */}
                                    <MiniMBASection />
                                    {/* learn event */}
                                    <LearnEventSection />
                                </div>
                            </div>
                            <div className="gi-g-right-sidebar-right">
                                <GiraffeLearnBannerSection />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GiraffeMiniMBA