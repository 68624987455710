import React, { Component } from 'react'
import PageCount from '../../../components/PageCount/PageCount';

import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';

import InputComponentText from '../../../components/InputComponent/InputComponentText';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import RightInformation from '../../../components/InformationComponent/RightInformation';

import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import { MAX_NUMBER_36 } from '../../../config/Config';
export class PersonalDetailsPage4 extends Component {

    constructor(props) {
        super(props)
        this.setLocalStorageData();
        this.state = {
            addressAutoFill: '',
            apartmentUnit: '',
            postalCode: '',
            errorFlag: false,
            isLoading: false
        }
    }

    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null) {
            await this.setState({
                addressAutoFill: localStorageData?.addressAutoFill ?? '',
                apartmentUnit: localStorageData?.apartmentUnit ?? '',
                postalCode: localStorageData?.postalCode ?? '',
            })
        }
    }

    setAutoCompleteValue = async (autoCompleteValue, loading) => {
        await this.setState({
            addressAutoFill: autoCompleteValue.addressAutoFill,
            postalCode: autoCompleteValue.postalCode,
            errorFlag: autoCompleteValue.errorFlag,
            isLoading: loading
        });
    }

    setApartmentUnit = async (apartmentUnit) => {
        await this.setState({
            apartmentUnit: apartmentUnit
        });
    }

    setDetails = (value) => {
        this.details = {
            errorFlag: value.errorFlag,
            addressAutoFill: value.addressAutoFill,
            apartmentUnit: value.apartmentUnit,
            postalCode: value.postalCode
        }
    }

    render() {
        const { apartmentUnit, errorFlag, isLoading, postalCode } = this.state
        this.setDetails(this.state)
        return (
            <>
                {isLoading ? <LoadingComponent /> : ''}
                <div className="gi-from-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="py-3">
                                            <div className="gi-from-left">
                                                <PageCount count={4} pageName={'Personal details'} />
                                                <div className="gi-input mb-40">
                                                    <LabelComponent name={'addressAutoFill'} labelName={'personal_page4_label1'} />
                                                    <AutoComplete inputValue={this.setAutoCompleteValue} />
                                                    <ErrorComponent message={'error_invalid_address'} display={errorFlag ? 'block' : 'none'} />
                                                </div>
                                                <div className="row mx-md-n2">
                                                    <div className="col-md-8 px-md-2">
                                                        <div className="gi-input mb-40">
                                                            <LabelComponent name={'apartmentUnit'} labelName={'personal_page4_label2'} />
                                                            <InputComponentText name={'apartmentUnit'} placeholder={'placeholder_apartment_unit'} inputValue={this.setApartmentUnit} />
                                                            <ErrorComponent message={['error_max_length', { number: 36 }]} display={apartmentUnit.length > MAX_NUMBER_36 ? 'block' : 'none'} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 px-md-2">
                                                        <div className={`gi-input mb-40 ${postalCode ? 'valid-field' : ''}`}>
                                                            <LabelComponent name={'postalCode'} labelName={'personal_page4_label3'} />
                                                            <InputComponentText value={postalCode} name={'postalCode'} placeholder={'placeholder_zip_code'} readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gi-btn-group d-none d-sm-flex">
                                                    <ButtonComponent value={this.details} previousRouting={'page3'} nextRouting={'page5'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="gi-from-right">
                                        <RightInformation info1={'personal_detail_info1'} info2={'personal_detail_info2'} />

                                            <div className="gi-btn-group d-flex d-sm-none">
                                                <ButtonComponent value={this.details} previousRouting={'page3'} nextRouting={'page5'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PersonalDetailsPage4