//default library
import React, { Component, createRef } from 'react'

//custom library
import { getAllCountry } from '../../middleware/DropdownList';

import { injectIntl } from 'react-intl';

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

class AutoComplete extends Component {

    autoCompleteRef = createRef();
    autoComplete;
    constructor(props) {
        super(props)
        this.setLocalStorageData()
        this.state = {
            addressAutoFill: '',
            postalCode: '',
        }
    }

    //Store localStorage Data
    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null) {
            await this.setState({
                addressAutoFill: localStorageData['addressAutoFill'] ?? '',
                postalCode: localStorageData['postalCode'] ?? '',
            })
        }

        if (this.props.value) {
            await this.setState({
                addressAutoFill: this.props.value
            })
        }
    }

    //Load script when page is load
    loadScript = async (url, callback) => {
        const script = await document.createElement("script");
        // script.type = await "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => {
                callback();
            }
        }

        script.src = url;
        await document.getElementsByTagName("head")[0].appendChild(script);
    }

    //Set googleAPI for auto complete
    componentDidMount = async () => {
        await this.loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}&libraries=places`,
            () => this.handleScriptLoad(this.state.addressAutoFill, this.autoCompleteRef)
        );
    }

    //handle script
    handleScriptLoad = async (updateQuery, autoCompleteRef) => {
        this.autoComplete = await new window.google.maps.places.AutocompleteService();

        this.autoComplete = await new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
            componentRestrictions: { country: ["us"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
        });

        await this.autoComplete.addListener("place_changed", () => {
            this.handlePlaceSelect(updateQuery)
        });
    }

    //Set value for zipCode and addressField
    handlePlaceSelect = async (updateQuery) => {
        await this.props.inputValue(this.state, true)
        const place = this.autoComplete.getPlace();
        let address1 = "";
        let postcode = "";
        let city = "";
        let cityAlt = "";
        let state = "";
        let country = "";
        let cityField = '';
        let countryIdentifier = '';
        let errorFlag = false;
        // let data = updateQuery(place.formatted_address)

        if (place?.address_components) {
            for (const component of place?.address_components) {
                const componentType = component.types[0];
                // eslint-disable-next-line default-case
                switch (componentType) {
                    case "street_number": {
                        address1 = `${component.long_name} ${address1}`;
                        break;
                    }
                    case "route": {
                        address1 += component.short_name;
                        break;
                    }
                    case "locality": {
                        city = component.long_name;
                        break;
                    }
                    case "administrative_area_level_1": {
                        state = component.short_name;
                        cityAlt = component.long_name;
                        break;
                    }
                    case "postal_code": {
                        postcode = `${component.long_name}${postcode}`;
                        break;
                    }
                    case "postal_code_suffix": {
                        postcode = `${postcode}-${component.long_name}`;
                        break;
                    }
                    case "country": {
                        country = component.long_name;
                    }
                }
            }
        }

        cityField = city !== '' ? city : cityAlt;

        //get data from localStorage
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));

        // set countryIdentifier
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        let dataCountry = await getAllCountry(accessToken)
        for (let i = 0; i < dataCountry.length; i++) {
            if (dataCountry[i].label === country) {
                countryIdentifier = dataCountry[i].value
            }
        }

        //set data from localStorage
        let onBoardingDetails = {
            ...localStorageData,
            street1: address1,
            city: city,
            province: state,
            postalCode: postcode,
            country: countryIdentifier,
            countryDisclosure: country
        }
        await localStorage.setItem('onBoardingDetails', JSON.stringify(onBoardingDetails))

        if (address1 === '' || city === '' || state === '' || postcode === '' || country === '') {
            errorFlag = true
        }

        //set data to component
        await this.setState({
            addressAutoFill: `${address1}, ${cityField}, ${state}`,
            postalCode: postcode,
            errorFlag: errorFlag
        })

        this.props.inputValue(this.state)
        await this.props.inputValue(this.state, false)
        //send props to parent component
    }

    //handle onChange event 
    onChangeHandle = async (e) => {
        await this.setState({
            addressAutoFill: e.target.value,
            errorFlag: false,
            postalCode: '',
        })
        // this.props.inputValue(this.state)
        await this.props.inputValue(this.state, false)

    }

    render() {
        const { readOnly, intl, textCenter } = this.props
        const { addressAutoFill } = this.state
        return (
            <>
                <input
                    className={`form-control ${textCenter ? 'text-center' : ''}`}
                    ref={this.autoCompleteRef}
                    onChange={this.onChangeHandle} //invoke on every key press
                    placeholder={intl.formatMessage({ id: 'placeholder_auto_select' })}
                    value={addressAutoFill}
                    readOnly={readOnly ?? false} //check field is readOnly or not
                />
            </>
        )
    }
}

export default withAuth0(injectIntl(AutoComplete))