import React, { Component } from 'react'
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'

//prefect scroll bar react
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export class BasketLogoComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            basketValue: [],
        }
    }

    componentDidMount = async () => {
        const { basketValue } = this.props
        await this.setState({
            basketValue: basketValue
        })
    }

    render() {
        const { basketValue } = this.state
        return (
            <div className="gi-cb-esgc-value-card">
                <div className="gi-cb-esgc-value-header">
                    {IntlMessages('basket_your_value')}
                </div>
                <ScrollBar component="div" className="gi-cb-esgc-value-body">
                    {/* <div className="gi-cb-esgc-value-body"> */}
                    <div className="gi-cb-esgc-value-ul">
                        {basketValue.length !== 0
                            ? basketValue.map((e, i) => {
                                return (
                                    <div className={`gi-cb-esgc-value-li ${e.boosted ? 'active' : ''}`} key={i}>
                                        <div className="gi-cb-esgc-value-li-img">
                                            <img src={e?.thumbnailLarge} className="img-fluid" alt="" />
                                        </div>
                                        <div className="gi-cb-esgc-value-li-text">
                                            <span>
                                                <img src={IMAGES.BOOST_UP} alt="" />
                                                {IntlMessages('basket_boosted2')}
                                            </span>
                                            <h6>
                                                {e.name}
                                            </h6>
                                        </div>
                                    </div>
                                )
                            })
                            : ''
                        }
                    </div>
                    {/* </div> */}
                </ScrollBar>
            </div>
        )
    }
}

export default BasketLogoComponent