//default library
import React from 'react'
import IntlMessages from '../../helper/IntlMessages'

//custom library
import LoginComponent from '../LoginComponent/LoginComponent'

//logo component
import DashboardLogoComponent from '../LogoComponent/DashboardLogoComponent'

function LandingPageFooter(props) {

    return (
        <footer className="gi-lp-footer d-none d-lg-block">
            <nav className="navbar navbar-expand-lg navbar-dark" aria-label="headerNav">
                <div className="container">
                    <div className="header-bottom-left">
                        <a className="navbar-brand" href="index.html">
                            <DashboardLogoComponent mobileView={true} />
                        </a>
                    </div>
                    <div className="collapse navbar-collapse order-lg-1 order-2" id="web-navbar">
                        <ul className="navbar-nav mt-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="#!">
                                    {IntlMessages('footer_giraffe')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#!">
                                    {IntlMessages('footer_products')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#!">
                                    {IntlMessages('footer_education')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#!">
                                    {IntlMessages('footer_gifts')}
                                </a>
                            </li>
                            <LoginComponent directionShow={false} />
                        </ul>
                    </div>
                </div>
            </nav>
        </footer>
    )
}

export default LandingPageFooter