//default library
import React, { Component } from 'react'
import { injectIntl } from 'react-intl';
import Select from 'react-select';

export class SelectCreateBasket extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fieldValue: '',
            defaultValueFlag: false,
            changeValue: []
        }
    }

    handleOnChange = async (e) => {
        await this.setState({
            fieldValue: e.value,
            defaultValueFlag: true,
            changeValue: {
                value: e.value,
                label: e.label
            }
        })
        this.props.selectValue(this.state.fieldValue)
    }

    render() {
        const { option, placeholder, name, value, intl } = this.props
        const { defaultValueFlag, changeValue } = this.state
        const defaultValue = option.map(e => {
            if (e.value === value) {
                return e
            }
            else {
                return null
            }
        });

        return (
            <div className='custom-react-portfolio'>
                <Select
                    name={name}//set field name
                    classNamePrefix='react-select'
                    options={option} //dropdown value
                    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                    onChange={this.handleOnChange}
                    value={defaultValueFlag ? changeValue : defaultValue}
                />
            </div>
        )
    }
}

export default injectIntl(SelectCreateBasket)