import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom';

import _ from 'underscore';

//import 404 image
import IMAGES from '../../config/Images';
import IntlMessages from '../../helper/IntlMessages';

import { withAuth0 } from '@auth0/auth0-react';

export class PageNotFound extends Component {

    constructor(props) {
        super(props)
        require('../../assets/styles/newCSS/main.scss') //import css
        this._handleWindowResize = _.debounce(this._handleWindowResize.bind(this), 100);
        this.state = {
            redirectHome: false
        }
    }

    componentDidMount = () => {
        // css
        document.body.style.fontFamily = `'Roboto Slab', serif`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1390px'
        }

        this._isMounted = true;
        window.addEventListener('resize', this._handleWindowResize);
    }

    componentWillUnmount = () => {
        this._isMounted = false;
        window.removeEventListener('resize', this._handleWindowResize);
    }

    _handleWindowResize = () => {
        if (this._isMounted) {
            let errorImgHeight = document.getElementsByClassName("bg-error")[0];
            let errorSection = document.getElementsByClassName("error-section")[0];
            if (window.innerWidth >= 992) {
                errorSection.style.paddingBottom = errorImgHeight.offsetHeight - 80 + 'px';
            } else {
                errorSection.style.paddingBottom = '1px';
            }
        }
    }

    login = async () => {
        const { loginWithRedirect } = this.props.auth0
        await loginWithRedirect();
        localStorage.setItem('triggerMethod', 'register')
    }

    redirectHome = async () => {
        await this.setState({
            redirectHome: true
        })
    }

    render() {
        const { redirectHome } = this.state
        return (
            <Fragment>
                {redirectHome ? <Redirect push to='/' /> : ''}
                <header className="gi-g-header gi-g-header-error">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-header-main">
                                    <div className="gi-g-logo">
                                        <a href="#!">
                                            <img src={IMAGES.NEW_GIRAFFE_LOGO2} alt="" />
                                        </a>
                                    </div>
                                    <div className="gi-g-mini-nav">
                                        <div className="gi-st-login-btn">
                                            <button type="button" onClick={this.login}>
                                                <span className="user-icon"></span>
                                                {IntlMessages('error_log_in')}
                                            </button>
                                        </div>
                                        <div className="gi-st-register-btn">
                                            <button type="button">
                                                <span className="pencil-icon"></span>
                                                Pre-Register
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="error-section" ref={node => {
                    // this callback executes before componentDidMount
                    if (node !== null) {
                        this._containerTarget = node;
                        if (!this._isMounted) {
                            this._isMounted = true;
                            this._handleWindowResize();
                        }
                    }
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="error-section-text">
                                    <h1>
                                        {IntlMessages('error_404')}
                                    </h1>
                                    <h5>
                                        {IntlMessages('error_notFound')}
                                    </h5>
                                    <h6>
                                        {IntlMessages('error_validUrl')}
                                        <a href="#!" onClick={this.redirectHome}>
                                            {IntlMessages('error_returnHome')}
                                        </a>
                                    </h6>
                                    <p>
                                        {IntlMessages('error_fixURL')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-error">
                        <img src={IMAGES.BACKGROUND_404} className="w-100 bg-error-desktop" alt="" />
                        <img src={IMAGES.BACKGROUND_404_MOB} className="w-100 bg-error-mob" alt="" />
                    </div>
                </section>

            </Fragment>
        )
    }
}

export default withAuth0(PageNotFound)