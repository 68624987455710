/* eslint-disable jsx-a11y/anchor-is-valid */
//default library
import React, { Component } from 'react'

export class InputComponentCheckbox extends Component {

    //default constructor
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this) //bind this keyword to handleOnChange function
        this.state = {
            checkBoxValue: ''
        }
    }

    //handle onChange event
    handleOnChange = async (e) => {
        await this.setState({
            checkBoxValue: e.target.checked
        })
        this.props.inputValue(this.state.checkBoxValue)
    }

    render() {
        const { text, name } = this.props
        return (
            <>
                <input
                    className="form-check-input"
                    id={name}
                    type="checkbox"
                    name={name}
                    onChange={this.handleOnChange} //invoke on every key press
                />{text ?? ''}
            </>
        )
    }
}

export default InputComponentCheckbox