//default library
import React, { Component } from 'react'

//auth0 library
import { withAuth0 } from '@auth0/auth0-react'
import IntlMessages from '../../helper/IntlMessages';

export class StartJourney extends Component {

    login = async () => {
        const { loginWithRedirect } = this.props.auth0
        await loginWithRedirect();
        localStorage.setItem('triggerMethod', 'register')
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-9 mx-auto">
                    <div className="gi-g-lp-journey">
                        <p>
                            {IntlMessages('gift_include_start')}
                        </p>
                        <button type="button" onClick={this.login}>
                            {IntlMessages('gift_include_start_button')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth0(StartJourney)