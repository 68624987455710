//default library
import React, { Component, Fragment } from 'react'
import ReactApexChart from 'react-apexcharts';
import IntlMessages from '../../helper/IntlMessages';
// jquery library
import $ from 'jquery';
export class CompanyCountCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            seriesPieChart: [50, 65, 40, 38, 55], //static data for pie chart
            optionsPieChart: {
                chart: {
                    width: '100%',
                    type: "donut"
                },
                plotOptions: {
                    pie: {
                        // customScale: -1,
                        expandOnClick: false,
                        donut: {
                            size: '72%'
                        }
                    }
                },
                stroke: {
                    show: false,
                },
                colors: ['#B5FB7D', '#95E588', '#27B280', '#007777', '#48C35C',],
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                series: [50, 65, 40, 38, 55],
                grid: {
                    // yaxis: {
                    //     lines: {
                    //         offsetX: 10
                    //     }
                    // },
                    // padding: {
                    //     left: -5 //left: -10
                    // },

                }
            },//static data for pie chart

            companySummary: props.companySummary ?? '', //basket summary data
        }
    }

    componentDidMount = async () => {
        $('.gi-cb-esgc-index-text a').click(function (e) {
            //get selected href
            var href = $(this).attr('href');
            //set all nav tabs to inactive
            $('.nav-pills li').removeClass('active');
            $('.nav-pills li a').removeClass('active');

            //get all nav tabs matching the href and set to active
            $('.nav-pills li a[href="' + href + '"]').closest('li').addClass('active');
            $('.nav-pills li a[href="' + href + '"]').addClass('active');

            //active tab
            $('#pills-tabContent .tab-pane').removeClass('active show');
            $('#pills-tabContent .tab-pane' + href).addClass('active show');
        })

        $('.gi-cb-esgc-graph-text a').click(function (e) {
            //get selected href
            var href = $(this).attr('href');
            //set all nav tabs to inactive
            $('.nav-pills li').removeClass('active');
            $('.nav-pills li a').removeClass('active');

            //get all nav tabs matching the href and set to active
            $('.nav-pills li a[href="' + href + '"]').closest('li').addClass('active');
            $('.nav-pills li a[href="' + href + '"]').addClass('active');

            //active tab
            $('#pills-tabContent .tab-pane').removeClass('active show');
            $('#pills-tabContent .tab-pane' + href).addClass('active show');
        })
    }

    render() {
        const { seriesPieChart, optionsPieChart, companySummary } = this.state

        return (
            <Fragment>
                <div className="gi-cb-esgc-graph-card">
                    <div>
                        <div className="piechartbox">
                            <ReactApexChart options={optionsPieChart} series={seriesPieChart} type='donut' />
                        </div>
                        <div className="gi-cb-esgc-graph-text">
                            <span>
                                {companySummary.totalIndustries}
                                &nbsp;
                                {IntlMessages('basket_industry')}
                            </span>
                            <a href="#pills-home">
                                {IntlMessages('basket_view_list')}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="gi-cb-esgc-index-card">
                    <div className="gi-cb-esgc-index-header">
                        Index Breakdown
                    </div>
                    <div className="gi-cb-esgc-index-text">
                        <h5>
                            {companySummary.totalCompanies}
                        </h5>
                        <span>
                            {IntlMessages('total')}
                            &nbsp;
                            {IntlMessages('basket_companies')}
                        </span>
                        <a href="#pills-home">
                            {IntlMessages('basket_view_list')}
                        </a>
                    </div>
                    <div className="gi-cb-esgc-index-text">
                        <h5>
                            {companySummary.excludedCompanies}
                        </h5>
                        <span>
                            {IntlMessages('basket_excluded')}
                            &nbsp;
                            {IntlMessages('basket_companies')}
                        </span>
                        <a href="#pills-profile">
                            {IntlMessages('basket_view_list')}
                        </a>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default CompanyCountCard