//default library
import React, { Component, Fragment } from 'react'
import Modal from "react-bootstrap/Modal";

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import InputComponentText from '../InputComponent/InputComponentText';
// import MaskInput from '../InputComponent/MaskInput';
import LabelComponent from '../LabelComponent/LabelComponent';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SelectModal from '../SelectComponent/SelectModal'
import AutoComplete from '../AutoComplete/AutoComplete';
import InputComponentCurrency from '../InputComponent/InputComponentCurrency';
import InputComponentRadio from '../InputComponent/InputComponentRadio';
import InformationComponent from '../InformationComponent/InformationComponent';
import TextAreaComponent from '../InputComponent/TextAreaComponent';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import IntlMessages from '../../helper/IntlMessages';

//error message and regex
import * as Config from '../../config/Config';

//custom function
import { ConsoleLog } from '../../service/utils/ConsoleLog';
import { removeUndefined } from '../../service/utils/RemoveUndefineValue';
// api call
import { updateSettingData } from '../../middleware/UpdateCall';

import { actionValue } from '../../config/DefaultValue'

export class AccountEditModal extends Component {

    disabled = true; // submit button disabled
    validationFlagArray = {} //validation flag array each field

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            myAccountModalOpen: false, //my account 
            personalInformationModalOPen: false, //personal information
            employmentStatusModalOpen: false, //employment status
            brokerDealerModalOpen: false, //broker dealer
            investingInformationModalOpen: false, //investing information

            countryName: props.countryName, //country dropdown value
            statusList: props.statusList, //employment status dropdown value
            industryList: props.industryList, //industry dropdown value
            positionList: props.positionList, //position dropdown value
            investingList: props.investingList, //investing experience dropdown value
            riskToleranceList: props.riskToleranceList, //risk tolerance dropdown value
            userData: props.userData, //user data

            email: props.email, //user email id
            ssnNumber: props.ssnNumber, //formatted ssn number
            dateOfBirth: props.dateOfBirth, //formatted dob
            employmentData: props.employmentData, //employment data of current user
            userInvestor: props.userInvestor, //user investor data of current user
            phoneNumber: props.phoneNumber, //formatted phone number

            //? declaring fields of account setting 
            brokerDealer: false, // brokerDealer flag
            stockOwner: false, // stockOwner flag
            publicOfficial: false, // publicOfficial flag
            employmentStatusLabel: props.employmentStatusLabel, //use for employment status label


            //edit data field 
            errorFlag: false,
            personalDetails: {
                middleName: props.userData.middleNameEncrypted, //user middle name
                personalDetailsFlag: false
            },
            userAddressEdit: {
                addressFlag: false,
                addressAutoFill: `${props.userAddress.street1}, ${props.userAddress.city}, ${props.userAddress.province}`,
                postalCode: props.userAddress.postalCode,
                apartmentUnit: props.userAddress.street2,
            },
            userEmploymentStatus: {
                employmentStatusFlag: false,
                employmentStatus: props.employmentData.statusIdentifier,
                employmentCompany: props.employmentData.company ?? '',
                employmentIndustry: props.employmentData.typeIdentifier,
                employmentPosition: props.employmentData.positionIdentifier,
            },
            userBrokerDealer: {
                userBrokerDealerFlag: false,
                affiliatedWithBroker: props.employmentData.affiliatedWithBroker,
                directorOf: Boolean(props.employmentData.directorOf),
                politicallyExposedNames: Boolean(props.userData.politicallyExposedNames),
                companyName: props.employmentData.directorOf ? props.employmentData.directorOf.split(' ')[0] : '',
                tickerSymbol: props.employmentData.directorOf ? props.employmentData.directorOf.split(' ')[1] : '',
                officialField: props.userData.politicallyExposedNames ?? ''
            },
            userInvestingInformation: {
                investingInformationFlag: false,
                investingExperience: props.userInvestor.experienceIdentifier,
                annualIncome: props.userInvestor.annualIncome,
                netWorth: props.userInvestor.networthTotal,
                riskTolerance: props.userInvestor.riskToleranceIdentifier,
            }
        }
    }

    UNSAFE_componentWillReceiveProps = async (props) => {
        const flagSet = props.openModalEdit
        await this.setState({
            myAccountModalOpen: flagSet === 'myAccountModalOpen' ? true : false, //my account modal open
            personalInformationModalOPen: flagSet === 'personalInformationModalOPen' ? true : false, //personal information modal open
            employmentStatusModalOpen: flagSet === 'employmentStatusModalOpen' ? true : false, //employment status modal open
            brokerDealerModalOpen: flagSet === 'brokerDealerModalOpen' ? true : false, //broker dealer modal open
            investingInformationModalOpen: flagSet === 'investingInformationModalOpen' ? true : false, //investing information modal open
        })
    }

    //component update based on props received
    componentDidUpdate = async () => {
        const { personalInformationModalOPen, employmentStatusModalOpen, brokerDealerModalOpen, investingInformationModalOpen } = this.state
        if (personalInformationModalOPen) {
            this.validationFlagArray['disabledAutoComplete'] = false
            this.validationFlagArray['disabledApartmentUnit'] = false
            this.validationFlagArray['disabledMiddleName'] = false
        }
        if (employmentStatusModalOpen) {
            this.validationFlagArray['disabledCompanyName'] = false
            this.validationFlagArray['disabledIndustry'] = false
            this.validationFlagArray['disabledPosition'] = false
        }
        if (brokerDealerModalOpen) {
            this.validationFlagArray['disabledCompanyName'] = false
            this.validationFlagArray['disabledTickerSymbol'] = false
            this.validationFlagArray['disabledOfficialField'] = false
        }
        if (investingInformationModalOpen) {
            this.validationFlagArray['disabledAnnualIncome'] = false
            this.validationFlagArray['disabledNetWorthTotal'] = false
        }

        const modal = document.querySelector(".personal-information");
        if (modal) {
            modal.onscroll = function (e) {
                const pacContainer = document.querySelector(".pac-container.pac-logo");
                let top = window.scrollY + document.querySelector(".pac-input").getBoundingClientRect().top;
                pacContainer.style.top = `${top + 77}px`;
            }
        }
    }

    //update account details
    onUpdateDetails = async () => {
        const { getAccessTokenSilently } = this.props.auth0

        const { userAddressEdit, userEmploymentStatus, userBrokerDealer, userInvestingInformation, personalDetails } = this.state
        userEmploymentStatus.employmentCompany = userEmploymentStatus.employmentCompany === '' ? null : userEmploymentStatus.employmentCompany
        let data = { ...userAddressEdit, ...userEmploymentStatus, ...userBrokerDealer, ...userInvestingInformation, personalDetails }
        await this.setState({
            isLoading: true,
            myAccountModalOpen: false,
            personalInformationModalOPen: false,
            employmentStatusModalOpen: false,
            brokerDealerModalOpen: false,
            investingInformationModalOpen: false,
        })
        const accessToken = await getAccessTokenSilently() //get access token

        updateSettingData(accessToken, data)
            .then(async (res) => {
                await this.setState({
                    isLoading: false
                })
                await this.props.componentDidMount()
                await this.props.closeEditModal()
            })
            .catch((err) => ConsoleLog('', err))
    }

    //close all open modal
    onHandleClose = async () => {
        await this.setState({
            myAccountModalOpen: false,
            personalInformationModalOPen: false,
            employmentStatusModalOpen: false,
            brokerDealerModalOpen: false,
            investingInformationModalOpen: false,
        })
        this.props.closeEditModal()
        this.validationFlagArray = {}
    }

    //check field validation
    checkValidation = async () => {
        this.disabled = false;
        const flagArray = Object.values(this.validationFlagArray) // set value in flagArray
        // if any value in flagArray is true then submit button is disabled else enabled
        for (let i = 0; i < flagArray.length; i++) {
            if (flagArray[i]) {
                this.disabled = true
                break;
            }
            else {
                this.disabled = false
            }
        }
    }

    // set middleName
    setMiddleName = async (middleName) => {
        if (middleName && (middleName.length > Config.MAX_NUMBER_36 || !middleName.match(Config.NAME_REGEX))) {
            this.validationFlagArray['disabledMiddleName'] = true
        }
        await this.setState({
            personalDetails: {
                middleName: middleName,
                personalDetailsFlag: true
            }
        });
    }

    //google auto complete address
    autoCompleteAddress = async (value) => {
        const { userAddressEdit } = this.state
        if (value.errorFlag || value.addressAutoFill.length === 0) {
            this.validationFlagArray['disabledAutoComplete'] = true
        }
        await this.setState({
            errorFlag: value.errorFlag,
            userAddressEdit: {
                addressFlag: true,
                addressAutoFill: value.addressAutoFill,
                postalCode: value.postalCode,
                apartmentUnit: userAddressEdit.apartmentUnit,
            }
        })
    }
    apartmentUnit = async (value) => {
        const { userAddressEdit } = this.state
        if (value.length > Config.MAX_NUMBER_36 || value.length === 0) {
            this.validationFlagArray['disabledApartmentUnit'] = true
        }
        await this.setState({
            userAddressEdit: {
                addressFlag: true,
                addressAutoFill: userAddressEdit.addressAutoFill,
                postalCode: userAddressEdit.postalCode,
                apartmentUnit: value,
            },
        })
    }

    //employment Details
    employmentStatus = async (value) => {
        const { statusList, userEmploymentStatus } = this.state
        let employmentStatusLabel = await removeUndefined(statusList.map(e => {
            if (e.value === value) {
                return e.label
            }
            else {
                return null
            }
        }))

        if (!(employmentStatusLabel[0] === 'Currently working' || employmentStatusLabel[0] === 'Self-employed')) {
            await this.setState({
                employmentStatusLabel: employmentStatusLabel[0],
                userEmploymentStatus: {
                    employmentStatusFlag: true,
                    employmentStatus: value,
                    employmentCompany: '',
                    employmentIndustry: null,
                    employmentPosition: null,
                }
            })
            this.validationFlagArray['disabledCompanyName'] = false
            this.validationFlagArray['disabledIndustry'] = false
            this.validationFlagArray['disabledPosition'] = false
        }
        else {
            this.validationFlagArray['disabledCompanyName'] = true
            this.validationFlagArray['disabledIndustry'] = true
            this.validationFlagArray['disabledPosition'] = true
            // if value present
            if (userEmploymentStatus.employmentCompany) {
                this.validationFlagArray['disabledCompanyName'] = false
            } if (userEmploymentStatus.employmentIndustry) {
                this.validationFlagArray['disabledIndustry'] = false
            } if (userEmploymentStatus.employmentPosition) {
                this.validationFlagArray['disabledPosition'] = false
            }
            await this.setState({
                employmentStatusLabel: employmentStatusLabel[0],
                userEmploymentStatus: {
                    employmentStatusFlag: true,
                    employmentStatus: value,
                    employmentCompany: userEmploymentStatus.employmentCompany,
                    employmentIndustry: userEmploymentStatus.employmentIndustry,
                    employmentPosition: userEmploymentStatus.employmentPosition,
                }
            })
        }

    }
    companyNameEmployment = async (value) => {
        const { userEmploymentStatus } = this.state
        if (value.length > Config.MAX_NUMBER_64 || value.length === 0) {
            this.validationFlagArray['disabledCompanyName'] = true
        }
        if (!userEmploymentStatus.employmentIndustry) {
            this.validationFlagArray['disabledIndustry'] = true
        } if (!userEmploymentStatus.employmentPosition) {
            this.validationFlagArray['disabledPosition'] = true
        }
        await this.setState({
            userEmploymentStatus: {
                employmentStatusFlag: true,
                employmentStatus: userEmploymentStatus.employmentStatus,
                employmentCompany: value,
                employmentIndustry: userEmploymentStatus.employmentIndustry,
                employmentPosition: userEmploymentStatus.employmentPosition,
            }
        })
    }
    typeIdentifier = async (value) => {
        const { userEmploymentStatus } = this.state
        if (userEmploymentStatus.employmentCompany.length === 0) {
            this.validationFlagArray['disabledCompanyName'] = true
        }
        if (!userEmploymentStatus.employmentPosition) {
            this.validationFlagArray['disabledPosition'] = true
        }
        await this.setState({
            userEmploymentStatus: {
                employmentStatusFlag: true,
                employmentStatus: userEmploymentStatus.employmentStatus,
                employmentCompany: userEmploymentStatus.employmentCompany,
                employmentIndustry: value,
                employmentPosition: userEmploymentStatus.employmentPosition,
            }
        })
    }
    positionIdentifier = async (value) => {
        const { userEmploymentStatus } = this.state
        if (userEmploymentStatus.employmentCompany.length === 0) {
            this.validationFlagArray['disabledCompanyName'] = true
        }
        if (!userEmploymentStatus.employmentIndustry) {
            this.validationFlagArray['disabledIndustry'] = true
        }
        await this.setState({
            userEmploymentStatus: {
                employmentStatusFlag: true,
                employmentStatus: userEmploymentStatus.employmentStatus,
                employmentCompany: userEmploymentStatus.employmentCompany,
                employmentIndustry: userEmploymentStatus.employmentIndustry,
                employmentPosition: value,
            }
        })
    }

    //broker dealer and political exposure
    affiliatedWithBroker = async (value) => {
        const { userBrokerDealer } = this.state
        await this.setState({
            userBrokerDealer: {
                userBrokerDealerFlag: true,
                affiliatedWithBroker: JSON.parse(value.fieldValue),
                directorOf: userBrokerDealer.directorOf,
                politicallyExposedNames: userBrokerDealer.politicallyExposedNames,
                companyName: userBrokerDealer.companyName,
                tickerSymbol: userBrokerDealer.tickerSymbol,
                officialField: userBrokerDealer.officialField,
            }
        })
    }
    directorOf = async (value) => {
        const { userBrokerDealer } = this.state
        // value YES
        if (JSON.parse(value.fieldValue)) {
            // disable update button
            if (userBrokerDealer.companyName.length > Config.MAX_NUMBER_255 || userBrokerDealer.companyName.length === 0) {
                this.validationFlagArray['disabledCompanyName'] = true
            }
            if (userBrokerDealer.tickerSymbol.length > Config.MAX_NUMBER_10 || userBrokerDealer.tickerSymbol.length === 0 || userBrokerDealer.tickerSymbol.match(Config.TICKER_REGEX)) {
                this.validationFlagArray['disabledTickerSymbol'] = true
            }
            await this.setState({
                userBrokerDealer: {
                    userBrokerDealerFlag: true,
                    affiliatedWithBroker: userBrokerDealer.affiliatedWithBroker,
                    directorOf: JSON.parse(value.fieldValue),
                    politicallyExposedNames: userBrokerDealer.politicallyExposedNames,
                    companyName: userBrokerDealer.companyName,
                    tickerSymbol: userBrokerDealer.tickerSymbol,
                    officialField: userBrokerDealer.officialField,
                }
            })
        }
        // value NO
        else {
            await this.setState({
                userBrokerDealer: {
                    userBrokerDealerFlag: true,
                    affiliatedWithBroker: userBrokerDealer.affiliatedWithBroker,
                    directorOf: JSON.parse(value.fieldValue),
                    politicallyExposedNames: userBrokerDealer.politicallyExposedNames,
                    companyName: '',
                    tickerSymbol: '',
                    officialField: userBrokerDealer.officialField,
                }
            })
        }
    }
    politicallyExposedNames = async (value) => {
        const { userBrokerDealer } = this.state
        // value YES
        if (JSON.parse(value.fieldValue)) {
            // disable update button
            if (userBrokerDealer.officialField.length > Config.MAX_NUMBER_1000 || userBrokerDealer.officialField.length === 0) {
                this.validationFlagArray['disabledOfficialField'] = true
            }
            await this.setState({
                userBrokerDealer: {
                    userBrokerDealerFlag: true,
                    affiliatedWithBroker: userBrokerDealer.affiliatedWithBroker,
                    directorOf: userBrokerDealer.directorOf,
                    politicallyExposedNames: JSON.parse(value.fieldValue),
                    companyName: userBrokerDealer.companyName,
                    tickerSymbol: userBrokerDealer.tickerSymbol,
                    officialField: userBrokerDealer.officialField,
                }
            })
        }
        // value NO
        else {
            await this.setState({
                userBrokerDealer: {
                    userBrokerDealerFlag: true,
                    affiliatedWithBroker: userBrokerDealer.affiliatedWithBroker,
                    directorOf: userBrokerDealer.directorOf,
                    politicallyExposedNames: JSON.parse(value.fieldValue),
                    companyName: userBrokerDealer.companyName,
                    tickerSymbol: userBrokerDealer.tickerSymbol,
                    officialField: '',
                }
            })
        }
    }
    companyName = async (value) => {
        const { userBrokerDealer } = this.state
        if (value.length > Config.MAX_NUMBER_255 || value.length === 0) {
            this.validationFlagArray['disabledCompanyName'] = true
        }
        if (userBrokerDealer.tickerSymbol.length > Config.MAX_NUMBER_10 || userBrokerDealer.tickerSymbol.length === 0 || userBrokerDealer.tickerSymbol.match(Config.TICKER_REGEX)) {
            this.validationFlagArray['disabledTickerSymbol'] = true
        }
        await this.setState({
            userBrokerDealer: {
                userBrokerDealerFlag: true,
                affiliatedWithBroker: userBrokerDealer.affiliatedWithBroker,
                directorOf: userBrokerDealer.directorOf,
                politicallyExposedNames: userBrokerDealer.politicallyExposedNames,
                companyName: value,
                tickerSymbol: userBrokerDealer.tickerSymbol,
                officialField: userBrokerDealer.officialField,
            }
        })
    }
    tickerSymbol = async (value) => {
        const { userBrokerDealer } = this.state
        if (userBrokerDealer.companyName.length > Config.MAX_NUMBER_255 || userBrokerDealer.companyName.length === 0) {
            this.validationFlagArray['disabledCompanyName'] = true
        }
        if (value.length > Config.MAX_NUMBER_10 || value.length === 0 || value.match(Config.TICKER_REGEX)) {
            this.validationFlagArray['disabledTickerSymbol'] = true
        }
        await this.setState({
            userBrokerDealer: {
                userBrokerDealerFlag: true,
                affiliatedWithBroker: userBrokerDealer.affiliatedWithBroker,
                directorOf: userBrokerDealer.directorOf,
                politicallyExposedNames: userBrokerDealer.politicallyExposedNames,
                companyName: userBrokerDealer.companyName,
                tickerSymbol: value,
                officialField: userBrokerDealer.officialField,
            }
        })
    }
    officialField = async (value) => {
        const { userBrokerDealer } = this.state
        if (value.length > Config.MAX_NUMBER_1000 || value.length === 0) {
            this.validationFlagArray['disabledOfficialField'] = true
        }
        await this.setState({
            userBrokerDealer: {
                userBrokerDealerFlag: true,
                affiliatedWithBroker: userBrokerDealer.affiliatedWithBroker,
                directorOf: userBrokerDealer.directorOf,
                politicallyExposedNames: userBrokerDealer.politicallyExposedNames,
                companyName: userBrokerDealer.companyName,
                tickerSymbol: userBrokerDealer.tickerSymbol,
                officialField: value,
            }
        })
    }

    //investing experience details
    investorExperience = async (value) => {
        const { userInvestingInformation } = this.state
        await this.setState({
            userInvestingInformation: {
                investingInformationFlag: true,
                investingExperience: value,
                annualIncome: userInvestingInformation.annualIncome,
                netWorth: userInvestingInformation.netWorth,
                riskTolerance: userInvestingInformation.riskTolerance,
            }
        })
    }
    annualIncome = async (value) => {
        const { userInvestingInformation } = this.state
        if (value > Config.MAX_NUMBER || value === '') {
            this.validationFlagArray['disabledAnnualIncome'] = true
        }
        await this.setState({
            userInvestingInformation: {
                investingInformationFlag: true,
                investingExperience: userInvestingInformation.investingExperience,
                annualIncome: Number(value),
                netWorth: userInvestingInformation.netWorth,
                riskTolerance: userInvestingInformation.riskTolerance,
            }
        })
    }
    netWorthTotal = async (value) => {
        const { userInvestingInformation } = this.state
        if (value > Config.MAX_NUMBER || value === '') {
            this.validationFlagArray['disabledNetWorthTotal'] = true
        }

        await this.setState({
            userInvestingInformation: {
                investingInformationFlag: true,
                investingExperience: userInvestingInformation.investingExperience,
                annualIncome: userInvestingInformation.annualIncome,
                netWorth: Number(value),
                riskTolerance: userInvestingInformation.riskTolerance,
            }
        })
    }
    riskToleranceIdentifier = async (value) => {
        const { userInvestingInformation } = this.state
        await this.setState({
            userInvestingInformation: {
                investingInformationFlag: true,
                investingExperience: userInvestingInformation.investingExperience,
                annualIncome: userInvestingInformation.annualIncome,
                netWorth: userInvestingInformation.netWorth,
                riskTolerance: value,
            }
        })
    }

    render() {
        const { isLoading, myAccountModalOpen, personalInformationModalOPen, employmentStatusModalOpen, brokerDealerModalOpen, investingInformationModalOpen,
            countryName, statusList, industryList, positionList, investingList, riskToleranceList,
            email, userData, ssnNumber, dateOfBirth, phoneNumber, employmentStatusLabel,
            userAddressEdit, errorFlag, userEmploymentStatus, userBrokerDealer, userInvestingInformation, personalDetails } = this.state

        this.checkValidation()
        return (
            <Fragment>
                {isLoading ? <LoadingComponent /> : ''}

                {/* myAccount section modal open */}
                <Modal
                    centered
                    show={myAccountModalOpen}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-d-fund-modal setting-modal">
                    <Modal.Body className="gi-fund-modal-body">
                        <div className="gi-fund-modal-header gi-d-header">
                            <h5 className="modal-title">
                                {IntlMessages('account_setting_my_account')}
                                <div className='modal-note'>
                                    {IntlMessages('customer_note1')}
                                </div>
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={this.onHandleClose}></button>
                        </div>
                        <div className="gi-fund-modal-body">
                            {/* email address */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'email'} labelName={'account_setting_email'} />
                                <div >
                                    {email}
                                </div>
                                {/* <InputComponentText placeholder={'placeholder_email'} name={'email'} readOnly={true} value={email} /> */}
                            </div>
                            {/* phone number */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'phoneNumber'} labelName={'account_setting_phone'} />
                                <div className="gi-country-input">
                                    <div >
                                        {`+1 ` + phoneNumber}
                                    </div>
                                    {/* <MaskInput name={'phoneNumber'} placeholder={'placeholder_phone'} value={phoneNumber} readOnly={true} /> */}
                                </div>
                            </div>
                            {/* buttons */}
                            <div className="gi-sell-btn-group mt-30">
                                <button className="gi-cancel-btn" type="button" onClick={this.onHandleClose}>
                                    {IntlMessages('button_cancel')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* personal Information section modal open */}
                <Modal
                    centered
                    show={personalInformationModalOPen}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-d-fund-modal setting-modal personal-information">
                    <Modal.Body className="gi-fund-modal-body">
                        <div className="gi-fund-modal-header gi-d-header">
                            <h5 className="modal-title">
                                {IntlMessages('personal')}&nbsp;
                                {IntlMessages('information')}
                                <div className='modal-note'>
                                    {IntlMessages('customer_note2')}
                                </div>
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={this.onHandleClose}></button>
                        </div>
                        <div className="gi-fund-modal-body">
                            {/* first name */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'firstName'} labelName={'account_setting_first'} />
                                <div >
                                    {userData.firstNameEncrypted}
                                </div>
                                {/* <InputComponentText placeholder={'placeholder_type_here'} name={'firstName'} value={userData.firstNameEncrypted} readOnly={true} /> */}
                            </div>
                            {/* middle name */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'middleName'} labelName={'account_setting_middle'} />
                                <div>
                                    <InputComponentText name={'middleName'} placeholder={'placeholder_type_here'} value={personalDetails.middleName} inputValue={this.setMiddleName} />
                                    <ErrorComponent message={'error_max_length_36_name'} dashboard={true} display={personalDetails.middleName && personalDetails.middleName.length > Config.MAX_NUMBER_36 ? 'block' : 'none'} />
                                    <ErrorComponent message={'error_middle_name'} dashboard={true} display={personalDetails.middleName && !personalDetails.middleName.match(Config.NAME_REGEX) ? 'block' : 'none'} />
                                </div>
                                {/* <InputComponentText placeholder={'placeholder_type_here'} name={'firstName'} value={userData.firstNameEncrypted} readOnly={true} /> */}
                            </div>
                            {/* last name */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'firstName'} labelName={'account_setting_last'} />
                                <div >
                                    {userData.lastNameEncrypted}
                                </div>
                                {/* <InputComponentText placeholder={'placeholder_type_here'} name={'lastName'} value={userData.lastNameEncrypted} readOnly={true} /> */}
                            </div>
                            {/* address */}
                            <div className={`row mx-md-n2`}>
                                <div className="col-12">
                                    <div className={`gi-g-new-input mb-40 pac-input`}>
                                        <LabelComponent name={'addressAutoFill'} labelName={'personal_page4_label1'} />
                                        <AutoComplete value={userAddressEdit.addressAutoFill} inputValue={this.autoCompleteAddress} />
                                        <ErrorComponent message={'error_invalid_address'} dashboard={true} display={errorFlag ? 'block' : 'none'} />
                                    </div>
                                </div>
                                <div className="col-md-8 px-md-2">
                                    <div className={`gi-g-new-input mb-40 `}>
                                        <LabelComponent name={'apartmentUnit'} labelName={'personal_page4_label2'} />
                                        <InputComponentText placeholder={'placeholder_apartment_unit'} name={'apartmentUnit'} value={userAddressEdit.apartmentUnit} inputValue={this.apartmentUnit} />
                                        <ErrorComponent message={['error_max_length', { number: 36 }]} dashboard={true} display={userAddressEdit.apartmentUnit.length > Config.MAX_NUMBER_36 ? 'block' : 'none'} />
                                    </div>
                                </div>
                                <div className="col-md-4 px-md-2">
                                    <div className={`gi-g-new-input mb-40 ${userAddressEdit.postalCode ? 'valid-field' : ''}`}>
                                        <LabelComponent name={'postalCode'} labelName={'personal_page4_label3'} />
                                        <InputComponentText placeholder={'placeholder_zip_code'} name={'postalCode'} value={userAddressEdit.postalCode} readOnly={true} />
                                    </div>
                                </div>
                            </div>
                            {/* citizenship */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'citizenship'} labelName={'account_setting_citizenship'} />
                                <div >
                                    {countryName}
                                </div>
                                {/* <SelectModal option={countryList} name={'bankName'} value={userData.citizenship} disabled={true} /> */}
                            </div>
                            {/* ssn number */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'ssnNumber'} labelName={'account_setting_ssn'} />
                                <div >
                                    {ssnNumber}
                                </div>
                                {/* <MaskInput name={'ssnNumber'} placeholder={'placeholder_ssn'} value={ssnNumber} readOnly={true} /> */}
                            </div>
                            {/* date of birth */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'dateOfBirth'} labelName={'account_setting_dob'} />
                                <div >
                                    {dateOfBirth}
                                </div>
                                {/* <MaskInput name={'dateOfBirth'} placeholder={'placeholder_dob'} value={dateOfBirth} readOnly={true} /> */}
                            </div>
                            {/* buttons */}
                            <div className="gi-sell-btn-group mt-30">
                                <button className="gi-cancel-btn" type="button" onClick={this.onHandleClose}>
                                    {IntlMessages('button_cancel')}
                                </button>
                                <button className="gi-next-btn" type="button" disabled={this.disabled} onClick={this.onUpdateDetails}>
                                    {IntlMessages('button_update')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* employment status section modal open */}
                <Modal
                    centered
                    show={employmentStatusModalOpen}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-d-fund-modal setting-modal">
                    <Modal.Body className="gi-fund-modal-body">
                        <div className="gi-fund-modal-header gi-d-header">
                            <h5 className="modal-title">
                                {IntlMessages('account_setting_status')}
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={this.onHandleClose}></button>
                        </div>
                        <div className="gi-fund-modal-body">
                            {/* employment status */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'employmentStatus'} labelName={'account_setting_status1'} />
                                <SelectModal option={statusList} name={'employmentStatus'} value={userEmploymentStatus.employmentStatus} selectValue={this.employmentStatus} />
                            </div>
                            {employmentStatusLabel === 'Currently working' || employmentStatusLabel === 'Self-employed'
                                ? <>
                                    {/* company name employment */}
                                    <div className={`gi-g-new-input mb-40 `}>
                                        <LabelComponent name={'company'} labelName={'account_setting_company_name'} />
                                        <InputComponentText placeholder={'placeholder_type_here'} name={'company'} value={userEmploymentStatus.employmentCompany} inputValue={this.companyNameEmployment} />
                                        <ErrorComponent message={'error_max_length_64'} dashboard={true} display={userEmploymentStatus.employmentCompany.length > Config.MAX_NUMBER_64 ? 'block' : 'none'} />

                                    </div>
                                    {/* industry list */}
                                    <div className={`gi-g-new-input mb-40 `}>
                                        <LabelComponent name={'typeIdentifier'} labelName={'account_setting_industry'} />
                                        <SelectModal option={industryList} name={'typeIdentifier'} value={userEmploymentStatus.employmentIndustry} placeholder={'placeholder_industry'} selectValue={this.typeIdentifier} />
                                    </div>
                                    {/* position list */}
                                    <div className={`gi-g-new-input mb-40 `}>
                                        <LabelComponent name={'positionIdentifier'} labelName={'account_setting_position'} />
                                        <SelectModal option={positionList} name={'positionIdentifier'} value={userEmploymentStatus.employmentPosition} placeholder={'placeholder_position'} selectValue={this.positionIdentifier} />
                                    </div>
                                </>
                                : ''
                            }

                            {/* buttons */}
                            <div className="gi-sell-btn-group mt-30">
                                <button className="gi-cancel-btn" type="button" onClick={this.onHandleClose}>
                                    {IntlMessages('button_cancel')}
                                </button>
                                <button className="gi-next-btn" type="button" disabled={this.disabled} onClick={this.onUpdateDetails}>
                                    {IntlMessages('button_update')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* broker dealer section modal open */}
                <Modal
                    centered
                    show={brokerDealerModalOpen}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-d-fund-modal setting-modal">
                    <Modal.Body className="gi-fund-modal-body">
                        <div className="gi-fund-modal-header gi-d-header">
                            <h5 className="modal-title">
                                {IntlMessages('account_setting_broker')}
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={this.onHandleClose}></button>
                        </div>
                        <div className="gi-fund-modal-body">
                            {/* broker dealer  */}
                            <div>
                                <div className="gi-g-new-input gi-select">
                                    <LabelComponent name={'brokerDealer'} labelName={'employment_page3_label1'} />
                                </div>
                                <div className="mb-40">
                                    {actionValue.length !== 0 ? actionValue.map((value, i) => {
                                        const checked = userBrokerDealer.affiliatedWithBroker === JSON.parse(value.value)
                                        return (
                                            <div className="customize-radio form-check mt-20" key={i}>
                                                <label className={`form-check-label `} htmlFor={`${value.label.props.id}1`}>
                                                    {/* <label className={`form-check-label ${brokerDealer === value.value ? 'active' : ''}`} htmlFor={`${value.label}1`}> */}
                                                    <InputComponentRadio name={'brokerDealer'} value={value.value} title={`${value.label.props.id}1`} text={value.label} checked={checked} inputValue={this.affiliatedWithBroker} />
                                                </label>
                                            </div>
                                        )
                                    }) : <LoadingComponent />}
                                    {/* if true */}
                                    {userBrokerDealer.affiliatedWithBroker ?
                                        <div className="gi-radio-show-card mt-20">
                                            <InformationComponent strongFlag={false} message={'employment_static_t1'} message2={'employment_static_t2'} />
                                        </div> : ''
                                    }
                                    {/* if true over */}
                                </div>
                            </div>
                            {/* stock owner */}
                            <div>
                                <div className="gi-g-new-input gi-select">
                                    <LabelComponent name={'stockOwner'} labelName={'employment_page3_label2'} />
                                </div>
                                <div className="mb-40">
                                    {actionValue.length !== 0 ? actionValue.map((value, i) => {
                                        const checked = userBrokerDealer.directorOf === JSON.parse(value.value)
                                        return (
                                            <div className="customize-radio form-check mt-20" key={i}>
                                                <label className={`form-check-label `} htmlFor={`${value.label.props.id}2`}>
                                                    {/* <label className={`form-check-label ${brokerDealer === value.value ? 'active' : ''}`} htmlFor={`${value.label}1`}> */}
                                                    <InputComponentRadio name={'stockOwner'} value={value.value} title={`${value.label.props.id}2`} text={value.label} checked={checked} inputValue={this.directorOf} />
                                                </label>
                                            </div>
                                        )
                                    }) : <LoadingComponent />}
                                    {/* if true */}
                                    {userBrokerDealer.directorOf
                                        ? <div className="gi-radio-show-card mt-20" id="stock-owner" >
                                            <InformationComponent strongFlag={true} message={'employment_static_t4'} />
                                            <div className="gi-g-new-input mt-20">
                                                <LabelComponent name={'companyName'} labelName={`employment_page3_label2_1`} />
                                                <InputComponentText name={'companyName'} placeholder={'placeholder_type_here'} value={userBrokerDealer.companyName} inputValue={this.companyName} />
                                                <ErrorComponent message={'error_max_length_64'} dashboard={true} display={userBrokerDealer.companyName.length > Config.MAX_NUMBER_255 ? 'block' : 'none'} />
                                            </div>
                                            <div className="gi-g-new-input mt-20 mb-10">
                                                <LabelComponent name={'tickerSymbol'} labelName={`employment_page3_label2_2`} />
                                                <InputComponentText name={'tickerSymbol'} placeholder={'placeholder_ticker'} value={userBrokerDealer.tickerSymbol} inputValue={this.tickerSymbol} />
                                                <ErrorComponent message={'error_ticker_max_length'} dashboard={true} display={userBrokerDealer.tickerSymbol.length > Config.MAX_NUMBER_10 ? 'block' : 'none'} />
                                                <ErrorComponent message={'error_ticker_message'} dashboard={true} display={userBrokerDealer.tickerSymbol.match(Config.TICKER_REGEX) ? 'block' : 'none'} /></div>
                                        </div>
                                        : ''
                                    }
                                    {/* if true over */}
                                </div>
                            </div>
                            {/* public official */}
                            <div>
                                <div className="gi-g-new-input gi-select">
                                    <LabelComponent name={'publicOfficial'} labelName={'employment_page3_label3'} />
                                </div>
                                <div className="mb-40">
                                    {actionValue.length !== 0 ? actionValue.map((value, i) => {
                                        const checked = userBrokerDealer.politicallyExposedNames === JSON.parse(value.value)
                                        return (
                                            <div className="customize-radio form-check mt-20" key={i}>
                                                <label className={`form-check-label `} htmlFor={`${value.label.props.id}3`}>
                                                    {/* <label className={`form-check-label ${brokerDealer === value.value ? 'active' : ''}`} htmlFor={`${value.label}1`}> */}
                                                    <InputComponentRadio name={'publicOfficial'} value={value.value} title={`${value.label.props.id}3`} text={value.label} checked={checked} inputValue={this.politicallyExposedNames} />
                                                </label>
                                            </div>
                                        )
                                    }) : <LoadingComponent />}
                                    {/* if true */}
                                    {userBrokerDealer.politicallyExposedNames
                                        ? <div className="gi-radio-show-card mt-20" id="officialField" >
                                            <InformationComponent strongFlag={true} message={`employment_static_t3`} />
                                            <div className="gi-g-new-input mt-20 mb-10">
                                                <LabelComponent name={'officialField'} labelName={`employment_page3_label3_1`} />
                                                <TextAreaComponent name={'officialField'} placeholder={'placeholder_type_here'} row={3} value={userBrokerDealer.officialField} inputValue={this.officialField} />
                                                <ErrorComponent message={'error_max_length_1000'} dashboard={true} display={userBrokerDealer.officialField.length > Config.MAX_NUMBER_1000 ? 'block' : 'none'} />
                                            </div>
                                        </div>
                                        : ''
                                    }
                                    {/* if true over */}
                                </div>
                            </div>
                            {/* buttons */}
                            <div className="gi-sell-btn-group mt-30">
                                <button className="gi-cancel-btn" type="button" onClick={this.onHandleClose}>
                                    {IntlMessages('button_cancel')}
                                </button>
                                <button className="gi-next-btn" type="button" disabled={this.disabled} onClick={this.onUpdateDetails}>
                                    {IntlMessages('button_update')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* investing information section modal open */}
                <Modal
                    centered
                    show={investingInformationModalOpen}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-d-fund-modal setting-modal">
                    <Modal.Body className="gi-fund-modal-body">
                        <div className="gi-fund-modal-header gi-d-header">
                            <h5 className="modal-title">
                                {IntlMessages('account_setting_investing')}
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={this.onHandleClose}></button>
                        </div>
                        <div className="gi-fund-modal-body">
                            {/* investing experience */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'citizenship'} labelName={'account_setting_investing_exp'} />
                                <SelectModal option={investingList} name={'bankName'} placeholder={'placeholder_investing_exp'} value={userInvestingInformation.investingExperience} selectValue={this.investorExperience} />
                            </div>
                            {/* annual income */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'annualIncome'} labelName={'employment_page4_label'} />
                                <div className="gi-currency-input">
                                    <InputComponentCurrency name={'annualIncome'} placeholder={'placeholder_currency_1'} value={userInvestingInformation.annualIncome} inputValue={this.annualIncome} />
                                </div>
                                <ErrorComponent message={'error_invalid_annual_income'} dashboard={true} display={userInvestingInformation.annualIncome > Config.MAX_NUMBER ? 'block' : 'none'} />
                            </div>
                            {/* net worth */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'netWorth'} labelName={'account_setting_net_worth'} />
                                <div className="gi-currency-input">
                                    <InputComponentCurrency name={'netWorth'} placeholder={'placeholder_currency_1'} value={userInvestingInformation.netWorth} inputValue={this.netWorthTotal} />
                                </div>
                                <ErrorComponent message={'error_invalid_net_worth'} dashboard={true} display={userInvestingInformation.netWorth > Config.MAX_NUMBER ? 'block' : 'none'} />
                            </div>
                            {/* risk tolerance */}
                            <div className={`gi-g-new-input mb-40 `}>
                                <LabelComponent name={'citizenship'} labelName={'investment_detail_info5'} />
                                <SelectModal option={riskToleranceList} name={'bankName'} place={'Risk tolerance'} value={userInvestingInformation.riskTolerance} selectValue={this.riskToleranceIdentifier} />
                            </div>
                            {/* button */}
                            <div className="gi-sell-btn-group mt-30">
                                <button className="gi-cancel-btn" type="button" onClick={this.onHandleClose}>
                                    {IntlMessages('button_cancel')}
                                </button>
                                <button className="gi-next-btn" type="button" disabled={this.disabled} onClick={this.onUpdateDetails}>
                                    {IntlMessages('button_update')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }
}

export default withAuth0(AccountEditModal)