//default library
import React, { Component, Fragment } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

//custom component
import GiftCard from '../../components/CardComponent/GiftCard'
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import ExchangeGiftCard from '../../components/CardComponent/ExchangeGiftCard'
import GiftReadMoreModal from '../../components/ModalComponent/GiftReadMoreModal'
import GiftAcceptModal from '../../components/ModalComponent/GiftAcceptModal'
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'

//custom function
import { removeUndefined } from '../../service/utils/RemoveUndefineValue'
//image import
import IMAGES from '../../config/Images'

// api call
import * as GiftAPI from '../../middleware/GiftAPI'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import IntlMessages from '../../helper/IntlMessages'
export class GiftPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      userName: localStorage.getItem('firstName'),
      readMoreModalOpen: false,
      acceptModalOpen: false,
      esgcValueList: [],
      esgcValue: [],
      currentGift: [],
      isLoading: true,
      isChanged: false,
      // giftIdentifier: '',
      esgcValueIdentifier: '',
      giftIdentifier: window.location.pathname.split('/')[3],
      giftGiver: '',
      esgcValues: [],
      acceptModelESGC: {}
    }
  }

  componentDidMount = async () => {
    window.scroll(0, 0)
    const { giftIdentifier } = this.state
    const { getAccessTokenSilently } = this.props.auth0
    const accessToken = await getAccessTokenSilently() //get access token
    const getGiftReceive = await GiftAPI.getGiftReceived(accessToken, giftIdentifier)
    const esgcValues = [...new Set([...getGiftReceive?.esgcValueChangeList, ...getGiftReceive?.esgcValues])];
    await this.setState({
      esgcValueList: getGiftReceive?.esgcValueChangeList ?? [], //ESG-C change list 
      currentGift: getGiftReceive, //current gift
      giftGiver: getGiftReceive.name, //giver name
      esgcValues: esgcValues, //all ESG-C value
      isLoading: false
    })
  }

  // open read more modal 
  openReadMoreModal = async (esgcValueBoostIdentifier, isChanged) => {
    const { esgcValues } = this.state
    let esgcValue = await removeUndefined(esgcValues.map((e) => {
      if (e.esgcValueBoostIdentifier === esgcValueBoostIdentifier) {
        return e
      }
      else {
        return null
      }
    }))
    await this.setState({
      readMoreModalOpen: true,
      acceptModalOpen: false,
      esgcValue: esgcValue,
      esgcValueIdentifier: esgcValue[0].esgcValueBoostIdentifier,
      isChanged: isChanged
    })
  }

  // open accept modal 
  openAcceptModal = async (esgcValueBoostIdentifier, isChanged) => {
    const { esgcValues, currentGift } = this.state
    const esgcValueAccept = await removeUndefined(esgcValues.map((e) => {
      if (e.esgcValueBoostIdentifier === esgcValueBoostIdentifier) {
        return e
      }
      else {
        return null
      }
    }))


    const acceptModelESGC = {
      amount: currentGift.amount,
      image: esgcValueAccept[0].giftImage,
      name: esgcValueAccept[0].name
    }

    await this.setState({
      readMoreModalOpen: false,
      acceptModalOpen: true,
      esgcValueIdentifier: esgcValueBoostIdentifier,
      isChanged: isChanged,
      acceptModelESGC: acceptModelESGC
    })
  }

  // close read more modal 
  closeReadMoreModal = async () => {
    await this.setState({
      readMoreModalOpen: false,
      acceptModalOpen: false,
      esgcValue: []
    })
  }

  // open accept modal 
  closeAcceptModal = async () => {
    await this.setState({
      readMoreModalOpen: false,
      esgcValue: []
    })
  }

  // open redeem modal 
  closeRedeemModal = async () => {
    await this.setState({
      readMoreModalOpen: false,
      acceptModalOpen: false,
      esgcValue: []
    })
    this.props.componentDidMount()
  }

  render() {
    const { userName, readMoreModalOpen, acceptModalOpen, esgcValueList, esgcValue, currentGift, isLoading, esgcValueIdentifier, giftIdentifier, giftGiver, acceptModelESGC } = this.state
    const redeemedGiftDetails = {
      giftIdentifier: giftIdentifier,
      esgcValueIdentifier: esgcValueIdentifier,
    }

    return (
      <Fragment>
        {isLoading
          ? <LoadingComponent />
          : <Fragment>
            <div className="row">
              <div className="col-12">
                <div className="gi-gift-heading">
                  <PageHeader h5={['gift_head_text', { giftGiver: giftGiver }]} strong={`hello`} userName={userName} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="gi-g-right-sidebar-main">
                  <div className="gi-g-right-sidebar-left">
                    <GiftCard openReadMoreModal={this.openReadMoreModal} openAcceptModal={this.openAcceptModal} currentGift={currentGift} />

                    {/* change gift */}
                    <div className="row">
                      <div className="col-12">
                        <div className="gi-gift-value-main mt-10">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="gi-gift-value-header">
                                <div>
                                  <h5>
                                    {IntlMessages('gift_static_text1', { br: <br /> })}
                                  </h5>
                                  <span>
                                    {IntlMessages('gift_static_text3')}
                                  </span>
                                </div>
                                <div className="mt-10">
                                  <img src={IMAGES.VALUE_SEARCH_IMG} className="img-fluid" alt="" />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* exchange value list */}
                          {esgcValueList.length > 0
                            ? <ExchangeGiftCard openReadMoreModal={this.openReadMoreModal} esgcValueList={esgcValueList} />
                            : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gi-g-right-sidebar-right">
                    <GiraffeLearnBannerSection />
                    {/* <div className="gi-d-gift-banner">
                      <div className="gi-d-gift-banner-img">
                        <div className="gi-d-gift-banner-text">
                          <h3>
                            {IntlMessages('gift_static_text4')}
                          </h3>
                          <p>
                            {IntlMessages('gift_static_text5')}
                          </p>
                          <a href="#!">
                            <img src={IMAGES.WHITE_PLUS} alt="" />
                            {IntlMessages('gift_static_text6')}
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* gift card */}


            {/* Read more modal */}
            {readMoreModalOpen ? <GiftReadMoreModal readMoreModalOpen={readMoreModalOpen} closeReadMoreModal={this.closeReadMoreModal} openAcceptModal={this.openAcceptModal} esgcValue={esgcValue} /> : ''}

            {/* accept gift modal */}
            {acceptModalOpen ? <GiftAcceptModal acceptModalOpen={acceptModalOpen} redeemedGiftDetails={redeemedGiftDetails} acceptModelESGC={acceptModelESGC} closeAcceptModal={this.closeRedeemModal} /> : ''}
          </Fragment>
        }
      </Fragment>
    )
  }
}

export default withAuth0(GiftPage)