//default library
import React from 'react'
//Auth0 library
import { useAuth0 } from "@auth0/auth0-react";

//custom component
import LogoComponent from '../LogoComponent/LogoComponent'
import LogoutComponent from '../LogoutComponent/LogoutComponent'
import StepProcessBarComponent from '../ProcessBarComponent/StepProcessBarComponent';

const Header = (props) => {
    //auth0 hook
    const { isAuthenticated } = useAuth0();

    return (
        <header className="gi-header-section">
            <section className={`gi-header ${props.kycProcessing ? 'gi-c-a-header' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-main-header">
                                {/* logo */}
                                <LogoComponent />
                                {/* logout button is visible if user is logged in */}
                                {isAuthenticated ? <LogoutComponent /> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="gi-tabs-section">
                <div className="container">
                    <div className="row">
                        {/* stepProcessBar is visible if user is logged in */}
                        {isAuthenticated && props.step !== 0 ? <StepProcessBarComponent check={props.step} /> : ''}
                    </div>
                </div>
            </section>
        </header>

    )
}

export default Header