//default library
import React, { Component } from 'react'

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

//import image
import IMAGES from '../../config/Images';

import IntlMessages from '../../helper/IntlMessages'

export class GiftHeader extends Component {

    login = async () => {
        const { loginWithRedirect } = this.props.auth0
        await loginWithRedirect();
        localStorage.setItem('triggerMethod', 'register')
    }

    register = async () => {
        const { loginWithRedirect } = this.props.auth0
        await loginWithRedirect({
            screen_hint: "signup",
        });
        localStorage.setItem('triggerMethod', 'register')
    }

    render() {
        return (
            <header className="gi-g-header gi-g-lp-header">
                <nav className="navbar navbar-expand-lg navbar-light py-0" aria-label="headerNav">
                    <div className="container">
                        <div className="header-bottom-left">
                            <a className="navbar-brand" href="index.html">
                                <img className="gi-new-logo img-fluid" src={IMAGES.NEW_GIRAFFE_LOGO2} alt="" />
                            </a>
                        </div>
                        <div className="gi-g-mini-nav d-flex order-lg-2 order-1">
                            <div className="gi-g-mini-nav d-none d-sm-flex">
                                <div className="gi-st-login-btn">
                                    <button type="button" onClick={this.login}>
                                        <span className="user-icon"></span>
                                        {IntlMessages('header_login')}
                                    </button>
                                </div>
                                <div className="gi-st-register-btn">
                                    <button type="button" onClick={this.register}>
                                        <span className="pencil-icon"></span>
                                        {IntlMessages('header_register')}
                                    </button>
                                </div>
                            </div>
                            <button className="gi-lp-menu d-lg-none d-md-flex" type="button" data-bs-toggle="collapse"
                                data-bs-target="#web-navbar" aria-controls="web-navbar" aria-expanded="false"
                                aria-label="Toggle navigation">
                            </button>
                        </div>
                        <div className="collapse navbar-collapse order-lg-1 order-2" id="web-navbar">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link" href="#!section-01">
                                        {IntlMessages('footer_products')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#!section-02" target="">
                                        {IntlMessages('footer_about')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#!section-03">
                                        {IntlMessages('footer_blog')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#!section-04">
                                        {IntlMessages('footer_business')}
                                    </a>
                                </li>
                            </ul>
                            <div className="gi-g-mini-nav mb-10 d-flex d-sm-none ">
                                <div className="gi-st-login-btn">
                                    <button type="button">
                                        <span className="user-icon"></span>
                                        {IntlMessages('header_login')}
                                    </button>
                                </div>
                                <div className="gi-st-register-btn">
                                    <button type="button" onClick={this.register}>
                                        <span className="pencil-icon"></span>
                                        {IntlMessages('header_register')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default withAuth0(GiftHeader)