//default library
import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import IntlMessages from '../../helper/IntlMessages';

//Loading Component
import LoadingComponent2 from '../LoadingComponent/LoadingComponent2';

export class LoadingModal extends Component {

    onHandleClose = async () => {
        this.props.onHandleClose()
    }

    render() {
        const { openLoadingModal, title } = this.props
        return (
            <Modal
                centered
                show={openLoadingModal}
                backdrop="static"
                keyboard={false} className="error-modal"
                onHide={this.onHandleClose}>
                <Modal.Body>
                    <div className="error-modal-body">
                        <div className="error-modal-text">
                            <LoadingComponent2 />
                            <h5>
                                {IntlMessages(title)}
                            </h5>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default LoadingModal