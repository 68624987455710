/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import IntlMessages from '../../helper/IntlMessages'

export class DetailButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectBasket: false
        }
    }

    onHandleClick = async () => {
        await this.setState({
            redirectBasket: true
        })
    }

    render() {
        const { redirectBasket } = this.state
        const { routing, id } = this.props
        return (
            <>
                {redirectBasket ? <Redirect push to={`/dashboard/${routing}/${id}`} /> : ''}
                <button type="button" className="view-details-btn" onClick={this.onHandleClick}>
                    {IntlMessages('button_view_details')}
                </button>
            </>
        )
    }
}

export default DetailButton