//default library
import React, { Component } from 'react';

// image import
import IMAGES from '../../config/Images';

export class B2CGiftHeader extends Component {
    render() {
        return (
            <header className="b2c-header">
                <nav className="navbar navbar-expand-lg navbar-light py-0" aria-label="headerNav">
                    <div className="container">
                        <div className="header-bottom-left">
                            <a className="navbar-brand" href="#!">
                                <img className="gi-new-logo img-fluid" src={IMAGES.NEW_GIRAFFE_LOGO2} alt="" />
                            </a>
                        </div>
                        <div className="gi-g-mini-nav d-flex order-lg-2 order-1 d-none">
                            <button className="gi-lp-menu d-lg-none d-md-flex" type="button" data-bs-toggle="collapse" data-bs-target="#web-navbar" aria-controls="web-navbar" aria-expanded="false" aria-label="Toggle navigation">
                            </button>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default B2CGiftHeader