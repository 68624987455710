import React, { Component, Fragment } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class RightInformation extends Component {
    render() {
        const { info1, info2, img, strong } = this.props
        return (
            <div className="gi-text mt-0 mt-sm-3">
                <div>
                    {img ? <img src={img} alt="lockIcon" /> : ''}
                    <p>
                        {strong
                            ? <strong className="d-block">
                                {IntlMessages(strong)}
                            </strong>
                            : ''
                        }
                        {info1 ? IntlMessages(info1) : ''}

                        {info2
                            ? <Fragment>
                                <br />
                                {IntlMessages(info2)}
                            </Fragment>
                            : ''
                        }
                    </p>
                </div>
            </div>
        )
    }
}

export default RightInformation