import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class ExpenseRatioCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            expenseRatio: props.expenseRatio
        }
    }

    render() {
        const { expenseRatio } = this.state
        return (
            <div className="gi-expense-ratio-card" >
                <div className="gi-expense-ratio-card-header" >
                    {IntlMessages('basket_expense_text2')}
                </div>
                < div className="gi-expense-ratio-card-body" >
                    <div className="row gx-80" >
                        <div className="col-12 " >
                            <div className="gi-expense-ratio-card-heading" >
                                {IntlMessages('basket_expense_breakdown')}
                            </div>
                        </div>
                        {Object.keys(expenseRatio).length !== 0
                            ? < div className="col-md-5" >
                                <div className="gi-expense-ratio-card-details" >
                                    {expenseRatio.esgcValueBreakDownList.length > 0
                                        ? expenseRatio.esgcValueBreakDownList.map((e) => {
                                            return (
                                                <ul className="mb-30" key={e.id}>
                                                    <li>
                                                        <strong>{e.name} </strong>
                                                        <span>{e.boosted ?
                                                            IntlMessages('basket_boosted')
                                                            : ''
                                                        } </span>
                                                    </li>
                                                    < li >
                                                        <span>{`${e.expenseRatioPercent}%`}</span>
                                                        <span>{e.boosted ? `${e.expenseRatioPercent}%` : ''}</span>
                                                    </li>
                                                </ul>
                                            )
                                        })
                                        : ''
                                    }
                                    < ul className="mb-30 gi-total-price" >
                                        <li>
                                            <strong>
                                                {IntlMessages('basket_expense_ratio')}
                                            </strong>
                                        </li>
                                        < li >
                                            <span>
                                                {expenseRatio.total}
                                                {IntlMessages('%')}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            : ''
                        }
                        < div className="col" >
                            <div className="gi-expense-ratio-card-text" >
                                <p className="mb-30" >
                                    {IntlMessages('expense_ratio_text1')}
                                </p>
                                < p className="mb-30" >
                                    {IntlMessages('expense_ratio_text2')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExpenseRatioCard