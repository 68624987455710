//Default Library
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//Handle Button Click Event
import ButtonHandler from '../../handler/ButtonHandler';
import IntlMessages from '../../helper/IntlMessages';

const ButtonComponent = (props) => {

    //handle localStorageData, nextButton and previousButton
    let buttonHandler = ButtonHandler(props.value);

    //set routing
    const [nextRoute, setNextRoute] = useState(false)
    const [previousRoute, setPreviousRoute] = useState(false)

    //control previous Button
    const handleRoutingPrevious = () => {
        // history.push(`/onBoarding/${props.previousRouting}`);
        setPreviousRoute(true)
    }

    //control next Button and store data into localStorage
    const nextRoutingPrevious = async () => {
        // history.push(`/onBoarding/${props.nextRouting}`);
        setNextRoute(true)
        await localStorage.setItem('onBoardingDetails', JSON.stringify(buttonHandler.onBoardingDetails))
    }

    //event listener                                                                                                                                                                                                                                                                                                                                                       function
    const eventListener = (e) => {
        if (e.key === 'Enter') {
            nextRoutingPrevious()
        }
    }

    // add event listener
    useEffect(() => {
        if (!buttonHandler.nextDisabled) {
            window.addEventListener("keydown", eventListener)
        }
        return () => {
            window.removeEventListener('keydown', eventListener);
        };
    })

    return (
        <>
            {previousRoute ? <Redirect push to={`${props.previousRouting}`} /> : ''}
            {nextRoute && !props.showButton ? <Redirect push to={`${props.nextRouting}`} /> : ''}
            {/* previous Button */}
            {props.previousButton
                ? ''
                : <button type="button" className="gi-prev-btn" disabled={buttonHandler.perviousDisabled || (props.previousButton ?? false)} onClick={handleRoutingPrevious}>
                    <span className="material-icons-round">
                        arrow_back_ios
                    </span>
                </button>
            }
            {/* next Button */}
            {!props.showButton
                ? <button className="gi-next-btn" disabled={buttonHandler.nextDisabled} type="button" onClick={nextRoutingPrevious}>
                    {
                        props.buttonName
                            ? IntlMessages(props.buttonName)
                            : IntlMessages('button_next')
                    }
                </button>
                : ''
            }
        </>
    )
}

export default ButtonComponent