//default library
import React, { Component, Fragment } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
// routing library
import { Redirect } from 'react-router-dom';

// jquery library
import $ from 'jquery';

// custom component
import AddPortfolioButton from '../../components/ButtonComponent/AddPortfolioButton';
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import ChartSection from '../../components/SectionComponent/ChartSection';
import CompanyDetailSection from '../../components/SectionComponent/CompanyDetailSection.js';
import ESGCInformationModal from '../../components/ModalComponent/ESGCInformationModal.js';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

// API call
import * as CreateBasket from '../../middleware/CreateBasket'
import IntlMessages from '../../helper/IntlMessages';

export class AddPortfolioPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isLoadingBasket: false, //loading when select ESG-C value
            userName: localStorage.getItem('firstName'), //user name
            nextClick: false, //click next button
            esgcValueList: [], //esgc value
            informationId: '', //store modal id
            openInformationModal: false, //store modal id
            disabled: true, //disabled next button unit data is not coming
            chartValue: {}, //value for chart component
            companyDetail: {}, //value for chart component
        };
    };

    componentDidMount = async () => {
        window.scroll(0, 0)
        const { getAccessTokenSilently } = this.props.auth0
        const selectedValue = await JSON.parse(localStorage.getItem('esgcValueIdentifierList'))
        let esgcValueIdentifierList = [] //empty array to store value of select company
        for (let i = 0; i < selectedValue?.length; i++) {
            let data2 = {
                "esgcValueIdentifier": selectedValue[i].esgcValueIdentifier,
                "boosted": false
            }
            esgcValueIdentifierList.push(data2)
        }
        await localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList))

        const accessToken = await getAccessTokenSilently() //get access token
        const basketSummary = await CreateBasket.getBasketCreateSummary(accessToken)
        const chartValue = {
            totalCompanies: basketSummary?.companySummary.totalCompanies,
            excludedCompanies: basketSummary?.companySummary.excludedCompanies,
            totalIndustry: basketSummary?.companySummary.totalIndustries,
        }
        const companyDetail = {
            totalCompanies: basketSummary?.companySummary.totalCompanyList,
            excludedCompanyList: basketSummary?.companySummary.excludedCompanyList,
            expenseRatio: basketSummary?.expenseRatio
        }
        await this.setState({
            esgcValueList: basketSummary?.esgcValueList,
            chartValue: chartValue,
            companyDetail: companyDetail,
            isLoading: false
        })

        // select value when click ESG-C value
        $(".gi-p-value-card").on('click touchstart', async (e) => {
            if (e.type === "touchstart") {
                $(this).off('click');
            } else if (e.type === "click") {
                $(this).off('touchstart');
            }
            $(e.currentTarget).toggleClass("active"); //toggle active class
            $(`[data-id=${e.currentTarget.id}]`).toggleClass("active"); //toggle active class
            this.getBasketCreateSummary(); //get basket summary by clicking ESG-C value
        });

        // user back to this page
        if (selectedValue) {
            for (let i = 0; i < selectedValue.length; i++) {
                const id = selectedValue[i].esgcValueIdentifier
                $(`#${id}`).addClass('active'); //toggle active class 
                $(`[data-id=${id}]`).toggleClass("active"); //toggle active class
                this.checkActiveClass()
            }
        }
    }

    componentWillUnmount = () => {
        this.setState = () => {
            return;
        };
    }

    //open information modal
    openInformationModal = async (e) => {
        await this.setState({
            informationId: e.target.attributes['data-id'].value, //open clicked information modal
            openInformationModal: true
        })
    }

    //close information modal
    closeInformationModal = async () => {
        await this.setState({
            openInformationModal: false
        })
    }

    checkActiveClass = async () => {
        let esgcValueIdentifierList = [] //empty array to store value of select company
        const data = document.querySelectorAll('.gi-p-value-card.active') //get data that the class name is gi-c-active
        for (let i = 0; i < data.length; i++) {
            let data2 = {
                "esgcValueIdentifier": data[i].id,
                "boosted": false
            }
            esgcValueIdentifierList.push(data2)
        }
        await localStorage.setItem('esgcValueIdentifierList', JSON.stringify(esgcValueIdentifierList))

        this.setState({
            disabled: data.length > 0 ? false : true
        })
    }

    //create basket summary
    getBasketCreateSummary = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        this.checkActiveClass()

        let companyDetail = {
            totalCompanies: [],
            excludedCompanyList: [],
            expenseRatio: []
        }
        await this.setState({
            isLoadingBasket: true,
            companyDetail: companyDetail
        })
        const accessToken = await getAccessTokenSilently() //get access token
        const basketSummary = await CreateBasket.getBasketCreateSummary(accessToken)

        const chartValue = {
            totalCompanies: basketSummary?.companySummary.totalCompanies,
            excludedCompanies: basketSummary?.companySummary.excludedCompanies,
            totalIndustry: basketSummary?.companySummary.totalIndustries,
        }
        companyDetail = {
            totalCompanies: basketSummary?.companySummary.totalCompanyList,
            excludedCompanyList: basketSummary?.companySummary.excludedCompanyList,
            expenseRatio: basketSummary?.expenseRatio
        }
        await this.setState({
            chartValue: chartValue,
            isLoadingBasket: false,
            companyDetail: companyDetail
        })
    }

    // handle next click
    onNextClick = async () => {
        await this.setState({
            nextClick: true
        })
    }

    render() {
        const { userName, isLoading, nextClick, disabled,
            esgcValueList, informationId, openInformationModal, isLoadingBasket,
            chartValue, companyDetail } = this.state
        return (
            <Fragment>
                {nextClick ? <Redirect push to='create-portfolio' /> : ''}
                {isLoading ?
                    <LoadingComponent />
                    : <Fragment>
                        {isLoadingBasket ? <LoadingComponent /> : ''}
                        {/* Page Header */}
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-gift-heading">
                                    <PageHeader
                                        h5={`create_basket_head_t1`}
                                        strong={`hello`}
                                        userName={`${userName}`}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Actual content */}
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-p-card mb-10">
                                    {/* button component */}
                                    <AddPortfolioButton
                                        buttonHeader={'create_basket_card_t1'}
                                        buttonLabel={'button_next'}
                                        backButton={false}
                                        onClick={this.onNextClick}
                                        disabledNext={disabled} />

                                    {/* value list esgc */}
                                    <div className="gi-p-card-body">
                                        <div className="gi-p-green-card">
                                            <div className="gi-p-green-card-header">
                                                {IntlMessages('create_basket_text9')}
                                            </div>
                                            <div className="gi-p-green-card-body">
                                                <div className="row">
                                                    {/* esgc value list */}
                                                    <div className="col-lg-7 col-md-12">
                                                        <div className="gi-p-value-ul">
                                                            {esgcValueList.length > 0
                                                                ? esgcValueList.map((e) => {
                                                                    return (
                                                                        <div className="gi-p-value-li" key={e.identifier}>
                                                                            <div className="gi-p-value-card-main">
                                                                                <a href='#!' className='gi-p-value-info' onClick={this.openInformationModal} data-id={e.identifier}> </a>
                                                                                <div className="gi-p-value-card" id={e.identifier}>
                                                                                    <div className="gi-p-value-card-img">
                                                                                        <img className="inactive" src={e.thumbnailSmall} alt={e.name} />
                                                                                        <img className="active" src={e.thumbnailMedium} alt={e.name} />
                                                                                    </div>
                                                                                    <div className="gi-p-value-card-text">
                                                                                        <h5>{e.name}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <a href='#!' className='gi-p-value-info' onClick={this.openInformationModal} id={e.identifier}> </a> */}
                                                                        </div>
                                                                    )
                                                                })
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* chart part */}
                                                    <div className="col-lg-5 col-md-6">
                                                        <ChartSection chartValue={chartValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* company details */}
                                        <CompanyDetailSection companyDetail={companyDetail} chartValue={chartValue} esgcValueList={esgcValueList} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }


                {openInformationModal
                    ? <ESGCInformationModal openInformationModal={openInformationModal} informationId={informationId} esgcValue={esgcValueList} closeInformationModal={this.closeInformationModal} />
                    : ''
                }
            </Fragment>
        )
    }
}

export default withAuth0(AddPortfolioPage)