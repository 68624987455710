//default library
import React, { Component } from 'react';

// custom component
import PaginationNew from '../PaginationComponent/PaginationNew';

//image import
import IMAGES from '../../config/Images';

// api call
import { downloadPdf } from '../../middleware/TaxDocumentation';
import IntlMessages from '../../helper/IntlMessages';

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

export class AccountDetailCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            taxStatementData: props.taxStatementData,
            currentTaxStatementData: [],
            totalTaxStatementData: [],
            currentPage: null,
            totalPages: null,
            dataLoad: false
        };
    };

    componentDidMount = async () => {
        const { totalTaxStatementData, taxStatementData } = this.state
        if (totalTaxStatementData.length === 0) {
            await this.setState({
                totalTaxStatementData: taxStatementData,
                dataLoad: true
            })
        }

    }

    // handle pagination data 
    onPageChanged = (data) => {
        const { totalTaxStatementData } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentTaxStatementData = totalTaxStatementData.slice(offset, offset + pageLimit);
        this.setState({
            currentTaxStatementData: currentTaxStatementData,
            currentPage: currentPage,
            totalPages: totalPages
        });
    }

    // downLoad pdf
    downLoadPDF = async (e) => {
        const { getAccessTokenSilently } = this.props.auth0

        const accessToken = await getAccessTokenSilently() //get access token
        const downLoadURL = await downloadPdf(accessToken, e.target.parentElement.id)
        if (downLoadURL) {
            window.open(downLoadURL)
        }
    }

    render() {
        const { currentTaxStatementData, totalTaxStatementData, dataLoad } = this.state
        return (
            <div className="row">
                <div className="col-12">
                    <div className="gi-d-ac-card-main mb-10">
                        <div className="gi-d-ac-new-card">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table gi-d-table-card mb-0" role="presentation">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><span> {IntlMessages('table_account_name')}</span></th>
                                                    <th scope="col"><span> {IntlMessages('table_form_type')}</span></th>
                                                    <th scope="col"><span> {IntlMessages('table_status')}</span></th>
                                                    <th scope="col"><span> </span></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {currentTaxStatementData.length > 0
                                                    ? currentTaxStatementData.map((e, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <p>
                                                                        <strong className="d-block">
                                                                            {e.accountName}
                                                                        </strong>
                                                                        {IntlMessages('taxes_static_text1')}
                                                                        {/* ({IntlMessages('taxes_static_text1')}) */}
                                                                    </p>
                                                                </td>
                                                                <td><span >{e.formType}</span></td>
                                                                <td><span >{e.status}</span></td>
                                                                <td>
                                                                    {e.fileKey ? < a href="#!" onClick={this.downLoadPDF} id={e.fileKey}><img src={IMAGES.PDF_ICON} alt="" /></a> : ''}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <tr className="bg-light">
                                                        <td colSpan="12">
                                                            <span>
                                                                {IntlMessages('table_no_data')}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {dataLoad
                                        ? <PaginationNew totalRecords={totalTaxStatementData.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth0(AccountDetailCard)