//default library
import React, { Component, Fragment } from 'react'

//import images
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'

export class ScheduleSection extends Component {

    forWeek = async (e) => {
        await this.props.forWeek(e.target.id)
    }

    render() {
        const { scheduleSection, learnList } = this.props

        return (
            <Fragment>
                {/* giraffe view week */}
                <div className="row">
                    <div className="col-12">
                        <div className="gi-g-learn-week-dark mb-10">
                            <div className="gi-g-giraffe-path">
                                <div className="gi-g-giraffe-path-img">
                                    <img src={IMAGES.GIRAFFE_VIEW_ACTIVE} alt="" />
                                </div>
                                <div className="gi-g-giraffe-path-text">
                                    {IntlMessages('dashboard_giraffe_view')}
                                </div>
                            </div>
                            {learnList
                                ? <div className="gi-g-learn-week-date">
                                    <div className="gi-g-learn-week-date-main">
                                        {scheduleSection.previousWeek
                                            ? <a href="#!" id={scheduleSection.previousWeek} className="gi-g-left-icon" onClick={this.forWeek}>
                                                {/* <img src={IMAGES.WHITE_PREV_ICON} alt="pervIcon" id={scheduleSection.previousWeek} /> */}
                                            </a>
                                            : ''
                                        }
                                        <span>{IntlMessages('week')} {scheduleSection.forWeek}</span>
                                        {scheduleSection.nextWeek
                                            ? <a href="#!" id={scheduleSection.nextWeek} className="gi-g-right-icon" onClick={this.forWeek}>
                                                {/* <img src={IMAGES.WHITE_NEXT_ICON} alt="nextIcon" id={scheduleSection.nextWeek} /> */}
                                            </a>
                                            : ''
                                        }
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ScheduleSection