import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import IntlMessages from '../../helper/IntlMessages'
import { Markup } from 'interweave';


export class GiftReadMoreModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            readMoreModalOpen: props.readMoreModalOpen,
            esgcValue: props.esgcValue[0] ?? ''
        }
    }

    onHandleClose = async () => {
        await this.setState({
            readMoreModalOpen: false
        })
        await this.props.closeReadMoreModal()
    }

    openAcceptModal = async () => {
        const { esgcValue } = this.props
        await this.props.openAcceptModal(esgcValue[0].esgcValueBoostIdentifier, false)
    }

    render() {
        const { readMoreModalOpen, esgcValue } = this.state
        const titleTextStyle = {
            background: `linear-gradient(${esgcValue.titleLinearGradientDirectionDeg}, ${esgcValue.titleLinearGradientColorStop1} ${esgcValue.titleLinearGradientColorStopPercent1}, ${esgcValue.titleLinearGradientColorStop2} ${esgcValue.titleLinearGradientColorStopPercent2})`,
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        };
        return (
            <>
                <Modal
                    centered
                    show={readMoreModalOpen}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className='read-more-modal'>
                    <Modal.Body className='p-0'>
                        <button type="button" className="read-more-btn-close" onClick={this.onHandleClose}></button>
                        <div className="gi-gift-read-card">
                            {/* just need background color from backend */}
                            <div className="gi-gift-read-card-body">
                                <div className="gi-gift-read-img">
                                    <img src={esgcValue.giftImage} alt="" />
                                </div>
                                <div className="gi-gift-read-text">
                                    <h5 style={titleTextStyle}> {esgcValue.name}</h5>
                                    <div className='gi-c-info-text-dynamic' >
                                        <Markup content={esgcValue.description} />
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-sm-6">
                                            <ul className="gi-gift-read-ul">
                                                <li><span>Clean Harbors Inc.</span></li>
                                                <li><span>Convanta Holding Corp.</span></li>
                                                <li><span>Donadlson Company, Inc.</span></li>
                                                <li><span>Evoqua Water Technologies</span></li>
                                                <li><span>Waste Management, Inc.</span></li>
                                                <li><span>Docusign Inc.</span></li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6">
                                            <ul className="gi-gift-read-ul">
                                                <li><span>Ecolab Inc. </span></li>
                                                <li><span>Zoom Video Communicaitons</span></li>
                                                <li><span>LKQ Corporation</span></li>
                                                <li><span>Energy Recovery, Inc.</span></li>
                                                <li><span>Weyerhaeuser Co.</span></li>
                                            </ul>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                        <div className="gi-gift-read-card-footer" style={{ background: esgcValue.footerBackgroundColor }}>
                            <div className="gi-gift-read-card-footer-left">
                                {IntlMessages('gift_change_value')}
                                &nbsp;
                                <i aria-hidden="true" style={{ color: esgcValue.footerEsgcValueColor }}>
                                    {esgcValue.name}
                                </i>
                            </div>
                            <div className="gi-gift-read-card-footer-right">
                                <button type="button" onClick={this.openAcceptModal} >
                                    {IntlMessages('gift_accept_gift')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default GiftReadMoreModal