import { withAuth0 } from '@auth0/auth0-react'
import React, { Component, Fragment } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'
import DashboardProfile from '../ProfileComponent/DashboardProfile'

export class DashboardHeader extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openAccountSetting: false
        };
    };


    logOut = () => {
        const { logout } = this.props.auth0
        logout({
            returnTo: window.location.origin,
        });
        localStorage.clear()
    }

    menuOpen = async () => {
        this.props.menuOpen()
    }

    openSetting = async () => {
        this.setState({
            openAccountSetting: true
        })
        setTimeout(() => {
            this.setState({
                openAccountSetting: false
            })
        }, 500);
    }

    render() {
        const { dashboard } = this.props
        const { openAccountSetting } = this.state
        return (
            <Fragment>

                {openAccountSetting ? <Redirect push to={'/dashboard/account-setting'} /> : ''}

                <header className="gi-g-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-header-main">
                                    <div className="gi-g-logo">
                                        <NavLink exact className="nav-link" to={'/dashboard'} activeClassName='active'>
                                            <img src={IMAGES.NEW_GIRAFFE_LOGO2} alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="gi-g-mini-nav">
                                        <div className="gi-g-profile d-none d-md-flex">
                                            <DashboardProfile shortNameFirst={true} username={localStorage.getItem('username')} />
                                        </div>
                                        {dashboard
                                            ? <div className="gi-g-menu-btn" onClick={this.menuOpen}>
                                                <button >
                                                    <span className="material-icons">
                                                        menu
                                                    </span>
                                                </button>
                                            </div>
                                            : ''
                                        }
                                        <div className="dropdown gi-g-toggle-ellipsis">
                                            <button type="button" className="gi-g-vertical-ellipsis-icon" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <li>
                                                    <button className='dropdown-item' onClick={this.openSetting}>
                                                        {IntlMessages('dashboard_account_setting')}
                                                    </button >
                                                </li>
                                                <li>
                                                    <button type='button' className="dropdown-item" onClick={this.logOut}>
                                                        {IntlMessages('logout2')}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </Fragment>
        )
    }
}

export default withAuth0(DashboardHeader)