//default library
import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";

//interweave library for html
import { Markup } from 'interweave';

// image import
import IMAGES from '../../config/Images';

export class ESGCInformationModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openInformationModal: props.openInformationModal, //open modal
            informationId: props.informationId, //identifier of select value
            esgcValue: props.esgcValue, //esgc value
            esgcName: '',
            esgcDescription: '',
        }
    }

    componentDidMount = async () => {
        const { esgcValue, informationId } = this.state
        let esgcName = ''
        let esgcDescription = ''
        let esgcImage = ''

        for (let i = 0; i < esgcValue.length; i++) {
            if (esgcValue[i].identifier === informationId) {
                esgcName = esgcValue[i].name
                esgcDescription = esgcValue[i].description
                esgcImage = esgcValue[i].thumbnailLarge
            }
        }

        await this.setState({
            esgcName: esgcName,
            esgcDescription: esgcDescription,
            esgcImage: esgcImage
        })
    }

    onHandleClose = async () => {
        await this.setState({
            openInformationModal: false
        })
        await this.props.closeInformationModal()
    }

    render() {
        const { openInformationModal, esgcName, esgcDescription, esgcImage } = this.state
        return (
            <Modal
                centered
                show={openInformationModal}
                onHide={this.onHandleClose}
                backdrop="static"
                keyboard={false} className="gi-p-modalInfo">
                <div className="modal-body">
                    <div className="gi-p-modalInfo-card">
                        <button type="button" className="gi-p-close-btn" onClick={this.onHandleClose}></button>
                        <div className="gi-p-modalInfo-img">
                            <img src={IMAGES.NATURE_RIGHT_BANNER} className="img-fluid" alt="" />
                        </div>
                        <div className="gi-p-modalInfo-text">
                            <img src={esgcImage} className="img-fluid" alt="" />
                            <h5>{esgcName}</h5>
                            <div className='gi-c-info-text-dynamic mt-30'>
                                <Markup content={esgcDescription} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        )
    }
}

export default ESGCInformationModal