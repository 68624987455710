//default library
import React, { Component, Fragment } from 'react'

//import image
import IMAGES from '../../config/Images'

// import IntlMessages from '../../helper/IntlMessages'

export class GiraffeLearnBannerSection extends Component {
    render() {
        return (
            <Fragment>
                {/* <div className="gi-g-set-up-box">
                    <div className="gi-g-processing-card">
                        <h6>
                            {IntlMessages('giraffe_banner_text1')}
                        </h6>
                        <h5>
                            {IntlMessages('giraffe_banner_10')}
                        </h5>
                        <ul className="gi-g-portfolio-progress">
                            <li className="active"></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>

                        <p>
                            {IntlMessages('giraffe_banner_text2')}
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-6 d-none">
                            <div className="gi-g-climate-vertical-card">
                                <div className="gi-g-climate-vertical-card-bg-1">
                                    <div className="gi-g-climate-vertical-card-bg-2">
                                        <div className="gi-g-climate-vertical-card-bg-3">
                                            <div className="gi-g-climate-vertical-price">
                                                {IntlMessages('giraffe_banner_75')}
                                            </div>
                                            <div className="gi-g-climate-vertical-card-img">
                                                <img src={IMAGES.CLIMATE_CHANGE_PNG} className="img-fluid" alt="climateChangeImg" />
                                            </div>
                                            <div className="gi-g-climate-vertical-card-text">
                                                <h5>
                                                    {IntlMessages('giraffe_banner_text3')}
                                                </h5>
                                                <p>
                                                    {IntlMessages('giraffe_banner_text4')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-6">
                            <div className="gi-g-climate-vertical-card-new-bg">
                                <div className="gi-g-climate-vertical-card-new">
                                    <div className="gi-g-climate-vertical-price">
                                        {IntlMessages('giraffe_banner_75')}
                                    </div>
                                    <div className="gi-g-climate-vertical-card-img">
                                        <img src={IMAGES.CLIMATE_CHANGE_PNG} className="img-fluid" alt="climateChangeImg" />
                                    </div>
                                    <div className="gi-g-climate-vertical-card-text">
                                        <h5>
                                            {IntlMessages('giraffe_banner_text3')}
                                        </h5>
                                        <p>
                                            {IntlMessages('giraffe_banner_text4')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-6">
                            <div className="gi-g-redeem-box">
                                <ul>
                                    <li>
                                        {IntlMessages('giraffe_banner_text5')}
                                    </li>
                                    <li>
                                        {IntlMessages('giraffe_banner_text6')}
                                    </li>
                                    <li>
                                        {IntlMessages('giraffe_banner_text7')}
                                    </li>
                                </ul>
                            </div>

                            <button className="gi-g-redeem-btn" type="button">
                                <img src={IMAGES.WHITE_RIGHT_ICON} alt="whiteRightIcon" />
                                {IntlMessages('giraffe_banner_text8')}
                            </button>
                        </div>
                    </div>
                </div> */}

                <div>
                    <img src={IMAGES.RIGHT_IMAGE_BANNER} className="img-fluid w-100" alt="" />
                </div>
            </Fragment>
        )
    }
}

export default GiraffeLearnBannerSection