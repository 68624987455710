//default library
import React, { Component } from 'react'

//image import
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'

export class GiftFooter extends Component {
    render() {
        return (
            <footer className="gi-g-lp-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-g-lp-footer-text">
                                <div className="gi-g-lp-footer-text-1">
                                    <img src={IMAGES.SUN_ICON} alt="" />
                                    {IntlMessages('gift_footer_text1')}
                                </div>
                                <span>|</span>
                                <div className="gi-g-lp-footer-text-2">
                                    {IntlMessages('gift_footer_text2')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default GiftFooter