//default library
import React from 'react'

//logo svg import
import IMAGES from '../../config/Images'

const LogoComponent = () => {
    return (
        <div className="gi-header-left">
            {/* <img src={IMAGES.LOGO_DASHBOARD} alt="" /> */}
            <img src={IMAGES.NEW_GIRAFFE_LOGO2} alt="" />
        </div>
    )
}

export default LogoComponent