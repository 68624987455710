//default library
import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'

import IntlMessages from '../../helper/IntlMessages'

export class LearnWeekCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentWeek: false,
            userCalendar: [],
            currentCalendar: {},
            currentIndex: ''
        }
    }

    componentDidMount = async () => {
        const { userCalendar } = this.props

        const currentIndex = userCalendar.userCalendar.findIndex(e => {
            return e.current
        })

        await this.setState({
            userCalendar: userCalendar.userCalendar,
            currentCalendar: userCalendar.currentCalendar,
            currentIndex: currentIndex
        })
    }

    // show user to current week
    currentWeek = async () => {
        await this.setState({
            currentWeek: true
        })
    }

    // go to previous week
    prevWeek = async () => {
        const { currentIndex, userCalendar } = this.state
        await this.setState({
            currentCalendar: userCalendar[currentIndex - 1],
            currentIndex: currentIndex - 1
        })
    }

    // go to next week
    nextWeek = async () => {
        const { currentIndex, userCalendar } = this.state
        await this.setState({
            currentCalendar: userCalendar[currentIndex + 1],
            currentIndex: currentIndex + 1
        })
    }

    // go to selected section
    gotoSection = async (e) => {
        const { userCalendar } = this.state
        await this.setState({
            currentCalendar: userCalendar[e.target.id],
            currentIndex: Number(e.target.id)
        })
    }

    render() {
        const { currentWeek, currentCalendar, userCalendar, currentIndex } = this.state

        return (
            <Fragment>

                {/* Redirect to giraffe learn page */}
                {currentWeek ? <Redirect push to='giraffe-learn' /> : ''}

                {/* main content */}
                <div className="row">
                    <div className="col-12">
                        <div className="gi-g-schedule-card mb-10">
                            {/* back giraffe learn page */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-g-full-view shadow-none">
                                        <button type='button' className="mt-1" onClick={this.currentWeek}>
                                            {IntlMessages('giraffe_learn_back')}
                                            <span className="gi-g-close-icon"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* calendar data */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-g-schedule-card-body">
                                        {/* heading */}
                                        <div className="gi-g-schedule-heading">
                                            <h3>
                                                {IntlMessages('giraffe_learn_path')}
                                            </h3>
                                        </div>

                                        {/* points active */}
                                        <div className="gi-g-schedule-month">
                                            <div className="gi-g-schedule-month-main">
                                                {userCalendar.length > 0
                                                    ? userCalendar.map((e, i) => {
                                                        return (
                                                            <div className={`gi-g-month ${currentIndex === i ? 'gi-g-current-month' : currentIndex > i ? 'gi-g-done-month' : ''}`} key={i} id={i}>
                                                                <a href="#!" onClick={this.gotoSection} id={i}>
                                                                    <span id={i}></span>
                                                                </a>
                                                            </div>
                                                        )
                                                    })
                                                    : ''}
                                            </div>
                                        </div>

                                        {/* week content section */}
                                        <div className="gi-g-schedule-text">
                                            <div className="row gx-30 ">
                                                {currentCalendar?.hasOwnProperty('userCalendarContentResponseList')
                                                    ? currentCalendar.userCalendarContentResponseList.map((e, i) => {
                                                        return (
                                                            <div className="col-md-6" key={i}>
                                                                <div className="gi-g-week-text-card">
                                                                    <div className="gi-g-week-text-card-header">
                                                                        <h5>
                                                                            {IntlMessages('giraffe_learn_of_week', { title: e.title, forWeek: e.forWeek })}
                                                                        </h5>
                                                                    </div>
                                                                    <div className="gi-g-week-text-card-body">
                                                                        {e.userContentVideoResponseList.map(a => {
                                                                            return (
                                                                                <p key={a.identifier}>{a.title}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </div>
                                        </div>

                                        {/* week change arrow */}
                                        <div className="gi-g-week-change mt-40">
                                            <div className="gi-g-weekbox gi-g-new-weekbox">
                                                {currentIndex > 0
                                                    ? <a href="#!" className="gi-g-left-icon" onClick={this.prevWeek}>
                                                    </a>
                                                    : ''
                                                }
                                                {currentIndex < (userCalendar.length - 1)
                                                    ? <a href="#!" className="gi-g-right-icon" onClick={this.nextWeek}>
                                                    </a>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default LearnWeekCard