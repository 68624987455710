import axios from "axios";

import { PLAID_CREATE_TOKEN, PLAID_GENERATE_TOKEN } from "../../config/UrlList";
import { ConsoleLog } from "../utils/ConsoleLog";

export const plaidLinkToken = async (accessToken) => {
    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };
    const params = {
        'customerIdentifier': localStorage.getItem('dwUserIdentifier'),
        'countryCode': 'US',
        'languageCode': 'en'
    }
    return await axios.post(PLAID_CREATE_TOKEN, params, config)
        .then(res => {
            return res.data.token
        })
        .catch(error => {
            ConsoleLog('There was an error!', error);
        });

}

export const plaidGenerateToken = async (accessToken, params) => {
    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.post(PLAID_GENERATE_TOKEN, params, config)
        .then(res => {
            return res.data
        })
        .catch(error => {
            ConsoleLog('There was an error!', error);
            if (error.response.status !== 200) {
                return {
                    'message': error.response.data.message,
                    'error': true
                }
            }
        });
}

