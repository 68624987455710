//default library
import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom';

// intl messages
import IntlMessages from '../../helper/IntlMessages'

export class B2CProcessBar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tabList: [
                { id: 1, label: 'b2c_tab_text1' },
                { id: 2, label: 'b2c_tab_text2' },
                { id: 3, label: 'b2c_tab_text3' },
                { id: 4, label: 'b2c_tab_text4' },
            ], //tab listing
            backClick: false, //back button click handle
            nextClick: false, //next button click handle
        };
    };

    // back button click handle
    onBackHandle = async () => {
        await this.setState({
            backClick: true, //back button click true
        })
    }

    // next button click handle
    onNextHandle = async () => {
        await this.setState({
            nextClick: true, //next button click true
        })
    }

    render() {
        const { backButton, activeTab, nextButton, backURL, nextURL, nextButtonDisabled } = this.props
        const { tabList, backClick, nextClick } = this.state
        return (
            <Fragment>
                {/* back button click */}
                {backClick ? <Redirect to={backURL} /> : null}

                {/* next button click */}
                {nextClick ? <Redirect to={nextURL} /> : null}

                {/* b2c tabs */}
                <div className="b2c-tabs">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="b2c-tabs-main">
                                    <div className="b2c-point-main">
                                        <div className={`b2c-prev-btn ${backButton ? '' : 'invisible'}`}>
                                            <button type="button" onClick={this.onBackHandle}>
                                                {IntlMessages('button_back')}
                                            </button>
                                        </div>
                                        <ul>
                                            {tabList.length > 0
                                                ? tabList.map((e, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <div className={`b2c-point ${e.id === activeTab ? 'b2c-point-active' : ''}`}>
                                                                <span className="b2c-point-num">
                                                                    {e.id}
                                                                </span>
                                                                <span className={`b2c-point-text ${e.id !== 1 ? 'fst-italic' : ''}`}>
                                                                    {IntlMessages(e.label)}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                : null
                                            }

                                        </ul>
                                        <div className={`b2c-next-btn  ${nextButton ? '' : 'invisible'}`}>
                                            <button type="button" onClick={this.onNextHandle} disabled={nextButtonDisabled}>
                                                {IntlMessages('button_next')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default B2CProcessBar