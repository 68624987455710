//default Library
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

//custom Library
import App from './App';
import { Auth0WithHistory } from './middleware/Auth0';

// data docs
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

if (JSON.parse(process.env.REACT_APP_DATADOGS)) {
  datadogRum.init({
    applicationId: '3959edc8-2e47-4b91-a317-04de08e52e12',
    clientToken: 'pub5697dc27da38f7bdedc24cfaf206e4c3',
    site: 'datadoghq.com',
    service: 'giraffeinvest-user',
    env: process.env.REACT_APP_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: 'R.0.18.0',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    premiumSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken: 'pub5697dc27da38f7bdedc24cfaf206e4c3',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: 'giraffeinvest-user',
    env: process.env.REACT_APP_ENVIRONMENT,
    version: '1.0.0',
    forwardConsoleLogs: ['error']
  })
}



ReactDOM.render(
  <Router>
    <Auth0WithHistory>
      <App />
    </Auth0WithHistory>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
