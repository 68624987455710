//default library
import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

//interweave library for html
import { Markup } from 'interweave';

// intl message
import IntlMessages from '../../helper/IntlMessages';

export class B2CGiftInfoModal extends Component {

    onHandleClose = async () => {
        this.props.onHandleClose()
    }

    render() {

        const { openInfoModal, esgcValue } = this.props
        const textGradient = {
            background: `linear-gradient(${esgcValue.titleLinearGradientDirectionDeg}, ${esgcValue.titleLinearGradientColorStop1} ${esgcValue.titleLinearGradientColorStopPercent1}, ${esgcValue.titleLinearGradientColorStop2} ${esgcValue.titleLinearGradientColorStopPercent2})`,
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textFillColor: 'transparent',
        }

        const footerBackground = {
            background: esgcValue.footerBackgroundColor,

        }
        return (
            <Modal
                centered
                show={openInfoModal}
                backdrop="static"
                keyboard={false} className="b2c-info-modal"
                onHide={this.onHandleClose}
            >
                <Modal.Body>
                    <div className="esgc-info-card">
                        <button type="button" className="b2c-close-btn" aria-label="Close" onClick={this.onHandleClose}></button>
                        <div className="esgc-info-body">
                            <div className="esgc-info-text">
                                <img src={esgcValue.giftImage} alt="" />
                                <h5 style={textGradient}>{esgcValue.name}</h5>
                                <Markup content={esgcValue.description} />
                            </div>
                        </div>
                        <div className="esgc-info-footer" style={footerBackground}>
                            <button type="button">{IntlMessages('select_esgc_info')}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default B2CGiftInfoModal