module.exports = {
    't_and_c': 'תנאים',

    't_and_c_t1': 'Giraffe Invest, Inc.',
    't_and_c_t2': 'תנאי שימוש',
    't_and_c_t3': 'תאריך תוקף: 1 באפריל, 2022',
    't_and_c_t4': 'הסכמה לתנאים',
    't_and_c_t5': `Giraffe Invest, Inc. ("ג'ירפה") היא יועצת השקעות רשומה באינטרנט בלבד, הרשומה ב-SEC. על ידי גישה לאתר של ג'ירף בכתובת`,
    't_and_c_t6': `www.giraffeinvest.com ("האתר"),`,
    't_and_c_t7': `אתה מסכים להיות מחויב לתנאי השימוש הבאים ולמדיניות הפרטיות של ג'ירף. אנא עיין בקפידה בהסכמים אלה וכן את גילויי הג'ירפות. ג'ירף רשאית לעדכן את תנאי השימוש מעת לעת ללא הודעה מוקדמת על ידי פרסום הסכם מתוקן. עליך להפסיק את השימוש באתר אם אינך מסכים להסכמים מתוקנים כלשהם. כמו כן האתר נתון לשינויים ללא הודעה מוקדמת.`,
    't_and_c_t8': `שירותי הייעוץ הניתנים על ידי ג'ירף הם`,
    't_and_c_t9': `נשלט`,
    't_and_c_t10': `לפי תנאי שימוש אלה, מדיניות הפרטיות של ג'ירף והסכם לקוח ג'ירף. שירותי תיווך מסופקים על ידי Drivewealth, סוחר ברוקר רשום ב-SEC, ונשלטים על ידי Drivewealth`,
    't_and_c_t11': `תנאי שימוש`,
    't_and_c_t12': `מדיניות פרטיות`,
    't_and_c_t13': `, ו`,
    't_and_c_t14': `הסכם חשבון לקוח`,
    't_and_c_t15': `למטרות תנאי שימוש אלה, המונח "ג'ירפה" כולל את השותפים, השותפים, הסוכנים והעובדים של ג'ירף. המונח "לקוח" מתייחס לאדם שמאפשר לג'ירף לשמש כ-RIA שלך על ידי חתימה על הסכם לקוח ג'ירף. המונח "חשבון לקוח" מתייחס לחשבון שלקוח יוצר באמצעות האתר כדי להשתמש בשירותים שלנו.`,
    't_and_c_t16': `שימוש באתר`,
    't_and_c_t17': `האתר מיועד אך ורק לאנשים שגילם 18 ומעלה. אתה מצהיר ומתחייב שאתה בן 18 ומעלה על ידי גישה או שימוש באתר. כל גישה לאתר או שימוש בו על ידי מי מתחת לגיל 18 מפרים את תנאי השימוש הללו, אינה מורשית וללא רישיון.`,
    't_and_c_t18': `האתר מיועד רק לשימוש על ידי אנשים הנמצאים בארצות הברית. ג'ירף אינה מצהירה כי האתר מתאים, זמין, חוקי או מותר לשימוש במקומות מחוץ לארצות הברית על פי החוק המקומי.`,
    't_and_c_t19': `גישה לאתר`,
    't_and_c_t20': `באחריותך, כלקוח, לשמור על פרטי חשבון הלקוח שלך, כולל הסיסמה שלך. אתה מסכים שכל מידע שתספק לנו כדי לרשום חשבון דרך האתר או האפליקציות לנייד יהיה נכון, מדויק, עדכני ומלא. אתה גם מסכים שאתה תהיה האחראי הבלעדי לכל הפעולות או הפעילויות שבוצעו במסגרת חשבון הלקוח שלך, ללא קשר אם אישרת פעולות או פעילויות כאלה. כלקוח אתה מסכים לא לחשוף את הסיסמה שלך לצד שלישי כלשהו. אם הסיסמה שלך אבדה או נגנבה או אם אתה חושד בשימוש לא מורשה בחשבון הלקוח שלך, אתה מסכים להודיע ​​מיד לג'ירף.`,
    't_and_c_t21': `שימוש חינוכי בלבד`,
    't_and_c_t22': `החלקים הזמינים לציבור של האתר (כלומר, אותם חלקים באתר הזמינים לאנשים שלא חתמו על הסכם לקוח ג'ירף) מסופקים למטרות חינוכיות בלבד. האתר אינו מיועד לספק ייעוץ משפטי, מס או תכנון פיננסי. אתה מסכים שאתה אחראי באופן מלא למחקר ההשקעות שלך והחלטות ההשקעה שלך בשימוש בחלקים הזמינים לציבור של האתר. אלא אם נקבע אחרת במסמך זה, ג'ירף לא תישא באחריות לכל פעולה שתנקוט על סמך מידע שתקבל דרך החלקים הזמינים לציבור של האתר.`,
    't_and_c_t23': `קישורים חיצוניים`,
    't_and_c_t24': `במהלך השימוש שלך באתר, אתה עלול להיתקל בקישורים לתכנים מסוימים שנוצרו על ידי צדדים שלישיים שאינם קשורים כגון תנאי שימוש, הוראות סודיות, הוראות פרטיות או הוראות אחרות השונות מתנאי השימוש של האתר. ג'ירף אינה מאשרת או מאשרת תוכן כזה, אינה אחראית בשום אופן לתכנים כאמור, ואינה נוטלת אחריות או אחריות כלשהי על הדיוק, המהימנות או הדעות הכלולים בתוכן כזה. משתמשים שמחליטים לגשת לתוכן כזה עושים זאת על אחריותם בלבד.`,
    't_and_c_t25': `סיום`,
    't_and_c_t26': `ג'ירף שומרת לעצמה את הזכות, לפי שיקול דעתה הבלעדי, להפסיק או להשעות את גישתך לכל התכונות של אתר האינטרנט של ג'ירף בכל עת ומכל סיבה ללא הודעה אליך. ג'ירף שומרת לעצמה את הזכות לנקוט בכל פעולה מתקנת נוספת שתמצא לנכון אם ג'ירף סבורה, לפי שיקול דעתה הבלעדי, שהתרחשה הפרה של תנאי שימוש אלה. ג'ירף שומרת לעצמה גם את הזכות לחקור חשדות להפרות של תנאי השימוש. ג'ירף עשויה לבקש לאסוף, ואתה מסכים לספק, כל מידע ממך או מכל אדם אחר החשוד בהפרת תנאי שימוש אלה.`,
    't_and_c_t27': `ההתחייבויות שלך כלפי ג'ירף על פי תנאי שימוש אלה (כולל אך לא רק בעלות, שיפוי והגבלת אחריות) לא יושפעו מכל השעיה, סיום או ביטול של גישתך לאתר האינטרנט והשירותים של ג'ירף. התחייבויות אלה, לפי מובן והקשרן, נועדו לשרוד השעיה, סיום או ביטול כאמור.`,
    't_and_c_t28': `זכויות קניין רוחני`,
    't_and_c_t29': `סימנים מסחריים וסמלי לוגו הם רכושם של בעליהם בהתאמה ואינם מייצגים המלצות מכל סוג שהוא. אלא אם נקבע אחרת בתנאי שימוש אלה, אינך רשאי להוריד או לשמור עותק של האתר או כל חלק ממנו, לכל מטרה. עם זאת, אתה רשאי להדפיס עותק של מסכים בודדים המופיעים כחלק מהאתר אך ורק לשימושך האישי, הלא מסחרי או לרישומים, ובלבד שלא תסיר כל סימן, לוגו, הודעות או אגדות אחרות המופיעות על גבי המודפס. או תמונות מאוחסנות של מסכים כאלה. כל זכויות הקניין הרוחני בתוכן ובתוכן של אתרים מקושרים הם רכושו של בעל התוכן המתאים ועשויות להיות מוגנות על ידי זכויות יוצרים החלות או חוקים ואמנות קניין רוחני אחרים.`,
    't_and_c_t30': `Digital Millennium Copyright Act.`,
    't_and_c_t31': `ג'ירפה מכבדת זכויות קניין רוחני. אם אתה בעל זכויות יוצרים או סוכן שלו ומאמין שזכויות היוצרים שלך הופרו, אתה רשאי לשלוח הודעה בהתאם לחוק Digital Millennium Copyright ("DMCA") על ידי מתן המידע הבא בכתב לסוכן זכויות היוצרים של ג'ירף (Josh) ראה 17 U.S.C § 512(c)(3) לפרטים נוספים):`,
    't_and_c_t32': `(i) חתימה פיזית או אלקטרונית של אדם המורשה לפעול בשמו של בעל זכות בלעדית שהופרה לכאורה;`,
    't_and_c_t33': `(ii) זיהוי היצירה המוגנת בזכויות יוצרים שנטען כי הופרו, או, אם מספר יצירות המוגנות בזכויות יוצרים באתר מקוון בודד מכוסות בהודעה אחת, רשימה מייצגת של יצירות כאמור באותו אתר;`,
    't_and_c_t34': `(iii) זיהוי החומר הנטען כמפר או שהוא נושא לפעילות מפרה ושיש להסיר או לבטל את הגישה אליו ומידע מספיק סביר כדי לאפשר לספק השירות לאתר את החומר;`,
    't_and_c_t35': `(iv) מידע מספיק סביר כדי לאפשר לספק השירות ליצור איתך קשר, כגון כתובת, מספר טלפון, ואם זמין, דואר אלקטרוני;`,
    't_and_c_t36': `(v) הצהרה על כך שאתה מאמין בתום לב שהשימוש בחומר באופן שהתלונן עליו אינו מורשה על ידי בעל זכויות היוצרים, סוכנו או החוק; ו`,
    't_and_c_t37': `(vi) הצהרה שהמידע בהודעה מדויק, ובכפוף לעונש של עדות שקר, שאתה מורשה לפעול בשמו של הבעלים של זכות בלעדית שהופרה לכאורה.`,
    't_and_c_t38': `התראות, הודעות ותקשורת שירות`,
    't_and_c_t39': `העובדים והסוכנים המורשים של ג'ירף עשויים לפקח ולהקליט את כל או חלק מהתקשורת שלך עם ג'ירף, לרבות שיחות טלפון, מיילים, הודעות טקסט וצ'אטים. אתה מסכים בזאת להקלטה כזו. למעט במידה שהחוק החל מחייב אחרת במפורש, הסכמה כזו היא מתמשכת ואין צורך לאשרה לפני, או במהלך, ניטור או הקלטה כאמור. ג'ירף גם שומרת על הזכות לאחסן כל תקשורת שלך עם ג'ירף למטרות רגולטוריות או אחרות.`,
    't_and_c_t40': `כלי השקעות וחיזוי מודלים`,
    't_and_c_t41': `ג'ירף לא מציגה שום ייצוג לגבי הסבירות או ההסתברות שכל השקעה ממשית או היפותטית תשיג תוצאה מסוימת או תבצע בכל דרך צפויה. ביצועי העבר אינם ערובה להצלחה עתידית, והתשואות בכל תקופה עשויות להיות הרבה מעל או מתחת לאלו של תקופה קודמת. לא ניתן מצג כי השקעה כלשהי תשיג או עשויה להשיג רווחים או הפסדים דומים לאלה שהושגו בעבר, או שיימנעו הפסדים משמעותיים. השקעה כרוכה בסיכון והשקעות בג'ירף עלולות לאבד מערך.`,
    't_and_c_t42': `כתב ויתור על אחריות`,
    't_and_c_t43': `השימוש שלך באתר, והמידע האישי שאתה מספק הוא על פי שיקול דעתך ובסיכון הבלעדי שלך. האתר וכל החומרים, המידע, המוצרים והשירותים הכלולים בו, מסופקים כפי שהם וכבסיס זמין ללא אחריות מכל סוג שהוא מג'ירפה.`,
    't_and_c_t44': `ג'ירפה מתנערת במפורש מכל האחריות מכל סוג שהוא, מפורשת או משתמעת או חוקתית, הקשורה לאתר האינטרנט, לרבות, אך לא מוגבלת, אחריות לסחירות, התאמה פרטנית, פרטנית, פרטנית ובפרטית. קורס ביצועים. כמו כן, ג'ירפה מתנערת מכל חובה לספק מידע מעודכן באתר, וג'ירפה מתנערת מאחריות בכל הנוגע לביצועים של, או דיוק, איכות, מטבע, מהימנות, עמידה בזמנים, עמידה, ויעילות. GIRAFFE לא מתחייבת שהאתר יהיה נקי משגיאות או וירוסים אלקטרוניים.`,
    't_and_c_t45': `שום עצה או מידע, בין בעל פה ובין בכתב, המסופק לך על ידי GIRAFFE, לא יצור כל אחריות שלא צוין במפורש בהסכם זה. כל הסתמכות על מידע כזה נעשית על אחריותך בלבד.`,
    't_and_c_t46': `מדינות מסוימות או תחומי שיפוט אינם מאפשרים לנו לא לכלול אחריות מסוימות. בהתאם לכך, ייתכן שחלק מההחרגות לעיל לא יחולו עליך.`,
    't_and_c_t47': `הגבלת אחריות`,
    't_and_c_t48': `בשום מקרה לא יישאו ג'ירפה או כל אחד מהמנהלים, הדירקטורים, העובדים או הסוכנים שלה באחריות כלפיך לכל נזק שהוא, כולל ללא הגבלה עקיף, מקרי, מיוחד, עונשי, או חוץ מזה, או חוץ מזה. מאתר האינטרנט של הג'ירפה, כולל אך לא מוגבל לאיכות, דיוק או שימושו של המידע המסופק כחלק מהג'ירפה או דרך כל החלטות השקעה שהתקבלו על בסיס מידע שכזה, ולא על בסיס מידע כזה. קיבל הודעה על האפשרות של נזקים כאלה. הגבלת החבות האמורה לעיל תחול במידה המלאה המותרת על פי חוק בשטח השיפוט הרלוונטי, ובשום מקרה, האחריות המצטברת של GIRAFFE כלפיך לא תעלה על 100 דולר ארה"ב.`,
    't_and_c_t49': `שיפוי`,
    't_and_c_t50': `אתה מסכים לשפות, להגן ולשמור על ג'ירף מכל אחריות, הפסד, תביעה והוצאה, לרבות שכר טרחת עורך דין, הקשורים להפרת תנאי שימוש אלה או שימוש באתר. שום דבר בתנאי שימוש אלה לא ישפיע על זכויות סטטוטוריות שאינן ניתנות לוויתור החלות עליך. ג'ירף שומרת לעצמה את הזכות לקבל את ההגנה והשליטה הבלעדית (על חשבונך) בכל עניין הכפוף לשיפוי לפי סעיף זה. במקרה כזה, אתה מסכים לשתף פעולה עם כל בקשה סבירה שתסייע להגנתה של ג'ירף בעניין זה.`,
    't_and_c_t51': `הפרדה ווויתור`,
    't_and_c_t52': `אם תנאי או הוראה של תנאי שימוש אלה ייחשבו כלא חוקיים, בלתי חוקיים או בלתי ניתנים לאכיפה, התוקף או האכיפה של שאר הסכם זה לא יושפעו.`,
    't_and_c_t53': `אי מימוש מכל בחינה שהיא זכות המפורטת בתנאי שימוש אלה לא תיחשב כוויתור על כל זכות אחרת של תנאי שימוש אלה.`,
    't_and_c_t54': `מְשִׁימָה`,
    't_and_c_t55': `אינך רשאי להקצות, להעביר או להעביר בכל דרך אחרת כל זכויות, מחובות או אינטרסים שלך על פי תנאי שימוש אלה (על פי חוק או אחר) ללא הסכמה מראש של ג'ירף. כל הקצאה או האצלה ללא הסכמה מראש של ג'ירף בטלה.`,
    't_and_c_t56': `יישוב סכסוכים`,
    't_and_c_t57': `כל מחלוקת או טענה שעלולה להתעורר המתייחסת בכל דרך לתנאי שימוש אלה, המתייחסת לכל עסקה, שגיאות ו/או השמטות הנובעות מתנאי שימוש אלה או המתייחסים אליהם, ו/או המתייחסת לכל הפרה של תנאי שימוש אלה של השימוש, ייפתר בבוררות בפני הרשות הרגולטורית של התעשייה הפיננסית, Inc. ("FINRA"). אם FINRA לא תקבל עניין זה לבוררות, אז כל מחלוקת או תביעה בין הצדדים תיפתרנה בבוררות בפני האגודה האמריקאית לבוררות ("AAA"). הבוררות תתנהל בהתאם לכללים הנוכחיים של FINRA, הנוגעים להליך, גילוי ומהות לבוררות של סכסוכי לקוחות, תוך יישום אמות המידה של החוק החל (או בהתאם לכללים הנוכחיים של AAA לסכסוכים מסחריים). בוררות FINRA (או AAA) תתקיים בפיטסבורג, פנסילבניה. כל צד יישא בהוצאות שכר טרחת עורכי הדין וההוצאות שלו. כל פסק שיינתן על ידי הבוררים של FINRA (או AAA) יהיה סופי ומחייב את כל הצדדים להסכם זה. הצדדים מסכימים כי ניתן לתת פסק דין על פסק הבוררות בכל בית משפט בעל סמכות שיפוט מוסמכת לצורך אכיפת הפסק. הוראת בוררות זו אינה מגבילה או משפיעה בשום אופן על זכויות הצדדים על פי החוק החל או מהווה ויתור על הזכות לפנות לפורום שיפוטי אם הוראת בוררות זו, או כל ויתור בה, יהיו בטלים על פי חוקי ניירות ערך הפדרליים או המדינתיים.`,
    't_and_c_t58': `בחירת חוק`,
    't_and_c_t59': `חוקי ניו יורק (מבלי לתת תוקף לעקרונות התנגשויות החוק שלה) מסדירים את כל העניינים הנובעים מתנאי שימוש אלה ומתייחסים אליהם, לרבות נזיקין.`,
}