//default library
import React, { Component, Fragment } from 'react'
import Modal from "react-bootstrap/Modal";
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//api call
import { transferFund } from '../../middleware/TransferFundCall';

//custom function
import { removeUndefined } from '../../service/utils/RemoveUndefineValue';
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';

//custom component
import InputComponentCurrency from '../InputComponent/InputComponentCurrency';
import LoadingModal from './LoadingModal';
import SuccessModal from './SuccessModal';

import TextError from '../ErrorComponent/TextError';
import { ConsoleLog } from '../../service/utils/ConsoleLog';
import { MAX_NUMBER_10, MAX_NUMBER_AMOUNT } from '../../config/Config';
import IntlMessages from '../../helper/IntlMessages';
import IMAGES from '../../config/Images';

export class WithdrawMoneyModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openWithdrawModal: false, //open and close modal
            withdrawAmount: '',

            fromCashAccountValue: '', //cash account value
            fromCashAccountLabel: '', //cash account label
            toBankAccountValue: '', //bank account value
            toBankAccountLabel: '', //bank account label

            errorMessage: '', //error message comes from backend
            openLoadingModal: false, //loading modal
            openSuccessModal: false, //success modal
            openConfirmModal: false, //confirm modal
            errorFlag: false, //error flag when error message comes from backend 

            errorFlag10: false, //amount is less then $10
            errorFlagMax: false, //amount is reached max limit

            successMessage: 'bank_transfer', //successful transfer message
        }
    }

    UNSAFE_componentWillReceiveProps = async (props) => {
        await this.setState({
            openWithdrawModal: props.openWithdrawModal
        })
    }

    //load dropdown values
    componentDidMount = async () => {
        //new dropdown value
        let getFundLocation = this.props.getFundLocation
        let toBankAccount = await removeUndefined(getFundLocation.map((e) => {
            if (e.type === 'Bank Account') {
                return {
                    value: `${e.id}|${e.type}`,
                    label: e.name
                }
            }
            else {
                return null
            }
        }))

        let fromCashAccount = await removeUndefined(getFundLocation.map((e) => {
            if (e.type === 'Cash Account') {
                return {
                    value: `${e.id}|${e.type}`,
                    label: e.name
                }
            }
            else {
                return null
            }
        }))

        await this.setState({
            toBankAccountLabel: toBankAccount.length > 0 ? toBankAccount[0].label : '',
            fromCashAccountLabel: fromCashAccount[0].label,

            toBankAccountValue: toBankAccount.length > 0 ? toBankAccount[0].value : '',
            fromCashAccountValue: fromCashAccount[0].value,
        })
    }

    //close modal
    onHandleClose = async () => {
        await this.setState({
            openWithdrawModal: false, //close withdraw modal
            openConfirmModal: false, //close confirm modal
            openLoadingModal: false, //close loading modal
            openSuccessModal: false, //close success modal
            withdrawAmount: '', //clear withdraw amount
            errorFlag: false, //set error flag to false
            errorMessage: '', //clear error message
            errorFlag10: '', //clear error message
            errorFlagMax: '', //clear error message
        })
        await this.props.closeModal(false)
    }

    //close modal
    onHandleCloseSuccess = async () => {
        await this.setState({
            openSuccessModal: false, //close success modal
            withdrawAmount: '', //clear withdraw amount
            errorFlag: false, //set error flag to false
            errorMessage: '', //clear error message
        })
        await this.props.closeModal(false)
        await this.props.componentDidMount() //load total card component
    }

    //set amount
    setInputValue = async (value) => {
        await this.setState({
            withdrawAmount: value,
            errorMessage: '',
            errorFlag: false,
            errorFlag10: false,
            errorFlagMax: false,
        })
    }

    //enable and disable withdraw button
    checkValue = (value) => {
        const { withdrawAmount } = value
        if (withdrawAmount) {
            return false
        }
        else {
            return true
        }
    }

    //open confirm modal
    openConfirmModal = async () => {
        const { withdrawAmount } = this.state
        if (withdrawAmount < MAX_NUMBER_10) {
            await this.setState({
                errorFlag10: true,
                errorFlag: false,
                errorFlagMax: false,
            })
        }
        else if (withdrawAmount > MAX_NUMBER_AMOUNT) {
            await this.setState({
                errorFlag10: false,
                errorFlag: false,
                errorFlagMax: true,
            })
        }
        else {
            await this.setState({
                openConfirmModal: true,
                openWithdrawModal: false,
                errorFlag: false
            })
        }
    }

    //withdraw amount cash account to bank account
    withdrawTransfer = async () => {
        const { withdrawAmount, toBankAccountValue, fromCashAccountValue } = this.state
        const { getAccessTokenSilently } = this.props.auth0
        //if true set loading modal
        await this.setState({
            openLoadingModal: true, //loading modal
            openConfirmModal: false //confirm modal
        })

        // request modal 
        const data = {
            "amount": withdrawAmount,
            "fromAccount": {
                "id": fromCashAccountValue.split('|')[0],
                "type": fromCashAccountValue.split('|')[1]
            },
            "toAccount": {
                "id": toBankAccountValue.split('|')[0],
                "type": toBankAccountValue.split('|')[1]
            }
        }

        const accessToken = await getAccessTokenSilently() //get access token
        transferFund(accessToken, data)
            .then(async (res) => {
                let successMessage = ''

                if (res.data?.transferType === 'BASKET_BUY' || res.data?.transferType === 'BASKET_SELL') {
                    successMessage = res.data?.isOnHold ? 'fund_transfer_market_close' : 'fund_transfer_market_open'
                }
                else {
                    successMessage = 'bank_transfer'
                }

                await this.setState({
                    openLoadingModal: false, //loading modal
                    openSuccessModal: res.data?.isSuccessful, //success modal
                    successMessage: successMessage, //success message
                    openWithdrawModal: res.error, //fund modal
                    errorMessage: res.message, //error message
                    errorFlag: res.error //error flag
                })
                await this.props.closeModal(res.error) //close fund modal from parent component
            })
            .catch(err => {
                ConsoleLog(' ', err)
            })
    }

    render() {
        const { openWithdrawModal, toBankAccountLabel, withdrawAmount,
            openLoadingModal, openSuccessModal, openConfirmModal,
            errorMessage, errorFlag, errorFlag10, errorFlagMax, successMessage } = this.state

        const { withdrawalAmount } = this.props

        let isDisabled = this.checkValue(this.state)

        return (
            <Fragment>
                {/* withdraw modal */}
                <Modal
                    centered
                    show={openWithdrawModal}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-g-new-fund-modal">
                    <Modal.Body>
                        <button className="btn-close" type="button" aria-label="Close" onClick={this.onHandleClose}></button>
                        <div className="gi-g-new-fund-modal-card">
                            {/* left banner */}
                            <div className="gi-g-new-fund-modal-card-left">
                                <div>
                                    <img src={IMAGES.GIRAFFE_MODAL_ICON} alt="" />
                                    <h5 className="d-none d-md-block">
                                        {IntlMessages('withdraw_bank_account', { br: <br /> })}
                                    </h5>
                                    <h5 className="d-block d-md-none">
                                        {IntlMessages('withdraw_bank_account', { br: '' })}
                                    </h5>
                                    <div className="gi-g-p-tag">
                                        <p>
                                            {IntlMessages('withdraw_text2')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* right input */}
                            <div className="gi-g-new-fund-modal-card-right">
                                {/* input */}
                                <div className="row">
                                    <div className="col-12">
                                        {/* amount */}
                                        <div className={`gi-g-new-input mb-30 ${errorFlag || errorFlag10 || errorFlagMax ? 'gi-input-error' : ''}`}>
                                            <label className="form-label">
                                                {IntlMessages('fund_select_amount')} |&nbsp;
                                                {IntlMessages('withdraw_text3', { amount: currencyFormatter(withdrawalAmount) })}
                                            </label>
                                            <div className="gi-currency-input">
                                                <InputComponentCurrency placeholder={'placeholder_currency_10'} name={'withdrawAmount'} value={withdrawAmount} inputValue={this.setInputValue} />
                                                {/* <span className="gi-symbol">$</span> */}
                                            </div>
                                            <TextError message2={errorMessage} display={errorFlag ? 'block' : 'none'} />
                                            <TextError message={'error_minimum_amount_withdraw'} display={errorFlag10 ? 'block' : 'none'} />
                                            <TextError message={'error_maximum_amount'} display={errorFlagMax ? 'block' : 'none'} />
                                        </div>
                                        {/* from */}
                                        <div className="gi-g-new-input mb-30">
                                            <label className="form-label">
                                                {IntlMessages('from')}:
                                            </label>
                                            <div className="form-control-text">
                                                {IntlMessages('giraffe_cash_account')}
                                            </div>
                                        </div>
                                        {/* to */}
                                        <div className="gi-g-new-input mb-30">
                                            <label className="form-label">
                                                {IntlMessages('withdraw_account')}
                                            </label>
                                            <div className="form-control-text">
                                                {toBankAccountLabel}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* button */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-g-new-btn-groupe mt-30">
                                            <button className="gi-g-new-cancel" type="button" onClick={this.onHandleClose}>
                                                {IntlMessages('button_cancel')}
                                            </button>
                                            <button className="gi-g-new-transfer" type="button" disabled={isDisabled || errorFlag || errorFlag10 || errorFlagMax} onClick={this.openConfirmModal}>
                                                {IntlMessages('withdraw')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* confirm modal */}
                <Modal
                    centered
                    show={openConfirmModal}
                    onHide={this.onHandleClose}
                    backdrop="static"
                    keyboard={false} className="gi-g-new-fund-modal">
                    <Modal.Body>
                        <button className="btn-close" type="button" aria-label="Close" onClick={this.onHandleClose}></button>
                        <div className="gi-g-new-fund-modal-card">
                            {/* left banner */}
                            <div className="gi-g-new-fund-modal-card-left">
                                <div>
                                    <img src={IMAGES.GIRAFFE_MODAL_ICON} alt="" />
                                    <h5 className="d-none d-md-block">
                                        {IntlMessages('withdraw_bank_account', { br: <br /> })}
                                    </h5>
                                    <h5 className="d-block d-md-none">
                                        {IntlMessages('withdraw_bank_account', { br: '' })}
                                    </h5>
                                    <div className="gi-g-p-tag">
                                        <p>
                                            {IntlMessages('withdraw_text2')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* right input */}
                            <div className="gi-g-new-fund-modal-card-right">
                                {/* input */}
                                <div className="row">
                                    <div className="col-12">
                                        {/* title */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <h5>
                                                {IntlMessages('fund_confirm_transaction')}
                                            </h5>
                                        </div>
                                        {/* amount */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <label>
                                                {IntlMessages('table_amount')}
                                            </label>
                                            <h5>
                                                {`$ ` + currencyFormatter(withdrawAmount).replace('$', '')}
                                            </h5>
                                        </div>
                                        {/* from */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <label>
                                                {IntlMessages('from')}:
                                            </label>
                                            <h5>
                                                {IntlMessages('giraffe_cash_account')}
                                            </h5>
                                        </div>
                                        {/* to */}
                                        <div className="gi-g-new-input-text mb-40">
                                            <label>
                                                {IntlMessages('withdraw_account')}
                                            </label>
                                            <h5>
                                                {toBankAccountLabel}
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                {/* button */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-g-new-btn-groupe mt-30">
                                            <button className="gi-g-new-cancel" type="button" onClick={this.onHandleClose}>
                                                {IntlMessages('button_cancel')}
                                            </button>
                                            <button className="gi-g-new-transfer" type="button" onClick={this.withdrawTransfer}>
                                                {IntlMessages('withdraw')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Loading Modal */}
                <LoadingModal openLoadingModal={openLoadingModal} title={'fund_preparing'} onHandleClose={this.onHandleClose} />

                {/* SuccessModal */}
                <SuccessModal openSuccessModal={openSuccessModal} successMessage={successMessage} onHandleClose={this.onHandleClose} onHandleCloseSuccess={this.onHandleCloseSuccess} />
            </Fragment>
        )
    }
}

export default withAuth0(WithdrawMoneyModal)