//default library
import React, { Component } from 'react'

//import image
import IMAGES from '../../config/Images';

import IntlMessages from '../../helper/IntlMessages';


export class GiftMainSection extends Component {
    //static counter
    count = 1;

    constructor(props) {
        super(props)

        this.state = {
            miniMbaCount: 0,
            giraffeLearnCount: 0,
            treeOfImpactCount: 0,
        }
    }

    componentDidMount = async () => {
        const { miniMba, giraffeLearn, treeOfImpact } = this.props.giftImpact
        await this.setState({
            miniMbaCount: miniMba ? ++this.count : 0,
            giraffeLearnCount: giraffeLearn ? ++this.count : 0,
            treeOfImpactCount: treeOfImpact ? ++this.count : 0,
        })
    }

    render() {
        const { miniMbaCount, giraffeLearnCount, treeOfImpactCount } = this.state
        const { miniMba, giraffeLearn, treeOfImpact } = this.props.giftImpact
        return (
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-lp-impact-gift-card-main">
                        <div className="gi-g-lp-impact-gift-header">
                            <span>
                                {IntlMessages('gift_include')}
                            </span>
                        </div>
                        <div className="gi-g-lp-impact-gift-card">
                            <div className="gi-g-lp-include-card gi-g-lp-impact-stock">
                                <div className="gi-g-lp-impact-stock-img">
                                    <img src={IMAGES.PIE_CHART} className="img-fluid" alt="" />
                                </div>
                                <div className="gi-g-lp-impact-stock-text">
                                    <h5>
                                        {IntlMessages('gift_include_basket')}
                                    </h5>
                                    <p>
                                        {IntlMessages('gift_include_basket_description')}
                                    </p>
                                </div>
                            </div>
                            {miniMba
                                ? <div className="gi-g-lp-include-card gi-g-lp-impact-mini">
                                    <div className="gi-g-lp-impact-mini-text">
                                        <div>
                                            <h5>{miniMbaCount}. {IntlMessages('dashboard_mini_mba')}</h5>
                                            <span>{IntlMessages('giraffe_mini_mba_text2')}</span>
                                            <p>
                                                {IntlMessages('giraffe_mini_mba_text3')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            {giraffeLearn
                                ? <div className="gi-g-lp-include-card gi-g-lp-impact-learn">
                                    <div className="gi-g-lp-impact-learn-text">
                                        <h5>{giraffeLearnCount}. {IntlMessages('dashboard_giraffe_learn')}</h5>
                                        <p>
                                            {IntlMessages('gift_static_text5')}
                                        </p>
                                    </div>
                                </div>
                                : ''
                            }
                            {treeOfImpact
                                ? <div className={`gi-g-lp-include-card gi-g-lp-impact-tree ${this.count === 3 ? 'gi-g-lp-include-card-full' : ''}`}>
                                    <div className="gi-g-lp-impact-tree-text">
                                        <h5>{treeOfImpactCount}. {IntlMessages('gift_include_tree')}</h5>
                                        <p>
                                            {IntlMessages('gift_include_tree_description')}
                                        </p>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default GiftMainSection