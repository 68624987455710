import React, { Component } from 'react'
import IMAGES from '../../config/Images'

export class DashboardLogoComponent extends Component {
    render() {
        const { mobileView } = this.props
        return (
            <img src={mobileView ? IMAGES.LOGO_DASHBOARD : IMAGES.DASHBOARD_LOGO} alt="logo" className="img-fluid" />
        )
    }
}

export default DashboardLogoComponent