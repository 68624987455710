//default library
import React, { Fragment } from 'react'
import IntlMessages from '../../helper/IntlMessages';

const PageCount = (props) => {

    let count; //count page number base on pageName
    let lastPageTitle; //set last page title base on pageName


    if (props.pageName === 'Personal details') {
        count = 7
        //count is last page set lastPageTitle
        if (props.count === count) {
            lastPageTitle = 'personal_details_last'
        }
    }
    else if (props.pageName === 'Employment details') {
        count = 4
        //count is last page set lastPageTitle
        if (props.count === count) {
            lastPageTitle = 'employment_details_last'
        }
    }
    else if (props.pageName === 'Investment details') {
        count = 3
        //count is last page set lastPageTitle
        if (props.count === count) {
            lastPageTitle = `investment_details_last`
        }
    }
    else if (props.pageName === 'Complete application') {
        lastPageTitle = `complete_application_last`
    }

    return (
        <div className="gi-page">
            <span className="gi-page-name"> {props.pageName} </span>
            <span className="gi-page-number">
                <strong className="d-inline-block d-sm-none">
                    {lastPageTitle ? IntlMessages(lastPageTitle) : ''}
                </strong>
                {props.count ?
                    // `${props.count} ${}`
                    <Fragment>
                        {props.count}&nbsp;
                        {IntlMessages('of')}&nbsp;
                    </Fragment>
                    : ''
                }
                {count}
                <strong className="d-none d-sm-inline-block">
                    &nbsp;{lastPageTitle ? IntlMessages(lastPageTitle) : ''}
                </strong>
            </span>
        </div>
    )
}

export default PageCount