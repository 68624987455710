import React, { Component, Fragment } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import ListView from '../../components/ListViewComponent/ListView'
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import AccountEditModal from '../../components/ModalComponent/AccountEditModal'
import ErrorPendingModal from '../../components/ModalComponent/ErrorPendingModal'
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'
import ConfirmationModal from '../../components/ModalComponent/ConfirmationModal'
import LoadingModal from '../../components/ModalComponent/LoadingModal'
//currency formatter
import { currencyFormatter } from '../../service/utils/CurrencyFormatter'

//api call
import { getUserData } from '../../middleware/User'
import * as DropdownList from '../../middleware/DropdownList'
import { bankAccountUnlink, bankService } from '../../service/ApiService/BankService'
import { checkFundTransfer } from '../../middleware/TransferFundCall'

//remove undefined
import { removeUndefined } from '../../service/utils/RemoveUndefineValue'
import IntlMessages from '../../helper/IntlMessages'
import IMAGES from '../../config/Images'

export class AccountSetting extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '', //email id
            bankName: [{
                "name": "No bank account linked",
                "id": ''
            }], // bank label
            isLoading: true, // loader
            openEditModal: '', //open edit modal
            openEditModalFlag: true, //open edit modal flag

            userData: [], //user userData
            statusList: [], //employment status list api
            industryList: [], //industry list api
            positionList: [], //position list api
            investingList: [], //employment investment list api
            riskToleranceList: [], //risk take list api

            countryName: '', //country label from api 
            statusName: '', //employment label from api 
            industryName: '', //industry label from api 
            positionName: '', //position label from api 
            investingName: '', //investment label from api 
            riskToleranceName: '', //risk label from api 
            dateOfBirth: '', // formatted date of birth
            ssnNumber: '', //formatted ssn number
            phoneNumber: '', //formatted phone number

            transferPending: [], //amount is pending
            openDeleteModal: false, //handle delete modal
            openUnlinkErrorModal: false, //handle unlink modal   
            openUnlinkSuccessModal: false, //handle unlink modal   
            transferType: '', //define transfer type   

            openConfirmationModal: false, //bank unlink confirmation modal
            openLoadingModal: false, //bank unlink confirmation modal
            bankId: '', //bank unlink confirmation modal
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0) //scroll to top
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true
        })

        const accessToken = await getAccessTokenSilently() //get access token

        const [
            transferPending,
            userData,
            bankList,
            statusList,
            industryList,
            positionList,
            investingList,
            riskToleranceList
        ] = await Promise.all([
            checkFundTransfer(accessToken),
            getUserData(accessToken),
            bankService(accessToken),
            DropdownList.getEmploymentStatus(accessToken),
            DropdownList.getIndustryList(accessToken),
            DropdownList.getPositionIndustryList(accessToken),
            DropdownList.getInvestorExperience(accessToken),
            DropdownList.getInvestorRiskTolerance(accessToken)
        ]);

        let countryName = await DropdownList.getCountryIdentifier(accessToken, userData.citizenship) //get country list

        let statusName = await removeUndefined(statusList.map((e) => {
            if (e.value === userData.userEmployment.statusIdentifier) {
                return e.label
            }
            else {
                return null
            }
        }))

        let industryName = await removeUndefined(industryList.map((e) => {
            if (e.value === userData.userEmployment.typeIdentifier) {
                return e.label
            }
            else {
                return null
            }
        }))

        let positionName = await removeUndefined(positionList.map((e) => {
            if (e.value === userData.userEmployment.positionIdentifier) {
                return e.label
            }
            else {
                return null
            }
        }))

        let investingName = await removeUndefined(investingList.map((e) => {
            if (e.value === userData.userInvestor.experienceIdentifier) {
                return e.label
            }
            else {
                return null
            }
        }))

        let riskToleranceName = await removeUndefined(riskToleranceList.map((e) => {
            if (e.value === userData.userInvestor.riskToleranceIdentifier) {
                return e.label
            }
            else {
                return null
            }
        }))

        let employmentStatusLabel = await removeUndefined(statusList.map(e => {
            if (e.value === userData.userEmployment.statusIdentifier) {
                return e.label
            }
            else {
                return null
            }
        }))

        let bankName = await removeUndefined(bankList.map((e, i) => {
            if (e.active) {
                return {
                    name: bankList[i].value,
                    id: bankList[i].id
                }
            }
            else {
                return null
            }
        }))

        bankName = bankName.length > 0
            ? bankName
            : [{
                "name": "No bank account linked",
                "id": ''
            }]

        //split dob
        let dateOfBirth = userData.dob.split('-')
        //store all userData to state
        await this.setState({
            isLoading: false,
            email: localStorage.getItem('userEmailId'), //email
            bankName: bankName, //bank 

            userData: userData,
            statusList: statusList, //employment status List
            industryList: industryList, //industry List
            positionList: positionList, //position List
            investingList: investingList, //investing experience List
            riskToleranceList: riskToleranceList, //risk tolerance List

            countryName: countryName.name, //country label
            statusName: statusName[0], //status label
            industryName: industryName[0], //industry label
            positionName: positionName[0], //position label
            investingName: investingName[0], //investing label
            riskToleranceName: riskToleranceName[0], //risk tolerance label

            dateOfBirth: `${dateOfBirth[1] ? `${dateOfBirth[1]}/` : ''}${dateOfBirth[2] ? `${dateOfBirth[2]}/` : ''}${dateOfBirth[0] ? `${dateOfBirth[0]}` : ''}`, //set dob formatted
            ssnNumber: `${userData.taxIdNumber ? `${userData.taxIdNumber.substring(0, 3)}-${userData.taxIdNumber.substring(3, 5)}-${userData.taxIdNumber.substring(5, 9)}` : ''}`, //set ssnNumber

            phoneNumber: `${userData.phoneEncrypted ? `(${userData.phoneEncrypted.substring(2, 5)}) ${userData.phoneEncrypted.substring(5, 8)} - ${userData.phoneEncrypted.substring(8, 13)}` : ''}`, //phone number formatted

            employmentStatusLabel: employmentStatusLabel[0],

            transferPending: transferPending

        })

    }

    componentWillUnmount = async () => {
        await this.setState(() => {
            return;
        })
    }

    //after the update
    updateAccountSetting = async () => {
        await this.props.updateDashboardProfile()
        await this.componentDidMount()
    }

    closeEditModal = async () => {
        await this.setState({
            openEditModal: '',
            openEditModalFlag: true
        })
    }
    //open my account modal
    myAccountModalOpen = () => {
        this.setState({
            openEditModal: 'myAccountModalOpen',
            openEditModalFlag: false
        })
    }

    //open personal information modal
    personalInformationModalOPen = () => {
        this.setState({
            openEditModal: 'personalInformationModalOPen',
            openEditModalFlag: false
        })
    }

    //open current employment status modal
    employmentStatusModalOpen = () => {
        this.setState({
            openEditModal: 'employmentStatusModalOpen',
            openEditModalFlag: false
        })
    }

    //open broker dealer and political exposure modal
    brokerDealerModalOpen = () => {
        this.setState({
            openEditModal: 'brokerDealerModalOpen',
            openEditModalFlag: false
        })
    }

    //open investing information modal
    investingInformationModalOpen = () => {
        this.setState({
            openEditModal: 'investingInformationModalOpen',
            openEditModalFlag: false
        })
    }

    //unlink bank account
    unlinkBankAccount = async (e) => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        let unlinkBankAccount = await bankAccountUnlink(accessToken, e.target.id)
        await this.setState({
            openLoadingModal: false
        })
        if (unlinkBankAccount) {
            await this.componentDidMount()
        }
    }

    // open delete modal
    deleteModal = async (e) => {
        await this.setState({
            openDeleteModal: !this.state.openDeleteModal
        })
    }

    // unlink modal 
    unlinkModal = async (e) => {
        const { transferPending, bankId } = this.state
        if (transferPending.isPending) {
            await this.setState({
                openUnlinkErrorModal: true,
                transferType: transferPending.transferType.toLowerCase()
            })
        }
        else {
            await this.setState({
                openConfirmationModal: false,
                openLoadingModal: true
            })
            await this.unlinkBankAccount(bankId)
            await this.onHandleClose()
        }
    }

    // open unlink confirmation modal
    openBankConfirmationModal = async (e) => {
        const { transferPending } = this.state
        if (transferPending.isPending) {
            await this.setState({
                openUnlinkErrorModal: true,
                transferType: transferPending.transferType.toLowerCase()
            })
        }
        else {
            await this.setState({
                openConfirmationModal: true,
                bankId: e
            })
        }
    }


    // close modal
    onHandleClose = async () => {
        await this.setState({
            openConfirmationModal: false,
            openLoadingModal: false,
            openUnlinkErrorModal: false
        })
    }

    render() {
        //destructure state
        const { email, isLoading, userData, bankName, openDeleteModal, openUnlinkErrorModal,
            statusList, industryList, positionList, investingList, riskToleranceList,
            countryName, statusName, industryName, positionName, investingName, riskToleranceName,
            openEditModal, dateOfBirth, ssnNumber, phoneNumber, employmentStatusLabel, openEditModalFlag,
            openConfirmationModal, openLoadingModal } = this.state
        // store user address object
        const address = userData.userAddress ?? []
        // store user employment object
        const employmentData = userData.userEmployment ?? []
        // store user investor object
        const userInvestor = userData.userInvestor ?? []
        // set full address
        const fullAddress = `${address.street2 ? `${address.street2},` : ''} 
        ${address.street1 ? `${address.street1},` : ''}
        ${address.city ? `${address.city},` : ''}
        ${address.province ? `${address.province},` : ''}
        ${address.postalCode ? `${address.postalCode}` : ''}`

        return (
            <Fragment>
                {isLoading ? <LoadingComponent /> : ''}
                {/* Page Header */}
                <div className="row">
                    <div className="col-12">
                        <div className="gi-gift-heading">
                            <PageHeader h5={'gift_head_text2'} strong={`hello`} userName={`${localStorage.getItem('firstName')}`} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="gi-g-right-sidebar-main">
                            <div className="gi-g-right-sidebar-left">
                                {/* My account */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main mb-10">
                                            <div className="gi-d-new-heading">
                                                <h5>
                                                    {IntlMessages('dashboard_account_setting')}
                                                </h5>
                                            </div>
                                            <div className="gi-d-ac-new-card">
                                                <div className="gi-d-ac-heading-main">
                                                    <div className="gi-d-ac-heading">
                                                        <h5>
                                                            {IntlMessages('account_setting_my_account')}
                                                        </h5>
                                                    </div>
                                                    <div className="gi-d-ac-heading">
                                                        <a href='#!' onClick={this.myAccountModalOpen}>
                                                            {IntlMessages('edit')}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="gi-d-ac-card">
                                                    <ListView listLabel={`account_setting_email`} listData={`${email}`} aTagTrue={true} />
                                                    <ListView listLabel={`account_setting_phone`} listData={phoneNumber ? `+1 ${phoneNumber}` : ''} />
                                                    {/* <ul className="gi-d-ac-tr gi-d-ac-tr-new">
                                                        <li className="gi-d-ac-td">
                                                            <span> Enable multi-factor autorization <div
                                                                className="gi-d-customize-switch form-check form-switch form-check-inline">
                                                                <input className="form-check-input" type="checkbox"
                                                                    id="flexSwitchCheckDefault" />
                                                            </div></span>
                                                        </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Bank Account */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main mb-10">
                                            <div className='gi-d-ac-new-card'>
                                                <div className="gi-d-ac-heading-main">
                                                    <div className="gi-d-ac-heading">
                                                        <h5>
                                                            {IntlMessages('account_setting_bank')}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="gi-d-bank-ac-card">
                                                    <div className="gi-d-bank-detail">
                                                        <div className="gi-d-bank-text">
                                                            {bankName[0].name}
                                                        </div>
                                                    </div>
                                                    <div className="gi-d-bank-link">
                                                        {bankName[0].id
                                                            ? <a href="#!" id={bankName[0].id} onClick={this.openBankConfirmationModal}>
                                                                {IntlMessages('unlink')}
                                                            </a>
                                                            : ''
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Personal information */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main mb-10">
                                            <div className="gi-d-ac-new-card">
                                                <div className="gi-d-ac-heading-main">
                                                    <div className="gi-d-ac-heading">
                                                        <h5>
                                                            {IntlMessages('personal')}&nbsp;
                                                            {IntlMessages('information')}
                                                        </h5>
                                                    </div>
                                                    <div className="gi-d-ac-heading">
                                                        <a href='#!' onClick={this.personalInformationModalOPen}>
                                                            {IntlMessages('edit')}
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="gi-d-ac-card">
                                                    <ListView listLabel={`account_setting_first`} listData={`${userData.firstNameEncrypted ?? ''}`} />
                                                    <ListView listLabel={`account_setting_middle`} listData={`${userData.middleNameEncrypted ?? ''}`} />
                                                    <ListView listLabel={`account_setting_last`} listData={`${userData.lastNameEncrypted ?? ''}`} />
                                                    <ListView listLabel={`account_setting_address`} listData={`${fullAddress}`} />
                                                    <ListView listLabel={`account_setting_citizenship`} listData={`${countryName}`} />
                                                    {/* <ListView listLabel={`Beneficiaries`} listData={`None`} /> */}
                                                    <ListView listLabel={`account_setting_ssn`} listData={`${ssnNumber ?? ''}`} />
                                                    <ListView listLabel={`account_setting_dob`} listData={`${dateOfBirth ?? ''}`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Current Employment Status */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main mb-10">
                                            <div className='gi-d-ac-new-card'>
                                                <div className="gi-d-ac-heading-main">
                                                    <div className="gi-d-ac-heading">
                                                        <h5>
                                                            {IntlMessages('account_setting_status')}
                                                        </h5>
                                                    </div>
                                                    <div className="gi-d-ac-heading">
                                                        <a href='#!' onClick={this.employmentStatusModalOpen}>
                                                            {IntlMessages('edit')}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="gi-d-ac-card">
                                                    <ListView listLabel={`account_setting_status1`} listData={`${statusName}`} />
                                                    <ListView listLabel={`account_setting_company_name`} listData={`${employmentData.company ?? 'No'}`} />
                                                    <ListView listLabel={`account_setting_industry`} listData={`${industryName ?? 'No'}`} />
                                                    <ListView listLabel={`account_setting_position`} listData={`${positionName ?? 'No'}`} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* Broker Dealer and Political Exposure */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main mb-10">
                                            <div className='gi-d-ac-new-card'>
                                                <div className="gi-d-ac-heading-main">
                                                    <div className="gi-d-ac-heading">
                                                        <h5>
                                                            {IntlMessages('account_setting_broker')}
                                                        </h5>
                                                    </div>
                                                    <div className="gi-d-ac-heading">
                                                        <a href='#!' onClick={this.brokerDealerModalOpen}>
                                                            {IntlMessages('edit')}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="gi-d-ac-card">
                                                    <ListView listLabel={`account_setting_broker_dealer`} listData={`${employmentData.affiliatedWithBroker ? 'Yes' : 'No'} `} />
                                                    <ListView listLabel={`account_setting_public_company`} listData={`${employmentData.directorOf ?? 'No'}`} />
                                                    <ListView listLabel={`account_setting_political`} listData={`${userData.politicallyExposedNames ?? 'No'}`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Investing Information */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main mb-10">
                                            <div className='gi-d-ac-new-card'>
                                                <div className="gi-d-ac-heading-main">
                                                    <div className="gi-d-ac-heading">
                                                        <h5>
                                                            {IntlMessages('account_setting_investing')}
                                                        </h5>
                                                    </div>
                                                    <div className="gi-d-ac-heading">
                                                        <a href='#!' onClick={this.investingInformationModalOpen}>
                                                            {IntlMessages('edit')}
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="gi-d-ac-card">
                                                    <ListView listLabel={`account_setting_investing_exp`} listData={`${investingName}`} />
                                                    <ListView listLabel={`employment_page4_label`} listData={`$` + currencyFormatter(`${userInvestor.annualIncome ?? 0}`).replace('$', '')} />
                                                    <ListView listLabel={`account_setting_net_worth`} listData={`$` + currencyFormatter(`${userInvestor.networthTotal ?? 0}`).replace('$', '')} />
                                                    <ListView listLabel={`investment_detail_info5`} listData={`${riskToleranceName}`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Delete Component */}
                                {/* <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-delete-ac mt-20 mb-30">
                                            <a href="#!" id={userData.id} onClick={this.deleteModal}>
                                                {IntlMessages('account_setting_delete')}
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            {/* banner */}
                            <div className="gi-g-right-sidebar-right">
                                <GiraffeLearnBannerSection />
                            </div>
                        </div>
                    </div>
                </div>

                {/* All edit modal open */}
                {isLoading || openEditModalFlag ? '' : <AccountEditModal openModalEdit={openEditModal} userData={userData}
                    email={email} ssnNumber={ssnNumber} dateOfBirth={dateOfBirth} phoneNumber={phoneNumber}
                    employmentData={employmentData} userInvestor={userInvestor} userAddress={address}
                    countryName={countryName} statusList={statusList} industryList={industryList}
                    positionList={positionList} investingList={investingList} riskToleranceList={riskToleranceList} employmentStatusLabel={employmentStatusLabel}
                    closeEditModal={this.closeEditModal} componentDidMount={this.updateAccountSetting} />}

                {/* delete modal */}
                {openDeleteModal
                    ? <ErrorPendingModal openModelError={openDeleteModal} closeErrorModal={this.deleteModal} h3={'account_delete'} label={'account_delete_text'} />
                    : ''
                }

                {/* bank unlink confirmation modal */}
                {openConfirmationModal
                    ? <ConfirmationModal openConfirmationModal={openConfirmationModal} message={'account_unlink_confirmation'} onHandleClose={this.onHandleClose} onHandleConfirm={this.unlinkModal} />
                    : ''
                }

                {openLoadingModal
                    ? <LoadingModal openLoadingModal={openLoadingModal} title={'loading'} onHandleClose={this.onHandleClose} />
                    : ''
                }

                {/* transaction inprogress modal */}
                {openUnlinkErrorModal
                    ? <ErrorPendingModal openModelError={openUnlinkErrorModal} closeErrorModal={this.onHandleClose} h3={'error_unlink_bank'} label={'error_please_try'} image={IMAGES.UNLINK_BANK_SVG} />
                    : ''
                }
            </Fragment>
        )
    }
}

export default withAuth0(AccountSetting)