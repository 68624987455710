import React, { Component } from 'react'

import PageCount from '../../../components/PageCount/PageCount';
import RightInformation from '../../../components/InformationComponent/RightInformation';
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import MaskInput from '../../../components/InputComponent/MaskInput';

import * as CryptoJS from "crypto-js";
import * as Config from '../../../config/Config'

export class PersonalDetailsPage1 extends Component {
  constructor(props) {
    super(props)
    this.setLocalStorageData()
    this.state = {
      phoneNumber: '',
      localStorageFlag: CryptoJS.AES.encrypt(JSON.stringify(false), Config.SECRET_KEY).toString()
    }
  }

  setLocalStorageData = async () => {
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    if (localStorageData !== null && localStorageData?.phoneNumber !== undefined) {
      let phoneNumber = localStorageData?.phoneNumber
      let phoneNumber1 = phoneNumber.substring(0, 3)
      let phoneNumber2 = phoneNumber.substring(3, 6)
      let phoneNumber3 = phoneNumber.substring(6, 10)
      await this.setState({
        phoneNumber: `(${phoneNumber1}) ${phoneNumber2} - ${phoneNumber3}` ?? '',
        localStorageFlag: localStorageData?.verifyPhoneNumber
      })
    }
    else {
      await localStorage.setItem('onBoardingDetails', JSON.stringify(
        { verifyPhoneNumber: CryptoJS.AES.encrypt(JSON.stringify(false), Config.SECRET_KEY).toString() }
      ))
    }


  }

  setPhoneNumber = async (phoneNumber) => {
    await this.setState({
      phoneNumber: phoneNumber,
      localStorageFlag: CryptoJS.AES.encrypt(JSON.stringify(false), Config.SECRET_KEY).toString()
    });
  }

  setDetails = (phoneNumber) => {
    const { localStorageFlag } = this.state

    this.details = {
      phoneNumber: '',
      verifyPhoneNumber: localStorageFlag
    }
    let part1 = phoneNumber.split('(')
    if (part1.length === 2) {
      let part2 = part1[1].split(')')
      let part3 = part2[1].split('-')
      let num = `${part2[0]}${part3[0].trim()}${part3[1].trim()}`
      if (!isNaN(num)) {
        this.details = {
          phoneNumber: num,
          verifyPhoneNumber: localStorageFlag
        }
      }
    }

    const pageFlag = JSON.parse(CryptoJS.AES.decrypt(localStorageFlag, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8))
    if (pageFlag) {
      this.page = 'page2'
    }
    else {
      this.page = 'verifyCode'
    }

  }

  render() {
    this.setDetails(this.state.phoneNumber)

    return (
      <div className="gi-from-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-8">
                  <div className="py-3">
                    <div className="gi-from-left">
                      <PageCount count={1} pageName={'Personal details'} />
                      <div className="gi-input mb-40">
                        <LabelComponent name={'phoneNumber'} labelName={'personal_page1_label'} />
                        <div className="gi-country-input">
                          <MaskInput name={'phoneNumber'} placeholder={'placeholder_phone'} inputValue={this.setPhoneNumber} />
                        </div>
                      </div>
                      <div className="gi-btn-group d-none d-sm-flex">
                        <ButtonComponent value={this.details} previousButton={true} nextRouting={this.page} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="gi-from-right">
                    <RightInformation info1={'personal_detail_info1'} info2={'personal_detail_info2'} />
                    <div className="gi-btn-group d-flex d-sm-none">
                      <ButtonComponent value={this.details} previousButton={true} nextRouting={this.page} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PersonalDetailsPage1