//default library
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import Header from '../../../components/HeaderComponent/Header'

//api call
import { getUserDetails } from '../../../middleware/User';

//loading image
import IMAGES from '../../../config/Images'

export class LastDetailsPages extends Component {
    // intervalId = '';
    constructor(props) {
        super(props)
        require('../../../assets/styles/oldCSS/main.scss') //import css
        this.state = {
            direction: props.dir,
            isKycProcessing: false,
            dashboard: false,
            isLoading: false
        }
    }

    componentDidMount = async () => {

        document.body.style.fontFamily = `Lato`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1125px'
        }

        this.intervalId = setInterval(this.reloadPage, 1000 * 10) //call reload function every 10 sec

        window.scroll(0, 2)
    }

    //check every 10 second kyc is approved or not
    reloadPage = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        let userDetails = await getUserDetails(accessToken)
        await this.setState({
            isKycProcessing: userDetails.isKycProcessing,
            dashboard: !userDetails.isKycProcessing,
            isLoading: false
        })
    }

    componentWillUnmount = () => {
        clearInterval(this.intervalId)
    }

    render() {
        const { header } = this.props
        const { dashboard, isLoading, isKycProcessing } = this.state
        return (
            <>
                {dashboard ? <Redirect push to='/dashboard' /> : ''}
                {isKycProcessing ? <Redirect push to='/kycPending' /> : ''}

                {header && !isLoading ? <Header step={0} kycProcessing={true} /> : ''}
                {isLoading
                    ? <LoadingComponent />
                    : <div className="gi-from-section gi-green-base-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="gi-app-complete gi-tree-leaves-bg">
                                        <img src={IMAGES.KYC_PROCESSING_ANIMATION} alt={'loading-cat'} />
                                        {/* <h2>
                                            {IntlMessages('waiting_page_t1', { br: <br /> })}
                                        </h2>
                                        <p className="mt-40">
                                            {IntlMessages('waiting_page_t3')}
                                            <br />
                                            {IntlMessages('waiting_page_t4')}
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </>
        )
    }
}

export default withAuth0(LastDetailsPages)