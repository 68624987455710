import React, { Component } from 'react'
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'

export class MiniMBASection extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-mini-mba-video-02 mb-10">
                        <div className="gi-g-mini-mba-text">
                            <div className="gi-g-cap-card">
                                <div className="gi-g-cap-img">
                                    <img src={IMAGES.MINI_MBA_CAP} alt="" />
                                </div>
                                <div className="gi-g-cap-text">
                                    {IntlMessages('mini_mba_text1', { br: <br /> })}
                                </div>
                            </div>
                            <h3>
                                {IntlMessages('mini_mba')}
                            </h3>
                            <h5>
                                {IntlMessages('mini_mba_text2')}
                            </h5>
                            <p>
                                {IntlMessages('mini_mba_text3')}
                            </p>
                            <button className="gi-g-start-btn mt-40" type="button">
                                <img src={IMAGES.WHITE_RIGHT_ICON} alt="" />
                                {IntlMessages('mini_mba_register')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MiniMBASection