// default library
import React, { Component, Fragment } from 'react'
import { Switch, NavLink, Route, Redirect } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';

//pages
import * as DashboardPage from '../pages/Dashboard'

//component
// import DashboardLogoComponent from '../components/LogoComponent/DashboardLogoComponent';
import DashboardProfile from '../components/ProfileComponent/DashboardProfile';
import LoadingComponent from '../components/LoadingComponent/LoadingComponent';

//ProtectedRoute for dashboard
import ProtectedRoute from './ProtectedRoute';

//image import
import IMAGES from '../config/Images';

//api call
import * as BasketDetails from '../middleware/PortfolioDetails';
import * as GiftDetails from '../middleware/GiftAPI';


// config import
import * as Config from '../config/Config'
import IntlMessages from '../helper/IntlMessages';
import DashboardHeader from '../components/HeaderComponent/DashboardHeader';

export class DashboardRoutes extends Component {
    constructor(props) {
        super(props)

        require('../assets/styles/newCSS/main.scss') //import css
        this.state = {
            isLoading: true, //loading data
            sideMenuOpen: false, //side menu in mobile
            basketNameArray: [], //basket name array
            giftReceiverList: [], //gift route array
            username: '', //user name
        }
    }

    componentDidMount = async () => {
        await this.setState({
            isLoading: true
        })
        // css
        document.body.style.fontFamily = `'Roboto Slab', serif`
        const container = document.querySelectorAll('.container')
        for (let i = 0; i < container.length; i++) {
            container[i].style.width = '1390px'
        }

        await localStorage.removeItem('onBoardingDetails') //remove on boarding details from local storage
        await localStorage.removeItem('boostedEsgcValueIdentifier') //remove boostedEsgcValueIdentifier from local storage
        await this.createBasketRoute()
        await this.updateDashboardProfile()
        if (Config.SHOW_NAVIGATION_GIFT) {
            await this.createGiftRoute() //gift router list
        }
        await this.setState({
            isLoading: false
        })
    }

    // creating basket menu
    createBasketRoute = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        let getBasketList = await BasketDetails.getBasketList(accessToken); //basket summary
        await this.setState({
            // isLoading: false,
            basketNameArray: getBasketList,
        })
    }

    // creating gift menu
    createGiftRoute = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently() //get access token
        let giftReceiverList = await GiftDetails.giftRouter(accessToken); //gift router list

        await this.setState({
            // isLoading: false,
            giftReceiverList: giftReceiverList,
        })
    }

    menuOpen = async () => {
        await this.setState({
            sideMenuOpen: !this.state.sideMenuOpen
        })
    }

    componentWillUnmount = () => {
        this.setState = () => {
            return;
        };
    }

    updateDashboardProfile = async () => {
        const username = await localStorage.getItem('username')
        await this.setState({
            username: username
        })
    }

    render() {
        const { isLoading, sideMenuOpen, basketNameArray, giftReceiverList, username } = this.state
        return (
            <Fragment>
                {/* Header */}
                <DashboardHeader dashboard={true} menuOpen={this.menuOpen} />

                {/* Main section */}
                <section className="gi-g-main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-main-wrapper">
                                    <div className={`gi-g-left-sidebar ${sideMenuOpen ? 'active' : ''}`} id="gi-g-menu-open">
                                        {/* mobile header */}
                                        <div className="gi-g-menu d-block d-md-none">
                                            <div className="gi-g-profile d-flex">
                                                <DashboardProfile shortNameFirst={false} username={username} />
                                            </div>
                                        </div>

                                        {/* current investment */}
                                        <div className="gi-g-menu-new">
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    {/* <a className="nav-link collapsed" aria-current="page" data-bs-toggle="collapse" href="#investmentsCollapse" role="button" aria-expanded="false" aria-controls="investmentsCollapse">
                                                        <span className="nav-icon">
                                                            <img src={IMAGES.HOME_ICON} alt="" />
                                                        </span>
                                                        {IntlMessages('dashboard_current_investment')}
                                                    </a> */}
                                                    <NavLink exact className="nav-link" to={'/dashboard'} activeClassName='active' onClick={this.menuOpen}>
                                                        <span className="nav-icon">
                                                            <img src={IMAGES.HOME_ICON} className="gi-d-icons-inactive" alt="" />
                                                            <img src={IMAGES.HOME_ICON_ACTIVE} className="gi-d-icons-active" alt="" />
                                                        </span>
                                                        {IntlMessages('dashboard_home')}
                                                    </NavLink >
                                                </li>
                                                {/* <div className="collapse" id="investmentsCollapse">
                                                    <div className="collapse-nav small-point">
                                                        <ul>
                                                            //BASKET LISTING
                                                            <span className="sm-point"></span>

                                                            //remove className in navlink
                                                        </ul>
                                                    </div>
                                                </div> */}

                                                <div className="gi-basket-list small-point">
                                                    <ul>
                                                        {basketNameArray.length > 0
                                                            ? basketNameArray?.map((e) => {
                                                                return (
                                                                    e.giftIdentifier
                                                                        ? <li className="nav-item" key={e.id}>
                                                                            <NavLink exact to={`/dashboard/gift/${e.identifier}`} activeClassName='active' onClick={this.menuOpen}>
                                                                                <span className="sm-point"></span>
                                                                                {e.name}
                                                                            </NavLink >
                                                                        </li>
                                                                        : <li className="nav-item" key={e.id}>
                                                                            <NavLink exact to={`/dashboard/basket/${e.id}`} activeClassName='active' onClick={this.menuOpen}>
                                                                                <span className="sm-point"></span>
                                                                                {e.name}
                                                                            </NavLink >
                                                                        </li>
                                                                )
                                                            })
                                                            : ''
                                                        }
                                                    </ul>
                                                </div>
                                            </ul>
                                        </div>

                                        {/* add portfolio and gift */}
                                        <div className="gi-g-menu-new">
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <NavLink exact className="nav-link" to={'/dashboard/add-portfolio'} activeClassName='active' onClick={this.menuOpen}>
                                                        <span className="nav-icon">
                                                            <img src={IMAGES.LINE_CHART_ICON} className="gi-d-icons-inactive" alt="" />
                                                            <img src={IMAGES.LINE_CHART_ICON_ACTIVE} className="gi-d-icons-active" alt="" />
                                                        </span>
                                                        {IntlMessages('dashboard_create_investment')}
                                                    </NavLink >
                                                </li>
                                                {JSON.parse(process.env.REACT_APP_SHOW_NAVIGATION_GIFT)
                                                    ? <Fragment>
                                                        <li className="nav-item">
                                                            <a className="nav-link" href="#!">
                                                                <span className="nav-icon">
                                                                    <img src={IMAGES.HEART_ICON} className="gi-d-icons-inactive" alt="" />
                                                                    <img src={IMAGES.HEART_ICON_ACTIVE} className="gi-d-icons-active" alt="" />
                                                                </span>
                                                                {IntlMessages('dashboard_buy_gift')}
                                                            </a>
                                                        </li>
                                                        <div className="collapse-nav lg-point">
                                                            <ul>
                                                                {giftReceiverList.length > 0
                                                                    ? giftReceiverList.map((e) => {
                                                                        return (
                                                                            !e.isRedeemed
                                                                                ? <li key={e.id} >
                                                                                    <NavLink exact to={`/dashboard/gift/${e.identifier}`} activeClassName='active' onClick={this.menuOpen}>
                                                                                        <span className="lg-point"></span>
                                                                                        {/* {e.giverName}'s Gift */}
                                                                                        New Gift Pending
                                                                                    </NavLink>
                                                                                </li>
                                                                                : ''

                                                                        )
                                                                    })
                                                                    : ''
                                                                }
                                                            </ul>
                                                        </div>
                                                    </Fragment>
                                                    : ''
                                                }
                                            </ul>
                                        </div>

                                        {/* giraffe learn */}
                                        {JSON.parse(process.env.REACT_APP_SHOW_NAVIGATION_LEARN)
                                            ? <div className="gi-g-menu-new">
                                                <ul className="nav flex-column">
                                                    <li className="nav-item">
                                                        <a className="nav-link collapsed" aria-current="page" data-bs-toggle="collapse" href="#learnCollapse" role="button" aria-expanded="false" aria-controls="learnCollapse">
                                                            <span className="nav-icon">
                                                                <img src={IMAGES.LEARN_ICON} className="gi-d-icons-inactive" alt="" />
                                                                <img src={IMAGES.LEARN_ICON_ACTIVE} className="gi-d-icons-active" alt="" />
                                                            </span>
                                                            {IntlMessages('dashboard_learn')}
                                                        </a>
                                                    </li>
                                                    <div className="collapse" id="learnCollapse">
                                                        <div className="collapse-nav small-point">
                                                            <ul>
                                                                <li>
                                                                    <NavLink exact to={`/dashboard/giraffe-view`} activeClassName='active' onClick={this.menuOpen}>
                                                                        <span className="sm-point"></span>{IntlMessages('dashboard_giraffe_learn')}
                                                                    </NavLink >
                                                                </li>
                                                                {process.env.REACT_APP_ENVIRONMENT === 'dev'
                                                                    ? <li>
                                                                        <NavLink exact to={`/dashboard/giraffe-mini-mba`} activeClassName='active' onClick={this.menuOpen}>
                                                                            <span className="sm-point"></span>{IntlMessages('giraffe_mini_mba')}
                                                                        </NavLink >
                                                                    </li>
                                                                    : ''
                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                            : ''
                                        }

                                        {/* gift given */}
                                        <div className="gi-g-menu-new">
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#!">
                                                        <span className="nav-icon">
                                                            <img src={IMAGES.GIFT_ICON} className="gi-d-icons-inactive" alt="" />
                                                            <img src={IMAGES.GIFT_ICON_ACTIVE} className="gi-d-icons-active" alt="" />
                                                        </span>
                                                        {IntlMessages('footer_gifts')}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* taxes and activity */}
                                        <div className="gi-g-menu-new border-bottom-0">
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <NavLink exact className='nav-link no-icon' to={`/dashboard/taxes`} activeClassName='active' onClick={this.menuOpen}>
                                                        {IntlMessages('dashboard_taxes')}
                                                    </NavLink >
                                                    {/* <a className="nav-link no-icon" href="#"> Taxes & Documentation</a> */}
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink exact className='nav-link no-icon' to={`/dashboard/activity`} activeClassName='active' onClick={this.menuOpen}>
                                                        {IntlMessages('dashboard_activity')}
                                                    </NavLink >
                                                    {/* <a className="nav-link no-icon" href="#"> Activity</a> */}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="gi-g-right-sidebar">
                                        {isLoading
                                            ? <LoadingComponent />
                                            : <Switch>
                                                <ProtectedRoute exact path={`/dashboard`} >
                                                    <DashboardPage.HomePage createBasketRoute={this.createBasketRoute} />
                                                </ProtectedRoute>

                                                {basketNameArray.length > 0
                                                    ? basketNameArray?.map((e) => {
                                                        return (
                                                            e.giftIdentifier
                                                                ? <ProtectedRoute exact path={`/dashboard/gift/${e.identifier}`} component={DashboardPage.GiftRedeemPage} key={e.id} />
                                                                : <ProtectedRoute exact path={`/dashboard/basket/${e.id}`} key={e.id} >
                                                                    <DashboardPage.PortfolioPage createBasketRoute={this.createBasketRoute} />
                                                                </ProtectedRoute>
                                                        )
                                                    })
                                                    : ''
                                                }

                                                <ProtectedRoute exact path={`/dashboard/add-portfolio`} component={DashboardPage.AddPortfolioPage} />
                                                <ProtectedRoute exact path={`/dashboard/create-portfolio`}>
                                                    <DashboardPage.CreatePortfolioPage createBasketRoute={this.createBasketRoute} />
                                                </ProtectedRoute>


                                                <ProtectedRoute exact path={`/dashboard/activity`} component={DashboardPage.ActivityPage} />
                                                <ProtectedRoute exact path={`/dashboard/account-setting`} >
                                                    <DashboardPage.AccountSetting updateDashboardProfile={this.updateDashboardProfile} />
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path={`/dashboard/taxes`} component={DashboardPage.TaxesPage} />

                                                {/* mini mba */}
                                                {process.env.REACT_APP_ENVIRONMENT === 'dev'
                                                    ? <ProtectedRoute exact path={`/dashboard/giraffe-mini-mba`} component={DashboardPage.GiraffeMiniMBA} />
                                                    : ''
                                                }

                                                {Config.SHOW_NAVIGATION_GIFT
                                                    ? giftReceiverList.length > 0
                                                        ? giftReceiverList.map((e) => {
                                                            return (
                                                                !e.isRedeemed
                                                                    ? <ProtectedRoute exact path={`/dashboard/gift/${e.identifier}`} key={e.id} >
                                                                        <DashboardPage.GiftPage componentDidMount={this.componentDidMount} />
                                                                    </ProtectedRoute>
                                                                    : ''
                                                            )
                                                        })
                                                        : ''
                                                    : ''
                                                }


                                                {Config.SHOW_NAVIGATION_LEARN
                                                    ? <Fragment>
                                                        <ProtectedRoute exact path={`/dashboard/giraffe-view`} component={DashboardPage.GiraffeViewPage} />
                                                        <ProtectedRoute exact path={`/dashboard/giraffe-learn`} component={DashboardPage.GiraffeLearnPage} />
                                                        <ProtectedRoute exact path={`/dashboard/giraffe-learn-list`} component={DashboardPage.GiraffeLearnListPage} />
                                                    </Fragment>
                                                    : ''
                                                }

                                                <Route path={'*'}>
                                                    <Redirect to='/dashboard' />
                                                </Route>
                                            </Switch>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </Fragment >
        )
    }
}


export default withAuth0(DashboardRoutes)