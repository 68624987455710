import React, { Component } from 'react'
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import { Redirect } from 'react-router-dom';

export class ProtectedRouteOnBoarding extends Component {
    isAuthenticate = ''
    componentDidMount = async () => {
        this.props.step(this.props.path)
    }
    componentDidUpdate = async (previousProps, previousState) => {
        if (previousProps.path !== this.props.path) {
            this.props.step(this.props.path)
        }
    }
    render() {
        this.isAuthenticate = localStorage.getItem('accessToken') !== null ? true : false
        return (
            <>
                {this.isAuthenticate ?
                    <Route
                        component={withAuthenticationRequired(this.component, {
                            onRedirecting: () => <LoadingComponent />,
                        })}
                        {...this.props}
                    />
                    :
                    <Redirect push to={'/'} />
                }

            </>
        )
    }
}

export default ProtectedRouteOnBoarding