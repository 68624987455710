//default library
import React, { Component } from 'react'

// custom component
import PageCount from '../../../components/PageCount/PageCount';
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import SelectComponent from '../../../components/SelectComponent/SelectComponent';
import RightInformation from '../../../components/InformationComponent/RightInformation';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';

//auth0 library
import { withAuth0 } from '@auth0/auth0-react';

// api call
import { getAllCountry } from '../../../middleware/DropdownList'
export class PersonalDetailsPage5 extends Component {

    errorFlag = false;

    constructor(props) {
        super(props)
        this.state = {
            options: [],
            citizenship: ''
        }
    }

    componentDidMount = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        const accessToken = await getAccessTokenSilently()

        const dataCountry = await getAllCountry(accessToken)

        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null) {
            await this.setState({
                citizenship: localStorageData?.citizenship ?? '',
            })
        }

        this.setState({
            options: dataCountry
        })

    }

    setCountryValue = async (citizenship) => {
        await this.setState({
            citizenship: citizenship
        });
    }

    componentWillUnmount = () => {
        this.setState = () => {
            return;
        };
    }

    setDetails = (citizenship) => {
        this.errorFlag = false
        for (let i = 0; i < this.state.options.length; i++) {
            if (this.state.options[i].value === citizenship) {
                this.active = this.state.options[i].active
                this.fieldName = this.state.options[i].label
            }
        }
        this.details = {
            citizenship: citizenship,
            active: this.active,
            countryLabel: this.fieldName
        }
        if (this.active === false) {
            this.errorFlag = true
        }
        if (this.fieldName === 'United States') {
            this.page = 'page7'
        }
        else {
            this.page = 'page6'
        }
    }

    render() {
        const { options } = this.state
        this.setDetails(this.state.citizenship)
        return (
            <>
                {options.length === 0 ? <LoadingComponent /> : ''}
                <div className="gi-from-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="py-3">
                                            <div className="gi-from-left">
                                                <PageCount count={5} pageName={'Personal details'} />

                                                <div className="gi-input gi-select mb-40">
                                                    <div>
                                                        <LabelComponent name={'citizenship'} labelName={'personal_page5_label'} />
                                                        {options.length !== 0
                                                            ? <SelectComponent name={'citizenship'} option={options} placeholder={'placeholder_country'} selectValue={this.setCountryValue} />
                                                            : ''
                                                        }
                                                    </div>

                                                    <ErrorComponent message={'error_invalid_citizenship'} display={this.errorFlag ? 'block' : 'none'} />
                                                </div>
                                                <div className="gi-btn-group d-none d-sm-flex">
                                                    <ButtonComponent value={this.details} previousRouting={'page4'} nextRouting={this.page} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="gi-from-right">
                                            <RightInformation info1={'personal_detail_info1'} info2={'personal_detail_info2'} />

                                            <div className="gi-btn-group d-flex d-sm-none">
                                                <ButtonComponent value={this.details} previousRouting={'page4'} nextRouting={this.page} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withAuth0(PersonalDetailsPage5)