import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';

//session timeout library
import IdleTimer from "react-idle-timer";

//Auth0 library
import { withAuth0 } from "@auth0/auth0-react";

//config
import { COUNTDOWN, DELAY, SESSION_EXPIRED } from '../../config/Config';

//image import
import IMAGES from '../../config/Images';

import { Fragment } from 'react';
import IntlMessages from '../../helper/IntlMessages';

let countdownInterval; //count down interval
let timeout; //timeout 
let interval;

export class SessionTimeout extends Component {

    constructor(props) {
        super(props)
        require('../../assets/styles/newCSS/main.scss') //import css
        this.state = {
            countdownModalOpen: false,
            timeoutModalOpen: false,
            timeoutCountdown: 0,
            isAuthenticated: this.props.isAuthenticated
        };
        this.idleTimer = null
        this.onAction = this.onAction.bind(this)
        this.onActive = this.onActive.bind(this)
        this.onIdle = this.onIdle.bind(this)
    };

    // load data when component load
    componentDidMount = async () => {
        const isAuthenticate = JSON.parse(localStorage.getItem('isAuthenticate'))
        // if user is not authenticate
        if (!isAuthenticate) {
            this.sessionLogout() //logout 
        }

        // check session expire timeout every 5 sec 
        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem('expiredTime'))
            if (expiredTime < Date.now() && isAuthenticate) {
                this.handleClose() //logout 
                clearInterval(this.interval)
            }
        }, 2000)


    }

    // session logout 
    sessionLogout = () => {
        const { logout } = this.props.auth0
        this.setState({
            timeoutModalOpen: false,
        })
        logout({
            returnTo: window.location.origin, //return login page
        });
        localStorage.clear() //clear localStorage
    }

    // clear session timeout 
    clearSessionTimeout = () => {
        clearTimeout(timeout); //clear timeout
    };

    // clear interval of session 
    clearSessionInterval = () => {
        clearInterval(countdownInterval);//clear countdownInterval
    };

    // session expire
    handleClose = () => {
        clearInterval(interval)
        this.clearSessionInterval(); //clear session interval
        this.clearSessionTimeout(); //clear session timeout
        this.setState({
            countdownModalOpen: false,
            timeoutModalOpen: true,
        })
        localStorage.setItem('isAuthenticate', false) //set isAuthenticate flag false
    }

    // continue session
    handleContinue = () => {
        this.clearSessionInterval(); //clear session interval
        this.clearSessionTimeout(); //clear session timeout
        this.setState({
            countdownModalOpen: false,
        })
        this.componentDidMount()
    };

    // call when user do something on screen
    onAction = () => {
        localStorage.setItem('expiredTime', (Date.now() + (SESSION_EXPIRED + 12 * 1000)))
    };

    // call when continue there session
    onActive = () => {
        this.clearSessionTimeout(); //clear session timeout
    };

    // call when user inactive 
    onIdle = () => {
        const { countdownModalOpen, isAuthenticated, timeoutModalOpen } = this.state
        if (isAuthenticated && !countdownModalOpen) {
            timeout = setTimeout(() => {
                let countDown = COUNTDOWN; //countdown
                this.setState({
                    countdownModalOpen: timeoutModalOpen ? false : true,
                    timeoutCountdown: countDown
                })
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        this.setState({
                            timeoutCountdown: --countDown
                        })
                    } else {
                        this.handleClose(); //countdown 0 then logout
                    }
                }, DELAY);
            }, DELAY);
        }
    };

    render() {
        const { countdownModalOpen, timeoutModalOpen, timeoutCountdown } = this.state
        return (
            <Fragment>
                {/* session handler */}
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onAction={this.onAction}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={SESSION_EXPIRED}
                />

                {/* countDown modal */}
                <Modal
                    centered
                    show={countdownModalOpen}
                    backdrop="static"
                    keyboard={false} className="session-time-out-modal"
                >
                    <Modal.Body>
                        <button className="btn-close" type="button" onClick={this.handleContinue} aria-label="Close"></button>
                        <div className="gi-fund-modal-body countdown-modal-body">
                            <div className='mb-40 text-center'>
                                <h5>
                                    {IntlMessages('session_timeout_t1')}
                                    &nbsp;
                                    <span className='red-text'>
                                        {timeoutCountdown}
                                    </span>
                                    &nbsp;
                                    {IntlMessages('session_timeout_t2')}
                                </h5>
                            </div>
                            <div className="gi-sell-btn-group d-flex justify-content-center">
                                <button className="ok-btn" type="button" onClick={this.sessionLogout}>
                                    {IntlMessages('logout')}
                                </button>
                                <button className="ok-btn" type="button" onClick={this.handleContinue}>
                                    {IntlMessages('button_continue')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* session timeout modal */}
                <Modal
                    centered
                    show={timeoutModalOpen}
                    backdrop="static"
                    keyboard={false} className="session-time-out-modal">
                    <Modal.Body>
                        <button className="btn-close" type="button" onClick={this.sessionLogout} aria-label="Close"></button>
                        <div className="gi-fund-modal-body">
                            <div className="gi-session-text">
                                <div className="gi-session-hourglass">
                                    <img src={IMAGES.HOURGLASS_ANIMATION} alt="" />
                                </div>
                                {/* <div className="gi-session-logo">
                                    <img src={IMAGES.TIME_OUT_LOGO} alt="" />
                                </div> */}
                                <h5>
                                    {IntlMessages('session_timeout2')}
                                </h5>
                                <p>
                                    {IntlMessages('session_timeout_t3')}
                                </p>
                                <div className="text-center">
                                    <button className="ok-btn" type="button" onClick={this.sessionLogout}>
                                        {IntlMessages('button_confirm')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }
}

export default withAuth0(SessionTimeout)