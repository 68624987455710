import React, { Component, Fragment } from 'react'
import { currencyFormatter } from '../../service/utils/CurrencyFormatter'

//import processing image
// import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'

export class BasketDetailsCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentValue: props.basketDetails.currentValue,
            futureProjection: currencyFormatter(props.basketDetails.twentyYearProjection),
            earning: props.basketDetails.totalGain === 0 ? '-' : currencyFormatter(props.basketDetails.totalGain),
            isPending: props.basketDetails.isPending,
        }
    }

    render() {
        const { currentValue, futureProjection, earning, } = this.state
        // const { basketName } = this.props

        return (
            <>
                {/* {isPending
                    ? <div className="gi-d-processing-card mb-20">
                        <div className="gi-d-processing-img">
                            <img src={IMAGES.PROCESSING_BAR} alt="" />
                        </div>
                        <div className="gi-d-processing-text">
                            {IntlMessages('home_processing', { basketName: <strong> {basketName} </strong> })}
                        </div>
                    </div>
                    :  */}
                <Fragment>
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-d-cb-lg">
                                <span>
                                    {IntlMessages('transaction_current')}
                                </span>
                                <h5>
                                    <i aria-hidden="true">$</i>
                                    {currencyFormatter(currentValue).replace('$', '')}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-20">
                        <div className="col-sm-6">
                            <div className="gi-d-cb-sm">
                                <span>
                                    {IntlMessages('transaction_projection')}
                                </span>
                                <h5>
                                    {futureProjection}
                                </h5>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="gi-d-cb-sm">
                                <span>
                                    {IntlMessages('transaction_earning')}
                                </span>
                                <h5>
                                    {earning}
                                </h5>
                            </div>
                        </div>
                    </div>
                </Fragment>
                {/* } */}
            </>


        )
    }
}

export default BasketDetailsCard