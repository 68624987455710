//drive wealth home page url
export const DRIVE_HOME_PAGE = process.env.REACT_APP_DRIVE_HOME_PAGE;

//Data URL's
const DATA_BASE_URL = process.env.REACT_APP_DATA_BASE_URL;
export const GET_COUNTRY = DATA_BASE_URL + '/user/country/getAll';
export const GET_ESGC_VALUE = DATA_BASE_URL + '/user/esgcvalue/getAllLocalized'
export const GET_COUNTRY_IDENTIFIER = DATA_BASE_URL + '/user/country/getByIdentifier'

//Dashboard URL's
const DASHBOARD_BASE_URL = process.env.REACT_APP_DASHBOARD_BASE_URL;
export const TRANSFER_FUND_URL = DASHBOARD_BASE_URL + '/transferFund';
export const PORTFOLIO_SUMMARY_URL = DASHBOARD_BASE_URL + '/getPortfolioSummary';
export const CHECK_FUND_TRANSFER_URL = DASHBOARD_BASE_URL + '/isFundTransferPending';
export const GET_FUND_LOCATION = DASHBOARD_BASE_URL + '/getFundLocations';
export const GET_CASH_BALANCE = DASHBOARD_BASE_URL + '/getCashBalance';
export const GET_TRANSACTION = DASHBOARD_BASE_URL + '/getTransactions';
export const GET_INVESTING_HISTORY = DASHBOARD_BASE_URL + '/getInvestingHistory';
export const GET_DIVIDENDS = DASHBOARD_BASE_URL + '/getDividends';
export const GET_ADVISORY_FEES = DASHBOARD_BASE_URL + '/getAdvisoryFees';
export const BASKET_LIST = DASHBOARD_BASE_URL + '/getBasketBasicDetailsByUser';
export const GET_GIFT_IDENTIFIER = DASHBOARD_BASE_URL + '/getGiftDetailByGiftIdentifier';

//DriveWealth URL's
const DRIVE_WEALTH_BASE_URL = process.env.REACT_APP_DRIVE_WEALTH_BASE_URL;
export const DISCLOSURE_URL = DRIVE_WEALTH_BASE_URL + '/user/getDisclosureUrl';
export const EMPLOYMENT_STATUS = DRIVE_WEALTH_BASE_URL + '/employmentstatus/getAllLocalized';
export const INDUSTRY_LIST = DRIVE_WEALTH_BASE_URL + '/employmenttype/getAllLocalized';
export const EMPLOYMENT_POSITION = DRIVE_WEALTH_BASE_URL + '/employmentposition/getAllLocalized';
export const INVESTOR_EXPERIENCE = DRIVE_WEALTH_BASE_URL + '/investorexperience/getAllLocalized';
export const INVESTOR_RISK_TOLERANCE = DRIVE_WEALTH_BASE_URL + '/investorrisktolerance/getAllLocalized';
export const GET_STATEMENT = DRIVE_WEALTH_BASE_URL + '/statements/getStatementByUser';
export const GET_TAX_STATEMENT = DRIVE_WEALTH_BASE_URL + '/statements/getTaxStatementByUser';
export const GET_TRADE_CONFIRMATION = DRIVE_WEALTH_BASE_URL + '/statements/getTradeConfirmationByUser';
export const PDF_DOWNLOAD = DRIVE_WEALTH_BASE_URL + '/statements/getFileUrlByUser'

//User URL's
const USER_BASE_URL = process.env.REACT_APP_USER_BASE_URL;
export const REGISTER = USER_BASE_URL + '/register';
export const GET_BY_ACCESS_TOKEN = USER_BASE_URL + '/getByAccessToken';
export const USER_DATA_UPDATE = USER_BASE_URL + '/update';
export const USER_DETAIL = USER_BASE_URL + '/getDetail';
export const BANK_LIST_URL = USER_BASE_URL + '/userbankaccount/getAllByAccessToken';
export const BANK_ACCOUNT_LINKED = USER_BASE_URL + '/userbankaccount/isBankAccountLinked';
export const BANK_ACCOUNT_UNLINKED = USER_BASE_URL + '/userbankaccount/unlink';
export const SEND_OTP = USER_BASE_URL + '/sendOtp'
export const VERIFY_OTP = USER_BASE_URL + '/validateOtp'
export const RESEND_EMAIL = USER_BASE_URL + '/auth0/resendEmail'

//Basket URL's
const BASKET_BASE_URL = process.env.REACT_APP_BASKET_BASE_URL
export const GET_BASKET_COMPANY_COUNT = BASKET_BASE_URL + '/getBasketCompanyCount'
export const GET_BASKET_CREATE_SUMMARY = BASKET_BASE_URL + '/getCreateBasketSummary'
export const CREATE_BASKET = BASKET_BASE_URL + '/createBasket'
export const GET_BASKET_DETAIL = BASKET_BASE_URL + '/getBasketDetail'
export const PLACE_BASKET_ORDER = BASKET_BASE_URL + '/placeBasketOrder'
export const BASKET_ORDER_PENDING = BASKET_BASE_URL + '/isBasketOrderPending';
export const GET_HISTORICAL_PROJECTION = BASKET_BASE_URL + '/getBasketPerformanceHistorical';
export const GET_FUTURE_PROJECTION = BASKET_BASE_URL + '/getBasketPerformanceProjection';
export const REDEEM_GIFT = BASKET_BASE_URL + '/redeemGift';
export const REDEEM_GIFT_DETAILS = BASKET_BASE_URL + '/getGiftBasketDetail';
export const RENAME_BASKET = BASKET_BASE_URL + '/renameBasket';
export const BASKET_CASH_OUT = BASKET_BASE_URL + '/placeBasketCashOutOrder';

//Gift URL's
const GIFT_BASE_URL = process.env.REACT_APP_GIFT_BASE_URL
export const GET_GIFT_DETAIL = GIFT_BASE_URL + '/user/gift/giftLandingDetail'
export const UPDATE_GIFT_USER = GIFT_BASE_URL + '/user/giftaccessuser/updateGiftUser'
export const GIFT_ROUTE = GIFT_BASE_URL + '/user/giftaccessuser/getGiftUserByAccessToken'

// Giraffe Learn URL's
const GIRAFFE_LEARN_BASE_URL = process.env.REACT_APP_GIRAFFE_LEARN
export const USER_CONTENT = GIRAFFE_LEARN_BASE_URL + '/user/get-user-content'
export const USER_CALENDAR = GIRAFFE_LEARN_BASE_URL + '/user/get-user-calendar'
export const USER_VIDEO = GIRAFFE_LEARN_BASE_URL + '/user/user-video'
export const USER_ANY_CONTENT = GIRAFFE_LEARN_BASE_URL + '/any/get-any-content'

//Plaid URL's
export const PLAID_BASE_URL = process.env.REACT_APP_PLAID_BASE_URL;

export const PLAID_CREATE_TOKEN = PLAID_BASE_URL + '/user/createToken'
export const PLAID_GENERATE_TOKEN = PLAID_BASE_URL + '/user/generateProcessorToken'