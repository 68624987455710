import moment from "moment";
import { removeUndefined } from "./RemoveUndefineValue";

export const DateFilter = async (dropdownValue, currentData) => {
    let filterData = []
    let date = new Date(Date.now());
    if (dropdownValue === 'past-week') {
        const date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)
        filterData = await removeUndefined(currentData.map((e, i) => {
            if (new Date(e.date).getTime() >= date2.getTime()) {
                return e
            }
            else {
                return null
            }

        }))
    }
    else if (dropdownValue === 'three-month') {
        const date2 = new Date(date.getFullYear(), date.getMonth() - 3, date.getDate())
        filterData = await removeUndefined(currentData.map((e, i) => {
            if (new Date(e.date).getTime() >= date2.getTime()) {
                return e
            }
            else {
                return null
            }

        }))
    }
    else if (dropdownValue === 'six-month') {
        const date2 = new Date(date.getFullYear(), date.getMonth() - 6, date.getDate())
        filterData = await removeUndefined(currentData.map((e, i) => {
            if (new Date(e.date).getTime() >= date2.getTime()) {
                return e
            }
            else {
                return null
            }

        }))
    }
    else if (dropdownValue === 'one-year') {
        const date2 = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate())
        filterData = await removeUndefined(currentData.map((e, i) => {
            if (new Date(e.date).getTime() >= date2.getTime()) {
                return e
            }
            else {
                return null
            }

        }))
    }
    else {
        filterData = currentData
    }
    return filterData
}

export const DateRange = async (dropdownValue) => {
    let currentDate = new Date(Date.now());

    let startDate = ''
    let endDate = moment(new Date(Date.now())).format('YYYY-MM-DD')

    if (dropdownValue === 'past-week') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()-7)).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'one-month') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'three-month') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'six-month') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'ytd') {
        startDate = moment(new Date(currentDate.getFullYear(), 0, 1)).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'one-year') {
        startDate = moment(new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'two-year') {
        startDate = moment(new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'all-time') {
        startDate = ''
        endDate = ''
    }

    return (
        {
            'startDate': startDate,
            'endDate': endDate
        }
    )
}