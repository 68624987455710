//default library
import React, { Component, Fragment } from 'react'

//import image
import IMAGES from '../../config/Images'

import IntlMessages from '../../helper/IntlMessages'


export class GiraffeMiniMbaSection extends Component {
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="gi-g-mini-mba-video mb-10">
                            <div className="gi-g-mini-mba-text">
                                <h6>
                                    {IntlMessages('giraffe_mini_mba_text1')}
                                </h6>
                                <h3>
                                    {IntlMessages('giraffe_mini_mba')}
                                </h3>
                                <h5>
                                    {IntlMessages('giraffe_mini_mba_text2')}
                                </h5>
                                <p>
                                    {IntlMessages('giraffe_mini_mba_text3')}
                                </p>
                                <button className="gi-g-start-btn mt-40" type="button">
                                    <img src={IMAGES.WHITE_RIGHT_ICON} alt="" />
                                    {IntlMessages('giraffe_mini_mba_register')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GiraffeMiniMbaSection