//default library
import React, { Component, Fragment } from 'react';
//custom component
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import SellDropdown from '../../components/ButtonComponent/SellDropdown';
import BasketLogoComponent from '../../components/LogoComponent/BasketLogoComponent';
import ErrorPendingModal from '../../components/ModalComponent/ErrorPendingModal';
import BuyButton from '../../components/ButtonComponent/BuyButton';
import FundModal from '../../components/ModalComponent/FundModal';
import SellModal from '../../components/ModalComponent/SellModal';
import CompanyCountCard from '../../components/CardComponent/CompanyCountCard';
import BasketDetailsCard from '../../components/CardComponent/BasketDetailsCard';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import InputComponentText from '../../components/InputComponent/InputComponentText';
import CompanyDetailSection from '../../components/SectionComponent/CompanyDetailSection';
import ChartDetailSection from '../../components/SectionComponent/ChartDetailSection';
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'
import ErrorComponent from '../../components/ErrorComponent/ErrorComponent';
import BankConnectModal from '../../components/ModalComponent/BankConnectModal';

//api call
import * as TransferFundCall from '../../middleware/TransferFundCall';
import * as CreateBasket from '../../middleware/CreateBasket';
import * as User from '../../middleware/User';
import { bankLinkService } from '../../service/ApiService/BankService';

//custom function
import { DateRange } from '../../service/utils/DateFilter';
import IntlMessages from '../../helper/IntlMessages';


import IMAGES from '../../config/Images';
import * as Config from '../../config/Config';

// auth0
import { withAuth0 } from '@auth0/auth0-react';

export class PortfolioPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true, //for loading
      openBuyModelFund: false, //for open buy modal
      openModelError: false, //for open error modal

      isBucketCard: false, //refresh bucket card
      basketAmountPending: false, //show or hide edit and sell button
      basketId: window.location.pathname.split('/')[3], //fetch basket id
      investId: '', //invest button id
      openSellModal: false, //open sell modal
      editButtonShow: true,
      isFunding: false, //fund pending is check
      cashBalance: false, //check cash balance from total account

      companySummary: '', //basket summary data
      expenseRatio: '', //expense ratio summary data
      basketDetails: '', //all basket details

      sellMessage: '', //pending message for sell
      getFundLocation: [],

      basketName: '', //rename basket
      chartValue: {}, //value for chart component
      companyDetail: {}, //value for chart component

      userName: '', //user name
      bankMessage: '', //bank label message
      openBankModal: false, //bank modal
      plaidData: {
        customerFirstName: '',
        customerMiddleName: '',
        customerLastName: '',
        customerPhoneNumber: '',
        customerEmail: '',
        customerAddress: '',
      } //user data for plaid
    }
  }


  componentDidMount = async () => {
    const basketId = this.state.basketId //store basket id
    let sellMessage = '';

    const { getAccessTokenSilently } = this.props.auth0
    const accessToken = await getAccessTokenSilently()

    await this.setState({
      isLoading: true,
      basketDetails: '',
    })
    window.scrollTo(0, 0) //scroll to top

    let dateRange = await DateRange('one-month')//set default value date range

    let [
      basketDetails,
      historicalData,
      futureData,
      // cashBalance,
      isBuyOrderPending,
      isFunding,
      getFundLocation,
      bankConnect,
      userData,
    ] = await Promise.all([
      CreateBasket.getBasketDetails(accessToken, basketId), //basket detail for 
      CreateBasket.getHistoricalProjection(accessToken, basketId, dateRange), //get data for historical chart
      CreateBasket.getFutureProjection(accessToken, basketId, '15'), //get data for future chart
      // TransferFundCall.getCashBalance(), //check total amount in from cash account
      TransferFundCall.isOrderBuyPending(accessToken), //check buy order is pending or not
      TransferFundCall.checkFundTransfer(accessToken), //check funding is pending or not
      TransferFundCall.getFundLocations(accessToken), //get list of basket and account
      bankLinkService(accessToken), //check bank connect
      User.getUserData(accessToken) //get user data for plaid
    ])

    let historicalChartData = historicalData.map((e) => {
      return ({
        x: e.performanceDate,
        y: e.gain
      })
    })

    let seriesHistorical = [{
      name: basketDetails.name,
      data: historicalChartData
    }] //create data for historical chart

    let futureDataActual = await futureData.map((e) => {
      return ({
        x: e.year,
        y: e.growthRate
      })
    })
    let futureDataMin = await futureData.map((e) => {
      return ({
        x: e.year,
        y: e.minGrowthRate
      })
    })
    let futureDataMax = await futureData.map((e) => {
      return ({
        x: e.year,
        y: e.maxGrowthRate
      })
    })

    let seriesFuture = [
      {
        name: `${basketDetails.name} Max`,
        data: futureDataMax
      },
      {
        name: `${basketDetails.name}`,
        data: futureDataActual
      },
      {
        name: `${basketDetails.name} Min`,
        data: futureDataMin
      }
    ] //create data for future chart

    //error message for sell button
    if (isFunding.isPending) {
      if (isFunding.transferType === 'BANK_TO_BASKET') {
        sellMessage = ['sell_deposit_message', { type: 'bank to basket transfer', amount: `$${isFunding.transferAmount}` }]
      }
      else {
        sellMessage = ['sell_deposit_message', { type: isFunding.transferType.toLowerCase(), amount: `$${isFunding.transferAmount}` }]
      }
    }
    else if (isBuyOrderPending.isPending) {
      sellMessage = ['sell_withdraw_message', { type: isBuyOrderPending.orderType.toLowerCase(), basketName: isBuyOrderPending.basketName }]
    }

    const chartValue = {
      totalCompanies: basketDetails?.companySummary.totalCompanies,
      excludedCompanies: basketDetails?.companySummary.excludedCompanies,
      totalIndustry: basketDetails?.companySummary.totalIndustries,
    }
    const companyDetail = {
      totalCompanies: basketDetails?.companySummary.totalCompanyList,
      excludedCompanyList: basketDetails?.companySummary.excludedCompanyList,
      expenseRatio: basketDetails?.expenseRatio
    }

    const userAddress = userData.userAddress
    const plaidData = {
      customerFirstName: userData.firstNameEncrypted,
      customerMiddleName: userData.middleNameEncrypted,
      customerLastName: userData.lastNameEncrypted,
      customerPhoneNumber: userData.phoneEncrypted,
      customerEmail: localStorage.getItem('userEmailId'),
      customerAddress: `${userAddress.street1} ${userAddress.city} ${userAddress.province} ${userAddress.postalCode}`,
    }

    await this.setState({
      basketAmountPending: isBuyOrderPending.isPending, //active and disabled invest portfolio button
      editButtonShow: basketDetails.currentValue || basketDetails.pendingValue, //toggle edit and sell button
      basketDetails: basketDetails, //basket details array
      companySummary: basketDetails.companySummary, //company details
      isLoading: false,
      isFunding: isFunding.isPending,
      seriesHistorical: seriesHistorical, //historical data
      seriesFuture: seriesFuture, //future data
      // cashBalance: cashBalance === 0,
      sellMessage: sellMessage,
      expenseRatio: basketDetails.expenseRatio ?? [],
      getFundLocation: getFundLocation,
      chartValue: chartValue,
      companyDetail: companyDetail,
      bankConnect: bankConnect, //set bank link flag
      plaidData: plaidData, //plaid data
      userName: userData.name, //set username
    })
  }

  //is fund transfer is pending at that time error modal will open else fund transfer modal is open
  setBuyOpenModal = async () => {
    const { bankConnect } = this.state
    await this.setState({
      openBuyModelFund: bankConnect,
      openBankModal: !bankConnect,
      bankMessage: 'bank_text1',
      isLoading: false
    })
  }

  //close fund transfer modal
  closeModal = async (value) => {
    await this.setState({
      openBuyModelFund: value,
    })
  }

  //close error modal
  closeErrorModal = async (value) => {
    await this.setState({
      openBuyModelFund: value,
      openSellModal: value,
    })
  }

  //get basket id when invest button click
  buyModalOpenId = async (value) => {
    await this.setState({
      investId: Number(value)
    })
  }

  //open sell modal
  openSellModal = async (value) => {
    await this.setState({
      openSellModal: value
    })
  }

  closeSellModal = async (value) => {
    await this.setState({
      openSellModal: value,
    })
  }

  // change basket name
  changeBasketName = async (value) => {
    await this.setState({
      basketName: value
    })
  }

  renameBasket = async () => {
    const { basketName, basketDetails } = this.state
    const { getAccessTokenSilently } = this.props.auth0
    const basket = {
      identifier: basketDetails.identifier,
      name: basketName
    }
    await this.setState({
      isLoading: true,
      basketName: ''
    })
    const accessToken = await getAccessTokenSilently() //get access token
    await CreateBasket.renameBasket(accessToken, basket) //rename basket
    await this.componentDidMount() //call api again
    await this.props.createBasketRoute() //rename in navigation
  }

  //close bank modal
  closeBankModal = async () => {
    await this.setState({
      openBankModal: false,
    })
  }

  render() {
    const { openBuyModelFund, basketName,
      basketAmountPending, basketId, openSellModal, userName, bankMessage, openBankModal, plaidData,
      companySummary, basketDetails, isLoading, isFunding,
      sellMessage, getFundLocation, chartValue, companyDetail, seriesHistorical, seriesFuture } = this.state

    return (
      <Fragment>
        {/* loading while component initialize */}
        {isLoading
          ? <LoadingComponent />
          : <Fragment>
            {/* page header */}
            <div className="row">
              <div className="col-12">
                <div className="gi-gift-heading">
                  <PageHeader h5={`home_heading`} strong={`hello`} userName={`${localStorage.getItem('firstName')}`} />
                </div>
              </div>
            </div>

            {/* content */}
            <div className="row">
              <div className="col-12">
                <div className="gi-g-right-sidebar-main">
                  {/* actual content */}
                  <div className="gi-g-right-sidebar-left">
                    <div className="gi-g-details-card-left">
                      {/* transaction card */}
                      <div className="row">
                        <div className="col-12">
                          <div className="gi-d-cb-card mb-10">
                            {/* basket name and button*/}
                            <div className="row">
                              <div className="col-12">
                                <div className="gi-d-cb-header">
                                  {/* basket name */}
                                  <div className="gi-d-cb-heading">
                                    {/* <div className="dropdown change-dropdown"> */}
                                    <div className="change-dropdown">
                                      <button className="gi-g-vertical-ellipsis-icon" type="button" id="change-dropdown-new" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={IMAGES.VERTICAL_WHILE_ICON} alt="" />
                                      </button>
                                      <ul className="dropdown-menu" aria-labelledby="change-dropdown-new">
                                        <div className="change-card">
                                          <label>
                                            {IntlMessages('basket_change_name')}
                                          </label>
                                          <InputComponentText name={'basket_name'} placeholder={'placeholder_change_name'} inputValue={this.changeBasketName} />
                                          <ErrorComponent message={['error_max_length', { number: Config.MAX_NUMBER_16 }]} dashboard={true} display={basketName.length > Config.MAX_NUMBER_16 ? 'block' : 'none'} />
                                          <ErrorComponent message={'error_basket_name'} dashboard={true} display={basketName && basketName.match(Config.WHITESPACE) ? 'block' : 'none'} />
                                          <button type="button" onClick={this.renameBasket} disabled={!(basketName.length > 1 && basketName.length <= Config.MAX_NUMBER_16) || basketName.match(Config.WHITESPACE)}>
                                            {IntlMessages('button_change')}
                                          </button>
                                        </div>
                                      </ul>
                                    </div>
                                    {basketDetails.name}
                                  </div>
                                  {/* button */}
                                  <div className="btn-group">
                                    <SellDropdown openSellModal={this.openSellModal} disabled={!(basketDetails.currentValue > 0)} />
                                    <BuyButton openBuyModel={this.setBuyOpenModal} basketPage={true} disabled={basketAmountPending || isFunding} id={basketId} buyModalOpenId={this.buyModalOpenId} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* basket amount */}
                            {basketDetails ? <BasketDetailsCard basketDetails={basketDetails} basketName={basketDetails.name} /> : ''}
                          </div>
                        </div>
                      </div>

                      {/* chart */}
                      <div className="row">
                        <div className="col-12">
                          <div className="gi-cb-graph-card mb-10">
                            {seriesHistorical && seriesHistorical
                              ? <ChartDetailSection
                                seriesHistorical={seriesHistorical}
                                seriesFuture={seriesFuture}
                                basketId={basketId}
                                basketDetails={basketDetails}
                              />
                              : ''
                            }
                          </div>
                        </div>
                      </div>

                      {/* index breakdown */}
                      <div className="row">
                        <div className="col-12">
                          <div className="gi-cb-esgc-card mb-10">
                            <div className="gi-cb-esgc-card-main">
                              {/* your value */}
                              {basketDetails ? <BasketLogoComponent basketValue={basketDetails.esgcValueList} /> : ''}
                              {/* chart & index breakdown*/}
                              {companySummary ? <CompanyCountCard companySummary={companySummary} /> : ''}
                              {/*  */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* company table */}
                      <div className="row">
                        <div className="col-12">
                          <div className="gi-b-table-card mb-10">
                            <CompanyDetailSection companyDetail={companyDetail} chartValue={chartValue} esgcValueExcluded={basketDetails?.esgcValueList} basketPage={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* banner */}
                  <div className="gi-g-right-sidebar-right">
                    <GiraffeLearnBannerSection />

                  </div>
                </div>
              </div>
            </div>

            {/* Bank connect modal */}
            <BankConnectModal userName={userName} openBankModal={openBankModal} closeBankModal={this.closeBankModal} componentDidMount={this.componentDidMount} plaidData={plaidData} message={bankMessage} />

            {/* if bank account is not account connect */}
            {/* <BuyModal openBuyModelFund={openBuyModelFund} closeModal={this.closeModal} componentDidMount={this.componentDidMount} investId={investId} getFundLocation={getFundLocation} /> */}
            {!basketAmountPending
              //  Buy modal
              ? <FundModal openFundModel={openBuyModelFund} closeModal={this.closeModal} componentDidMount={this.componentDidMount} getFundLocation={getFundLocation} />
              // if fund in pending state then open error modal
              : <ErrorPendingModal openModelError={openBuyModelFund} closeErrorModal={this.closeErrorModal} h3={sellMessage} label={'error_try_again'} image={IMAGES.UNLINK_BANK_SVG} />
            }

            {!(basketAmountPending || isFunding)
              ? <SellModal openSellModal={openSellModal} closeModal={this.closeSellModal} componentDidMount={this.componentDidMount} getFundLocation={getFundLocation} />
              // if fund in pending state then open error modal
              : <ErrorPendingModal openModelError={openSellModal} closeErrorModal={this.closeErrorModal} h3={sellMessage} label={'error_try_again'} image={IMAGES.UNLINK_BANK_SVG} />
            }
          </Fragment>
        }
      </Fragment>
    )
  }
}

export default withAuth0(PortfolioPage)