//default library
import React, { Component, Fragment } from 'react'

//image import
import IMAGES from '../../config/Images'

import IntlMessages from '../../helper/IntlMessages'

export class AchievementSection extends Component {
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="gi-g-achievements-card mb-10">
                            <h3>
                                {IntlMessages('giraffe_learn_achievement_text1')}
                            </h3>
                            <p>
                                {IntlMessages('giraffe_learn_achievement_text2')}
                            </p>
                            <img src={IMAGES.EXPERT_ACHIEVEMENT} className="img-fluid" alt="achievementsImg" />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default AchievementSection