//age caluculator
export default function AgeCalculator(dateOfBirth) {
    let dateNow = new Date() //store current date
    //check current year is leap 
    let isLeap = (year) => {
        return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    }

    //get days of month
    let days = Math.floor((dateNow.getTime() - dateOfBirth.getTime()) / 1000 / 60 / 60 / 24);
    
    //set default age is 0
    let age = 0;

    for (let y = dateOfBirth.getFullYear(); y <= dateNow.getFullYear(); y++) {
        let daysInYear = isLeap(y) ? 366 : 365;
        if (days >= daysInYear) {
            days -= daysInYear;
            age++;
        }
    }
    return age
}