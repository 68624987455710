/* 
    Giraffe Language Texts
*/

module.exports = {

    /* Button Name */
    'button_next': 'Next',
    'button_ok': 'OK',
    'button_continue': 'Continue',
    'button_verify': 'Verify',
    'button_cancel': 'Cancel',
    'button_update': 'Update',
    'button_confirm': 'Confirm',
    'button_transfer': 'Transfer',
    'button_submit': 'Submit',
    'button_contact_us': 'Contact Us',
    'button_fund': `Fund your account`,
    'button_buy_fund': 'Transfer',
    'button_buy_invest': 'Invest',
    'button_sell': 'Sell',
    'button_view_details': `View details`,
    'button_invest': `Invest in portfolio`,
    'button_create': `Create`,
    'button_close': `Close`,
    'button_connect': `Connect`,
    'button_back': `Back`,
    'button_change': `Change`,

    /* Common text */
    'logout': 'Logout',
    'logout2': 'Log out',
    'click_here': 'Click here',
    'of': 'of',
    'resend_code': 'Resend code',
    'common_verify': 'Verified!',
    'common_verify2': '(verified)',
    'thank_you': `Thank you`,
    'unlink': `Unlink`,
    'edit': 'Edit',
    'hello': `Hello`,
    'note': `Note`,
    'minimum': `minimum`,
    'from': `From`,
    'page': `Page :`,
    'previous': `Previous`,
    'next': `Next`,
    'week': `Week`,
    'faqs': `FAQs`,
    'loading': 'Loading...',
    'gift': `Gift`,
    'total': `Total`,
    'complete': `Complete`,
    'to': `to`,
    'from2': `from`,

    /* Symbol */
    '$': '$',
    '%': '%',

    /* Header and Footer */
    'header_login': `Login`,
    'header_register': `Register`,
    'header_rtl': `Left to Right`,
    'header_ltr': `Right to Left`,
    'footer_giraffe': `What is Giraffe 500?`,
    'footer_products': `Products`,
    'footer_about': `About`,
    'footer_blog': `Blog`,
    'footer_business': `For Business`,
    'footer_education': `Education`,
    'footer_gifts': `Gifts`,

    /* Placeholder */
    'placeholder_type_here': 'Type Here',
    'placeholder_auto_select': 'Street address, city, state',
    'placeholder_apartment_unit': 'Apartment, suite or unit number',
    'placeholder_country': 'Country',
    'placeholder_email': 'Email Address',
    'placeholder_ticker': 'zzzxxx',
    'placeholder_investing_exp': 'Investing experience',
    'placeholder_industry': 'Industry',
    'placeholder_position': 'Position',
    'placeholder_dob': 'MM/DD/YYYY',
    'placeholder_phone': '(123) 456 - 7890',
    'placeholder_zip_code': '123456',
    'placeholder_ssn': '111-11-1111',
    'placeholder_currency_1': '1.00',
    'placeholder_currency_10': '10.00',
    'placeholder_currency_100': '100.00',
    'placeholder_select_value': 'Select value...',
    'placeholder_change_name': 'My retirement',

    /* verify email page */
    'thank_you1': `Thank You`,
    'verify_email_t1': `Please click on the link in the verification email to continue`,
    'resend_email': `Resend Email`,
    'wrong_email': `Wrong email?`,
    'sign_out': `Sign out`,

    /* Session time out modal */
    'session_timeout1': `Session Timeout`,
    'session_timeout2': `Session time-out`,
    'session_timeout_t1': `The current session is about to expire in`,
    'session_timeout_t2': `seconds.`,
    'session_timeout_t3': `We signed you out so that your financial information stays safe. Any confirmed transactions are saved!`,
    'sign_in': `Sign in`,

    /* 1. Onboarding Journey */
    /* Journey Name */
    'personal_details': 'Personal Details',
    'employment_details': 'Employment details',
    'investment_details': 'Investment details',
    'complete_application': 'Complete application',
    'personal': 'Personal',
    'employment': 'Employment',
    'investment': 'Investment',
    'details': ' Details',
    'information': 'information',

    /* Journey Last Page Text */
    'personal_details_last': 'Last one here!',
    'employment_details_last': 'Getting closer...',
    'investment_details_last': `One more and we're done`,
    'complete_application_last': `Yey! it's the finish line`,

    /* Start onBoarding page */
    'onboarding_start_t1': `Let's open your account`,
    'onboarding_start_t2': `As a SEC registered investment advisor, we are required to ask a few identifying questions.`,
    'onboarding_start_t3': `The onboarding process takes about 5 minutes and has the following steps:`,
    'onboarding_start_t4': `Our partner is Drivewealth, a FINRA registered and SIPC-insured broker-dealer.`,
    'onboarding_start_t5': `to read more about them`,
    'onboarding_start_t6': `Your Gift from`,

    /* 1.1 Personal Detail */
    'personal_detail_info1': 'We encrypt all of the information you enter to the highest standards.',
    'personal_detail_info2': 'As a financial institution we are required to ask for identifying information to prevent money-laundering',
    'personal_detail_info3': 'As a financial institution, we are required by the Patriot Act to verify your identity with your social security number.',
    'verification_info': 'We will be sending a one-time password as an SMS to the phone number provided in order to verify your information and enable multi-factor authentication',
    'personal_page1_label': `U.S mobile phone number`,
    'personal_page2_label1': `Please enter your legal first name`,
    'personal_page2_label2': `Please enter your legal last name`,
    'personal_page2_label3': `Please enter your legal middle name (optional)`,
    'personal_page3_label': `What is your date of birth?`,
    'personal_page4_label1': `What is your legal address?`,
    'personal_page4_label2': `Apartment / unit number`,
    'personal_page4_label3': `Zip code`,
    'personal_page5_label': `What is your primary citizenship?`,
    'personal_page6_label': `Are you a U.S Tax Payer?`,
    'personal_page7_label': `What is your social security number or ITIN?`,
    'personal_verify_label1': `Six digit code -`,
    'personal_verify_label2': `Sent to +1`,

    /* 1.2 Employment Details */
    'employment_detail_info1': 'In order to open an investment account for you, we need to have some minor details about your employer',
    'employment_detail_info2': 'Account Restriction Questions',
    'employment_detail_info3': `These situations don't apply to most of our clients.`,
    'employment_detail_info4': 'But if you know, you know!',
    'employment_detail_info5': 'Approximate Annual Income',
    'employment_detail_info6': 'We are required to ask this so that we know the approximate tax effects of your portfolio and can build your investments to match your needs :)',
    'employment_page1_label': 'What is your current employment status?',
    'employment_page2_label': `What's the name of your company?`,
    'employment_page2_label2': 'What industry do you work in?',
    'employment_page2_label3': 'What is your position in the company?',
    'employment_page3_label1': 'Are you affiliated with or employed by a stock exchange, member firm of an exchange or FINRA, or a municipal securities broker-dealer?',
    'employment_page3_label2': 'Are you a control person of a publicly traded company? A Director, Officer or 10% stock owner?',
    'employment_page3_label2_1': 'Company Name',
    'employment_page3_label2_2': 'Ticker symbol',
    'employment_page3_label3': 'Are you a current or former Politically Exposed Person or Public Official (includes U.S. and Foreign)?',
    'employment_page3_label3_1': 'Name of official and family members',
    'employment_page4_label': 'Annual Income',
    'employment_static_t1': 'Ok, no problem!',
    'employment_static_t2': 'However you are obligated to notify your employer in writing of your intention to open an account',
    'employment_static_t3': `Please provide the names of that official and official's immediate family members (including former spouses)`,
    'employment_static_t4': `Please list the company name and its ticker symbol`,

    /* 1.3 Investment Details */
    'investment_detail_info1': 'Approximate Net Worth',
    'investment_detail_info2': `This is to help us understand what investments are most fitting for you and to make sure you're not investing with too much risk`,
    'investment_detail_info3': `Investing experience`,
    'investment_detail_info4': `This is an approximate idea so we know how to make sure you have all of the knowledge you need to make the right investments`,
    'investment_detail_info5': `Risk Tolerance`,
    'investment_detail_info6': `We want to make sure you are not taking on too much risk, so let us know how much you are willing to take on! Note that this does not affect the portfolio we are building for you right now`,
    'investment_page1_label': 'Total net worth',
    'investment_page2_label': 'What is your Investing experience?',
    'investment_page3_label': 'How much risk are you willing to take? Drag flag to let us know:',

    /* 1.4 complete application */
    'complete_journey_info1': `You've finished our onboarding - once we verify the details you've provided, you will be able to access the full Giraffe experience and invest according to your values, without sacrificing return.`,
    'complete_application_t1': `I also agree to Giraffe's`,
    'complete_application_t2': `privacy policy`,
    'complete_application_t3': `terms & conditions,`,
    'complete_application_t4': `DriveWealth's`,
    'complete_application_t5': `disclosures`,
    'complete_application_t6': `agreement.`,
    'complete_application_t7': `Agreement.`,
    'complete_application_t8': `Disclosures`,

    /* 1.5 waiting page */
    'waiting_page_t1': 'Your information {br} is being processed',
    'waiting_page_t2': 'is being processed',
    'waiting_page_t3': 'This could take up to 1 minute.',
    'waiting_page_t4': `We'll send you an email once you are confirmed.`,

    /* 2. Dashboard */
    /* 2.1 Route Name */
    'dashboard_home': 'Home',
    'dashboard_add_portfolio': 'Add Portfolio',
    'dashboard_activity': 'Activity',
    'dashboard_account_setting': 'Account Settings',
    'dashboard_taxes': 'Taxes & Documentation',
    'dashboard_my_gift': 'My Gifts',
    'dashboard_buy_gift': 'Buy a gift',
    'dashboard_giraffe_view': 'Giraffe View',
    'dashboard_giraffe_learn': 'Giraffe Learn',
    'dashboard_mini_mba': 'Mini-MBA',
    'dashboard_current_investment': 'Current Investments',
    'dashboard_create_investment': 'Create an investment',
    'dashboard_learn': 'Learn',
    'dashboard_gift_given': `Gifts I've given`,

    /* KYC error */
    'kyc_trouble_data': `We're having trouble verifying your data`,
    'kyc_address': `We're having trouble verifying address`,
    'kyc_address_label': `Please verify or change your legal address`,
    'kyc_apartment_label': `Apartment/ unit`,
    'kyc_ssn': `We're having trouble verifying your social security number`,
    'kyc_dob': `We're having trouble verifying your date of birth`,
    'kyc_dob_label': `Please verify or change your date of birth`,
    'kyc_name': `We're having trouble verifying your name`,
    'kyc_number': `We're having trouble verifying your phone number`,
    'kyc_number_label': `Please verify or change your phone number`,
    'kyc_citizenship': `We're having trouble verifying your citizenship`,

    /* Home Page */
    'home_heading': `Here's an overview of your investment`,
    'home_today': `Invest today {br} for a better tomorrow...`,
    'home_tomorrow': `for a better tomorrow...`,
    'home_processing': `Great! Processing your {basketName} portfolio now.`,
    'home_portfolio': `portfolio now.`,
    'home_fund': `Fund your {br} future today`,
    'home_future': `future today`,
    'home_withdraw': `Withdraw money`,
    'home_tooltip': `Withdraw money from your giraffe account to your bank account`,
    'home_banner_t1': `Two main things to do`,
    'home_banner_t2': `Build your index {br} Give a gift`,
    'home_gift_date': `Date redeemed`,
    'home_static_t1': `{span} this is a hypothetical projection based on the historical returns of the S&P 500`,
    'home_bank_connect': `In order to invest please {br} connect to your bank account`,

    /* Transaction card heading */
    'transaction_total_account': `Total account`,
    'transaction_total_current': `Current total invested`,
    'transaction_total_cash': `Total cash`,
    'transaction_earning': `Earnings`,
    'transaction_current': `Current value`,
    'transaction_projection': `20 Years projection`,
    'transaction_total_value': `Total Value`,
    'transaction_redeemed_value': `Redeemed Value`,

    /* Basket Details Page */
    'basket_historical': `Historical Returns`,
    'basket_future': `Future projection`,
    'basket_not_enough': `Not enough data to display`,
    'basket_chart_text1': `Chart is a hypothetical comparison. Investing involves risk of loss and performance is not guaranteed.`,
    'basket_chart_text2': `Projected variance of your portfolio vs. the S&P 500 over the same time`,
    'basket_your_value': 'Your values',
    'basket_companies': `Companies`,
    'basket_industry': `Industries`,
    'basket_excluded_companies': `Excluded companies`,
    'basket_view_list': `View list`,
    'basket_companies_list': `{totalCompany} Companies Total`,
    'basket_excluded': `Excluded`,
    'basket_excluded1': `{excludedCompany} Excluded`,
    'basket_expense': `Expense ratio`,
    'basket_text1': `The following companies have met the criteria for high return without compromising on your values.`,
    'basket_text2': `The following companies are excluded from your portfolio because of your selected values`,
    'basket_expense_text2': `See below for a breakdown of this portfolio's expenses:`,
    'basket_expense_breakdown': `Expense breakdown:`,
    'basket_boosted': `Boost`,
    'basket_boosted2': `Boosted`,
    'basket_expense_ratio': `Total Expense Ratio`,
    'basket_change_name': 'Change portfolio name:',
    'basket_excluded_text': `Choose your values above to start excluding companies and customizing the S&P 500`,

    /* Bank connect */
    'bank_text1': `In order to invest {br} please connect to your bank account`,
    'bank_text2': `please connect to your bank account`,
    'bank_text3': `In order to withdraw cash, {br} please connect to your bank account`,

    /* Fund Modal */
    'fund_select_amount': `Select amount`,
    'fund_account': `From account:`,
    'fund_portfolio': 'To portfolio:',
    'giraffe_cash_account': 'Your Giraffe Cash Account',
    'fund_confirm_transaction': 'Please confirm your transaction',
    'fund_preparing': 'Preparing Transfer',
    'fund_success': 'Transfer success!',
    'fund_your_portfolio': 'Fund your {br} portfolio',
    'bank_transfer': 'Bank transfers take 2-3 days to process.',
    'fund_transfer_market_close': 'We will finalize the transaction while the stock market is open.',
    'fund_transfer_market_open': 'Your transaction will be completed in the next 5-10 minutes.',

    /* Buy Modal */
    'buy_head1': `Add money to your`,
    'buy_head2': `do-good portfolio`,

    /* Sell Modal */
    'sell_head': 'Sell',
    'sell_portfolio': 'From portfolio:',
    'sell_amount': `Amount`,
    'sell_stock': `Sell stocks`,

    /* Withdraw Modal */
    'withdraw_head1': `Withdraw money to your bank account`,
    'withdraw_text1': `You cannot transfer more than you have in cash`,
    'withdraw_account': `To account:`,
    'withdraw': `Withdraw`,
    'withdraw_charge': `Our partners charge $0.25 for outgoing money transfers`,
    'withdraw_bank_account': 'Withdraw to your {br} bank account',
    'withdraw_text2': `* Giraffe and Drivewealth have a seasoning period that prevents unnecessary charges for our users. You can withdraw cash from your Giraffe account at least five days after depositing it.`,
    'withdraw_text3': 'Your cash avaliable for withdrawal: {amount}*',

    /* Create Basket */
    'create_basket_text1': `Choose the values that are necessary for`,
    'create_basket_text2': `creating a bright future`,
    'create_basket_text3': 'Creating your portfolio',
    'create_basket_text4': 'Including companies from the S&P 500 that align with your values and excluding the top ones that do not',
    'create_basket_text5': `Your 'Do-Good' portfolio`,
    'create_basket_text6': `Start with:`,
    'create_basket_text7': `Wait patiently for:`,
    'create_basket_text8': `And you'll have:`,
    'view_portfolio': 'View portfolio',
    'create_basket_boost': `Boost!`,
    'create_basket_unboost': `Unboost`,
    'create_basket_remove': `Remove`,
    'create_basket_trending': `Trending values`,
    'create_basket_more': `More values`,
    'create_basket_head_t1': `Please choose from the following values to focus your investment`,
    'create_basket_card_t1': `Customize the S&P 500 by excluding companies`,
    'create_basket_text9': `Choose the values that are necessary for creating a bright future.`,
    'create_basket_text10': `Wish to boost your impact?`,
    'create_basket_text11': `By choosing to boost, you will be removing more companies that don't align with your values`,
    'create_basket_text12': `How did we choose who to exclude?`,

    /* 2.5 Account Setting */
    'account_setting_my_account': 'My account',
    'account_setting_bank': 'Bank Account',
    'account_setting_status': 'Current Employment Status',
    'account_setting_broker': 'Broker Dealer and Political Exposure',
    'account_setting_investing': 'Investing Information',
    'account_setting_delete': 'Delete account',
    'account_delete': 'Delete Account',
    'account_delete_text': 'In order to delete your account, at this time you must first speak to customer support. Apologies for the inconvenience.',

    /* 2.4 Activity Page */
    'activity_tab1': 'Transactions',
    'activity_tab2': 'Investing history',
    'activity_tab3': 'Dividends',
    'activity_tab4': 'Advisory fees',

    /* 2.5.1 Account Setting Modal */
    'account_setting_email': 'Email',
    'account_setting_phone': 'US Phone Number',
    'account_setting_first': 'Legal first name',
    'account_setting_middle': 'Legal middle name (optional)',
    'account_setting_last': 'Legal last name',
    'account_setting_address': 'Legal address',
    'account_setting_citizenship': 'Primary citizenship',
    'account_setting_ssn': 'SSN Number',
    'account_setting_dob': 'Date of Birth',
    'account_setting_status1': 'Status',
    'account_setting_company_name': 'Company name',
    'account_setting_industry': 'Industry',
    'account_setting_position': 'Position',
    'account_setting_broker_dealer': 'Broker/Dealer affiliation',
    'account_setting_public_company': 'Public company control person',
    'account_setting_political': 'Political exposures person',
    'account_setting_investing_exp': 'Investing Experience',
    'account_setting_net_worth': 'Net Worth',
    'account_unlink_modal': 'You cannot unlink your bank account while money is being moved. Please wait until your {type} is complete and then try again!',
    'account_unlink_confirmation': 'Are you sure you want to unlink your bank account?',

    /* Taxes and Documentation */
    'taxes_head_text': `Taxes and Documentation`,

    /* Gift Page */
    'gift_head_text': 'Your {giftGiver} Gift is Ready',
    'gift_head_text2': `Here's an overview of your investment`,
    'gift_static_text1': 'You can also change {br} the impact value of your gift card!',
    'gift_static_text2': 'the impact value of your gift card!',
    'gift_static_text3': 'See your options below:',
    'gift_static_text4': 'Giraffe Learn',
    'gift_static_text5': 'Learn how to invest according to your values and make responsible financial decisions for you and the planet.',
    'gift_static_text6': 'View course',
    'gift_switch_value': 'Switch values',
    'gift_read_more': 'Read More',
    'gift_show_less': 'Show less',
    'gift_change_value': 'Yes! Change the value to',
    'gift_accept_gift': 'Accept the gift',
    'gift_cash_out': 'Cash out',
    'gift_redeemed_ready': `Your gift is ready to go!`,
    'gift_redeemed_text1': `And the world will be better for it.`,
    'gift_redeemed_button1': `Redeem Your Stock Basket`,
    'gift_redeemed_button2': `Read more`,
    'gift_cash_out_text1': 'Are you sure you want to cash out your entire basket?',

    /* Gift accept modal */
    'gift_accept_text1': `Gift Redeemed!`,
    'gift_accept_text2': `Your investment is being finalized`,
    'gift_accept_text3': `The money transfer and investment will take 2-3 business days to process. After which you'll be able to start tracking your do-good investment!`,

    /* Giraffe Learn */
    'giraffe_learn_welcome': `Welcome aboard!`,
    'giraffe_static_text1': `You have received the gift of knowledge from someone who cares about you.`,
    'giraffe_start_learning': `Let's start learning`,
    'giraffe_mini_mba': `Mini-MBA`,
    'giraffe_mini_mba_text1': `Also included in your gift:`,
    'giraffe_mini_mba_text2': `Exclusive two-day workshop.`,
    'giraffe_mini_mba_text3': `The program was designed to capture the highlights of top ivy league MBA schools and was developed originally for Microsoft Venture's Academy.`,
    'giraffe_mini_mba_register': `Register today`,
    'giraffe_work': `How does Giraffe {br} learn work?`,
    'giraffe_work_learn': `learn work?`,
    'giraffe_work_text1': `Have you ever wondered how companies {br} and investors use the market today? {br} How was it even invented?`,
    'giraffe_work_text2': `and investors use the market today?`,
    'giraffe_work_text3': `x`,
    'giraffe_watch': `Watch now`,
    'giraffe_learn_list_topics': `View full list of topics`,
    'giraffe_learn_back': `Back to Schedule`,
    'giraffe_learn_path': `Your giraffe learn path`,
    'giraffe_learn_of_week': `{title} of week {forWeek}`,
    'giraffe_learn_achievement_text1': `Each step of the way, you'll learn and get achievements`,
    'giraffe_learn_achievement_text2': `For each achievement, you'll be able to share with friends and become an expert in the world of impact`,

    /* Giraffe Mini-MBA */
    'mini_mba': 'Mini-MBA',
    'mini_mba_text1': 'You have received the gift of knowledge {br} from someone who cares about you.',
    'mini_mba_text2': `Exclusive two-day workshop.`,
    'mini_mba_text3': `The program was designed to capture the highlights of top ivy league MBA schools and was developed originally for Microsoft Venture's Academy.`,
    'mini_mba_register': `Register today`,
    'mini_mba_text4': `We need a title about the event here`,
    'mini_mba_text5': `We need a description about the event here`,
    'mini_mba_text6': `Led by leading business school professors.`,
    'mini_mba_secure': `Secure your place today.`,

    /* Giraffe Learn Banner */
    'giraffe_banner_10': `10%`,
    'giraffe_banner_75': `$75`,
    'giraffe_banner_text1': `Your portfolio set-up is at:`,
    'giraffe_banner_text2': `Complete your account registration in order to redeem your gift card.`,
    'giraffe_banner_text3': `Climate Change Stock Bundle`,
    'giraffe_banner_text4': `A high-impact portfolio of companies supporting Climate Change.`,
    'giraffe_banner_text5': `Enter legal information`,
    'giraffe_banner_text6': `Redeem your gift card`,
    'giraffe_banner_text7': `Grow your impact tree.`,
    'giraffe_banner_text8': `Redeem Gift`,

    /* Modal Header */
    'modal_submit': `Submitting you data`,
    'modal_connect_bank': `Connecting Bank...`,
    'modal_fund_account': `Please fund your account`,

    /* Table Fields */
    'table_not_range': `No existing transactions in the specified date range`,
    'table_date': 'Date',
    'table_description': 'Description',
    'table_amount': 'Amount',
    'table_range': 'Date range',
    'table_ticker': 'Ticker',
    'table_dollar_value': 'Dollar Value',
    'table_industry': 'Industry',
    'table_percentage': 'Percentage of Basket',
    'table_document': 'Document name',
    'table_account_name': 'Account Name',
    'table_form_type': 'Form type',
    'table_status': 'Status',
    'table_trade': 'Trade Confirmation',
    'table_no_data': 'No data in specific date range',

    /* Gift Landing Page */
    'gift_landing_impact': 'The Gift of Impact',
    'gift_landing_impact_button': 'Your gift of impact',
    'gift_include': 'Your Impact Gift Includes:',
    'gift_include_basket': '1. Basket of Stocks',
    'gift_include_basket_description': 'A high-impact portfolio of stocks that support the value set of your choice.',
    'gift_include_tree': 'Tree of Impact',
    'gift_include_tree_description': 'From a seed grows a tree. Watch as your impact grow with each gift you give. another the impact grows stronger and Stronger.',
    'gift_include_start': 'We hope that this gift will help you start investing in the future, both yours and the world.',
    'gift_include_start_button': 'Start your journey',
    'gift_faq': 'Have any questions about redeeming your gift?',
    'gift_faq_please_see': 'Please see our',
    'gift_faq_help': 'help center',
    'gift_faq_article': 'to see a few articles that may help you',
    'gift_footer_text1': 'One year without ANY management fees',
    'gift_footer_text2': 'Giraffe Gift cards are non-refundable and for US residents and persons only. Four+ lines',
    'question1': `What do I need to do in order to redeem my gift?`,
    'answer1': `In order to redeem your gift, you will need to open a brokerage account with our FINRA-member and SIPC insured partners, Drivewealth, as well as become a client of the SEC-registered investment advisor Giraffe Invest, Inc. In order to do this, you will need to answer a few personal questions so that we can verify your identity.`,
    'question2': `How can I trust that my information is safe?`,
    'answer2': `Giraffe uses end-to-end encryption and your data is safeguarded to the highest standards. We work with partners such as Stripe and Wells Fargo and others that power the same apps as Sofi, Betterment, Robinhood and Venmo`,
    'question3': `Where is my gift now?`,
    'answer3': `The cash value of your gift is currently held at our banking partner, Wells Fargo. Once you have finished the onboarding process, you will be able to redeem your gift, with the click of a button! You will also be able to invest more and more, all in the values that matter to you...`,
    'redeemed_error_modal': 'Oops!',
    'redeemed_error_modal_text1': 'It looks like this gift has already been redeemed.',
    'redeemed_error_modal_text2': 'Please contact',
    'redeemed_error_modal_text3': 'support@giraffe.us',
    'redeemed_error_modal_text4': 'if you think there has been a mistake.',

    /* Error page*/
    'error_404': 'Oops! 404',
    'error_log_in': 'Log in',
    'error_register': 'Pre-Register',
    'error_notFound': 'The page you requested could not be found.',
    'error_validUrl': 'Please enter a valid page or',
    'error_returnHome': ' return to our homepage :)',
    'error_fixURL': 'We are here to stay, we are not going anywhere. You just need to fix the url!',

    /* Error Message */
    'error_max_length': `Sorry, you can only enter up to {number} characters`,
    'error_max_length_36_name': `Sorry, you can only enter up to 36 characters for your name.`,
    'error_max_length_255': `Sorry, you can only enter up to 255 characters for your name.`,
    'error_max_length_64': `Sorry, you can only enter up to 64 characters for your name.`,
    'error_max_length_1000': `Users cannot enter more than 1,000 characters in the field.`,
    'error_maximum_amount': `Max. Amount $9,999,999,999,999,998`,
    'error_minimum_amount_fund': `The minimum funding amount is $100`,
    'error_minimum_amount_withdraw': `The minimum withdrawal amount is $10`,
    'error_minimum_amount_invest': `The minimum investment amount is $100`,
    'error_minimum_amount_sell': `The minimum sell amount is $100`,
    'error_first_name': `Sorry, your first name may contain letters, periods, apostrophes and spaces but starting with letter only`,
    'error_middle_name': `Sorry, your middle name may contain letters, periods, apostrophes and spaces but starting with letter only`,
    'error_last_name': `Sorry, your last name may contain letters, periods, apostrophes and spaces but starting with letter only`,
    'error_not_valid_dob': `Oops! It looks like you didn't enter a valid date`,
    'error_dob_under_18': `Sorry! We can't currently allow people under the age of 18 to sign up! Check in with your parents and see what you can do :)`,
    'error_invalid_address': `Sorry, this address is invalid address`,
    'error_invalid_citizenship': `Sorry, unfortunately we cannot accept citizens of that citizenship`,
    'error_invalid_ssn_number': `Oops! It looks like you didn't enter a valid SSN number`,
    'error_invalid_annual_income': `Annual income can not be escalate to 9,223,372,036,854,775,295 amount`,
    'error_invalid_net_worth': `Net worth can not be escalate to 9,223,372,036,854,775,295 amount`,
    'error_invalid_verification_code': `That code isn't correct, please try again`,
    'error_ticker_max_length': `Sorry, you can only enter up to 10 characters for ticker symbol.`,
    'error_ticker_message': `Sorry, you can only enter a valid ticker symbol`,
    'error_update_call_error': `Oops! Something went wrong. We couldn't submit your information at this time. Please retry in some time.`,
    'error_try_again': `Please try again later`,
    'error_not_enough_balance': `Sorry, you can't invest in your portfolio since you don't have enough balance in your cash account. Please fund your account.`,
    'error_something_wrong': `Uh oh, something went wrong with processing your information.`,
    'error_multiple_account_holder': `Looks like your information may be being used by multiple account holders.`,
    'error_contact_support': `Please contact our support.`,
    'error_contact_support2': `Please contact customer support if that isn't in line with your activity.`,
    'error_connecting_account': `Please verify that your name in our system is correct and if not then fix it either from account settings page or contact customer support.`,
    'error_connecting_account1': `There was an error while connecting to your bank account through Plaid.`,
    'error_money_route': `Sorry, you can't initiate another transaction while your money is still in route`,
    'error_transaction_process': `Your portfolio transaction is processing. Please wait until the transaction is finalized.`,
    'error_please_try': `Please try again.`,
    'error_unlink_bank': 'Sorry, you cannot unlink your bank account while a transaction is ongoing',
    'error_basket_name': 'Sorry, your basket name is not start with space.',
    'error_non_us_taxpayer': 'Uy! Sorry we can only accept US tax payers at this time. Please contact support@giraffe.us if you think there has been an error',

    /* customer notes */
    'customer_note1': `If you would like to change your phone number or email, please first contact customer support.`,
    'customer_note2': `If you would like to change any of the locked information here, please first contact customer support.`,

    /* Static message */
    'buy_modal_static_message': `Due to constraints around minimum stock holdings, we restrict the lowest fraction of a stock you can buy to $0.05. As such, you may see a lower number of stocks purchased in your portfolio than shown in the page showing the ideal portfolio.`,
    'expense_ratio_text1': `Giraffe uses the term "expense ratio" to describe the percentage of the portfolio that Giraffe charges on an annual basis. Just as an exchange traded fund charges an expense ratio on the total assets in the fund, each portfolio Giraffe offers has its own specific annual fee charged as a percentage of the value of the assets in the portfolio.`,
    'expense_ratio_text2': `The fee shown is on an annual basis, however the fee will be charged on a monthly basis on the first of the month.`,
    'sell_info_text1': `Because of fees associated in selling hundreds of different stocks, Giraffe will need to charge portfolio deconstruction fee of {currency} in order to cover our costs`,
    'sell_info_text2': `in order to cover our costs`,
    'create_basket_static_text1': `Take out most of the companies in the S&P 500 that are in contrast with this value (20 companies)`,
    'create_basket_static_text2': `companies`,
    'create_basket_static_text3': `Only one value can be boosted`,
    'create_basket_static_text4': `Projected variance of your portfolio vs. the S&P 500 over the same time`,
    'taxes_static_text1': `Supplemental Tax Statement`,
    'taxes_static_text2': `foreign sourced income, US government interest, state-by-state municipal breakdown`,
    'giraffe_learn_text1': `With Giraffe learn we wish to inspire you and provide you with knowledge about the impact of your investments. We hope that this will help you make more responsible decisions for yourself and the planet.`,

    /* Transaction Pending message */
    'funding_deposit_message': 'Funding transactions are not available until your {type} amount of {amount} is finalized.',
    'funding_withdraw_message': 'Withdrawal transactions are not available until your {type} amount of {amount} is finalized.',
    'funding_total_card_message': 'Your transfer of {amount} is processing. The total account and cash balance will be updated once the {type} is finalized.',

    'buy_deposit_message': 'Funding transactions are not available until your {type} transaction in {basketName} is finalized.',
    'buy_withdraw_message': 'Withdrawal transactions are not available until your {type} transaction in {basketName} is finalized.',
    'buy_total_card_message': 'Your transaction is processing. The total cash and investments will be updated once the {type} transaction in {basketName} is finalized.',

    'sell_deposit_message': `Portfolio transactions are not available until your {type} amount of {amount} is finalized.`,
    'sell_withdraw_message': `Portfolio transactions are not available until your {type} transaction in {basketName} is finalized.`,

    'total_card_message': 'Portfolio and withdrawal transactions are not available since your total cash balance is $0. Please fund your account.',

    'transfer_pending': 'Pending',
    'transfer_amount': 'Amount',
    'transfer_business_day_deposit': 'This transaction will take 2-3 business days to process. Afterwards, you will see the cash in your account and can invest!',
    'transfer_business_day_withdrawal': 'This transaction will take 2-3 business days to process. Afterwards, you will see the cash in your bank account.',

    'bank_transfer_text1': 'Cash transfer',

    'direct_bank': `your transaction will be completed within 2-3 business days`,
    'market_open': `your transaction will be completed in market open hours`,
    'simple_basket_transfer': `your transaction will be completed within 5-10 minute`,

    /* B2C Gift */
    /* Tabs */
    'b2c_tab_text1': 'Choose your cause',
    'b2c_tab_text2': 'How much to give',
    'b2c_tab_text3': 'Sender/receiver info',
    'b2c_tab_text4': 'Checkout',

    /* Select ESG-C Value */
    'select_esgc_text1': 'Personalize their investment',
    'select_esgc_text2': 'Choose one value to customize the S&P 500 for the Giraffe giftee :)',
    'select_esgc_total_companies': 'Companies in Portfolio',
    'select_esgc_text_excluded_companies': 'Excluded companies',
    'select_esgc_info': `Yeah! This is the value I want in the gift`,

    /* Select Amount */
    'select_amount_text1': `Your gift in the making`,
    'select_amount_text2': `Choose how much to give:`,
    'select_amount_text3': `Give your friends and family an investment to get them started with investing for good`,
    'select_amount_text4': `Select an amount:`,
    'select_amount_text5': `Or enter the amount you want to give:`,
    'select_amount_text6': `From {min} to {max}`,
    'select_amount_placeholder': `Enter $ amount here...`,

    /* Default Dropdown Value */
    'yes': 'Yes',
    'no': 'No',
    'past_week': 'Past Week',
    'past_three_week': 'Past three months',
    'past_six_week': 'Past six months',
    'past_year': 'Past year',
    'all_time': 'All time',

    'one_month': 'One Month',
    'three_month': 'Three Month',
    'year_to_date': 'YTD',
    'one_year': 'One Year',
    'two_year': 'Two Year',

    'year_number': '{number} Year',
    'years_number': '{number} years',
    'number': '{number}',
}