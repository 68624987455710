//api call 
import axios from "axios";
//import Config for url
import * as UrlList from '../config/UrlList';
import { ConsoleLog } from "../service/utils/ConsoleLog";

//get country list
export const getAllCountry = async (accessToken) => {

    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(UrlList.GET_COUNTRY, config)
        .then(res => {
            return res.data.map((value) => {
                return {
                    active: value.active,
                    label: value.name,
                    value: value.identifier,
                }
            })
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))

}

//get country by identifier
export const getCountryIdentifier = async (accessToken, identifier) => {
    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(`${UrlList.GET_COUNTRY_IDENTIFIER}/${identifier}`, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}

// employment status
export const getEmploymentStatus = async (accessToken) => {

    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        //set params for post
        params: {
            'locale': 'en_US'
        }
    };

    return await axios.get(UrlList.EMPLOYMENT_STATUS, config)
        .then(res => {
            return res.data.employmentStatusList.map((value) => {
                return {
                    value: value.identifier,
                    label: value.name,
                }
            })
        })
        .catch(err => ConsoleLog(' ', err))
}

// Industry List
export const getIndustryList = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        //set params for post
        params: {
            'locale': 'en_US'
        }
    };

    return await axios.get(UrlList.INDUSTRY_LIST, config)
        .then(res => {
            return res.data.employmentTypeList.map((value) => {
                return {
                    active: value.active,
                    label: value.name,
                    value: value.identifier,
                }
            })
        })
        .catch(err => ConsoleLog(' ', err))

}

// Investor Experience
export const getInvestorExperience = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        //set params for post
        params: {
            'locale': 'en_US'
        }
    };

    return await axios.get(UrlList.INVESTOR_EXPERIENCE, config)
        .then(res => {
            return res.data.investorExperienceList.map((value) => {
                return {
                    value: value.identifier,
                    label: value.name,
                }
            })
        })
        .catch(err => ConsoleLog(' ', err))
}

// Investor Risk Tolerance
export const getInvestorRiskTolerance = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        //set params for post
        params: {
            'locale': 'en_US'
        }
    };

    return await axios.get(UrlList.INVESTOR_RISK_TOLERANCE, config)
        .then(res => {
            return res.data.investorRiskToleranceList.map((value) => {
                return {
                    value: value.identifier,
                    label: value.name,
                }
            })
        })
        .catch(err => ConsoleLog(' ', err))
}

// Position Industry List
export const getPositionIndustryList = async (accessToken) => {

    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        //set params for post
        params: {
            'locale': 'en_US'
        }
    };

    return await axios.get(UrlList.EMPLOYMENT_POSITION, config)
        .then(res => {
            return res.data.employmentPositionList.map((value) => {
                return {
                    active: value.active,
                    label: value.name,
                    value: value.identifier,
                }
            })
        })
        .catch(err => ConsoleLog(' ', err))

}

