//default library
import React, { Component, Fragment } from 'react'

//custom component
import SelectActivity from '../SelectComponent/SelectActivity';
import PaginationNew from '../PaginationComponent/PaginationNew';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

//image import
import IMAGES from '../../config/Images';

//custom function
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';
import { DateFormatter } from '../../service/utils/DateFormatter';
import { DateRange } from '../../service/utils/DateFilter';
// api call
import { getInvestingHistory } from '../../middleware/ActivityDetails';

import IntlMessages from '../../helper/IntlMessages';

import { taxesDropdown } from '../../config/DefaultValue';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';


export class InvestingHistoryCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            investingHistoryData: props.investingHistoryData,
            currentInvestingHistoryData: [],
            totalInvestingHistoryData: [],
            currentPage: null,
            totalPages: null,
            dataLoad: false
        }
    }

    componentDidMount = async () => {
        const { totalInvestingHistoryData, investingHistoryData } = this.state
        if (totalInvestingHistoryData?.length === 0) {
            await this.setState({
                totalInvestingHistoryData: investingHistoryData,
                dataLoad: true
            })
        }

    }

    onPageChanged = data => {
        const { totalInvestingHistoryData } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentInvestingHistoryData = totalInvestingHistoryData?.slice(offset, offset + pageLimit);
        this.setState({
            currentInvestingHistoryData: currentInvestingHistoryData,
            currentPage: currentPage,
            totalPages: totalPages
        });
    }

    onSelectValue = async (value) => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            dataLoad: false
        })

        let dateRange = await DateRange(value)//set default value date range

        const accessToken = await getAccessTokenSilently() //get access token
        let investingHistoryData = await getInvestingHistory(accessToken, dateRange)
        await this.setState({
            totalInvestingHistoryData: investingHistoryData,
            dataLoad: true
        })
    }

    render() {
        const { totalInvestingHistoryData, currentInvestingHistoryData, dataLoad } = this.state
        return (
            <Fragment>
                {dataLoad ? '' : <LoadingComponent />}

                <div className="row gx-20">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="gi-d-ac-select-dropdown mb-30">
                                    <div>
                                        <label>
                                            {IntlMessages('table_range')}:
                                        </label>
                                    </div>
                                    <SelectActivity name='transaction' option={taxesDropdown} value={'past-week'} selectValue={this.onSelectValue} />
                                </div>
                            </div>
                            {/* <div className="col-sm-8 col-md-9">
                                Pagination
                                {dataLoad
                                    ? <PaginationNew totalRecords={totalInvestingHistoryData?.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                                    : ''
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table gi-d-table-striped-02 gi-d-table-investing-history mb-0" role="presentation">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <span>
                                                {IntlMessages('table_date')}
                                            </span>
                                        </th>
                                        <th scope="col" className="description-th">
                                            <span>
                                                {IntlMessages('table_description')}
                                            </span>
                                        </th>
                                        <th scope="col"></th>
                                        <th scope="col" className="amount-th">
                                            <span>
                                                {IntlMessages('table_amount')}
                                            </span>
                                        </th>
                                        <th scope="col" className="collapse-th"></th>
                                    </tr>
                                </thead>
                                {currentInvestingHistoryData?.length > 0
                                    ? currentInvestingHistoryData.map((e, i) => {
                                        if (e.orders) {
                                            return (
                                                <Fragment key={i}>
                                                    <tbody>
                                                        <tr className={i % 2 === 0 ? 'bg-light' : ''}>
                                                            <td>
                                                                <span>{DateFormatter(e.date)}</span>
                                                            </td>
                                                            <td>
                                                                <span>{e.description}</span>
                                                            </td>
                                                            <td></td>
                                                            <td className="amount-td">
                                                                <span className={e.actualAmount > 0 ? 'text-gi-green' : 'text-gi-clay'}>{e.actualAmount > 0 ? `+${currencyFormatter(e.actualAmount)}` : currencyFormatter(e.actualAmount)}</span>
                                                            </td>
                                                            <td>
                                                                <a className="gi-d-down-btn collapsed" data-bs-toggle="collapse" href={`#collapse-${i}`} role="button"
                                                                    aria-expanded="false" aria-controls={`collapse-${i}`}>
                                                                    <img className="down-collapsed" src={IMAGES.DOWN_ARROW_BLACK} alt="" />
                                                                    <img className="up-collapsed" src={IMAGES.UP_ARROW_BLACK} alt="" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody className="collapse" id={`collapse-${i}`}>
                                                        {
                                                            e.orders.map((v, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td></td>
                                                                        <td>
                                                                            <span >{v.side}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span >{v.symbol}</span>
                                                                        </td>
                                                                        <td className="amount-td">
                                                                            <span className={v.actualAmount > 0 ? 'text-gi-green' : 'text-gi-clay'}>{v.actualAmount > 0 ? `+${currencyFormatter(v.actualAmount)}` : currencyFormatter(v.actualAmount)}</span>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Fragment>
                                            )
                                        }
                                        else {
                                            return (
                                                <tbody key={i}>
                                                    <tr className={i % 2 === 0 ? 'bg-light' : ''}>
                                                        <td>
                                                            <span className="text-gi-bright-black">{DateFormatter(e.date)}</span>
                                                        </td>
                                                        <td>
                                                            <span className="text-gi-black">{e.description}</span>
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            <span className={e.actualAmount > 0 ? 'text-gi-green' : 'text-gi-clay'}>{e.actualAmount > 0 ? `+${currencyFormatter(e.actualAmount)}` : currencyFormatter(e.actualAmount)}</span>
                                                        </td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        }
                                    })
                                    : <tbody>
                                        <tr className="bg-light">
                                            <td colSpan="12">
                                                <span>
                                                    {IntlMessages('table_not_range')}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                        {dataLoad
                            ? <PaginationNew totalRecords={totalInvestingHistoryData?.length} pageLimit={5} pageNeighbors={1} onPageChanged={this.onPageChanged} />
                            : ''
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withAuth0(InvestingHistoryCard)