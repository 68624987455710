import React, { Component } from 'react'
import { injectIntl } from 'react-intl';
import Select from 'react-select';

export class SelectModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fieldValue: '',
            defaultValueFlag: false,
            changeValue: []
        }
    }

    handleOnChange = async (e) => {
        await this.setState({
            fieldValue: e.value,
            defaultValueFlag: true,
            changeValue: {
                value: e.value,
                label: e.label
            }
        })
        //send to parent component
        this.props.selectValue(this.state.fieldValue)
    }

    render() {
        const { option, placeholder, name, value, disabled, intl } = this.props
        const { defaultValueFlag, changeValue } = this.state
        const defaultValue = option.map(e => {
            if (e.value === value) {
                return e
            }
            else{
                return null
            }
        });
        return (
            <div className='custom-react-dropdown-modal'>
                <Select
                    isDisabled={disabled ?? false}
                    name={name}//set field name
                    classNamePrefix='react-select-modal'
                    options={option} //dropdown value
                    onChange={this.handleOnChange} //invoke on every dropdown select value
                    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                    value={defaultValueFlag ? changeValue : defaultValue}
                />
            </div>
        )
    }
}

export default injectIntl(SelectModal)