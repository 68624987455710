//import ageCalculator
import AgeCalculator from '../service/utils/AgeCalculator'
import * as CryptoJS from "crypto-js";

import * as Config from '../config/Config';

export default function ButtonHandler(value) {
    //get localStorage data
    let localStorageData = JSON.parse(localStorage.getItem('onBoardingDetails'));
    //set button disable or not
    let nextDisabled = true;
    let perviousDisabled = true;

    //set data into localStorage
    let onBoardingDetails;


    //personal details page1
    if (value.phoneNumber) {
        // check phoneNumber length
        if (value.phoneNumber.length === 10) {
            nextDisabled = false;
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            phoneNumber: `${value.phoneNumber}`,
            verifyPhoneNumber: value.verifyPhoneNumber
        }
    }

    if (value.verifyPhoneNumber && JSON.parse(CryptoJS.AES.decrypt(value.verifyPhoneNumber, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8))) {
        nextDisabled = false;
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            verifyPhoneNumber: value.verifyPhoneNumber
        }
    }

    //check if localStorage data in available or nor if yes the previous button is active
    if (localStorageData) {
        perviousDisabled = false
    }
    //personal details page2
    if (value.firstName && value.lastName) {
        //check condition
        const maxLength = value.firstName.length <= Config.MAX_NUMBER_36 && value.lastName.length <= Config.MAX_NUMBER_36 //maximum length
        const minLength = value.firstName.length >= 2 && value.lastName.length >= 2 //minimum length
        const regex = !!value.firstName.match(Config.NAME_REGEX) && !!value.lastName.match(Config.LAST_NAME_REGEX) //regex of name

        if ((maxLength && minLength && regex) && value.middleName) {
            nextDisabled = false;
            // if middle name is present
            const minLengthMiddle = value.middleName.length >= 0 //minimum length
            const maxLengthMiddle = value.middleName.length <= Config.MAX_NUMBER_36 //maximum length
            const regexMiddle = !value.middleName.match(Config.NAME_REGEX) //regex of middle name

            if (minLengthMiddle && maxLengthMiddle) {
                if (regexMiddle) {
                    nextDisabled = true;
                }
                else {
                    nextDisabled = false;
                }
            }
        }
        else if ((maxLength && minLength && regex)) {
            nextDisabled = false;
        }
        else {
            nextDisabled = true;
        }

        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            firstName: value.firstName,
            lastName: value.lastName,
            middleName: value.middleName,
        }
    }

    //personal details page3
    if (value.dateOfBirth) {
        // check dob is greater 1/1/1901 and less today's data
        if ((new Date(value.dateOfBirth) > new Date('1/1/1901')) && (new Date(value.dateOfBirth) < new Date(Date.now()))) {
            //check dob is greater 18 years
            if (AgeCalculator(new Date(value.dateOfBirth)) >= 18) {
                nextDisabled = false;
            }
            //create modal for set localStorage data
            onBoardingDetails = {
                ...localStorageData,
                dateOfBirth: value.dateOfBirth
            }
        }
    }

    //personal details page4
    if (value.addressAutoFill && value.postalCode && !value.errorFlag) {
        if (value.apartmentUnit.length <= Config.MAX_NUMBER_36) {
            nextDisabled = false;
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            apartmentUnit: value.apartmentUnit,
            postalCode: value.postalCode,
            addressAutoFill: value.addressAutoFill
        }

    }

    //personal details page5
    if (value.citizenship && value.active) {
        nextDisabled = false;
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            citizenship: value.citizenship,
            countryLabel: value.countryLabel
        }
    }

    //personal details page6
    if (value.taxPayer && value.taxPayer !== 'false') {
        nextDisabled = false;
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            taxPayer: value.taxPayer
        }
    }

    //personal details page7
    if (value.ssnNumber) {
        // check ssnNumber length and regex
        if (value.ssnNumber.length === 9 && value.ssnNumberMask.match(Config.SSN_REGEX)) {
            nextDisabled = false;
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            ssnNumber: value.ssnNumber
        }
    }

    //employment details page1
    if (value.employmentStatus) {
        nextDisabled = false;
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            employmentStatus: value.employmentStatus,
            employmentLabel: value.employmentLabel
        }
    }

    //employment details page2
    if (value.company && value.position && value.industry) {
        //check length of companyName
        if (value.company.length <= Config.MAX_NUMBER_64 && value.company.length >= 2) {
            nextDisabled = false;
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            company: value.company,
            positionIdentifier: value.position,
            typeIdentifier: value.industry
        }
    }

    //employment details page3
    if (value.brokerDealer && value.stockOwner && value.publicOfficial) {
        // stockOwner value is true
        if (value.stockOwner === 'true' && value.publicOfficial === 'false') {
            //if tru then check companyName and tickerSymbol value and regex
            if (value.companyName.length <= Config.MAX_NUMBER_255 && value.companyName.length >= 2 && value.tickerSymbol.length >= 2 && value.tickerSymbol.length <= 10 && !value.tickerSymbol.match(Config.TICKER_REGEX)) {
                nextDisabled = false;
            }
        }
        // publicOfficial value is true
        if (value.stockOwner === 'false' && value.publicOfficial === 'true') {
            if (value.officialField.length <= 1000 && value.officialField.length >= 3 && value.officialField) {
                nextDisabled = false;
            }
        }
        //stockOwner and publicOfficial value is true
        if (value.stockOwner === 'true' && value.publicOfficial === 'true') {
            if (value.officialField.length <= 1000 && value.officialField.length >= 3 && value.officialField &&
                value.companyName.length <= Config.MAX_NUMBER_255 && value.companyName.length >= 2 &&
                value.tickerSymbol.length >= 2 && value.tickerSymbol.length <= 10 && !value.tickerSymbol.match(Config.TICKER_REGEX)
            ) {
                nextDisabled = false;
            }
        }
        //stockOwner and publicOfficial value is false 
        if (value.stockOwner === 'false' && value.publicOfficial === 'false') {
            nextDisabled = false
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            brokerDealer: value.brokerDealer,
            stockOwner: value.stockOwner,
            publicOfficial: value.publicOfficial,
            directorOf: value.stockOwner === 'true' ? `${value.companyName} ${value.tickerSymbol}` : null,
            politicallyExposedNames: value.publicOfficial === 'true' ? value.officialField : null,
            companyName: value.companyName,
            tickerSymbol: value.tickerSymbol,
            officialField: value.officialField
        }
    }

    //employment details page4
    if (value.annualIncome) {
        if (value.annualIncome <= Config.MAX_NUMBER) {
            nextDisabled = false;
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            annualIncome: value.annualIncome
        }
    }

    //investment details page1
    if (value.netWorth) {
        if (value.netWorth <= Config.MAX_NUMBER) {
            nextDisabled = false;
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            netWorth: value.netWorth
        }
    }

    //investment details page2
    if (value.experienceIdentifier) {
        nextDisabled = false;
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            experienceIdentifier: value.experienceIdentifier
        }
    }

    //investment details page3
    if (value.riskTolerance) {
        nextDisabled = false;
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            riskTolerance: value.riskTolerance,
            currentIndex: value.currentIndex
        }
    }

    //completion details page
    if (value.terms) {
        nextDisabled = false;
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            terms: value.terms
        }
    }

    //return all value
    return {
        nextDisabled,
        perviousDisabled,
        onBoardingDetails,
    }
}