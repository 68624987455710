/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class ListView extends Component {
    render() {
        const { listLabel, listData, aTagTrue } = this.props
        return (
            <>
                <ul className="gi-d-ac-tr">
                    <li className="gi-d-ac-th">
                        <span>
                            {IntlMessages(listLabel)}
                        </span>
                    </li>
                    <li className="gi-d-ac-td">
                        <span> {listData}</span>
                        {aTagTrue && listData ? <a href="#!"> {IntlMessages('common_verify2')}</a> : ''}
                    </li>
                </ul>
            </>
        )
    }
}

export default ListView