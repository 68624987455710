//default library
import React from 'react'

//import check-icon svg
import IMAGES from '../../config/Images'
import IntlMessages from '../../helper/IntlMessages'

function StepProcessBarComponent(props) {
    return (
        <>
            <div className="col-md-9 mx-auto">
                <div className="gi-tabs">
                    <ul>
                        {/* check based on props check  */}
                        <li className={`gi-tab-${props.check === 1 ? 'active' : 'check'}`}>
                            <div className="gi-point-section">
                                <div className="gi-point-main">
                                    <div className="gi-point">
                                        <span>1</span>
                                        <img src={IMAGES.CHECK_ICON} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="gi-tab-text">
                                {IntlMessages('personal_details')}
                            </div>
                        </li>
                        {/* check based on props check  */}
                        <li className={`gi-tab-${props.check === 2 ? 'active' : props.check < 2 ? '' : 'check'}`}>
                            <div className="gi-point-section">
                                <div className="gi-point-main">
                                    <div className="gi-point">
                                        <span>2</span>
                                        <img src={IMAGES.CHECK_ICON} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="gi-tab-text">
                                {IntlMessages('employment_details')}
                            </div>
                        </li>
                        {/* check based on props check  */}
                        <li className={`gi-tab-${props.check === 3 ? 'active' : props.check < 3 ? '' : 'check'}`}>
                            <div className="gi-point-section">
                                <div className="gi-point-main">
                                    <div className="gi-point">
                                        <span>3</span>
                                        <img src={IMAGES.CHECK_ICON} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="gi-tab-text">
                                {IntlMessages('investment_details')}
                            </div>
                        </li>
                        {/* check based on props check  */}
                        <li className={`gi-tab-${props.check === 4 ? 'active' : props.check < 4 ? '' : 'check'}`}>
                            <div className="gi-point-section">
                                <div className="gi-point-main">
                                    <div className="gi-point">
                                        <span>4</span>
                                        <img src={IMAGES.CHECK_ICON} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="gi-tab-text">
                                {IntlMessages('complete_application')}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default StepProcessBarComponent