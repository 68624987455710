//api call 
import axios from "axios";
//import Config for url
import * as UrlList from "../config/UrlList";

import { ConsoleLog } from '../service/utils/ConsoleLog'

// get the data for giraffe learn
export const getUserContent = async (accessToken, forWeek) => {

    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "locale": "en_US",
        "forWeek": forWeek ?? null,
        "user": localStorage.getItem('identifier')
    }

    return await axios.post(UrlList.USER_CONTENT, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog('', err)
        })
}

// get the data for giraffe learn list
export const getUserCalendar = async (accessToken) => {

    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "locale": "en_US",
        "user": localStorage.getItem('identifier')
    }

    return await axios.post(UrlList.USER_CALENDAR, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog('', err)
        })
}

// get the data for giraffe video
export const getUserVideo = async (accessToken, videoUpdate) => {

    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "viewedTill": videoUpdate.currentTime,
        "lastViewedDateTime": videoUpdate.currentDate,
        "liked": false,
        "user": localStorage.getItem('identifier'),
        "contentVideo": videoUpdate.videoIdentifier
    }

    return await axios.put(UrlList.USER_VIDEO, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog('', err)
        })
}

export const getUserAnyVideos = async (identifier) => {

    //set params
    const params = {
        "locale": "en_US",
        "identifier": identifier,
        "user": localStorage.getItem('identifier') ?? null,
    }

    return await axios.post(UrlList.USER_ANY_CONTENT, params)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog('', err)
        })
}