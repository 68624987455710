//default library
import React, { Component, Fragment } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import AccountDetailCard from '../../components/CardComponent/AccountDetailCard';
import StatementCard from '../../components/CardComponent/StatementCard';
import TradeConfirmationTableCard from '../../components/CardComponent/TradeConfirmationTableCard';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import PageHeader from '../../components/PageHeaderComponent/PageHeader';
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'

// api call
import * as TaxDocumentation from '../../middleware/TaxDocumentation';
import { DateRange } from '../../service/utils/DateFilter';

export class TaxesPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      statementData: [],
      taxStatementData: [],
      tradeConfirmationData: [],
    };
  };

  componentDidMount = async () => {
    window.scroll(0, 0)
    const { getAccessTokenSilently } = this.props.auth0
    const startData = await DateRange('past-week')
    const accessToken = await getAccessTokenSilently() //get access token
    const [
      statementData,
      taxStatementData,
      tradeConfirmationData
    ] = await Promise.all([
      TaxDocumentation.getStatement(accessToken, startData),
      TaxDocumentation.getTaxStatement(accessToken),
      TaxDocumentation.getTradeConfirmation(accessToken, startData),
    ])

    await this.setState({
      statementData: statementData,
      taxStatementData: taxStatementData,
      tradeConfirmationData: tradeConfirmationData,
      isLoading: false
    })
  }

  render() {
    const { isLoading, statementData, taxStatementData, tradeConfirmationData } = this.state
    return (
      <Fragment>
        {isLoading
          ? <LoadingComponent />
          : <Fragment>
            {/* page header */}
            <div className="row">
              <div className="col-12">
                <div className="gi-gift-heading">
                  <PageHeader h5={'gift_head_text2'} strong={`hello`} userName={`${localStorage.getItem('firstName')}`} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="gi-g-right-sidebar-main">
                  <div className="gi-g-right-sidebar-left">
                    <div className="gi-g-details-card-left">
                      {/* statement card */}
                      <StatementCard statementData={statementData} />

                      {/* tax statement card */}
                      <AccountDetailCard taxStatementData={taxStatementData} />

                      {/* statement card */}
                      <TradeConfirmationTableCard tradeConfirmationData={tradeConfirmationData} />
                    </div>
                  </div>
                  <div className="gi-g-right-sidebar-right">
                    <GiraffeLearnBannerSection />
                  </div>
                </div>
              </div>
            </div>



          </Fragment>
        }
      </Fragment>
    )
  }
}

export default withAuth0(TaxesPage)