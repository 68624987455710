//default library
import React, { Component, Fragment } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import AdvisoryFeesCard from '../../components/CardComponent/AdvisoryFeesCard';
import DividendsCard from '../../components/CardComponent/DividendsCard';
import InvestingHistoryCard from '../../components/CardComponent/InvestingHistoryCard';
import TransactionsCard from '../../components/CardComponent/TransactionsCard';
import PageHeader from '../../components/PageHeaderComponent/PageHeader';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'

//api call
import * as ActivityDetails from '../../middleware/ActivityDetails';
import { DateRange } from '../../service/utils/DateFilter';
import IntlMessages from '../../helper/IntlMessages';

export class ActivityPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      transactionData: '',
      investingHistoryData: '',
      dividendData: '',
      advisoryFeesData: '',
    }
  }

  componentDidMount = async () => {
    window.scroll(0, 0)
    const { getAccessTokenSilently } = this.props.auth0
    await this.setState({
      isLoading: true
    })

    let dateRange = await DateRange('past-week')//set default value date range
    const accessToken = await getAccessTokenSilently() //get access token
    let [
      transactionData,
      investingHistoryData,
      dividendData,
      advisoryFeesData
    ] = await Promise.all([
      ActivityDetails.getTransaction(accessToken, dateRange),
      ActivityDetails.getInvestingHistory(accessToken, dateRange),
      ActivityDetails.getDividends(accessToken, dateRange),
      ActivityDetails.getAdvisoryFees(accessToken, dateRange)
    ])

    await this.setState({
      isLoading: false,
      transactionData: transactionData,
      investingHistoryData: investingHistoryData,
      dividendData: dividendData,
      advisoryFeesData: advisoryFeesData,
    })

  }

  render() {
    const { isLoading, transactionData, investingHistoryData, dividendData, advisoryFeesData } = this.state

    return (
      <Fragment>
        {
          isLoading
            ? <LoadingComponent />
            : <Fragment>
              <div className="row">
                <div className="col-12">
                  <div className="gi-gift-heading">
                    <PageHeader h5={'gift_head_text2'} strong={`hello`} userName={`${localStorage.getItem('firstName')}`} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="gi-g-right-sidebar-main">
                    {/* actual content */}
                    <div className="gi-g-right-sidebar-left">
                      <div className="gi-g-details-card-left">
                        <div className="row">
                          <div className="col-12">
                            <div className="gi-d-activity-card">
                              <div className="gi-d-new-heading">
                                <h5>
                                  {IntlMessages('dashboard_activity')}
                                </h5>
                              </div>
                              {/* tab navigation */}
                              <div className="gi-d-activity-card-body">
                                <div className="gi-d-activity-card-header">
                                  <ul className="nav nav-pills nav-fill" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                      <a href="#!" className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-01" role="tab" aria-controls="gi-tabs-01" aria-selected="true">
                                        {IntlMessages('activity_tab1')}
                                      </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <a href="#!" className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-02" role="tab" aria-controls="gi-tabs-02" aria-selected="false">
                                        {IntlMessages('activity_tab2')}
                                      </a>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                      <a href="#!" className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-03" role="tab" aria-controls="gi-tabs-03" aria-selected="false">
                                        {IntlMessages('activity_tab3')}
                                      </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <a href="#!" className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-04" role="tab" aria-controls="gi-tabs-04" aria-selected="false">
                                        {IntlMessages('activity_tab4')}
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div className="tab-content" id="myTabContent">
                                  <div className="tab-pane fade show active" id="gi-tabs-01" role="tabpanel" aria-labelledby="gi-tabs-01-tab">
                                    <TransactionsCard transactionData={transactionData} />
                                  </div>
                                  <div className="tab-pane fade" id="gi-tabs-02" role="tabpanel" aria-labelledby="gi-tabs-02-tab">
                                    <InvestingHistoryCard investingHistoryData={investingHistoryData} />
                                  </div>
                                  <div className="tab-pane fade" id="gi-tabs-03" role="tabpanel" aria-labelledby="gi-tabs-03-tab">
                                    <DividendsCard dividendData={dividendData} />
                                  </div>
                                  <div className="tab-pane fade" id="gi-tabs-04" role="tabpanel" aria-labelledby="gi-tabs-04-tab">
                                    <AdvisoryFeesCard advisoryFeesData={advisoryFeesData} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* banner */}
                    <div className="gi-g-right-sidebar-right">
                      <GiraffeLearnBannerSection />
                    </div>
                  </div>
                </div >
              </div >
            </Fragment >
        }

      </Fragment>
    )
  }
}

export default withAuth0(ActivityPage)