//default library
import React, { Component } from 'react';

//custom function
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';

//import image
import IMAGES from '../../config/Images';

//custom component
// import BasketLogoComponent from '../LogoComponent/BasketLogoComponent';
import IntlMessages from '../../helper/IntlMessages';
export class BasketCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentValue: '0.00',
            futureProjection: '0.00',
            earning: '-',
            isPending: '',
            id: props.id,
            esgcValueList: [],
            portfolioSummaryData: props.portfolioSummaryData ?? [],
            isOrderOnHold: false,
            isBankOrderPending: false,
            pendingOrderType: "BUY",
            pendingOrderAmount: '0.00',
        }
    }

    componentDidMount = async () => {
        const { portfolioSummaryData, id } = this.state
        for (let i = 0; i < portfolioSummaryData.baskets.length; i++) {
            if (portfolioSummaryData.baskets[i].id === Number(id)) {
                await this.setState({
                    isPending: portfolioSummaryData.baskets[i].isPending,
                    currentValue: currencyFormatter(portfolioSummaryData.baskets[i].currentValue),
                    earning: portfolioSummaryData.baskets[i].totalGain === 0 ? '-' : currencyFormatter(portfolioSummaryData.baskets[i].totalGain),
                    futureProjection: portfolioSummaryData.baskets[i].twentyYearProjection ? currencyFormatter(portfolioSummaryData.baskets[i].twentyYearProjection) : currencyFormatter(0),
                    esgcValueList: portfolioSummaryData.baskets[i].esgcValues,
                    isOrderOnHold: portfolioSummaryData.baskets[i]?.isOrderOnHold,
                    isBankOrderPending: portfolioSummaryData.baskets[i]?.isBankOrderPending,
                    pendingOrderType: portfolioSummaryData.baskets[i]?.pendingOrderType,
                    pendingOrderAmount: portfolioSummaryData.baskets[i]?.pendingOrderAmount,
                })
            }
        }
    }

    render() {
        const { currentValue, futureProjection, earning, isPending, esgcValueList, isOrderOnHold, isBankOrderPending, pendingOrderType, pendingOrderAmount } = this.state
        return (
            <>
                {isPending
                    ?
                    <div className="gi-g-d-r-pending-card">
                        <div className="gi-g-d-r-pending-main">
                            <div className="gi-g-d-r-pending-left">
                                <div>
                                    <img src={IMAGES.PENDING_LINE_CHART_PNG} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="gi-g-d-r-pending-right">
                                <div className="row">
                                    <div className="col-xl-4 col-sm-6">
                                        <div className="gi-g-d-r-p-text-card">
                                            <div className="gi-g-d-r-p-text-header">
                                                <h6>
                                                    {IntlMessages('basket_your_value')}:
                                                </h6>
                                            </div>
                                            <div className="gi-g-d-r-p-text-body">
                                                <ul>
                                                    {esgcValueList.length > 0
                                                        ? esgcValueList.map((e, i) => {
                                                            return (
                                                                <li key={i}>
                                                                    {e.name}
                                                                </li>
                                                            )
                                                        })
                                                        : ''
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-sm-6">
                                        <div className="gi-g-d-r-p-text-card">
                                            <div className="gi-g-d-r-p-text-header">
                                                <h6>
                                                    {IntlMessages('sell_amount')}:
                                                </h6>
                                            </div>
                                            <div className="gi-g-d-r-p-text-body">
                                                <h6>
                                                    {pendingOrderType === 'BUY' ? '+' : '-'}{currencyFormatter(pendingOrderAmount)}
                                                </h6>
                                                <p>
                                                    *{isOrderOnHold
                                                        // complete in market hours
                                                        ? IntlMessages('market_open')
                                                        : isBankOrderPending
                                                            // direct transfer from bank
                                                            ? IntlMessages('direct_bank')
                                                            // simple transfer
                                                            : IntlMessages('simple_basket_transfer')
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="gi-g-d-r-p-text-card">
                                            <div className="gi-g-d-r-p-text-header">
                                                <h6>
                                                    {IntlMessages('account_setting_status1')}:
                                                </h6>
                                            </div>
                                            <div className="gi-g-d-r-p-text-body">
                                                <h6>
                                                    {IntlMessages('transfer_pending')}*
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="gi-d-retirement-body">
                        <div className="gi-d-retirement-left">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-d-price-current">
                                        <span>
                                            {IntlMessages('transaction_current')}:
                                        </span>
                                        <h3>
                                            {currentValue}
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="gi-d-price-earnings">
                                        <span>
                                            {IntlMessages('transaction_earning')}
                                        </span>
                                        <h3>
                                            {earning}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gi-d-retirement-right">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-d-chart-img">
                                        <img src={IMAGES.LINE_CHART} alt="" />
                                    </div>
                                    <div className="gi-d-price-earnings">
                                        <span>
                                            {IntlMessages('transaction_projection')}&nbsp; *
                                        </span>
                                        <h3>
                                            {futureProjection}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default BasketCard