//default library
import React, { Component } from 'react'

//local message
import IntlMessages from '../../helper/IntlMessages'

class ErrorComponent extends Component {

    render() {
        const { dashboard, display } = this.props
        return (
            <div className={`${dashboard ? 'error-text' : 'error-msg'}`} style={{ display: display }}>
                {/* {IntlMessages(this.props.message)} */}
                {typeof (this.props.message) === 'object'
                    ? IntlMessages(...this.props.message)
                    : IntlMessages(this.props.message)
                }
            </div>
        )
    }
}

export default ErrorComponent