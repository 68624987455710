//api call 
import axios from "axios";

//import Config for url
import { REGISTER, GET_BY_ACCESS_TOKEN, USER_DETAIL } from "../config/UrlList";
import { ConsoleLog } from "../service/utils/ConsoleLog";
import { giftUpdate } from "./GiftAPI";

//create user
export const createUser = async (accessToken) => {

    //check user has gift or not
    const giftIdentifier = await localStorage.getItem('giftIdentifier')

    if (giftIdentifier) {
        await giftUpdate(accessToken, giftIdentifier)
    }

    // set header 
    const param = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //register user to out database
    return await axios.get(REGISTER, param)
        .then(res => {
            localStorage.setItem('username', res.data.name)
            localStorage.setItem('firstName', res.data.firstNameEncrypted)
            localStorage.setItem('lastName', res.data.lastNameEncrypted)
            localStorage.setItem('dwUserIdentifier', res.data.dwUserIdentifier) //set user dwUserIdentifier to localStorage
            localStorage.setItem('identifier', res.data.identifier) //set user identifier value to localStorage
            localStorage.removeItem("triggerMethod");
            return res.data
        })
        //handle err
        .catch(err => {
            ConsoleLog(' ', err);
        })

}

//get user details by access token 
export const getUserDetails = async (accessToken) => {

    //access token
    // const accessToken = token ?? await localStorage.getItem('accessToken');
    //set header
    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //post call for get user details
    return await axios.get(GET_BY_ACCESS_TOKEN, config)
        .then(res => {
            localStorage.setItem('username', res.data.name)
            localStorage.setItem('firstName', res.data.firstNameEncrypted)
            localStorage.setItem('lastName', res.data.lastNameEncrypted)
            localStorage.setItem("dwUserIdentifier", res.data.dwUserIdentifier); // set user dwUserIdentifier to localStorage
            localStorage.setItem("identifier", res.data.identifier); // set user dwUserIdentifier to localStorage
            return res.data
        })
        //handle err
        .catch(err => ConsoleLog(' ', err.response))
    //get user details
}

//get user details by access token 
export const getUserData = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');//get access token from localStorage

    //set header
    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //post call for get user details
    return await axios.get(USER_DETAIL, config)
        .then(res => {
            return res.data
        })
        //handle err
        .catch(err => ConsoleLog(' ', err.response))
    //get user details
}