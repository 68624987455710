//default library
import React, { Component } from 'react'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import InputComponentRadio from '../../../components/InputComponent/InputComponentRadio';
// information component
import InformationComponent from '../../../components/InformationComponent/InformationComponent';
// loading component
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
// button component
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
// get investor experience list
import { getInvestorExperience } from '../../../middleware/DropdownList';

export class InvestmentDetailsPage2 extends Component {
  //default constructor
  constructor(props) {
    super(props)
    this.setLocalStorageData()//initial call for localStorage
    this.state = {
      experienceList: [],
      experienceIdentifier: '',
    }
  }

  //check localStorage is data is present or not if present then store
  setLocalStorageData = async () => {
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    if (localStorageData !== null && localStorageData?.experienceIdentifier !== undefined) {
      await this.setState({
        localStorageData: localStorageData?.experienceIdentifier ?? '',
        localStorageDataFlag: true
      })
    }
  }

  //get list of employment list and store into experienceList field
  componentDidMount = async () => {
    const { getAccessTokenSilently } = this.props.auth0
    const accessToken = await getAccessTokenSilently() //get access token
    let getListAPI = await getInvestorExperience(accessToken)
    this.setState({
      experienceList: getListAPI
    })
  }

  //set component value to field value to experienceIdentifier
  setInvestingExperience = async (experienceIdentifier) => {
    await this.setState({
      experienceIdentifier: experienceIdentifier.fieldValue,
      localStorageDataFlag: false
    });
  }

  //set details for store data
  setDetails = (experienceIdentifier) => {
    const { localStorageData, localStorageDataFlag } = this.state
    this.details = {
      experienceIdentifier: localStorageDataFlag ? localStorageData : experienceIdentifier,
    }
  }

  componentWillUnmount = () => {
    this.setState = () => {
      return;
    };
  }

  render() {
    //destructuring state
    const { experienceIdentifier, experienceList, localStorageDataFlag, localStorageData } = this.state;
    this.setDetails(experienceIdentifier) //call every time when user change value
    return (
      <>
        <div className="gi-from-section">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row">
                  <div className="col-md-8">
                    <div className="py-3">
                      <div className="gi-from-left">
                        {/* page count */}
                        <PageCount count={2} pageName={'Investment details'} />
                        <div className="gi-input gi-select">
                          {/* label component */}
                          <LabelComponent name={'experienceIdentifier'} labelName={`investment_page2_label`} />
                        </div>
                        <div className="mb-40">
                          {/* loop for every radio button */}
                          {experienceList.length !== 0 ? experienceList.map((value, i) => {
                            let text = value.label.split('(')
                            const checked = localStorageDataFlag ? value.value === localStorageData : value.value === experienceIdentifier
                            return (
                              <div className="customize-radio form-check mt-20" key={i}>
                                <label className="form-check-label" htmlFor={value.name}>
                                  <InputComponentRadio name={'experienceIdentifier'} value={value.value} title={value.label} text={text[0]} spanGreen={true} year={text[1] ? `(${text[1]}` : null} checked={checked} inputValue={this.setInvestingExperience} />
                                </label>
                              </div>
                            )
                          }) : <LoadingComponent />}
                        </div>
                        <div className="gi-btn-group d-none d-sm-flex">
                          {/* button component */}
                          <ButtonComponent value={this.details} previousRouting={'page12'} nextRouting={'page14'} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gi-from-right">
                      <div className="gi-text gi-employment-details-3 mt-0 mt-sm-3">
                        <div>
                          {/* investment details information component */}
                          <InformationComponent strongFlag={true} message={'investment_detail_info3'} message2={`investment_detail_info4`} />
                        </div>
                      </div>
                      <div className="gi-btn-group d-flex d-sm-none">
                        {/* button component */}
                        <ButtonComponent value={this.details} previousRouting={'page12'} nextRouting={'page14'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withAuth0(InvestmentDetailsPage2)