import React, { Component } from 'react'

import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
import PageCount from '../../../components/PageCount/PageCount';

import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import RightInformation from '../../../components/InformationComponent/RightInformation';

import AgeCalculator from '../../../service/utils/AgeCalculator';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';

import MaskInput from '../../../components/InputComponent/MaskInput';


export class PersonalDetailsPage3 extends Component {

    errorFlag = false;
    validAgeFlag = false;
    constructor(props) {
        super(props)
        this.setLocalStorageData()
        this.state = {
            dateOfBirth: '',
        }
    }

    setLocalStorageData = async () => {
        let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
        if (localStorageData !== null && localStorageData?.dateOfBirth !== undefined) {
            let dob = localStorageData?.dateOfBirth.split('-')
            let dd = dob[2]
            let mm = dob[1]
            let yyyy = dob[0]
            await this.setState({
                dateOfBirth: `${mm}/${dd}/${yyyy}` ?? '',
            })
        }
    }

    setDOB = async (dateOfBirth) => {
        await this.setState({
            dateOfBirth: dateOfBirth
        });
    }

    setDetails = (dateOfBirth) => {
        this.errorFlag = false
        this.validAgeFlag = false

        this.details = {
            dateOfBirth: ''
        }
        let date = this.state.dateOfBirth.split('/')
        let mm = date[0] ?? ''
        let dd = date[1] ?? ''
        let yyyy = date[2] ?? ''
        if (mm.length === 2 && dd.length === 2 && yyyy.length === 4 && !isNaN(mm) && !isNaN(dd) && !isNaN(yyyy)) {
            this.details = {
                dateOfBirth: `${yyyy}-${mm}-${dd}`
            }
            if ((new Date(dateOfBirth) < new Date('1/1/1901')) || (new Date(dateOfBirth) > new Date(Date.now()))) {
                this.errorFlag = true
            }
            if (AgeCalculator(new Date(dateOfBirth)) < 18 && (new Date(dateOfBirth) < new Date(Date.now()))) {
                this.validAgeFlag = true
            }

        }
    }

    render() {
        this.setDetails(this.state.dateOfBirth)
        return (
            <>
                <div className="gi-from-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="py-3">
                                            <div className="gi-from-left">
                                                <PageCount count={3} pageName={'Personal details'} />

                                                <div className="gi-input mb-40">
                                                    <LabelComponent name={'dateOfBirth'} labelName={'personal_page3_label'} />
                                                    <MaskInput name={'dateOfBirth'} placeholder={'placeholder_dob'} inputValue={this.setDOB} />
                                                    <ErrorComponent message={'error_not_valid_dob'} display={this.errorFlag ? 'block' : 'none'} />
                                                    <ErrorComponent message={'error_dob_under_18'} display={this.validAgeFlag ? 'block' : 'none'} />
                                                </div>

                                                <div className="gi-btn-group d-none d-sm-flex">
                                                    <ButtonComponent value={this.details} previousRouting={'page2'} nextRouting={'page4'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="gi-from-right">
                                        <RightInformation info1={'personal_detail_info1'} info2={'personal_detail_info2'} />

                                            <div className="gi-btn-group d-flex d-sm-none">
                                                <ButtonComponent value={this.details} previousRouting={'page2'} nextRouting={'page4'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PersonalDetailsPage3