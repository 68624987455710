import React, { Component } from 'react'

import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import RightInformation from '../../../components/InformationComponent/RightInformation'
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';
import { MAX_NUMBER } from '../../../config/Config';
import InputComponentCurrency from '../../../components/InputComponent/InputComponentCurrency';
export class EmploymentDetailsPage4 extends Component {
  constructor(props) {
    super(props)
    this.setLocalStorageData()
    this.state = {
      annualIncome: ''
    }
  }

  setLocalStorageData = async () => {
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    if (localStorageData !== null) {
      await this.setState({
        annualIncome: localStorageData?.annualIncome ?? '',
      })
    }
  }

  setAnnualIncome = async (annualIncome) => {
    await this.setState({
      annualIncome: annualIncome
    })
  }

  setDetails = async (annualIncome) => {
    this.details = {
      annualIncome: annualIncome
    }
  }
  render() {
    const { annualIncome } = this.state
    this.setDetails(this.state.annualIncome)
    return (
      <>
        <div className="gi-from-section">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row">
                  <div className="col-md-8">
                    <div className="py-3">
                      <div className="gi-from-left">
                        <PageCount count={4} pageName={'Employment details'} />
                        <div className="gi-input mb-40">
                          <LabelComponent name={'annualIncome'} labelName={'employment_page4_label'} />
                          <div className="gi-currency-input">
                            <InputComponentCurrency name={'annualIncome'} placeholder={'placeholder_currency_1'} inputValue={this.setAnnualIncome} />
                          </div>
                          <ErrorComponent message={'error_invalid_annual_income'} display={annualIncome > MAX_NUMBER ? 'block' : 'none'} />
                        </div>
                        <div className="gi-btn-group d-none d-sm-flex">
                          {/* <SubmitButton value={this.details} previousRouting={'page10'} /> */}
                          <ButtonComponent value={this.details} previousRouting={'page10'} nextRouting={'page12'} />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gi-from-right">
                      <RightInformation strong={'employment_detail_info5'} info1={'employment_detail_info6'} />

                      <div className="gi-btn-group d-flex d-sm-none">
                        {/* <SubmitButton value={this.details} previousRouting={'page10'} /> */}
                        <ButtonComponent value={this.details} previousRouting={'page10'} nextRouting={'page12'} />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default EmploymentDetailsPage4