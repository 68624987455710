//default library
import React, { Component, Fragment } from 'react'
import IMAGES from '../../config/Images'

//Loading component
export class LoadingComponent extends Component {
  render() {
    return (
      <Fragment>
        <div className="gi-spinner-animation">
          {/* <div className={`spinner-border ${this.props.classFalse ? '' : 'mt-40 mb-20'}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </div> */}
          <img src={IMAGES.SMILE_LOADER_ANIMATION} alt='loading' />
        </div>
      </Fragment>
    )
  }
}

export default LoadingComponent
