//default library
import React, { Component, Fragment } from 'react'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//import images
import IMAGES from '../../config/Images';
//custom component
import BasketCard from '../../components/CardComponent/BasketCard';
import TotalCard from '../../components/CardComponent/TotalCard';
import PageHeader from '../../components/PageHeaderComponent/PageHeader';
import DetailButton from '../../components/ButtonComponent/DetailButton';
import FundButton from '../../components/ButtonComponent/FundButton';
import ErrorPendingModal from '../../components/ModalComponent/ErrorPendingModal';
import FundModal from '../../components/ModalComponent/FundModal';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import BuyButton from '../../components/ButtonComponent/BuyButton';
// import BuyModal from '../../components/ModalComponent/BuyModal';
import KYCErrorModal from '../../components/ModalComponent/KYCErrorModal';
import WithdrawMoneyModal from '../../components/ModalComponent/WithdrawMoneyModal';
import WithdrawButton from '../../components/ButtonComponent/WithdrawButton';
import HomeGiftCard from '../../components/CardComponent/HomeGiftCard';
import BankConnectModal from '../../components/ModalComponent/BankConnectModal';
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'
import InputComponentText from '../../components/InputComponent/InputComponentText';
import ErrorComponent from '../../components/ErrorComponent/ErrorComponent';

//api service
import { bankLinkService } from '../../service/ApiService/BankService';
import * as PortfolioDetails from '../../middleware/PortfolioDetails'
import * as User from '../../middleware/User';
import * as TransferFundCall from '../../middleware/TransferFundCall';
import * as CreateBasket from '../../middleware/CreateBasket';
import { getAllCountry } from '../../middleware/DropdownList';

//local message
import IntlMessages from '../../helper/IntlMessages';
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';
import * as Config from '../../config/Config';

export class HomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userFirstName: localStorage.getItem('firstName'), //username
            hasKycErrors: JSON.parse(localStorage.getItem('hasKycErrors')), //check kyc has error or not

            bankConnect: true, //plaid connect
            openModelFund: false, //fund modal
            fundTransfer: false, //check fund pending or not
            isLoading: true, //loader
            isFunding: false, //fund pending is check
            openBuyModelFund: false, // open buy modal
            isBuyOrderPending: false, //check buy pending or not
            checkTotalCash: 0, //check total cash balance
            checkFundTransfer: false, //cash account has not balance
            openWithdrawModal: false, //open withdraw modal
            openBankModal: false, //bank modal

            investId: '', //invest button id
            portfolioSummaryData: '', //portfolio summary data
            userName: '', //user name

            kycError: [], //kyc error name
            basketArray: [], //add basket name and id
            countryList: [], //list of all country

            depositMessage: '', //pending message for funding
            withdrawMessage: '', //pending message for withdraw
            totalCardNote: '', //note for total card

            getFundLocation: [], //fund details
            giftDetails: [], //gift details
            transferDetails: {}, //transfer details

            basketName: '', //basket rename

            bankMessage: '', //bank label message

            plaidData: {
                customerFirstName: '',
                customerMiddleName: '',
                customerLastName: '',
                customerPhoneNumber: '',
                customerEmail: '',
                customerAddress: '',
            } //user data for plaid
        }
    }

    componentDidMount = async () => {
        window.scroll(0, 0)

        const { getAccessTokenSilently } = this.props.auth0

        let isBuyOrderPending = false
        let checkTotalCash = 0
        let isFunding = false
        let isBuyOrder = false
        let portfolioSummaryData = []
        let basketArray = [];
        let countryList = [];
        let depositMessage = '';
        let withdrawMessage = '';
        let totalCardNote = '';
        let getFundLocation = [];
        let giftDetails = [];
        let transferDetails = {};
        let checkFundTransfer = false;

        await this.setState({
            isLoading: true,
            portfolioSummaryData: ''
        })

        const accessToken = await getAccessTokenSilently() //get access token
        let [bankConnect, userData] = await Promise.all([bankLinkService(accessToken), User.getUserData(accessToken)])

        const userAddress = userData.userAddress
        const plaidData = {
            customerFirstName: userData.firstNameEncrypted,
            customerMiddleName: userData.middleNameEncrypted,
            customerLastName: userData.lastNameEncrypted,
            customerPhoneNumber: userData.phoneEncrypted,
            customerEmail: localStorage.getItem('userEmailId'),
            customerAddress: `${userAddress.street1} ${userAddress.city} ${userAddress.province} ${userAddress.postalCode}`,
        }
        //if no kyc error
        if (!userData.hasKycErrors) {

            [isFunding, isBuyOrder, portfolioSummaryData, getFundLocation] = await Promise.all([
                TransferFundCall.checkFundTransfer(accessToken), //funding is pending or not check
                TransferFundCall.isOrderBuyPending(accessToken), //order is pending or not check
                PortfolioDetails.portfolioSummary(accessToken), //get portfolio summary
                TransferFundCall.getFundLocations(accessToken) //get list of basket and account
            ])

            isBuyOrderPending = isBuyOrder.isPending || isFunding.isPending //portfolioSummaryData.totalCashBalance === 0
            checkTotalCash = portfolioSummaryData.totalCashBalance
            giftDetails = portfolioSummaryData?.giftDetails ?? []

            //set basket name
            for (let i = 0; i < portfolioSummaryData.baskets.length; i++) {
                // let data = await {
                //     id: portfolioSummaryData.baskets[i].id,
                //     name: portfolioSummaryData.baskets[i].name
                // }
                basketArray.push(portfolioSummaryData.baskets[i])
            }

            if (isFunding.isPending) {
                transferDetails = {
                    transferType: isFunding.transferType,
                    transferAmount: isFunding.transferAmount
                }
                if (isFunding.transferType === 'BANK_TO_BASKET') {
                    checkFundTransfer = false
                    depositMessage = ['funding_deposit_message', { type: 'bank to basket transfer', amount: `$${isFunding.transferAmount}` }]
                    withdrawMessage = ['funding_withdraw_message', { type: 'bank to basket transfer', amount: `$${isFunding.transferAmount}` }]
                    totalCardNote = ['funding_total_card_message', { type: 'bank to basket transfer', amount: `$${isFunding.transferAmount}` }]
                }
                else {
                    checkFundTransfer = isFunding.isPending
                    depositMessage = ['funding_deposit_message', { type: isFunding.transferType.toLowerCase(), amount: `$${isFunding.transferAmount}` }]
                    withdrawMessage = ['funding_withdraw_message', { type: isFunding.transferType.toLowerCase(), amount: `$${isFunding.transferAmount}` }]
                    totalCardNote = ['funding_total_card_message', { type: isFunding.transferType.toLowerCase(), amount: `$${isFunding.transferAmount}` }]
                }
            }
            else if (isBuyOrder.isPending) {
                depositMessage = ['buy_deposit_message', { type: isBuyOrder.orderType.toLowerCase(), basketName: isBuyOrder.basketName }]
                withdrawMessage = ['buy_withdraw_message', { type: isBuyOrder.orderType.toLowerCase(), basketName: isBuyOrder.basketName }]
                totalCardNote = ['buy_total_card_message', { type: isBuyOrder.orderType.toLowerCase(), basketName: isBuyOrder.basketName }]
            }
            else if (checkTotalCash <= 0) {
                totalCardNote = `total_card_message`
            }
        }
        else {
            countryList = await getAllCountry(accessToken)
        }
        localStorage.removeItem('hasKycErrors')
        await this.setState({
            hasKycErrors: userData.hasKycErrors,
            kycError: userData.kycError ? userData.kycError.split('|') : [],
            bankConnect: bankConnect, //set bank link flag
            isBuyOrderPending: isBuyOrderPending, //active and disabled invest portfolio button
            checkTotalCash: checkTotalCash, //active and disabled invest 
            portfolioSummaryData: portfolioSummaryData, //set portfolio summary data
            isFunding: !(isFunding.isPending || isBuyOrder.isPending), //check funding is inprogress or not
            basketArray: portfolioSummaryData.baskets, //basket name and id
            checkFundTransfer: checkFundTransfer, //check funding is pending or not
            userName: userData.name, //set username
            countryList: countryList, //list of country

            depositMessage: depositMessage, //pending message for funding
            withdrawMessage: withdrawMessage, //pending message for withdraw
            totalCardNote: totalCardNote, //note for total card
            bankMessage: 'bank_text1', //bank modal label text

            getFundLocation: getFundLocation, //fund location
            giftDetails: giftDetails, //fund location
            transferDetails: transferDetails, //fund transfer details

            plaidData: plaidData, //plaid data
            isLoading: false,
        })
    }

    //open fund transfer modal
    setOpenModal = async () => {
        const { bankConnect } = this.state
        await this.setState({
            openModelFund: bankConnect,
            openBankModal: !bankConnect,
            bankMessage: 'bank_text1'
        })
    }

    //open bank connect modal
    openBankModal = async () => {
        const { bankConnect } = this.state
        await this.setState({
            openModelFund: bankConnect,
            openBankModal: !bankConnect,
            bankMessage: 'bank_text1'
        })
    }

    //open withdraw modal
    openWithDrawModal = async () => {
        const { bankConnect } = this.state
        await this.setState({
            openWithdrawModal: bankConnect,
            openBankModal: !bankConnect,
            bankMessage: 'bank_text3'
        })
    }

    //open buy modal
    setBuyOpenModal = async () => {
        const { bankConnect } = this.state
        await this.setState({
            openModelFund: bankConnect,
            openBankModal: !bankConnect,
            bankMessage: 'bank_text1'
        })
    }

    //close fund modal
    closeModal = async (value) => {
        await this.setState({
            openModelFund: value,
        })
    }

    //close fund modal
    closeBuyModal = async (value) => {
        await this.setState({
            openBuyModelFund: value,
        })
    }

    //close error modal
    closeErrorModal = async (value) => {
        await this.setState({
            openModelFund: value,
            openBuyModelFund: value,
            openWithdrawModal: value
        })
    }

    //close bank modal
    closeBankModal = async () => {
        await this.setState({
            openBankModal: false,
        })
    }

    //close error modal
    closeBuyErrorModal = async (value) => {
        await this.setState({
            openBuyModelFund: value
        })
    }

    //get basket id when invest button click
    buyModalOpenId = async (value) => {
        await this.setState({
            investId: value
        })
    }

    //close withdraw modal 
    closeWithdrawModal = async (value) => {
        await this.setState({
            openWithdrawModal: value
        })
    }

    // change basket name
    changeBasketName = async (value) => {
        await this.setState({
            basketName: value
        })
    }

    renameBasket = async (e) => {
        const { basketName } = this.state
        const { getAccessTokenSilently } = this.props.auth0
        const basket = {
            identifier: e.target.id,
            name: basketName
        }
        await this.setState({
            isLoading: true,
            basketName: ''
        })
        const accessToken = await getAccessTokenSilently() //get access token
        await CreateBasket.renameBasket(accessToken, basket) //rename basket
        await this.componentDidMount() //call api again
        await this.props.createBasketRoute() //rename in navigation
    }

    render() {
        const { userFirstName, bankConnect, openModelFund, isLoading, isFunding,
            isBuyOrderPending, basketArray, hasKycErrors, kycError,
            openWithdrawModal, portfolioSummaryData, checkTotalCash, countryList, checkFundTransfer, userName, openBankModal,
            depositMessage, withdrawMessage, totalCardNote, getFundLocation, giftDetails, transferDetails, basketName, plaidData, bankMessage } = this.state
        // openBuyModelFund,investId
        return (
            <Fragment>
                {/* {kycError.length > 0 */}
                {kycError.length > 0 && hasKycErrors
                    ? <KYCErrorModal openKYCErrorModal={hasKycErrors} kycError={kycError} countryList={countryList} componentDidMount={this.componentDidMount} />
                    :
                    hasKycErrors
                        ? <LoadingComponent />
                        :
                        isLoading
                            ? <LoadingComponent />
                            : <Fragment>
                                {/* page header */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-gift-heading">
                                            <PageHeader h5={`home_heading`} strong={`hello`} userName={`${userFirstName}`} />
                                        </div>
                                    </div>
                                </div>
                                {/* actual content */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-g-right-sidebar-main">
                                            <div className="gi-g-right-sidebar-left">
                                                <div className="gi-g-details-card-left">
                                                    {/* fund account modal */}
                                                    {bankConnect
                                                        ? <div className="row">
                                                            <div className="col-12">
                                                                <div className="gi-d-invest-today-card mb-10">
                                                                    <div className="gi-d-invest-today-card-text">
                                                                        <h6>
                                                                            {IntlMessages('home_today', { br: <br /> })}
                                                                        </h6>
                                                                        <FundButton openFundModel={this.setOpenModal} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                    }

                                                    {/* bank connect modal */}
                                                    {!bankConnect
                                                        ? <div className="row">
                                                            <div className="col-12">
                                                                <div className="gi-new-b-connect-card mb-10">
                                                                    <div className="gi-new-b-connect-card-main">
                                                                        <div className="gi-new-b-connect-img">
                                                                            <img src={IMAGES.BANK_CONNECT_SVG} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="gi-new-b-connect-text">
                                                                            <h5>
                                                                                {IntlMessages('home_bank_connect', { br: <br /> })}
                                                                            </h5>
                                                                            <button type="button" onClick={this.openBankModal}>
                                                                                {IntlMessages('button_connect')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                    }

                                                    {/* total card */}
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="gi-d-total-card mb-10">
                                                                {portfolioSummaryData ? <TotalCard portfolioSummaryData={portfolioSummaryData} note={totalCardNote} /> : ''}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* transfer pending card */}
                                                    {checkFundTransfer
                                                        ? <div className="row">
                                                            <div className="col-12">
                                                                <div className="gi-d-bank-card mb-10">
                                                                    <div className="gi-d-bp-header"></div>
                                                                    <div className="gi-d-bp-main">
                                                                        <div className="gi-d-bp-card">
                                                                            <div className="gi-d-bp-img">
                                                                                <img src={IMAGES.PENDING_BANK_IMAGE} className="img-fluid" alt="" />
                                                                            </div>
                                                                            <div className="gi-d-bp-text">
                                                                                <h4>
                                                                                    {IntlMessages('transfer_pending')}:&nbsp;
                                                                                    {IntlMessages('bank_transfer_text1')}&nbsp;
                                                                                    {transferDetails.transferType === 'DEPOSIT' ? IntlMessages('from2') : ''}
                                                                                    {transferDetails.transferType === 'WITHDRAWAL' ? IntlMessages('to') : ''}
                                                                                    &nbsp;
                                                                                    {getFundLocation.map((e) => {
                                                                                        if (e.type === 'Bank Account') {
                                                                                            return (
                                                                                                e.name
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return null
                                                                                        }
                                                                                    })}
                                                                                </h4>
                                                                                <div className="mt-10">
                                                                                    <span>
                                                                                        {IntlMessages('transfer_amount')}:</span>
                                                                                    <h5>
                                                                                        {currencyFormatter(transferDetails.transferAmount)}
                                                                                    </h5>
                                                                                </div>
                                                                                <p className="mt-10">
                                                                                    {transferDetails.transferType === 'DEPOSIT' ? IntlMessages('transfer_business_day_deposit') : ''}
                                                                                    {transferDetails.transferType === 'WITHDRAWAL' ? IntlMessages('transfer_business_day_withdrawal') : ''}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                    }

                                                    {/* basket card*/}
                                                    {basketArray.length > 0
                                                        ? basketArray.map((e, i) => {
                                                            return (
                                                                <div className="row" key={i}>
                                                                    <div className="col-12">
                                                                        <div className="gi-d-retirement-card mb-10">
                                                                            <div className="gi-d-retirement-header">
                                                                                <div className="gi-d-retirement-heading">
                                                                                    {/* <div className="dropdown change-dropdown"> */}
                                                                                    <div className="change-dropdown">
                                                                                        <button type="button" id="change-dropdown-new" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                            <img src={IMAGES.VERTICAL_ICON} alt="" />
                                                                                        </button>
                                                                                        <ul className="dropdown-menu" aria-labelledby="change-dropdown-new">
                                                                                            <div className="change-card">
                                                                                                <label>
                                                                                                    {IntlMessages('basket_change_name')}
                                                                                                </label>
                                                                                                <InputComponentText name={'basket_name'} placeholder={'placeholder_change_name'} inputValue={this.changeBasketName} />
                                                                                                <ErrorComponent message={['error_max_length', { number: Config.MAX_NUMBER_16 }]} dashboard={true} display={basketName.length > Config.MAX_NUMBER_16 ? 'block' : 'none'} />
                                                                                                <ErrorComponent message={'error_basket_name'} dashboard={true} display={basketName && basketName.match(Config.WHITESPACE) ? 'block' : 'none'} />
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={this.renameBasket}
                                                                                                    disabled={
                                                                                                        !(basketName.length > 1 && basketName.length <= Config.MAX_NUMBER_16) ||
                                                                                                        basketName.match(Config.WHITESPACE)
                                                                                                    }
                                                                                                    id={e.identifier}
                                                                                                >
                                                                                                    {IntlMessages('button_change')}
                                                                                                </button>
                                                                                            </div>
                                                                                        </ul>
                                                                                    </div>
                                                                                    {e.name}
                                                                                    {portfolioSummaryData.baskets.map((basket, i) => {
                                                                                        if (basket.id === Number(e.id) && basket.isPending) {
                                                                                            return (
                                                                                                <Fragment key={i}>
                                                                                                    : <span>&nbsp;{IntlMessages('transfer_pending')}</span>
                                                                                                </Fragment>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return null
                                                                                        }
                                                                                    })
                                                                                    }
                                                                                </div>
                                                                                <DetailButton id={e.id} routing={'basket'} />
                                                                                <BuyButton openBuyModel={this.setBuyOpenModal} basketPage={false} disabled={isBuyOrderPending} id={e.id} buyModalOpenId={this.buyModalOpenId} />
                                                                            </div>
                                                                            {portfolioSummaryData ? <BasketCard id={e.id} portfolioSummaryData={portfolioSummaryData} /> : <LoadingComponent />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ''
                                                    }

                                                    {/* Gift card */}
                                                    {giftDetails.length > 0 && JSON.parse(process.env.REACT_APP_SHOW_NAVIGATION_GIFT)
                                                        ? giftDetails.map((e, i) => {
                                                            return (
                                                                // 
                                                                <div className="row" key={i}>
                                                                    <div className="col-12">
                                                                        <div className="gi-d-gift-card mb-10">
                                                                            <div className="gi-d-gift-header">
                                                                                <div className={`gi-d-gift-heading ${e.isPending ? 'roboto-serif' : ''}`}>
                                                                                    <a href="#!">
                                                                                        <img src={IMAGES.VERTICAL_ICON} alt="" />
                                                                                    </a>
                                                                                    {e.name}
                                                                                    {e.isPending
                                                                                        ? <Fragment>:<span>&nbsp;Pending</span></Fragment>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                                <DetailButton id={e.identifier} routing={'gift'} />
                                                                            </div>
                                                                            <HomeGiftCard giftDetails={e} key={i} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ''
                                                    }
                                                    {/* withdraw */}
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="gi-d-withdraw">
                                                                <WithdrawButton openWithDrawModal={this.openWithDrawModal} disabled={!(checkTotalCash > 0)} />

                                                                <p>{IntlMessages('home_static_t1', { span: <span>* </span> })}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gi-g-right-sidebar-right">
                                                <GiraffeLearnBannerSection />

                                                {/* <div className="gi-think-bnr-card">
                                                    <div className="gi-think-bnr-text">
                                                        <h5>
                                                            {IntlMessages('home_banner_t1')}
                                                        </h5>
                                                        <p>
                                                            {IntlMessages('home_banner_t2', { br: <br /> })}
                                                        </p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Bank connect modal */}
                                <BankConnectModal userName={userName} openBankModal={openBankModal} closeBankModal={this.closeBankModal} componentDidMount={this.componentDidMount} plaidData={plaidData} message={bankMessage} />

                                {/* if bank account is not account connect */}
                                {isFunding
                                    // Open fund transfer Modal
                                    ? <FundModal openFundModel={openModelFund} closeModal={this.closeModal} componentDidMount={this.componentDidMount} getFundLocation={getFundLocation} />
                                    // if fund in pending state then open error modal
                                    : <ErrorPendingModal openModelError={openModelFund} closeErrorModal={this.closeErrorModal} h3={depositMessage} label={'error_try_again'} image={IMAGES.UNLINK_BANK_SVG} />
                                }

                                {/* //! not using cause buy modal and fund modal is combine */}
                                {/* {!isBuyOrderPending
                                    //  Buy modal
                                    ? <BuyModal openBuyModelFund={openBuyModelFund} closeModal={this.closeBuyModal} componentDidMount={this.componentDidMount} investId={investId} getFundLocation={getFundLocation} />
                                    // if fund in pending state then open error modal
                                    : <ErrorPendingModal openModelError={openBuyModelFund} closeErrorModal={this.closeBuyErrorModal} h3={checkFundTransfer ? 'error_money_route' : 'error_transaction_process'} label={'error_try_again'} />
                                } */}

                                {/* Withdraw Money Modal */}
                                {isFunding
                                    // Open Modal
                                    ? <WithdrawMoneyModal openWithdrawModal={openWithdrawModal} closeModal={this.closeWithdrawModal} componentDidMount={this.componentDidMount} getFundLocation={getFundLocation} withdrawalAmount={portfolioSummaryData?.totalCashBalanceForWithdrawal} />
                                    // if transfer in pending state then open error modal
                                    : <ErrorPendingModal openModelError={openWithdrawModal} closeErrorModal={this.closeErrorModal} h3={withdrawMessage} label={'error_try_again'} image={IMAGES.UNLINK_BANK_SVG} />
                                }
                            </Fragment>
                }
            </Fragment>
        )
    }
}

export default withAuth0(HomePage)