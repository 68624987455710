import IntlMessages from "../helper/IntlMessages";

// default direction
export const defaultDirection = 'ltr'
export const defaultLocale = 'en-US'
export const localeOptions = [
    { id: 'en-US', name: 'English - US', direction: 'ltr' },
    { id: 'he-IL', name: 'Hebrew', direction: 'rtl' },
]

// radio button value
export const actionValue = [
    { value: 'true', label: IntlMessages('yes') },
    { value: 'false', label: IntlMessages('no') }
]

// taxes document
export const taxesDropdown = [
    { value: 'past-week', label: IntlMessages('past_week') },
    { value: 'three-month', label: IntlMessages('past_three_week') },
    { value: 'six-month', label: IntlMessages('past_six_week') },
    { value: 'one-year', label: IntlMessages('past_year') },
    { value: 'all-time', label: IntlMessages('all_time') },
]

export const historicalDropdown = [
    { value: 'one-month', label: IntlMessages('one_month') },
    { value: 'three-month', label: IntlMessages('three_month') },
    { value: 'ytd', label: IntlMessages('year_to_date') },
    { value: 'one-year', label: IntlMessages('one_year') },
    { value: 'two-year', label: IntlMessages('two_year') },
    { value: 'all-time', label: IntlMessages('all_time') },
] //static data for chart for historical

export const futureDropdown = [
    { value: '15', label: IntlMessages('year_number', { number: '15' }) },
    { value: '20', label: IntlMessages('year_number', { number: '20' }) },
    { value: '30', label: IntlMessages('year_number', { number: '30' }) },
    { value: '50', label: IntlMessages('year_number', { number: '50' }) },

] //static data for chart for future

export const startWithDropdown = [
    { value: 1000, label: IntlMessages('number', { number: '$1,000' }) },
    { value: 2000, label: IntlMessages('number', { number: '$2,000' }) },
    { value: 3000, label: IntlMessages('number', { number: '$3,000' }) },
]

export const waitPatientlyDropdown = [
    { value: 20, label: IntlMessages('years_number', { number: '20' }) },
    { value: 30, label: IntlMessages('years_number', { number: '30' }) },
    { value: 40, label: IntlMessages('years_number', { number: '40' }) },
]