//api call 
import axios from "axios";
//import Config for url
import { GET_TRANSACTION, GET_INVESTING_HISTORY, GET_DIVIDENDS, GET_ADVISORY_FEES } from "../config/UrlList";

import { ConsoleLog } from '../service/utils/ConsoleLog'
//get transaction
export const getTransaction = async (accessToken, dateRange) => {

    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "startDate": dateRange.startDate,
        "endDate": dateRange.endDate,
    }

    return await axios.post(GET_TRANSACTION, params, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))

}

//get transaction
export const getInvestingHistory = async (accessToken, dateRange) => {

    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "startDate": dateRange.startDate,
        "endDate": dateRange.endDate,
    }

    return await axios.post(GET_INVESTING_HISTORY, params, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))

}

//get transaction
export const getDividends = async (accessToken, dateRange) => {

    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "startDate": dateRange.startDate,
        "endDate": dateRange.endDate,
    }

    return await axios.post(GET_DIVIDENDS, params, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))

}

//get transaction
export const getAdvisoryFees = async (accessToken, dateRange) => {

    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params
    const params = {
        "startDate": dateRange.startDate,
        "endDate": dateRange.endDate,
    }

    return await axios.post(GET_ADVISORY_FEES, params, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))

}