//Default Library
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//Handle Button Click Event
import ButtonHandler from '../../handler/ButtonHandler';

//api call
import { UpdateCall } from '../../middleware/UpdateCall';

//console function
import { ConsoleLog } from '../../service/utils/ConsoleLog';

//auth0 library
import { useAuth0 } from "@auth0/auth0-react";

const SubmitButton = (props) => {
    const { getAccessTokenSilently } = useAuth0(); //get auth0 details
    const [isKycProcessing, setIsKycProcessing] = useState(false)
    const [dashboard, setDashboard] = useState(false)
    const [previousRoute, setPreviousRoute] = useState(false)

    //handle localStorageData, nextButton and previousButton
    let buttonHandler = ButtonHandler(props.value);

    //control previous Button
    const handleRoutingPrevious = () => {
        setPreviousRoute(true)
    }

    //control submit Button and store data into localStorage and send to API
    const submitValue = async () => {
        props.isLoading(true, false)
        await localStorage.setItem('onBoardingDetails', JSON.stringify(buttonHandler.onBoardingDetails))
        const accessToken = await getAccessTokenSilently() //get access token
        UpdateCall(accessToken)
            .then(res => {
                props.isLoading(res.data, res.error);
                if (!res.error) {
                    if (res.data.isKycProcessing) {
                        setIsKycProcessing(true)
                    }
                    if (!res.data.isKycProcessing) {
                        setDashboard(true)
                    }
                }
            })
            .catch(err => {
                ConsoleLog(' ', err)
            })
    }

    const eventListener = (e) => {
        if (e.key === 'Enter') {
            submitValue()
        }
    }

    // add event listener
    useEffect(() => {
        if (!buttonHandler.nextDisabled) {
            window.addEventListener("keydown", eventListener)
        }
        return () => {
            window.removeEventListener('keydown', eventListener);
        };
    })

    return (
        <>
            {isKycProcessing ? <Redirect push to={`/${props.nextRouting}`} /> : ''}
            {previousRoute ? <Redirect push to={`/onBoarding/${props.previousRouting}`} /> : ''}
            {dashboard ? <Redirect push to='/dashboard' /> : ''}
            {/* previous Button */}
            <button type="button" className="gi-prev-btn" disabled={buttonHandler.perviousDisabled} onClick={handleRoutingPrevious}>
                <span className="material-icons-round">
                    arrow_back_ios
                </span>
            </button>
            {/* next Button */}
            <button className="gi-next-btn" disabled={buttonHandler.nextDisabled} type="button" onClick={submitValue}>Submit</button>
        </>
    )
}

export default SubmitButton