module.exports = {
    'privacy_policy': 'מדיניות פרטיות',

    'privacy_policy_t1': 'Giraffe Invest, Inc.',
    'privacy_policy_t2': 'תאריך תוקף: 1 באפריל, 2022.',
    'privacy_policy_t3': 'הפרטיות שלך חשובה לנו',
    'privacy_policy_t4': `Giraffe Invest, Inc. ("ג'ירפה") מחויבת לשמור על השימוש במידע אישי שאנו משיגים כיועץ ההשקעות הרשום שלך ("RIA"), כמתואר כאן במדיניות הפרטיות שלנו ("המדיניות"). ג'ירפה אינה מוכרת את המידע האישי הלא-פומבי שלך לאף אחד. אנחנו גם לא מספקים מידע כזה לאחרים, למעט למטרות עסקיות דיסקרטיות והגיוניות בקשר עם השירות והניהול של מערכת היחסים שלנו איתך, כפי שנדון להלן. ג'ירפה אינה חולקת מידע אישי שאינו פומבי עם צדדים שלישיים שאינם קשורים, אלא אם לקוח מסכים באופן ספציפי לחשיפה. כל שיתוף כזה ידרוש "הצטרפות" על ידי חתימה על הצהרה המציגה הצהרה חיובית על הרשאה לחשוף מידע כזה.`,
    'privacy_policy_t5': `למטרות מדיניות זו, המונח "ג'ירפה" כולל את השותפים, השותפים, הסוכנים והעובדים של ג'ירף. המונח "לקוח" מתייחס לאדם שמאפשר לג'ירף לשמש כ-RIA שלך על ידי חתימה על הסכם לקוח ג'ירף.`,
    'privacy_policy_t6': `RIAs חייבים לשתף חלק מהמידע האישי שלך במהלך הטיפול בחשבונך. חוקים פדרליים ומדינתיים מעניקים לך את הזכות להגביל חלק מהשיתוף הזה ומחייבים את ה-RIA לחשוף כיצד אנו אוספים, משתפים ומגנים על המידע האישי שלך. פרטים על הגישה שלנו לפרטיות והאופן שבו המידע האישי שלך שאינו ציבורי נאסף והשימוש בו מפורטים במדיניות זו.`,
    'privacy_policy_t7': `מידע שאנו אוספים`,
    'privacy_policy_t8': `ג'ירף אוספת מידע אישי שאינו פומבי מלקוחות על מנת להבטיח שירות באיכות גבוהה.`,
    'privacy_policy_t9': `מידע לקוח.`,
    'privacy_policy_t10': `בעת פתיחת חשבון עם ג'ירף, עליך לספק לג'ירף את המידע הבא כדי שנוכל להבטיח שירות באיכות גבוהה:`,
    'privacy_policy_t11': `שמך החוקי המלא, הכתובות, כתובת הדוא"ל, מספרי הטלפון, תאריך הלידה, הביטוח הלאומי או מספר זיהוי משלם המסים שלך;`,
    'privacy_policy_t12': `חווית ההשקעה, היעדים והפעילות שלך; ו`,
    'privacy_policy_t13': `פרטי החשבון שלך (כולל מוסדות אחרים), הכנסות והוצאות, נכסים והתחייבויות.`,
    'privacy_policy_t14': `מידע ממקורות אחרים.`,
    'privacy_policy_t15': `ג'ירף גם אוספת את פרטי הלקוח הבאים ממקורות אחרים:`,
    'privacy_policy_t16': `כל הסכמי משמורת, תיווך וייעוץ, הסכמי ייעוץ אחרים ומסמכים משפטיים;`,
    'privacy_policy_t17': `כל מידע עסקה אצלנו או אצל אחרים, בקשות וטפסים לחשבון, שאלוני השקעה ומסמכי התאמה; ו`,
    'privacy_policy_t18': `כל מידע אחר שאנו עשויים להזדקק לשירות הולם בחשבונך.`,
    'privacy_policy_t19': `עוגיות ופיקסלים.`,
    'privacy_policy_t20': `בכל פעם שאתה מבקר באתר האינטרנט שלנו או משתמש בשירותים שלנו, ג'ירפה עשויה להשתמש בקובצי Cookie או בפיקסלים כדי לאחסן מידע אישי, כגון כתובת ה-IP שלך או היסטוריית הגלישה שלך. איסוף מידע זה נועד לייעל את ביצועי האתר וחווית המשתמש. יש לך אפשרות לא לקבל את השימוש בעוגיות או בפיקסלים באתר של ג'ירף, אך פעולה זו עלולה להשפיע על ביצועי האתר.`,
    'privacy_policy_t21': `כיצד אנו משתמשים במידע שלך`,
    'privacy_policy_t22': `שימוש עסקי.`,
    'privacy_policy_t23': `ג'ירף משתמשת במידע האישי הלא-פומבי שלך למגוון מטרות עסקיות כגון לספק ייעוץ ושירותים פיננסיים, להציע תמיכת לקוחות, לייעל את המוצרים והתכונות שלנו, למקסם את שביעות רצון הלקוחות מהשירותים שלנו ולהבטיח ציות לכללים והתקנות של הפדרלי. גופים כמו רשות ניירות ערך והרשות לפיקוח על התעשייה הפיננסית.`,
    'privacy_policy_t24': `שימושים אחרים.`,
    'privacy_policy_t25': `אנו עשויים גם להשתמש במידע האישי שאינו ציבורי שלך כדי ליצור איתך קשר לגבי שירותים או משאבים שבהם אתה עשוי להתעניין. אם אינך מעוניין יותר לקבל הודעות אלה, אנא עקוב אחר הוראות הביטול להלן.`,
    'privacy_policy_t26': `כיצד אנו משתפים את המידע שלך`,
    'privacy_policy_t27': `RIA משתף מידע אישי של הלקוח כדי ליישם את שירותיו ביעילות. בסעיף שלהלן, אנו מפרטים כמה סיבות מדוע אנו עשויים לשתף את המידע האישי שלך.`,
    'privacy_policy_t28': `מתן שירות ללקוחותינו.`,
    'privacy_policy_t29': `אנו עשויים לשתף מידע אישי שאינו פומבי עם צדדים שלישיים שאינם קשורים (כגון מנהלים, ברוקרים, אפוטרופוסים, רגולטורים, סוכנויות אשראי, יועצים ומוסדות פיננסיים אחרים) לפי הצורך כדי שנוכל לספק לך שירותים מוסכמים, בהתאם להחלה חוק, לרבות אך לא רק: עיבוד עסקאות; תחזוקת חשבון כללית; תגובה לרגולטורים או חקירות משפטיות; ודיווח אשראי. ההסכמים שלנו עם צדדים שלישיים אלה מחייבים אותם לשמור על סודיות המידע שלך.`,
    'privacy_policy_t30': `מטרות משפטיות.`,
    'privacy_policy_t31': `אנו עשויים לשתף את המידע האישי שלך שאינו ציבורי כאשר הדבר נדרש על פי חוק, כגון כאשר אנו מאמינים כי יש צורך לחקור, למנוע או לנקוט בפעולה בנוגע לפעילויות לא חוקיות, חשד להונאה, ריצה חזיתית או בריחה, מצבים הכרוכים באיומים פוטנציאליים על בטיחות פיזית של כל אדם, או חשד להפרות של תנאי השימוש או הסכמי הלקוחות שלנו, בתגובה לשירות של הליך משפטי, או כחלק מדיווח ללשכת אשראי.`,
    'privacy_policy_t32': `מטרות שיווק.`,
    'privacy_policy_t33': `ג'ירפה אינה חושפת ואינה מתכוונת לחשוף מידע אישי עם צדדים שלישיים שאינם קשורים כדי להציע לך שירותים. חוקים מסוימים עשויים להעניק לנו את הזכות לשתף את המידע האישי שלך עם מוסדות פיננסיים שבהם אתה לקוח ושיש לג'ירף או ללקוח הסכם רשמי עם המוסד הפיננסי. אנו נשתף מידע רק למטרות של שירות חשבונותיך, לא למטרות שיווקיות.`,
    'privacy_policy_t34': `משתמשים מורשים.`,
    'privacy_policy_t35': `המידע האישי שלך שאינו פומבי עשוי להיחשף לך ולאנשים שאנו מאמינים שהם הסוכן/ים או הנציג/ים המורשים שלך. ללקוחות יש את הזכות להגביל את חשיפת המידע למשתמשים מורשים.`,
    'privacy_policy_t36': `מידע על לקוחות לשעבר.`,
    'privacy_policy_t37': `ג'ירפה אינה חושפת ואינה מתכוונת לחשוף מידע אישי שאינו פומבי לצדדים שלישיים שאינם קשורים ביחס לאנשים שאינם עוד לקוחותינו.`,
    'privacy_policy_t38': `מידע על ביטול הסכמה`,
    'privacy_policy_t39': `בהתאם לחוק הפדרלי, ג'ירפה מספקת ללקוחות את הבחירה לבטל את הסכמתך לשימושים מסוימים ולחשיפה של המידע האישי שלך שאינו פומבי.`,
    'privacy_policy_t40': `מסרב לספק.`,
    'privacy_policy_t41': `אתה תמיד רשאי לסרב לספק לג'ירף מידע אישי שאינו פומבי, אך פעולה זו עלולה לפסול אותך מהשימוש בשירותי ג'ירף הדורשים מידע כזה.`,
    'privacy_policy_t42': `ביטול הסכמה.`,
    'privacy_policy_t43': `לקוחות שהסכימו לכך`,
    'privacy_policy_t44': `השימוש של ג'ירפה במידע האישי שאינו ציבורי שלך עשוי לבטל את הסכמתך בכל עת על ידי יצירת קשר בטלפון או בדוא"ל. אם לקוח יבטל את הסכמתו לספק מידע אישי שאינו פומבי, הוא לא יוכל להמשיך להשתמש בשירותי ג'ירף. לפני השימוש במידע אישי שאינו ציבורי למטרות חדשות, נספק לך מידע על ההזדמנות החדשה הזו.`,
    'privacy_policy_t45': `תקשורת דוא"ל וטלפון.`,
    'privacy_policy_t46': `ג'ירף עשויה להשתמש במידע האישי שאינו ציבורי שלך כדי לתקשר איתך לגבי הזדמנויות או שירותים מסוימים. ללקוחות יש אפשרות לבטל את הסכמתם לאימיילים פרסומיים או שיווקיים על ידי ביצוע הוראות "ביטול הרשמה" בהודעות דוא"ל אלה. אך אינך יכול לבטל את הסכמתך לתקשורת מנהלתית, לרבות הודעות דוא"ל המכילות דפי חשבון, עדכונים לתנאי השימוש או למדיניות זו, או הודעות לגבי שינויים בחשבונך.`,
    'privacy_policy_t47': `כיצד לגשת למידע שלך ולשנות אותו`,
    'privacy_policy_t48': `לאחר שתהפוך ללקוח, תוכל לגשת לפרופיל החשבון שלך, להציג כל מידע מאוחסן ולשנות מידע זה. אם יש לך בעיות כלשהן עם גישה או שינוי של המידע שלך, אתה יכול לפנות אלינו בכתובת`,
    'privacy_policy_t49': `(860) 878-7180 ​​או`,
    'privacy_policy_t50': `legal@giraffeinvest.com`,
    'privacy_policy_t51': `כיצד לבקש מחיקת המידע שלך`,
    'privacy_policy_t52': `לקוחות יכולים לבחור לסגור את חשבון הג'ירפה שלהם על ידי ביצוע ההוראות באתר שלנו או פנייה ישירה אלינו. שימו לב שג'ירף שומרת מידע מחשבונות שנמחקו כנדרש למטרות משפטיות, רגולטוריות ואבטחה.`,
    'privacy_policy_t53': `כיצד אנו מגנים על המידע שלך`,
    'privacy_policy_t54': `אמצעי הגנה.`,
    'privacy_policy_t55': `כדי להגן על המידע האישי שלך מפני גישה ושימוש בלתי מורשית, אנו שומרים על אמצעי אבטחה פיזיים, פרוצדורליים ואלקטרוניים. אלה כוללים אמצעי הגנה כמו סיסמאות מאובטחות, אחסון קבצים מוצפן וסביבת משרד מאובטחת. ספקי הטכנולוגיה שלנו מספקים אבטחה ובקרת גישה על מידע אישי ויש להם מדיניות לגבי העברת נתונים. השותפים שלנו מאומנים באחריותם להגן על המידע האישי של הלקוחות שלנו.`,
    'privacy_policy_t56': `צד שלישי.`,
    'privacy_policy_t57': `אנו דורשים מצדדים שלישיים המסייעים במתן השירותים שלנו לך להגן על המידע האישי שהם מקבלים מאיתנו.`,
    'privacy_policy_t58': `חשבונות סגורים.`,
    'privacy_policy_t59': `אם תבחר לסגור את חשבונך או שחשבונך ייסגר אצלנו, ג'ירף תמשיך לפעול לפי הנוהלים המפורטים במדיניות זו.`,
    'privacy_policy_t60': `שינויים במדיניות הפרטיות שלנו`,
    'privacy_policy_t61': `אנו נשלח לך עותק של מדיניות זו מדי שנה, כל עוד אתה מקיים איתנו קשר מתמשך. מעת לעת אנו עשויים לשנות מדיניות זו ונספק לך מדיניות מתוקנת אם השינויים ישנו מהותית את מדיניות הפרטיות הקודמת. עם זאת, לא נשנה את מדיניות הפרטיות שלנו כדי לאפשר שיתוף של מידע אישי שאינו פומבי מלבד כמתואר בהודעה זו, אלא אם כן נודיע לך תחילה ונספק לך הזדמנות למנוע את שיתוף המידע.`,
    'privacy_policy_t62': `זכויות הפרטיות של תושבי קליפורניה`,
    'privacy_policy_t63': `על פי חוק פרטיות הצרכן של קליפורניה, לקוחות שהם תושבי קליפורניה עשויים לבקש ולקבל מג'ירפה מידע הנוגע למידע האישי הלא-פומבי שלך שאספנו ב-12 החודשים האחרונים. יש לך את הזכות לבקש שנמחק לצמיתות מידע אישי זה שאינו פומבי. שימו לב שג'ירף עשויה לדחות בקשת מחיקה זו למידע הנדרש למטרות משפטיות, רגולטוריות ואבטחה.`,
    'privacy_policy_t64': `לקוחות שהם תושבי קליפורניה יכולים לבקש ולקבל מאיתנו פעמיים בשנה, ללא תשלום, מידע הקשור למידע אישי שאספנו ב-12 החודשים שקדמו לבקשה. חוק פרטיות הצרכן של קליפורניה ("החוק") מעניק ללקוחות גם את הזכות לבקש מחיקה לצמיתות של מידע אישי. לקוחות יכולים לבקש מידע הקשור למידע אישי שאספנו לגביהם ו/או לבקש מחיקה של המידע האישי שלהם על ידי ביצוע הליך "ביטול ההסכמה" לעיל.`,
    'privacy_policy_t65': `החוק גם מתיר ללקוחות ג'ירפים שהם תושבי קליפורניה לבקש ולקבל מג'ירף רשימה של צדדים שלישיים להם חשפנו מידע אישי שאינו פומבי למטרות השיווק של אותו צד שלישי וסוג המידע שנחשף לאותם צדדים. כאמור לעיל, ג'ירף אינה מוכרת מידע אישי שאינו פומבי לצדדים שלישיים.`,
    'privacy_policy_t66': `שאלות`,
    'privacy_policy_t67': `אתה יכול לשאול שאלות או להשמיע חששות, כמו גם לקבל עותק של מדיניות הפרטיות הנוכחית שלנו על ידי יצירת קשר בכתובת`,
}