import React, { Component, Fragment } from 'react';
import IntlMessages from '../../helper/IntlMessages';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

class PaginationNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0
        };
    }

    fetchPageNumbers = () => {
        const totalPages = this.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbors = this.pageNeighbors + 1;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (this.pageNeighbors * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {

            const startPage = Math.max(2, currentPage - pageNeighbors);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages, totalPages]; // handle: < {5 6} [7] {8 9} (10)
                    // pages = [LEFT_PAGE, ...extraPages, ...pages]; // handle: (1) < {5 6} [7] {8 9} (10)
                    break;
                }

                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [1, ...pages, ...extraPages, RIGHT_PAGE]; // handle: (1) {2 3} [4] {5 6} >
                    // pages = [...pages, ...extraPages, RIGHT_PAGE]; // handle: (1) {2 3} [4] {5 6} > (10)
                    break;
                }

                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]; // handle: < {4 5} [6] {7 8} >
                    break;
                }
            }

            return [...pages]; // handle: < {4 5} [6] {7 8} >
            // return [1, ...pages, totalPages]; // handle: (1) < {4 5} [6] {7 8} > (10)
        }

        return range(1, totalPages);
    }

    componentDidMount() {
        const { totalRecords = null, pageLimit = 30, pageNeighbors = 0 } = this.props;

        this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
        this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;
        // pageNeighbors can be: 0, 1 or 2
        this.pageNeighbors = typeof pageNeighbors === 'number' ? Math.max(0, Math.min(pageNeighbors, 2)) : 0;

        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

        this.setState({
            currentPage: 1
        })

        this.gotoPage(1);
    }

    componentDidUpdate = (previousProps, previousState) => {
        if (this.props.totalRecords !== previousProps.totalRecords) {
            this.componentDidMount()
        }
    }

    gotoPage = page => {
        const { onPageChanged = f => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, this.totalPages));
        const paginationData = {
            currentPage,
            totalPages: this.totalPages,
            pageLimit: this.pageLimit,
            totalRecords: this.totalRecords
        };

        this.setState({ currentPage }, () => onPageChanged(paginationData));
    }

    handleClick = page => evt => {
        evt.preventDefault();
        this.gotoPage(page);
    }

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - (this.pageNeighbors));
    }

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + (this.pageNeighbors));
    }

    render() {
        if (!this.totalRecords || this.totalPages === 1) return null;

        const { currentPage } = this.state;
        const pages = this.fetchPageNumbers();

        return (
            <Fragment>
                <nav className='gi-p-pagination' aria-label="paginationNav">
                    <ul className="pagination pagination-sm">
                        {pages.map((page, index) => {
                            if (page === LEFT_PAGE) {
                                return (
                                    <li key={index} className="page-item">
                                        <a className="page-link page-previous" href="#!" onClick={this.handleMoveLeft}>
                                            {IntlMessages('previous')}
                                        </a>
                                    </li>
                                );
                            }
                            else if (page === RIGHT_PAGE) {
                                return (
                                    <li key={index} className="page-item">
                                        <a className="page-link page-next" href="#!" onClick={this.handleMoveRight}>
                                            {IntlMessages('next')}
                                        </a>
                                    </li>
                                );
                            }
                            else {
                                return (
                                    <li key={index} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                        <a className="page-link" href="#!" onClick={this.handleClick(page)}>{page}</a>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </nav>
            </Fragment>
        );
    }
}

export default PaginationNew;