//default library
import React, { Component } from 'react'

class InputComponentRadio extends Component {

    //default constructor
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this) //bind this keyword to handleOnChange function
        this.state = { 
            fieldValue: this.props.value ?? '',
            fieldName: '',
            localStorageData: '',
            localStorageFlag: false
        }
    }

    //handle onChange event
    handleOnChange = async (e) => {
        await this.setState({
            localStorageFlag: false,
            fieldValue: e.target.value,
            fieldName: e.target.title
        })
        this.props.inputValue(this.state)
    }

    render() {
        //destructuring props
        const { name, value, title, text, spanGreen, year, checked } = this.props
        return (
            <>
                <input
                    type='radio'
                    className='form-check-input'
                    id={title}
                    name={name}
                    value={value}
                    onChange={this.handleOnChange} //invoke on every key press
                    title={title}  
                    checked={checked}
                />{text}{spanGreen ? <span className="text-nowrap"> {year}</span> : ''}
            </>
        )
    }
}

export default InputComponentRadio