//default library
import React, { Component, Fragment } from 'react'
import Select from 'react-select';
import $ from 'jquery';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
// chart 
import ReactApexChart from 'react-apexcharts';
// formatted message
import IntlMessages from '../../helper/IntlMessages';
// API
import * as CreateBasket from '../../middleware/CreateBasket';
// custom function
import { currencyFormatter } from '../../service/utils/CurrencyFormatter';
import { DateRange } from '../../service/utils/DateFilter';
// custom component
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SelectDashBoard from '../SelectComponent/SelectDashBoard';
// default library
import { futureDropdown, historicalDropdown } from '../../config/DefaultValue';

export class ChartDetailSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isChartLoading: false,
            seriesHistorical: [{
                name: '',
                data: ''
            }], //static data for chart for historical

            seriesFuture: [{
                name: '',
                data: ''
            }, {
                name: '',
                data: ''
            }, {
                name: '',
                data: ''
            }], //static data for chart for future

            optionsHistorical: {
                chart: {
                    height: 200,
                    type: "area"
                },
                colors: ['#43B780'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 3,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0,
                        opacityFrom: 0,
                        opacityTo: 0,
                        stops: [0, 90, 100]
                    }
                },
                annotations: {
                    yaxis: [{
                        y: 0,
                        borderColor: 'black',
                        fillColor: 'black',
                        label: {
                            show: true,
                            // text: 'Support',
                            style: {
                                color: "#fff",
                                background: '#00E396'
                            }
                        }
                    }]
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            colors: '#42474B',
                            fontSize: '14px',
                            fontFamily: 'Roboto Slab',
                            fontWeight: '400',
                        },
                        // offsetY: 0,
                        // offsetX: 6,
                    },
                },
                yaxis: [{
                    tickAmount: 5,
                    floating: false,
                    opposite: true,
                    labels: {
                        style: {
                            colors: '#42474B',
                            fontSize: '11px',
                            fontFamily: 'Roboto Slab',
                            fontWeight: 400,
                            letterSpacing: '0.02em',
                            opacity: 0.7
                        },
                        formatter: function (val) {
                            return currencyFormatter(val);
                        },
                        offsetY: 0,
                        offsetX: 0,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false
                    },
                    min: (min) => Math.floor(min),
                    max: (max) => Math.ceil(max),
                }],
                legend: {
                    horizontalAlign: 'left',
                    fontSize: '12px',
                    fontFamily: `Roboto Slab`,
                    fontWeight: 400,
                    offsetX: -35,
                    offsetY: 0,
                    markers: {
                        width: 8,
                        height: 8,
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 0
                    },
                    labels: {
                        colors: '#484848',
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                    }
                },
            }, //static data for chart for historical

            optionsFuture: {
                chart: {
                    id: 'area-datetime',
                    type: 'area',
                    height: 200,
                    zoom: {
                        autoScaleYaxis: true
                    }
                },
                colors: ['#4FAA7E', '#4FAA7E', '#4FAA7E'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2, 4, 2],
                    curve: 'smooth',
                    dashArray: [2, 0, 2]
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 0,
                        opacityFrom: 0,
                        opacityTo: 0,
                        stops: [0, 90, 100]
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            colors: '#42474B',
                            fontSize: '14px',
                            fontFamily: 'Roboto Slab',
                            fontWeight: '400',
                        },
                    },
                },
                yaxis: [{
                    tickAmount: 5,
                    floating: false,
                    opposite: true,
                    labels: {
                        style: {
                            colors: '#42474B',
                            fontSize: '11px',
                            fontFamily: 'Roboto Slab',
                            fontWeight: 400,
                            letterSpacing: '0.02em',
                            opacity: 0.7
                        },
                        formatter: function (val) {
                            return currencyFormatter(val);
                        },
                        offsetY: 0,
                        offsetX: 0,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false
                    },
                    min: (min) => Math.floor(min),
                    max: (max) => Math.ceil(max),
                }],
                legend: {
                    horizontalAlign: 'left',
                    fontSize: '12px',
                    fontFamily: `Roboto Slab`,
                    fontWeight: 400,
                    offsetX: -35,
                    offsetY: 0,
                    markers: {
                        width: 8,
                        height: 8,
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 0
                    },
                    labels: {
                        colors: '#484848',
                    }
                },
                tooltip: {
                    x: {
                        format: 'yyyy'
                    }
                },
            }, //static data for chart for future

            option: [
                { value: '0', label: IntlMessages('basket_historical') },
                { value: '1', label: IntlMessages('basket_future') },
            ],
            activeTab: 0
        };
    };

    componentDidMount = async () => {
        const { seriesHistorical, seriesFuture } = this.props
        await this.setState({
            seriesHistorical: seriesHistorical,
            seriesFuture: seriesFuture
        })
    }

    componentDidUpdate = async (previousProps, previousState) => {
        const { chartValue } = this.props
        if (previousProps.chartValue !== chartValue) {
            await this.setState({
                option: [
                    { value: '0', label: IntlMessages('basket_historical') },
                    { value: '1', label: IntlMessages('basket_future') },
                ],
            })
        }

        const tabActive = this.checkActiveTab()
        const { activeTab, count } = this.state
        if (previousState.activeTab === activeTab && previousState.count === count) {
            await this.setState({
                activeTab: tabActive,
                count: count + 1
            })

        }
    }

    selectValueHistorical = async (value) => {
        const { basketId, basketDetails } = this.props;
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isChartLoading: true
        })

        let dateRange = await DateRange(value)
        const accessToken = await getAccessTokenSilently() //get access token
        let historicalData = await CreateBasket.getHistoricalProjection(accessToken, basketId, dateRange) //get data for historical chart
        let historicalChartData = historicalData.map((e) => {
            return ({
                x: e.performanceDate,
                y: e.gain
            })
        })

        let seriesHistorical = [{
            name: basketDetails.name,
            data: historicalChartData
        }] //create data for historical chart

        await this.setState({
            seriesHistorical: seriesHistorical, //historical data
            isChartLoading: false
        })
    }

    selectValueFuture = async (value) => {
        const { basketId, basketDetails } = this.props;
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isChartLoading: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        let futureData = await CreateBasket.getFutureProjection(accessToken, basketId, value) //get data for future chart
        let futureDataActual = await futureData.map((e) => {
            return ({
                x: e.year,
                y: e.growthRate
            })
        })
        let futureDataMin = await futureData.map((e) => {
            return ({
                x: e.year,
                y: e.minGrowthRate
            })
        })
        let futureDataMax = await futureData.map((e) => {
            return ({
                x: e.year,
                y: e.maxGrowthRate
            })
        })

        let seriesFuture = [
            {
                name: `${basketDetails.name} Max`,
                data: futureDataMax
            },
            {
                name: `${basketDetails.name}`,
                data: futureDataActual
            },
            {
                name: `${basketDetails.name} Min`,
                data: futureDataMin
            }
        ] //create data for future chart

        await this.setState({
            seriesFuture: seriesFuture, //future data
            isChartLoading: false
        })
    }

    // select value change
    handleOnChange = async (e) => {
        $('#pills-tab1 li a').removeClass('active show');
        $('#pills-tab1 li a').eq(e.value).addClass('active show');

        $('#pills-tabContent1 .tab-pane').removeClass('active show');
        $('#pills-tabContent1 .tab-pane').eq(e.value).addClass('active show');

        let tabs = document.querySelectorAll('#pills-tab1 li a') //get all tab
        const activeTab = Array.from(tabs).findIndex((e, i) => {
            if (e.className.search('active') > 0) {
                return e
            }
            else {
                return null
            }
        }) //get index of active tab

        await this.setState({
            activeTab: activeTab
        })
    }

    // tab change
    tabClick = async () => {
        const activeTab = await this.checkActiveTab()

        await this.setState({
            activeTab: activeTab
        })
    }

    //check active tab in pill tabs
    checkActiveTab = () => {
        let tabs = document.querySelectorAll('#pills-tab1 li a') //get all tab
        const activeTab = Array.from(tabs).findIndex((e, i) => {
            if (e.className.search('active') > 0) {
                return e
            }
            else {
                return null
            }
        }) //get index of active tab
        return activeTab
    }

    render() {
        const { isChartLoading, optionsHistorical, optionsFuture, seriesHistorical, seriesFuture, option, activeTab } = this.state

        return (
            <Fragment>
                {/* loading while chart api call */}
                {isChartLoading ? <LoadingComponent /> : ''}

                {/* //TODO: select dropdown in mobile */}
                <div className="gi-p-tabs mt-0">
                    {/* tab */}
                    <div className="gi-cb-graph-header-card">
                        {/* select dropdown */}
                        <div className="gi-p-select-tabs custom-react-two">
                            <Select
                                classNamePrefix='react-select-chart'
                                options={option} //dropdown value
                                onChange={this.handleOnChange}
                                value={option[activeTab]}
                            />
                        </div>
                        {/* tabs click*/}
                        <div className="gi-p-tabs-btns">
                            <ul className="nav nav-pills nav-justified" id="pills-tab1" role="tablist" onClick={this.tabClick}>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="pills-Historical-tab" data-bs-toggle="pill" data-bs-target="#pills-Historical" href="#pills-Historical" role="tab" aria-controls="pills-Historical" aria-selected="true">
                                        {IntlMessages('basket_historical')}
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-Future-tab" data-bs-toggle="pill" data-bs-target="#pills-Future" href="#pills-Future" role="tab" aria-controls="pills-Future" aria-selected="false">
                                        {IntlMessages('basket_future')}
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" href='#!'> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* content */}
                    <div className="gi-cb-graph-body-card">
                        <div className="tab-content" id="pills-tabContent1">
                            {/* historical */}
                            <div className="tab-pane fade show active" id="pills-Historical" role="tabpanel" aria-labelledby="pills-Historical-tab">
                                <div className="gi-cb-dropdown">
                                    <SelectDashBoard option={historicalDropdown} name={'historicalReturn'} value={'one-month'} selectValue={this.selectValueHistorical} />
                                </div>
                                {seriesHistorical[0].data.length > 2
                                    ? <ReactApexChart options={optionsHistorical} series={seriesHistorical} type="area" height={200} />
                                    : <strong>
                                        <center>
                                            {IntlMessages('basket_not_enough')}
                                        </center>
                                    </strong>
                                }
                            </div>
                            {/* future */}
                            <div className="tab-pane fade" id="pills-Future" role="tabpanel" aria-labelledby="pills-Future-tab">
                                <div className="gi-cb-dropdown">
                                    <SelectDashBoard option={futureDropdown} name={'futurePrediction'} value={'15'} selectValue={this.selectValueFuture} />
                                </div>
                                {seriesFuture[1].data.length
                                    ? <ReactApexChart options={optionsFuture} series={seriesFuture} type="area" height={200} />
                                    : <strong>
                                        <center>
                                            {IntlMessages('basket_not_enough')}
                                        </center>
                                    </strong>
                                }
                                <div className="gi-cb-text">
                                    <p>
                                        {IntlMessages('basket_chart_text2')}
                                        <br />
                                        {IntlMessages('basket_chart_text1')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withAuth0(ChartDetailSection)