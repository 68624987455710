//api call 
import axios from "axios";
//import Config for url
import { BANK_LIST_URL, BANK_ACCOUNT_LINKED, BANK_ACCOUNT_UNLINKED } from "../../config/UrlList";
import { ConsoleLog } from "../utils/ConsoleLog";
//get bank list
export const bankService = async (accessToken) => {

    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(BANK_LIST_URL, config)
        .then(res => {
            return res.data.map((value) => {
                return {
                    label: `${value.bankName} - ${value.accountType} - ${value.accountNumber}`,
                    value: value.bankName,
                    id: value.id,
                    active: value.active
                }
            })
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))

}

export const bankLinkService = async (accessToken) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(BANK_ACCOUNT_LINKED, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}

//delete bank account
export const bankAccountUnlink = async (accessToken, bankId) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(`${BANK_ACCOUNT_UNLINKED}/${bankId}`, config)
        .then(res => {
            return res.data
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}