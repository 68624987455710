//default library
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

//regex from number
import { ONLY_NUMBER } from '../../config/Config'

export class InputComponentNumber extends Component {
    //default constructor
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this) //bind this keyword to handleOnChange function
    }

    //handle onChange event
    handleOnChange = async (e) => {
        //check for only number and set to the field
        let value = e.target.value.replace(ONLY_NUMBER, '')

        //send to parent component
        this.props.inputValue(value)
    }

    render() {
        //destructuring props
        const { placeholder, name, readOnly, value, maxLength, intl } = this.props

        return (
            <>
                <input
                    type='text'
                    className='form-control form-control-lg'
                    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                    name={name ?? ''} //set field name
                    value={value} //set field value
                    onChange={this.handleOnChange} //invoke on every key press
                    readOnly={readOnly ?? false} //check field is readOnly or not
                    maxLength={maxLength ?? ''} //set maximum length
                />
            </>
        )
    }
}

export default injectIntl(InputComponentNumber)