//default library
import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";

//interweave library for html
import { Markup } from 'interweave';

export class InformationModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openInformationModal: props.openInformationModal, //open modal
            informationId: props.informationId, //identifier of select value
            esgcValue: props.esgcValue, //esgc value
            esgcName: '',
            esgcDescription: '',
        }
    }

    componentDidMount = async () => {
        const { esgcValue, informationId } = this.state
        let esgcName = ''
        let esgcDescription = ''
        let esgcImage = ''

        for (let i = 0; i < esgcValue.length; i++) {
            if (esgcValue[i].identifier === informationId) {
                esgcName = esgcValue[i].name
                esgcDescription = esgcValue[i].description
                esgcImage = esgcValue[i].thumbnailMedium
            }
        }

        await this.setState({
            esgcName: esgcName,
            esgcDescription: esgcDescription,
            esgcImage: esgcImage
        })
    }

    onHandleClose = async () => {
        await this.setState({
            openInformationModal: false
        })
        await this.props.closeInformationModal()
    }

    render() {
        const { openInformationModal, esgcName, esgcDescription, esgcImage } = this.state
        return (
            <Modal
                centered
                show={openInformationModal}
                onHide={this.onHandleClose}
                backdrop="static"
                keyboard={false} className="gi-c-infoModal gi-c-dynamicModal">
                <div className="modal-header">
                    {/* <div className="modal-title">
                        <button type="button" className="gi-c-previous">
                            <span className="material-icons-round">
                                arrow_back_ios
                            </span>
                        </button>
                        <span className="gi-c-page">1 of 8</span>
                        <button type="button" className="gi-c-next">
                            <span className="material-icons-round">
                                arrow_forward_ios
                            </span>
                        </button>
                    </div> */}
                    <button type="button" className="btn-close" onClick={this.onHandleClose}></button>
                </div>
                <div className="modal-body">
                    <img src={esgcImage} className="img-fluid mb-40" alt="" />
                    <h3>{esgcName}</h3>
                    <div className='gi-c-info-text-dynamic'>
                        <Markup content={esgcDescription} />
                    </div>
                </div>
            </Modal>

        )
    }
}

export default InformationModal