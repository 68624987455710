//default library
import React, { useEffect, Fragment, useState } from 'react';
// datadog library
import { datadogLogs } from '@datadog/browser-logs'
// plaid link token
import { usePlaidLink } from 'react-plaid-link';

// config
import * as Config from '../../config/Config'

// api call
import { plaidGenerateToken } from '../../service/ApiService/PlaidService';
import * as User from '../../middleware/User';

//auth0 library
import { useAuth0 } from "@auth0/auth0-react";

// custom component
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

const PlaidRedirect2 = () => {

    const { getAccessTokenSilently } = useAuth0()
    const [isLoading, setIsLoading] = useState(false)

    // The Link token from the first Link initialization
    const linkToken = localStorage.getItem('plaidToken');

    const onSuccess = React.useCallback(async (publicToken, metadata) => {
        // send public_token to server, retrieve access_token and item_id
        // return to "https://example.com" upon completion
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()

        const userData = await User.getUserData(accessToken)
        const userAddress = userData.userAddress

        let processorTokenRequestData = {
            'customerIdentifier': localStorage.getItem('dwUserIdentifier'),
            "customerFirstName": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_FIRST_NAME : userData.firstNameEncrypted,
            "customerMiddleName": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_LAST_NAME : userData.middleNameEncrypted,
            "customerLastName": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_MIDDLE_NAME : userData.lastNameEncrypted,
            "customerPhoneNumber": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_PHONE : userData.phoneEncrypted,
            "customerEmail": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_EMAIL : localStorage.getItem('userEmailId'),
            "customerAddress": JSON.parse(process.env.REACT_APP_USE_CUSTOMER_NAME) ? Config.PLAID_CUSTOMER_ADDRESS : `${userAddress.street1} ${userAddress.city} ${userAddress.province} ${userAddress.postalCode}`,
            'publicToken': publicToken,
            'institutionName': metadata.institution.name,
            'account': {
                'id': metadata.account.id,
                'mask': metadata.account.mask,
                'name': metadata.account.name,
                'subtype': metadata.account.subtype,
                'type': metadata.account.type
            }
        };

        let onSuccess = await plaidGenerateToken(accessToken, processorTokenRequestData);
        setIsLoading(false)
        if (onSuccess === true) {
            await localStorage.removeItem('plaidToken') //remove plaid token when bank link successfully
            window.location.href = '/dashboard';
        }
        else {

        }
    }, [getAccessTokenSilently]);

    const onExit = (err, metadata) => {
        // handle error...
        datadogLogs.logger.info(`Plaid ${err}`, { plaidR: metadata, plaidToken: this.state.token })
        window.location.href = '/dashboard';
    };

    const config = {
        token: linkToken,
        receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
        onSuccess,
        onExit,
    };

    const { open, ready } = usePlaidLink(config);

    // automatically reinitialize Link
    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    return (
        <Fragment>
            {isLoading
                ? <LoadingComponent />
                : null
            }
        </Fragment>
    )
}

export default PlaidRedirect2