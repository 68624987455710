//default library
import React, { Component, Fragment } from 'react'

//import intl messing
import IntlMessages from '../../helper/IntlMessages';

//custom component
import { currencyFormatter } from '../../service/utils/CurrencyFormatter'
import { DateFormatter2 } from '../../service/utils/DateFormatter';

export class HomeGiftCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            totalValue: '0',
            currentValue: '0',
            earning: '0',
            isPending: false,
            pendingOrderType: false,
            pendingOrderAmount: false,
            isOrderOnHold: false,
            isBankOrderPending: false,
            // redirectGift: false
        };
    };

    componentDidMount = async () => {
        const { giftDetails } = this.props
        await this.setState({
            totalValue: currencyFormatter(giftDetails.redeemedValue),
            currentValue: currencyFormatter(giftDetails.currentValue),
            earning: currencyFormatter(giftDetails.totalGain),
            isPending: giftDetails.isPending,
            pendingOrderType: giftDetails.pendingOrderType,
            pendingOrderAmount: giftDetails.pendingOrderAmount,
            isOrderOnHold: giftDetails.isOrderOnHold,
            isBankOrderPending: giftDetails.isBankOrderPending,
        })
    }

    // gotoGift = async () => {
    //     await this.setState({
    //         redirectGift: true
    //     })
    // }

    render() {
        const { totalValue, currentValue, earning, isPending, pendingOrderType, pendingOrderAmount, isOrderOnHold, isBankOrderPending } = this.state
        const { giftDetails } = this.props
        const esgcValue = giftDetails.esgcValues[0]
        const titleTextStyle = {
            background: `linear-gradient(${esgcValue.titleLinearGradientDirectionDeg}, ${esgcValue.titleLinearGradientColorStop1} ${esgcValue.titleLinearGradientColorStopPercent1}, ${esgcValue.titleLinearGradientColorStop2} ${esgcValue.titleLinearGradientColorStopPercent2})`,
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        };

        return (
            <Fragment>
                {isPending
                    ? <div className="gi-d-gift-body">
                        <div className="gi-d-gift-esgc-value">
                            <div>
                                <img src={esgcValue.giftImage} className="img-fluid" alt="" />
                                <h5 className="mt-0" style={titleTextStyle}>{esgcValue.name}</h5>
                            </div>

                        </div>
                        <div className="gi-d-gift-details">
                            <div className="gi-d-gift-details-left">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-gift-earnings">
                                            <span>{IntlMessages('from')}</span>
                                            <h5>{giftDetails.giftGiverName}</h5>
                                        </div>
                                        <div className="gi-d-gift-earnings">
                                            <span>{IntlMessages('transfer_amount')}:</span>
                                            <h5>
                                                {pendingOrderType === 'BUY' ? '+' : '-'}{currencyFormatter(pendingOrderAmount)}
                                            </h5>
                                            <p>
                                                *{isOrderOnHold
                                                    // complete in market hours
                                                    ? IntlMessages('market_open')
                                                    : isBankOrderPending
                                                        // direct transfer from bank
                                                        ? IntlMessages('direct_bank')
                                                        // simple transfer
                                                        : IntlMessages('simple_basket_transfer')
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gi-d-gift-details-right">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-gift-earnings">
                                            <span>{IntlMessages('account_setting_status1')}::</span>
                                            <h5>{IntlMessages('transfer_pending')}*</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="gi-d-gift-body">
                        <div className="gi-d-gift-esgc-value">
                            <div>
                                <img src={esgcValue.giftImage} className="img-fluid" alt="" />
                                <h5 style={titleTextStyle}>{esgcValue.name}</h5>
                            </div>

                        </div>
                        <div className="gi-d-gift-details">
                            <div className="gi-d-gift-details-left">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-gift-earnings">
                                            <span>{IntlMessages('from')}</span>
                                            <h5>{giftDetails.giftGiverName}</h5>
                                        </div>
                                        <div className="gi-d-gift-earnings">
                                            <span>{IntlMessages('home_gift_date')}</span>
                                            <h5>{DateFormatter2(giftDetails.giftRedemptionDate)}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gi-d-gift-details-right">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-gift-earnings">
                                            <span>
                                                {IntlMessages('transaction_redeemed_value')}
                                            </span>
                                            <h5>
                                                {totalValue}
                                            </h5>
                                        </div>
                                        <div className="gi-d-gift-earnings">
                                            <span>
                                                {IntlMessages('transaction_current')}
                                            </span>
                                            <h5>
                                                {currentValue}
                                            </h5>
                                        </div>
                                        <div className="gi-d-gift-earnings">
                                            <span>
                                                {IntlMessages('transaction_earning')}:
                                            </span>
                                            <h5>
                                                {earning}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default HomeGiftCard