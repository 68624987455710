import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class ExpenseSection extends Component {
    render() {
        const { expenseRatio } = this.props
        return (
            <div className="gi-expense-ratio-card">
                <div className="gi-expense-ratio-card-body">
                    {/* expense break down */}
                    {expenseRatio && Object.keys(expenseRatio).length > 0
                        ? <div className="gi-expense-ratio-left">
                            <div className="gi-expense-ratio-card-heading">
                                {IntlMessages('basket_expense_breakdown')}
                            </div>

                            <div className="gi-expense-ratio-card-details">
                                {/* {expenseRatio.esgcValueBreakDownList.map((e, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <ul className={e.boosted ? '' : 'mb-30'}>
                                                <li>
                                                    <strong>{e.name}</strong>
                                                </li>
                                                <li>
                                                    <span>{e.expenseRatioPercent}%</span>
                                                </li>
                                            </ul>
                                            {e.boosted
                                                ? <ul className="mb-30">
                                                    <li>
                                                        <span>({IntlMessages('basket_boosted')})</span>
                                                    </li>
                                                    <li>
                                                        <span>{e.expenseRatioPercent}%</span>
                                                    </li>
                                                </ul>
                                                : ''
                                            }
                                        </Fragment>
                                    )
                                })} */}
                                {/* <div className="gi-total-price mb-30"> */}
                                <ul>
                                    <li>
                                        <strong>
                                            {IntlMessages('basket_expense_ratio')}
                                        </strong>
                                    </li>
                                    <li>
                                        <span>{`${(0.65).toFixed(2)}%`}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        : ''
                    }
                    {/* static content */}
                    <div className="gi-expense-ratio-right">
                        <div className="gi-expense-ratio-card-text">
                            <p className="mb-30">
                                {IntlMessages('expense_ratio_text1')}
                            </p>
                            <p className="mb-30">
                                {IntlMessages('expense_ratio_text2')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExpenseSection