//default library
import React, { Component, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

// custom component
import B2CGiftHeader from '../components/HeaderComponent/B2CGiftHeader';

//b2c page
import * as B2CGift from '../pages/B2CGiftPages';

export class B2CGiftRoutes extends Component {

    constructor(props) {
        super(props)

        require('../assets/styles/newCSS/b2c-main.scss') //import css
        
    };

    componentDidMount = async () => {
        document.body.classList.add('b2c-bg')
    }

    componentWillUnmount = async () => {
        document.body.classList.remove('b2c-bg')
    }

    render() {
        return (
            <Fragment>
                <B2CGiftHeader />
                <Switch>
                    {/* select esg-c value */}
                    <Route exact path={'/b2c-gift'}>
                        <B2CGift.SelectEsgcValuePage />
                    </Route>

                    {/* select amount */}
                    <Route exact path={'/b2c-gift/select-value'}>
                        <B2CGift.SelectAmountPage />
                    </Route>

                    {/* error */}
                    <Route path={'*'}>
                        <Redirect push to='/error' />
                    </Route>
                </Switch>
            </Fragment>
        )
    }
}

export default B2CGiftRoutes