//api call 
import axios from "axios";

//import Config for url
import { GET_ESGC_VALUE } from "../../config/UrlList";
import { ConsoleLog } from "../utils/ConsoleLog";

export const getESGCValue = async (accessToken) => {

    // const accessToken = await localStorage.getItem('accessToken');

    const config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params for post
    const params = {
        'locale': 'en_US'
    }

    return await axios.post(GET_ESGC_VALUE, params, config)
        .then(res => {
            return res.data.esgcValueList
        })
        //handle error
        .catch(err => ConsoleLog(' ', err))
}