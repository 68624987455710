/* eslint-disable jsx-a11y/anchor-is-valid */
//default library
import React from 'react';

//Auth0 library
import { useAuth0 } from "@auth0/auth0-react";
import IntlMessages from '../../helper/IntlMessages';


const LoginComponent = (props) => {
    //redirect login page
    const { loginWithRedirect, } = useAuth0();

    //onClick login
    const logIn = async () => {
        await loginWithRedirect();
        localStorage.setItem('triggerMethod', 'register')
    }

    //onClick register
    const register = async () => {
        await loginWithRedirect({
            screen_hint: "signup",
        });
        localStorage.setItem('triggerMethod', 'register')
    }

    return (
        <>
            <li className="nav-item">
                <a className="nav-link" onClick={logIn}>
                    {IntlMessages('header_login')}
                </a>
            </li>
            {props.directionShow
                ? <>
                    <li className="nav-item">
                        <a className="nav-link" onClick={register}>
                            {IntlMessages('header_register')}
                        </a>
                    </li>
                    <li className="nav-item">
                        <div className="gi-header-contact">
                            <div className="dropdown">
                                <button type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="material-icons-outlined">
                                        format_align_right
                                    </span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                    <li>
                                        <a className="dropdown-item" >
                                            {IntlMessages('header_rtl')}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" >
                                            {IntlMessages('header_ltr')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </>
                : ''}
        </>
    )
}

export default LoginComponent