module.exports = {
    't_and_c': 'Terms and Conditions',
    
    't_and_c_t1': 'Giraffe Invest, Inc.',
    't_and_c_t2': 'Terms of Use',
    't_and_c_t3': 'Effective Date: April 1st, 2022',
    't_and_c_t4': 'Acceptance of Terms',
    't_and_c_t5': `Giraffe Invest, Inc. ("Giraffe") is an internet only, SEC-registered investment advisor. By accessing Giraffe's website at`,
    't_and_c_t6': `www.giraffeinvest.com (the "Website"),`,
    't_and_c_t7': `you are agreeing to be bound by the following Terms of Use and the Giraffe Privacy Policy. Please carefully review these Agreements as well as the Giraffe Disclosures. Giraffe may update the Terms of Use from time to time without notice by posting a revised agreement. You must stop using the Website if you do not agree with any revised agreements. The Website is also subject to change without notice.`,
    't_and_c_t8': `The advisory services provided by Giraffe are`,
    't_and_c_t9': `governed`,
    't_and_c_t10': `by these Terms of Use, the Giraffe Privacy Policy, and the Giraffe Client Agreement. Brokerage services are provided by Drivewealth, an SEC-registered broker-dealer, and are governed by the Drivewealth`,
    't_and_c_t11': `Terms of Use`,
    't_and_c_t12': `Privacy Policy`,
    't_and_c_t13': `, and`,
    't_and_c_t14': `Customer Account Agreement`,
    't_and_c_t15': `For the purposes of these Terms of Use, the term "Giraffe" includes Giraffe's affiliates, partners, agents, and employees. The term "Client" refers to an individual who allows Giraffe to serve as your RIA by signing the Giraffe Client Agreement. The term "Client Account" refers to the account a Client creates through the Website to use our services.`,
    't_and_c_t16': `Use of the Website`,
    't_and_c_t17': `The Website is intended solely for individuals who are age 18 or older. You represent and warrant that you are 18 or older by accessing or using the Website. Any access to or use of the Website by anyone under age 18 violates these Terms of Use, is unauthorized, and is unlicensed.`,
    't_and_c_t18': `The Website is only intended for use by persons located in the United States. Giraffe makes no representation that the Website is appropriate, available, legal, or permissible for use in locations outside the United States under local law.`,
    't_and_c_t19': `Access to the Website`,
    't_and_c_t20': `It is your responsibility, as a Client, to safeguard your Client Account information, including your password. You agree that any information you provide us to register an account through the Website or mobile applications will be true, accurate, current, and complete. You also agree that you shall be solely responsible for all actions or activities taken under your Client account, regardless of whether you have authorized such actions or activities. As a Client you agree not to reveal your password to any third party whatsoever. If your password is lost or stolen or if you suspect any unauthorized use of your Client Account, you agree to immediately notify Giraffe.`,
    't_and_c_t21': `Educational Use Only`,
    't_and_c_t22': `The publicly available portions of the Website (i.e., those sections of the Website available to individuals who have not signed the Giraffe Client Agreement) are provided for educational purposes only. The Website is not intended to provide legal, tax, or financial planning advice. You agree that you are fully responsible for your own investment research and investment decisions in using the publicly available portions of the Website. Except as otherwise provided herein, Giraffe will not be liable for any actions you take based on information you receive via the publicly available portions of the Website.`,
    't_and_c_t23': `External Links`,
    't_and_c_t24': `In the course of your use of the Website, you may encounter links to certain content created by unaffiliated third parties such as terms of use, confidentiality provisions, privacy provisions, or other provisions that differ from the Website's Terms of Use. Giraffe does not endorse or approve such content, is in no way responsible for such content, and assumes no liability or responsibility whatsoever for the accuracy, reliability, or opinions contained in such content. Users who decide to access such content do so at their own risk.`,
    't_and_c_t25': `Termination`,
    't_and_c_t26': `Giraffe reserves the right, in its sole discretion, to terminate or suspend your access to any and all features of Giraffe's Website at any time and for any reason without notice to you. Giraffe reserves the right to take any additional corrective action it deems appropriate if Giraffe believes, in its sole discretion, that a violation of these Terms of Use has occurred. Giraffe also reserves the right to investigate suspected violations of the Terms of Use. Giraffe may seek to gather, and you agree to provide, any information from you or any other person who is suspected of violating these Terms of Use.`,
    't_and_c_t27': `Your obligations to Giraffe under these Terms of Use (including but not limited to ownership, indemnification, and limitation of liability) shall not be affected by any suspension, termination, or cancellation of your access to Giraffe's Website and services. These obligations, by their sense and context, are intended to survive such suspension, termination, or cancellation.`,
    't_and_c_t28': `Intellectual Property Rights`,
    't_and_c_t29': `Trademarks and logos are the property of their respective owners and do not represent endorsements of any kind. Except as otherwise provided in these Terms of Use, you may not download or save a copy of the Website or any portion thereof, for any purpose. You may, however, print a copy of individual screens appearing as part of the Website solely for your personal, non-commercial use or records, provided that you do not remove any marks, logos, notices, or other legends that appear on the printed or stored images of such screens. All intellectual property rights in and to the content of linked sites is the property of the respective content owner and may be protected by applicable copyright or other intellectual property laws and treaties.`,
    't_and_c_t30': `Digital Millennium Copyright Act.`,
    't_and_c_t31': `Giraffe respects intellectual property rights. If you are a copyright owner or an agent thereof and believe that your copyrights have been infringed upon, you may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") by providing Giraffe's Copyright Agent at joshwith the following information in writing (see 17 U.S.C § 512(c)(3) for further detail):`,
    't_and_c_t32': `(i) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;`,
    't_and_c_t33': `(ii) Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;`,
    't_and_c_t34': `(iii) Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the service provider to locate the material;`,
    't_and_c_t35': `(iv) Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an electronic mail;`,
    't_and_c_t36': `(v) A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and`,
    't_and_c_t37': `(vi) A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.`,
    't_and_c_t38': `Alerts, Notifications, & Service Communications`,
    't_and_c_t39': `Giraffe's authorized employees and agents may monitor and record all or portions of your communications with Giraffe, including telephone calls, emails, text messages, and chats. You hereby consent to such recording. Except to the extent applicable law expressly requires otherwise, such consent is ongoing and need not be confirmed prior to, or during, such monitoring or recording. Giraffe also maintains the right to archive any of your communications with Giraffe for regulatory or other purposes.`,
    't_and_c_t40': `Investment Tools & Model Predictions`,
    't_and_c_t41': `Giraffe makes no representation regarding the likelihood or probability that any actual or hypothetical investment will in fact achieve a particular outcome or perform in any predictable manner. Past performance is not a guarantee of future success, and returns in any period may be far above or below those of a previous period. No representation is being made that any investment will or is likely to achieve profits or losses similar to those achieved in the past, or that significant losses will be avoided. Investing involves risk and investments at Giraffe may lose value.`,
    't_and_c_t42': `Disclaimer of Warranties`,
    't_and_c_t43': `YOUR USE OF THE WEBSITE, AND THE PERSONAL INFORMATION YOU PROVIDE IS AT YOUR SOLE DISCRETION AND RISK. THE WEBSITE AND ALL MATERIALS, INFORMATION, PRODUCTS AND SERVICES INCLUDED THEREIN, ARE PROVIDED ON AN AS IS AND AS AVAILABLE BASIS WITHOUT WARRANTIES OF ANY KIND FROM GIRAFFE.`,
    't_and_c_t44': `GIRAFFE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED OR STATUTORY, RELATED TO THE WEBSITE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. GIRAFFE ALSO DISCLAIMS ANY DUTY TO PROVIDE UPDATED INFORMATION ON THE WEBSITE, AND GIRAFFE DISCLAIMS LIABILITY WITH REGARD TO THE PERFORMANCE OF, OR ACCURACY, QUALITY, CURRENCY, RELIABILITY, TIMELINESS, USEFULNESS, AND COMPLETENESS OF THE INFORMATION ON THE WEBSITE. GIRAFFE DOES NOT GUARANTEE THAT THE WEBSITE WILL BE FREE FROM ERRORS OR ELECTRONIC VIRUSES.`,
    't_and_c_t45': `NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, PROVIDED TO YOU BY GIRAFFE, SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. ANY RELIANCE ON SUCH INFORMATION IS UNDERTAKEN SOLELY AT YOUR OWN RISK.`,
    't_and_c_t46': `SOME STATES OR JURISDICTIONS DO NOT PERMIT US TO EXCLUDE CERTAIN WARRANTIES. ACCORDINGLY, SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.`,
    't_and_c_t47': `Limitation of Liability`,
    't_and_c_t48': `IN NO EVENT SHALL GIRAFFE OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE GIRAFFE WEBSITE, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH GIRAFFE OR FOR ANY INVESTMENT DECISIONS MADE ON THE BASIS OF SUCH INFORMATION, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT GIRAFFE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL GIRAFFE'S CUMULATIVE LIABILITY TO YOU EXCEED U.S. $100.`,
    't_and_c_t49': `Indemnification`,
    't_and_c_t50': `You agree to indemnify, defend, and hold Giraffe harmless from any liability, loss, claim and expense, including attorney's fees, related to your violation of these Terms of Use or use of the Website. Nothing in these Terms of Use shall affect any non-waivable statutory rights that apply to you. Giraffe reserves the right to assume the exclusive defense and control (at your expense) of any matter that is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting Giraffe's defense of such matter.`,
    't_and_c_t51': `Severability & Waiver`,
    't_and_c_t52': `If any term or provision of these Terms of Use is held to be invalid, illegal, or unenforceable, the validity or enforceability of the remainder of this Agreement shall not be affected.`,
    't_and_c_t53': `A failure to exercise in any respect any right provided for in these Terms of Use shall not be deemed a waiver of any other right of these Terms of Use.`,
    't_and_c_t54': `Assignment`,
    't_and_c_t55': `You may not assign, convey, or otherwise transfer any of your rights, obligations, or interests under these Terms of Use (by operation of law or otherwise) without the prior consent of Giraffe. Any assignment or delegation without the prior consent of Giraffe is void.`,
    't_and_c_t56': `Dispute Resolution`,
    't_and_c_t57': `Any controversy or claim which may arise relating in any manner to these Terms of Use, relating to any transaction, errors and/or omissions arising out of, or relating to these Terms of Use, and/or relating to any breach of these Terms of Use, shall be resolved by arbitration before the Financial Industry Regulatory Authority, Inc. ("FINRA"). If FINRA does not accept this matter for arbitration, then any controversy or claim between the parties shall be resolved by arbitration before the American Arbitration Association ("AAA"). The arbitration shall be conducted in accordance with FINRA's then current rules relating to procedure, discovery, and substance for arbitration of customer disputes, applying the standards of applicable law (or in accordance with AAA's then current rules for commercial disputes). The FINRA (or AAA) arbitration shall take place in Pittsburgh, Pennsylvania. Each party shall bear the expense of their own attorneys' fees and costs. Any award rendered by the FINRA (or AAA) arbitrator(s) shall be final and binding on all parties to this Agreement. The parties agree that a judgment upon the arbitration award may be entered in any court having competent jurisdiction for the purpose of enforcing the award. This arbitration provision in no way limits or affects the parties' rights under applicable law or constitutes a waiver of the right to seek a judicial forum if this arbitration provision, or any waiver herein, would be void under federal or state securities laws.`,
    't_and_c_t58': `Choice of Law`,
    't_and_c_t59': `The laws of New York (without giving effect to its conflicts of law principles) govern all matters arising under and relating to these Terms of Use, including torts.`,
}