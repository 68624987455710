//default library
import React, { Component } from 'react'

//custom component
import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import InputComponentText from '../../../components/InputComponent/InputComponentText';
import TextAreaComponent from '../../../components/InputComponent/TextAreaComponent';
import InputComponentRadio from '../../../components/InputComponent/InputComponentRadio';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';

//button component
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent'

//error message
import { MAX_NUMBER_10, MAX_NUMBER_1000, MAX_NUMBER_255, TICKER_REGEX } from '../../../config/Config';
import { actionValue } from '../../../config/DefaultValue'
//loading component
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';

//information component
import RightInformation from '../../../components/InformationComponent/RightInformation';
import InformationComponent from '../../../components/InformationComponent/InformationComponent';
export class EmploymentDetailsPage3 extends Component {
  //default constructor
  constructor(props) {
    super(props)
    this.state = {
      brokerDealer: '',
      stockOwner: '',
      publicOfficial: '',
      companyName: '',
      tickerSymbol: '',
      officialField: '',
      localStorageDataBrokerDealer: '',
      localStorageDataBrokerDealerFlag: false,
      localStorageDataStockOwner: '',
      localStorageDataStockOwnerFlag: false,
      localStorageDataPublicOfficial: '',
      localStorageDataPublicOfficialFlag: false
    }
    this.setLocalStorageData()//initial call for localStorage
  }

  //check localStorage is data is present or not if present then store
  setLocalStorageData = async () => {
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    if (localStorageData !== null && localStorageData?.brokerDealer !== undefined) {
      await this.setState({
        companyName: localStorageData?.companyName ?? '',
        tickerSymbol: localStorageData?.tickerSymbol ?? '',
        officialField: localStorageData?.officialField ?? '',
        localStorageDataBrokerDealer: localStorageData?.brokerDealer ?? '',
        localStorageDataStockOwner: localStorageData?.stockOwner ?? '',
        localStorageDataPublicOfficial: localStorageData?.publicOfficial ?? '',
        localStorageDataBrokerDealerFlag: true,
        localStorageDataStockOwnerFlag: true,
        localStorageDataPublicOfficialFlag: true
      })
    }
  }

  //set component value to field value
  setBrokerDealer = async (brokerDealer) => {
    await this.setState({
      brokerDealer: brokerDealer.fieldValue,
      localStorageDataBrokerDealerFlag: false
    })
  }

  setStockOwner = async (stockOwner) => {
    await this.setState({
      stockOwner: stockOwner.fieldValue,
      localStorageDataStockOwnerFlag: false,
      companyName: '',
      tickerSymbol: ''
    })
  }

  setCompanyName = async (companyName) => {
    await this.setState({
      companyName: companyName
    })
  }

  setTickerSymbol = async (tickerSymbol) => {
    await this.setState({
      tickerSymbol: tickerSymbol
    })
  }

  setPublicOfficial = async (publicOfficial) => {
    await this.setState({
      publicOfficial: publicOfficial.fieldValue,
      localStorageDataPublicOfficialFlag: false,
      officialField: ''
    })
  }

  setOfficialField = async (officialField) => {
    await this.setState({
      officialField: officialField
    })
  }

  //set details for store data
  setDetails = (state) => {
    //destructuring state
    const { localStorageDataBrokerDealer,
      localStorageDataBrokerDealerFlag,
      localStorageDataStockOwner,
      localStorageDataStockOwnerFlag,
      localStorageDataPublicOfficial,
      localStorageDataPublicOfficialFlag } = this.state
    let employmentLabel = JSON.parse(localStorage.getItem('onBoardingDetails'))?.employmentLabel
    //change previous page based on employment label
    if (employmentLabel === 'Currently working' || employmentLabel === 'Self-employed') {
      this.page = 'page9'
    }
    else {
      this.page = 'page8'
    }

    this.detail = {
      brokerDealer: localStorageDataBrokerDealerFlag ? localStorageDataBrokerDealer : state.brokerDealer,
      stockOwner: localStorageDataStockOwnerFlag ? localStorageDataStockOwner : state.stockOwner,
      publicOfficial: localStorageDataPublicOfficialFlag ? localStorageDataPublicOfficial : state.publicOfficial,
      companyName: state.companyName,
      tickerSymbol: state.tickerSymbol,
      officialField: state.officialField
    }
  }

  componentWillUnmount = () => {
    this.setState = () => {
      return;
    };
  }

  render() {
    //destructuring state
    const {
      brokerDealer,
      stockOwner,
      publicOfficial,
      companyName,
      tickerSymbol,
      officialField,
      localStorageDataBrokerDealer,
      localStorageDataBrokerDealerFlag,
      localStorageDataStockOwner,
      localStorageDataStockOwnerFlag,
      localStorageDataPublicOfficial,
      localStorageDataPublicOfficialFlag } = this.state
    this.setDetails(this.state) //call every time when user change value
    return (
      <div className="gi-from-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-8">
                  <div className="py-3">
                    <div className="gi-from-left">
                      <PageCount count={3} pageName={'Employment details'} />

                      {/* brokerDealer */}
                      <div className="gi-input gi-select">
                        <LabelComponent name={'brokerDealer'} labelName={'employment_page3_label1'} />
                      </div>
                      <div className="mb-40">
                        {actionValue.length !== 0 ? actionValue.map((value, i) => {
                          const checked = localStorageDataBrokerDealerFlag ? value.value === localStorageDataBrokerDealer : value.value === brokerDealer
                          return (
                            <div className="customize-radio form-check mt-20" key={i}>
                              <label className={`form-check-label ${brokerDealer === value.value ? 'active' : ''}`} htmlFor={`${value.label.props.id}1`}>
                                <InputComponentRadio name={'brokerDealer'} value={value.value} title={`${value.label.props.id}1`} text={value.label} checked={checked} inputValue={this.setBrokerDealer} />
                              </label>
                            </div>
                          )
                        }) : <LoadingComponent />}
                        {/* if true */}
                        {(brokerDealer || localStorageDataBrokerDealer) === 'true' ?
                          <div className="gi-radio-show-card mt-20">
                            <InformationComponent strongFlag={false} message={'employment_static_t1'} message2={'employment_static_t2'} />
                          </div> : ''
                        }
                        {/* if true over*/}
                      </div>

                      {/* stockOwner */}
                      <div className="gi-input gi-select">
                        <LabelComponent name={'stockOwner'} labelName={'employment_page3_label2'} />
                      </div>
                      <div className="mb-40">
                        {actionValue.length !== 0 ? actionValue.map((value, i) => {
                          const checked = localStorageDataStockOwnerFlag ? value.value === localStorageDataStockOwner : value.value === stockOwner
                          return (
                            <div className="customize-radio form-check mt-20" key={i}>
                              <label className={`form-check-label ${stockOwner === value.value ? 'active' : ''}`} htmlFor={`${value.label.props.id}2`}>
                                <InputComponentRadio name={'stockOwner'} value={value.value} title={`${value.label.props.id}2`} text={value.label} checked={checked} inputValue={this.setStockOwner} />
                              </label>
                            </div>
                          )
                        }) : <LoadingComponent />}
                        {/* if true */}
                        {(stockOwner || localStorageDataStockOwner) === 'true' ?
                          <div className="gi-radio-show-card mt-20" id="stock-owner" >
                            <InformationComponent strongFlag={true} message={'employment_static_t4'} />
                            <div className="gi-input mt-20">
                              <LabelComponent name={'companyName'} labelName={`employment_page3_label2_1`} />
                              <InputComponentText name={'companyName'} placeholder={'placeholder_type_here'} inputValue={this.setCompanyName} />
                              <ErrorComponent message={'error_max_length_255'} display={companyName.length > MAX_NUMBER_255 ? 'block' : 'none'} />
                            </div>
                            <div className="gi-input mt-20 mb-10">
                              <LabelComponent name={'tickerSymbol'} labelName={`employment_page3_label2_2`} />
                              <InputComponentText name={'tickerSymbol'} placeholder={'placeholder_ticker'} inputValue={this.setTickerSymbol} />
                              <ErrorComponent message={'error_ticker_max_length'} display={tickerSymbol.length > MAX_NUMBER_10 ? 'block' : 'none'} />
                              <ErrorComponent message={'error_ticker_message'} display={tickerSymbol.match(TICKER_REGEX) ? 'block' : 'none'} />
                            </div>
                          </div>
                          :
                          ''
                        }
                        {/* if true over*/}

                      </div>

                      {/* publicOfficial */}
                      <div className="gi-input gi-select">
                        <LabelComponent name={'publicOfficial'} labelName={'employment_page3_label3'} />
                      </div>
                      <div className="mb-40">
                        {actionValue.length !== 0 ? actionValue.map((value, i) => {
                          const checked = localStorageDataPublicOfficialFlag ? value.value === localStorageDataPublicOfficial : value.value === publicOfficial
                          return (
                            <div className="customize-radio form-check mt-20" key={i}>
                              <label className={`form-check-label ${publicOfficial === value.value ? 'active' : ''}`} htmlFor={value.label.props.id}>
                                <InputComponentRadio name={'publicOfficial'} value={value.value} title={value.label.props.id} text={value.label} checked={checked} inputValue={this.setPublicOfficial} />
                              </label>
                            </div>
                          )
                        }) : <LoadingComponent />}
                        {/* if true */}
                        {(publicOfficial || localStorageDataPublicOfficial) === 'true' ?
                          <div className="gi-radio-show-card mt-20" id="public-official" >
                            <InformationComponent strongFlag={true} message={`employment_static_t3`} />
                            <div className="gi-input mt-20 mb-10">
                              <LabelComponent name={'publicOfficial'} labelName={`employment_page3_label3_1`} />
                              <TextAreaComponent name={'officialField'} placeholder={'placeholder_type_here'} row={3} inputValue={this.setOfficialField} />
                              <ErrorComponent message={'error_max_length_1000'} display={officialField.length > MAX_NUMBER_1000 ? 'block' : 'none'} />
                            </div>
                          </div>
                          :
                          ''}
                        {/* if true over*/}
                      </div>

                      <div className="gi-btn-group d-none d-sm-flex">
                        {/* button component */}
                        <ButtonComponent value={this.detail} previousRouting={this.page} nextRouting={'page11'} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="gi-from-right">
                    {/* employment details information component */}
                    <RightInformation strong={'employment_detail_info2'} info1={'employment_detail_info3'} info2={'employment_detail_info4'} />
                    <div className="gi-btn-group d-flex d-sm-none">
                      {/* button component */}
                      <ButtonComponent value={this.detail} previousRouting={this.page} nextRouting={'page11'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EmploymentDetailsPage3