//common Config url api key
// export const GOOGLE_PLACE_API_KEY = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

//Regex 
export const NAME_REGEX = /^[a-zA-Z](?:[a-zA-Z.' ]+)*$/g;
export const LAST_NAME_REGEX = /^[a-zA-Z](?:_?[a-zA-Z.'_ ]+)*$/g;
export const TICKER_REGEX = /[^A-Za-z.'0-9: ]/g;
export const ADD_ONLY_ONE_DECIMAL = /(\..*?)\..*/g;
export const ONLY_NUMBER = /[^0-9]/g;
export const SSN_REGEX = `^(?!666|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$`;
export const WHITESPACE = /^[\s]/g;

//maximum number
export const MAX_NUMBER = 9223372036854774807;
export const MAX_NUMBER_255 = 255;
export const MAX_NUMBER_36 = 36;
export const MAX_NUMBER_16 = 16;
export const MAX_NUMBER_64 = 64;
export const MAX_NUMBER_10 = 10;
export const MAX_NUMBER_1000 = 1000;
export const MAX_NUMBER_100 = 100;
export const MAX_NUMBER_AMOUNT = 9999999999999998;

//session timeout const
export const DELAY = 1000 * 1;
export const COUNTDOWN = 10;
export const SESSION_EXPIRED = Number(process.env.REACT_APP_SESSION_EXPIRED);

//
export const KYC_ERROR_HANDLING_FLAG = JSON.parse(process.env.REACT_APP_KYC_ERROR_HANDLING_FLAG)

//Plaid Customer Name
export const PLAID_CUSTOMER_FIRST_NAME = 'Alberta';
export const PLAID_CUSTOMER_MIDDLE_NAME = 'Bobbeth';
export const PLAID_CUSTOMER_LAST_NAME = 'Charleson';
export const PLAID_CUSTOMER_PHONE = '1112223333';
export const PLAID_CUSTOMER_EMAIL = 'accountholder0@example.com';
export const PLAID_CUSTOMER_ADDRESS = '2992 Cameron Road Malakoff NY 14236';

// show navigation gift and learn 
export const SHOW_NAVIGATION_GIFT = JSON.parse(process.env.REACT_APP_SHOW_NAVIGATION_GIFT)
export const SHOW_NAVIGATION_LEARN = JSON.parse(process.env.REACT_APP_SHOW_NAVIGATION_LEARN)

// secret key
export const SECRET_KEY = 'giraffeInvest'

