import React, { Component } from 'react'
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import { Redirect } from 'react-router-dom';

export class ProtectedRoute extends Component {
  isAuthenticate = ''

  render() {
    this.isAuthenticate = localStorage.getItem('accessToken') !== null ? true : false
    return (
      <>
        {this.isAuthenticate ?
          <Route
            component={withAuthenticationRequired(this.component, {
              onRedirecting: () => <LoadingComponent />,
            })}
            {...this.props}
          />
          :
          <Redirect push to={'/'} />
        }

      </>
    )
  }
}

export default ProtectedRoute