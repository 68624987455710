import React, { Component } from 'react';
import IntlMessages from '../../helper/IntlMessages';

export class GiftCard extends Component {

    openAcceptModal = async () => {
        const { currentGift } = this.props
        await this.props.openAcceptModal(currentGift.esgcValues[0].esgcValueBoostIdentifier, false)
    }

    openReadMoreModal = async () => {
        const { currentGift } = this.props
        await this.props.openReadMoreModal(currentGift.esgcValues[0].esgcValueBoostIdentifier, false)
    }

    render() {
        const { currentGift } = this.props

        return (
            <div className="row">
                <div className="col-12">
                    <div className="gi-gift-green-box">
                        <div className="gi-gift-green-bg">
                            <div className="gi-gift-green-header">
                                <h5>
                                    <strong> {IntlMessages('gift_redeemed_ready')}</strong>
                                    {IntlMessages('gift_redeemed_text1')}
                                </h5>
                            </div>

                            <div className="gi-gift-green-body">
                                <div className="gi-green-gift-card-new">
                                    <div className="gi-green-gift-card">
                                        <div className="gi-green-gift-price">
                                            ${currentGift.amount}
                                            {/* {currencyFormatter(currentGift.amount)} */}
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="gi-green-gift-card-img">
                                                    <img src={currentGift.esgcValues[0].giftImage} className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="gi-green-gift-card-text">
                                                    <h5>{currentGift.esgcValues[0].name}</h5>
                                                    <p>
                                                        {currentGift.esgcValues[0].shortDescription}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gi-gift-green-footer">
                            <button type="button" className="gi-redeem-btn" onClick={this.openAcceptModal}>{IntlMessages('gift_redeemed_button1')}</button>
                            <button type="button" className="gi-read-more-btn" onClick={this.openReadMoreModal}>{IntlMessages('gift_redeemed_button2')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GiftCard