import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class WithdrawButton extends Component {

    handleClick = async () => {
        this.props.openWithDrawModal()
    }

    render() {
        const { disabled } = this.props
        return (
            <button className="withdraw-btn" type="button" disabled={disabled} onClick={this.handleClick}>
                {IntlMessages('home_withdraw')}
                {/* <span className="tooltiptext">
                        {IntlMessages('home_tooltip')}
                    </span> */}
            </button>
        )
    }
}

export default WithdrawButton