//default library
import React, { Component } from 'react'

//custom library
import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
import InputComponentCurrency from '../../../components/InputComponent/InputComponentCurrency';
//button component
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';
// information component
import InformationComponent from '../../../components/InformationComponent/InformationComponent';
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';

//import error message
import { MAX_NUMBER } from '../../../config/Config';
export class InvestmentDetailsPage1 extends Component {
  //default constructor
  constructor(props) {
    super(props)
    this.setLocalStorageData() //initial call for localStorage
    this.state = {
      netWorth: ''
    }
  }

  //check localStorage is data is present or not if present then store
  setLocalStorageData = async () => {
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    if (localStorageData !== null) {
      await this.setState({
        netWorth: localStorageData?.netWorth ?? '',
      })
    }
  }

  //set component value to field value to netWorth
  setNetWorth = async (netWorth) => {
    await this.setState({
      netWorth: netWorth
    })
  }

  //set details for store data
  setDetails = async (netWorth) => {
    this.details = {
      netWorth: netWorth
    }
  }

  render() {
    const { netWorth } = this.state
    this.setDetails(this.state.netWorth) //call every time when user change value

    return (
      <>
        <div className="gi-from-section">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row">
                  <div className="col-md-8">
                    <div className="py-3">
                      <div className="gi-from-left">
                        {/* page count */}
                        <PageCount count={1} pageName={'Investment details'} />
                        <div className="gi-input mb-40">
                          {/* field label */}
                          <LabelComponent name={'netWorth'} labelName={'investment_page1_label'} />
                          <div className="gi-currency-input">
                            {/* input component */}
                            <InputComponentCurrency name={'netWorth'} placeholder={'placeholder_currency_1'} inputValue={this.setNetWorth} />
                          </div>
                          <ErrorComponent message={'error_invalid_net_worth'} display={netWorth > MAX_NUMBER ? 'block' : 'none'} />
                        </div>
                        <div className="gi-btn-group d-none d-sm-flex">
                          {/* button component */}
                          <ButtonComponent value={this.details} previousRouting={'page11'} nextRouting={'page13'} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gi-from-right">
                      <div className="gi-text gi-employment-details-3 mt-0 mt-sm-3">
                        {/* investment details information component */}
                        <InformationComponent strongFlag={true} message={'investment_detail_info1'} message2={`investment_detail_info2`} />
                      </div>
                      <div className="gi-btn-group d-flex d-sm-none">
                        {/* button component */}
                        <ButtonComponent value={this.details} previousRouting={'page11'} nextRouting={'page13'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default InvestmentDetailsPage1