//default library
import React, { Component, Fragment } from 'react'
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//image import
import IMAGES from '../../config/Images'
import GiftRedeemCard from '../../components/CardComponent/GiftRedeemCard'
//custom component
import PageHeader from '../../components/PageHeaderComponent/PageHeader'
import ReadMoreSection from '../../components/SectionComponent/ReadMoreSection'
import TableComponent2 from '../../components/TableComponent/TableComponent2'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import GiraffeLearnBannerSection from '../../components/SectionComponent/GiraffeLearnBannerSection'
import IntlMessages from '../../helper/IntlMessages'
import * as GiftAPI from '../../middleware/GiftAPI'
import ConfirmationModal from '../../components/ModalComponent/ConfirmationModal'
import LoadingModal from '../../components/ModalComponent/LoadingModal'

export class GiftRedeemPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            userName: localStorage.getItem('firstName'), //user first name
            totalCompanyList: [], //total company
            readMoreContent: '', //read more content
            detailRedeemed: [], //detail of gift redeemed
            giftIdentifier: window.location.pathname.split('/')[3], //gift identifier from url
            allowCashOut: false, //check cash-out
            isLoading: true,
            openConfirmationModal: false, //open cash out confirmation modal
            openLoadingModal: false, //open loading modal
        }
    }

    componentDidMount = async () => {
        window.scroll(0, 0)
        const { getAccessTokenSilently } = this.props.auth0
        const { giftIdentifier } = this.state
        await this.setState({
            isLoading: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        const detailRedeemed = await GiftAPI.giftDetailsRedeemed(accessToken, giftIdentifier)
        await this.setState({
            totalCompanyList: detailRedeemed?.companyList,
            readMoreContent: detailRedeemed?.esgcValueList?.[0]?.description,
            detailRedeemed: [detailRedeemed],
            allowCashOut: detailRedeemed?.allowCashOut,
            isLoading: false
        })
    }

    // cash out api call
    giftCashOut = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            openConfirmationModal: false,
            openLoadingModal: true
        })
        const { giftIdentifier } = this.state
        const accessToken = await getAccessTokenSilently() //get access token
        await GiftAPI.giftCashOut(accessToken, giftIdentifier)
        await this.setState({
            openLoadingModal: false,
        })
        await this.componentDidMount()
    }

    // open confirmation modal
    openConfirmationModal = async () => {
        await this.setState({
            openConfirmationModal: true,
            openLoadingModal: false
        })
    }

    // close modal
    onHandleClose = async () => {
        await this.setState({
            openConfirmationModal: false,
            openLoadingModal: false
        })
    }

    render() {
        const { userName, totalCompanyList, readMoreContent, detailRedeemed, isLoading, allowCashOut, openConfirmationModal, openLoadingModal } = this.state
        return (
            isLoading
                ? <LoadingComponent />
                : <Fragment>
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-gift-heading">
                                <PageHeader h5={`gift_head_text2`} strong={`hello`} userName={userName} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-g-right-sidebar-main">
                                {/* main section */}
                                <div className="gi-g-right-sidebar-left">
                                    <div className="gi-d-g-details-card-left">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="gi-b-details mb-10">
                                                    {/* transaction card */}
                                                    <div className="gi-d-b-gift-card">
                                                        {/* gift name */}
                                                        <div className="gi-d-b-gift-header">
                                                            <div className="gi-d-b-gift-heading">
                                                                <a href="#!">
                                                                    <img src={IMAGES.VERTICAL_ICON} alt="" />
                                                                </a>
                                                                {detailRedeemed[0]?.name}
                                                            </div>
                                                        </div>
                                                        {/* gift transaction details */}
                                                        {detailRedeemed.length > 0 ? <GiftRedeemCard detailRedeemed={detailRedeemed[0]} /> : ''}
                                                    </div>
                                                    {/* table */}
                                                    <div className="gi-b-details-table-card">
                                                        {/* read more section */}
                                                        {readMoreContent
                                                            ? <ReadMoreSection>
                                                                {readMoreContent}
                                                            </ReadMoreSection>
                                                            : ''
                                                        }

                                                        {/* table component */}
                                                        {totalCompanyList.length > 0 ? < TableComponent2 totalCompanyList={totalCompanyList} /> : ''}


                                                        {/* cash out */}
                                                        {allowCashOut
                                                            ? <div className="mt-40 mb-40">
                                                                <a href="#!" className="gi-d-gift-cash-btn" onClick={this.openConfirmationModal}>
                                                                    {IntlMessages('gift_cash_out')}
                                                                    <img src={IMAGES.BRIGHT_RIGHT_ICON} alt="" />
                                                                </a>
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* banner section */}
                                <div className="gi-g-right-sidebar-right">
                                    {/* <div className="gi-d-gift-banner">
                                        <div className="gi-d-gift-banner-img">
                                            <div className="gi-d-gift-banner-text">
                                                <a href="#!">
                                                    <img src={IMAGES.RIGHT_LONG_ICON} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <GiraffeLearnBannerSection />
                                </div>
                            </div>
                        </div>
                    </div>

                    {openConfirmationModal
                        ? <ConfirmationModal openConfirmationModal={openConfirmationModal} message={'gift_cash_out_text1'} onHandleClose={this.onHandleClose} onHandleConfirm={this.giftCashOut} />
                        : ''
                    }

                    {openLoadingModal
                        ? <LoadingModal openLoadingModal={openLoadingModal} title={'loading'} onHandleClose={this.onHandleClose} />
                        : ''
                    }

                </Fragment>
        )
    }
}

export default withAuth0(GiftRedeemPage)