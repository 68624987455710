/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import IntlMessages from '../../helper/IntlMessages'

export class FundButton extends Component {


    onHandleClick = async () => {
        this.props.openFundModel()
    }

    render() {
        return (
            <>
                <button className="gi-d-fya-btn home-icon-green" type="button" onClick={this.onHandleClick}>
                    {IntlMessages('button_fund')}
                </button>
            </>
        )
    }
}

export default FundButton