//default library
import React, { Component } from 'react';
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom library
import PageCount from '../../../components/PageCount/PageCount';
import LabelComponent from '../../../components/LabelComponent/LabelComponent';
// information component
import InformationComponent from '../../../components/InformationComponent/InformationComponent';
//get list investment risk list
import { getInvestorRiskTolerance } from '../../../middleware/DropdownList';
//button component
import ButtonComponent from '../../../components/ButtonComponent/ButtonComponent';

//custom process bar
import ProcessBar from '../../../components/ProcessBarComponent/ProcessBar'
import { Fragment } from 'react';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
export class InvestmentDetailsPage3 extends Component {
  //default constructor
  constructor(props) {
    super(props)
    this.setLocalStorageData()//initial call for localStorage
    this.state = {
      riskToleranceList: [],
      riskTolerance: '',
      identifierValue: [],
      currentIndex: '',
      labelName: [],
      isLoading: true
    }
  }

  //get list of investor and store into riskToleranceList field
  componentDidMount = async () => {
    const { getAccessTokenSilently } = this.props.auth0
    const accessToken = await getAccessTokenSilently() //get access token
    let getListAPI = await getInvestorRiskTolerance(accessToken);
    let identifierValue = await getListAPI.map((value) => value.value)
    let labelName = await getListAPI.map((value) => value.label)
    this.setState({
      labelName: labelName,
      riskToleranceList: getListAPI,
      identifierValue: identifierValue,
      isLoading: false
    })

  }


  //check localStorage is data is present or not if present then store
  setLocalStorageData = async () => {
    let localStorageData = await JSON.parse(localStorage.getItem('onBoardingDetails'));
    if (localStorageData !== null && localStorageData?.currentIndex !== undefined) {
      await this.setState({
        // riskTolerance: localStorageData?.riskTolerance ?? '',
        currentIndex: localStorageData?.currentIndex ?? '',
      })
    }
    else {
      await this.setState({
        // riskTolerance: this.state.identifierValue[0],
        currentIndex: 0,
      })
    }
  }

  setProcessBar = async (index) => {
    await this.setState({
      currentIndex: index,
      // riskTolerance: this.state.identifierValue[index]
    })
  };

  //set details for store data
  setDetails = (value) => {
    this.details = {
      riskTolerance: this.state.identifierValue[value.currentIndex],
      currentIndex: value.currentIndex
    }
  }

  componentWillUnmount = () => {
    this.setState = () => {
      return;
    };
  }

  render() {
    const { labelName, isLoading } = this.state
    this.setDetails(this.state)//call every time when user change value
    return (
      <Fragment>
        <div className="gi-from-section">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row">
                  <div className="col-md-8">
                    <div className="py-3">
                      <div className="gi-from-left">
                        {/* page count */}
                        <PageCount count={3} pageName={'Investment details'} />
                        <div className="gi-input gi-select">
                          {/* field label */}
                          <LabelComponent name={'riskTolerance'} labelName={`investment_page3_label`} />
                        </div>
                        <div className="mb-40">
                          <div className="row">
                            {/* process bar */}
                            {isLoading
                              ? <LoadingComponent />
                              : <ProcessBar setProcessBar={this.setProcessBar} labelName={labelName} />
                            }
                          </div>
                        </div>
                        <div className="gi-btn-group d-none d-sm-flex">
                          {/* button component */}
                          <ButtonComponent value={this.details} previousRouting={'page13'} nextRouting={'page15'} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gi-from-right">
                      <div className="gi-text gi-employment-details-4 mt-0 mt-sm-3">
                        <div>
                          {/* investment details information component */}
                          <InformationComponent strongFlag={true} message={'investment_detail_info5'} message2={`investment_detail_info6`} />
                        </div>
                      </div>
                      <div className="gi-btn-group d-flex d-sm-none">
                        {/* button component */}
                        <ButtonComponent value={this.details} previousRouting={'page13'} nextRouting={'page15'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withAuth0(InvestmentDetailsPage3)