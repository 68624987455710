//default library
import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
//image
import IMAGES from '../../config/Images';
import IntlMessages from '../../helper/IntlMessages';

export class GiftRedeemedErrorModal extends Component {

    render() {
        const { openRedeemedErrorModal } = this.props
        return (
            <Modal
                centered
                show={openRedeemedErrorModal}
                backdrop="static"
                keyboard={false} className="redeemed-error-modal">
                <Modal.Body>
                    <div className="redeemed-error-card">
                        <div className="redeemed-error-top-card">
                            <h5>
                                {IntlMessages('redeemed_error_modal')}
                                <br />
                                {IntlMessages('redeemed_error_modal_text1')}
                            </h5>
                            <img src={IMAGES.WHITE_GIRAFFE_ERROR} alt="" />
                        </div>
                        <div className="redeemed-error-bottom-card">
                            <p>
                                {IntlMessages('redeemed_error_modal_text2')}
                                <a href="mailto:support@giraffe.us"> support@giraffe.us </a>
                                {IntlMessages('redeemed_error_modal_text4')}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default GiftRedeemedErrorModal