//api call 
import axios from "axios";

//import Config for url
import * as UrlList from "../config/UrlList";

//custom function
import { ConsoleLog } from '../service/utils/ConsoleLog';

//get the gift details 
export const getGiftDetail = async (giftIdentifier) => {

    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    // let config = {
    //     headers: {
    //         authorization: `Bearer ${accessToken}`,
    //     }
    // };

    const params = {
        'giftIdentifier': giftIdentifier
    }

    return await axios.post(UrlList.GET_GIFT_DETAIL, params)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

//gift list in 
export const giftRouter = async (accessToken) => {
    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(UrlList.GIFT_ROUTE, config)
        .then((res) => {
            return res.data
        })
        .catch(err => {
            ConsoleLog('', err)
            return []
        })
}

export const giftUpdate = async (accessToken, giftIdentifier) => {
    //access token
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    //set params for post
    let params = {
        'giftIdentifier': giftIdentifier
    };

    return await axios.post(UrlList.UPDATE_GIFT_USER, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => ConsoleLog(' ', err))
}

// onboarding gift
export const getGiftReceived = async (accessToken, giftIdentifier) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    const params = {
        'locale': 'en_US',
        'identifier': giftIdentifier
    }

    return await axios.post(UrlList.GET_GIFT_IDENTIFIER, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

// gift redeemed api
export const redeemedGift = async (accessToken, redeemedGiftDetails) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    const params = {
        "giftIdentifier": redeemedGiftDetails.giftIdentifier,
        "esgcValueBoostIdentifier": redeemedGiftDetails.esgcValueIdentifier
    }

    return await axios.post(UrlList.REDEEM_GIFT, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

// gift details after redeemed
export const giftDetailsRedeemed = async (accessToken, giftIdentifier) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    const params = {
        'locale': 'en_US',
        'basketIdentifier': giftIdentifier
    }

    return await axios.post(UrlList.REDEEM_GIFT_DETAILS, params, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}

export const giftCashOut = async (accessToken, giftIdentifier) => {
    // const accessToken = await localStorage.getItem('accessToken');

    let config = {
        headers: {
            authorization: `Bearer ${accessToken}`,
        }
    };

    return await axios.get(`${UrlList.BASKET_CASH_OUT}/${giftIdentifier}`, config)
        .then(res => {
            return res.data
        })
        .catch(err => {
            ConsoleLog(' ', err)
        })
}