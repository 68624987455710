import React, { Component } from 'react'

export class DashboardProfile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            shortUserName: ''
        };
    };

    componentDidMount = async () => {
        let firstName = localStorage.getItem('firstName')
        let lastName = localStorage.getItem('lastName')

        await this.setState({
            shortUserName: `${firstName.charAt(0)}${lastName.charAt(0)}`
        })
    }

    render() {
        const { shortUserName } = this.state
        const { shortNameFirst, username } = this.props
        return (
            <a href="#!">
                {!shortNameFirst
                    ? <span className="gi-g-profile-img">
                        {shortUserName}
                    </span>
                    : ''
                }
                <span className="gi-g-profile-name">
                    {username}
                </span>
                {shortNameFirst
                    ? <span className="gi-g-profile-img">
                        {shortUserName}
                    </span>
                    : ''
                }
            </a>
        )
    }
}

export default DashboardProfile