import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";
import IntlMessages from '../../helper/IntlMessages';

export class ConfirmationModal extends Component {
    onHandleClose = async () => {
        this.props.onHandleClose()
    }

    onHandleConfirm = async () => {
        this.props.onHandleConfirm()
    }

    render() {
        const { openConfirmationModal, message } = this.props
        return (
            <Modal
                centered
                show={openConfirmationModal}
                backdrop="static"
                keyboard={false} className="error-modal"
                onHide={this.onHandleClose}>
                <Modal.Body>
                    <div className="error-modal-body">
                        <div className="error-modal-text">
                            <h5>
                                {IntlMessages(message)}
                            </h5>

                            <div className="text-center">

                            </div>
                            <div className="gi-sell-btn-group mt-30">
                                <button className="gi-cancel-btn" type="button" onClick={this.onHandleClose}>
                                    {IntlMessages('no')}
                                </button>
                                <button className="gi-next-btn" type="button" onClick={this.onHandleConfirm}>
                                    {IntlMessages('yes')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ConfirmationModal