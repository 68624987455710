/* eslint-disable jsx-a11y/anchor-is-valid */
//default library
import React, { Component, Fragment } from 'react'
import Modal from "react-bootstrap/Modal";
//auth0 library
import { withAuth0 } from '@auth0/auth0-react';
//custom component
import PageCount from '../../../components/PageCount/PageCount';
import SubmitButton from '../../../components/ButtonComponent/SubmitButton';
import InputComponentCheckbox from '../../../components/InputComponent/InputComponentCheckbox';

//Loading component
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
//get t&c url
import getDisclosureUrl from '../../../service/ApiService/DiscloserService';

//information component for complete application
import InformationComponent from '../../../components/InformationComponent/InformationComponent';

//error
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent';

import LoadingComponent2 from '../../../components/LoadingComponent/LoadingComponent2'
import IntlMessages from '../../../helper/IntlMessages';
export class CompleteDetailsPage extends Component {
    //default constructor
    constructor(props) {
        super(props)
        this.state = {
            checkBoxValue: '', //check box
            disclosureURL: '', //disclosure url
            taxDisclosure: '', //tax disclosure text based on citizenship 
            taxDisclosureURL: '', //tax disclosure url
            termAndConditionURl: '', //term and condition url
            privacyPolicyUrl: '',

            isLoading: false, //loading 
            errorMessage: false, //if error occurs when data not update success fully
            setDisclosuresModal: false, //open disclosure modal
            setPrivacyPolicyModalGiraffe: false, //open privacy policy modal
            setPrivacyPolicyModal: false, //open privacy policy modal
            setTaxPayerModal: false, //open tax payer modal
            setTAndCModal: false, //open term and condition modal
            setTAndCModalGiraffe: false, //open term and condition modal

            loadingIframe: true, //loader in iframe
        }
    }

    // call driveWealth t&c url and store disclosureURl field
    componentDidMount = async () => {
        const { getAccessTokenSilently } = this.props.auth0
        await this.setState({
            isLoading: true
        })
        const accessToken = await getAccessTokenSilently() //get access token
        let disclosure = await getDisclosureUrl(accessToken);
        await this.setState({
            disclosureURL: disclosure?.disclosureUrl,
            taxDisclosure: disclosure?.taxDisclosure,
            taxDisclosureURL: disclosure?.taxDisclosureUrl,
            termAndConditionURl: disclosure?.termsOfUseUrl,
            privacyPolicyUrl: disclosure?.privacyPolicyUrl,
            isLoading: false
        })
    }

    componentWillUnmount = () => {
        this.setState = () => {
            return;
        };
    }

    //show model
    onHandleShowDisclosure = () => {
        this.setState({
            setDisclosuresModal: true,
        })
    }
    onHandleShowPrivacyGiraffe = () => {
        this.setState({
            setPrivacyPolicyModalGiraffe: true
        })
    }
    onHandleShowPrivacy = () => {
        this.setState({
            setPrivacyPolicyModal: true
        })
    }
    onHandleShowTermsGiraffe = () => {
        this.setState({
            setTAndCModalGiraffe: true
        })
    }
    onHandleShowTaxPayer = () => {
        this.setState({
            setTaxPayerModal: true,
        })
    }
    onHandleTermAndCondition = () => {
        this.setState({
            setTAndCModal: true,
        })
    }

    //close model
    onHandleClose = () => {
        this.setState({
            setDisclosuresModal: false,
            setPrivacyPolicyModalGiraffe: false,
            setTaxPayerModal: false,
            setTAndCModal: false,
            setTAndCModalGiraffe: false,
            setPrivacyPolicyModal: false,
            loadingIframe: true,
        })
    }

    //hide loader when iframe data load
    hideSpinner = () => {
        this.setState({
            loadingIframe: false
        });
    };

    //get value checkbox field
    setCheckboxValue = async (value) => {
        await this.setState({
            checkBoxValue: value
        })
    }

    //send to button
    setDetails = (value) => {
        this.details = {
            terms: value,
        }
    }

    isLoading = async (...value) => {
        await this.setState({
            isLoading: value[0],
            errorMessage: value[1]
        })
    }

    render() {
        const { isLoading, errorMessage, taxDisclosure, disclosureURL, taxDisclosureURL, termAndConditionURl,
            setDisclosuresModal, setPrivacyPolicyModalGiraffe, setTaxPayerModal, setTAndCModal,
            setTAndCModalGiraffe, setPrivacyPolicyModal, privacyPolicyUrl, loadingIframe } = this.state
        //call every time when value change
        this.setDetails(this.state.checkBoxValue)
        return (
            <Fragment>
                {!isLoading ? '' : <LoadingComponent />}
                <div className="gi-from-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    {/* for error message */}
                                    <div className='gi-input'>
                                        <ErrorComponent message={'error_update_call_error'} display={errorMessage ? 'block' : 'none'} />
                                    </div>

                                    <div className="col-md-8">
                                        <div className="py-3">
                                            <div className="gi-from-left">
                                                <PageCount pageName={'Complete application'} />

                                                <div className="mb-40">
                                                    <div className="customize-check form-check">
                                                        <label className="form-check-label" htmlFor="terms">
                                                            {/* checkBox field */}
                                                            <InputComponentCheckbox name={'terms'} inputValue={this.setCheckboxValue} />
                                                            {IntlMessages('complete_application_t1')}&nbsp;
                                                            <a onClick={this.onHandleShowPrivacyGiraffe}>
                                                                {IntlMessages('complete_application_t2')}
                                                            </a>,&nbsp;
                                                            <a onClick={this.onHandleShowTermsGiraffe}>
                                                                {IntlMessages('complete_application_t3')}
                                                            </a>&nbsp;
                                                            {IntlMessages('complete_application_t4')}&nbsp;
                                                            <a onClick={this.onHandleShowDisclosure}>
                                                                {IntlMessages('complete_application_t5')}
                                                            </a>,&nbsp;
                                                            <a onClick={this.onHandleShowPrivacy}>
                                                                {IntlMessages('complete_application_t2')}
                                                            </a>,&nbsp;
                                                            {IntlMessages('complete_application_t3')}&nbsp;
                                                            {/* <a onClick={this.onHandleTermAndCondition}> terms & conditions </a>, */}
                                                            <a onClick={this.onHandleShowTaxPayer}>
                                                                {taxDisclosure}&nbsp;{IntlMessages('complete_application_t6')}
                                                            </a>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="gi-btn-group d-none d-sm-flex">
                                                    {/* Submit button */}
                                                    <SubmitButton value={this.details} previousRouting={'page14'} nextRouting={'onBoarding/page16'} isLoading={this.isLoading} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="gi-from-right">
                                            <div className="gi-text gi-employment-details-5 mt-0 mt-sm-3">
                                                {/* Information */}
                                                <InformationComponent strongFlag={true} message={'thank_you'} message2={`complete_journey_info1`} />
                                            </div>
                                            <div className="gi-btn-group d-flex d-sm-none">
                                                {/* Submit button */}
                                                <SubmitButton value={this.details} previousRouting={'page14'} nextRouting={'onBoarding/page16'} isLoading={this.isLoading} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* model open when user click on disclosure */}
                <Modal show={setDisclosuresModal} onHide={this.onHandleClose} centered size="lg" >
                    {/* Model header */}
                    <Modal.Header closeButton>
                        <Modal.Title>{IntlMessages('complete_application_t8')}</Modal.Title>
                    </Modal.Header>
                    {/* Model body */}
                    <Modal.Body className='modalBody'>
                        {loadingIframe
                            ? <div className='d-flex justify-content-center'>
                                <LoadingComponent2 />
                            </div>
                            : ''
                        }
                        <iframe src={disclosureURL} title="Disclosure" className='modalIframe' onLoad={this.hideSpinner} ></iframe>
                    </Modal.Body>
                    {/* Model footer */}
                    <Modal.Footer>
                        <button className="gi-next-btn" type="button" onClick={this.onHandleClose}>{IntlMessages('button_close')}</button>
                    </Modal.Footer>
                </Modal>

                {/* model open when user click on terms & condition */}
                <Modal show={setTAndCModal} onHide={this.onHandleClose} centered size="lg" >
                    {/* Model header */}
                    <Modal.Header closeButton>
                        <Modal.Title>{IntlMessages('complete_application_t3')}</Modal.Title>
                    </Modal.Header>
                    {/* Model body */}
                    <Modal.Body className='modalBody loadingIframe'>
                        {loadingIframe
                            ? <div className='d-flex justify-content-center'>
                                <LoadingComponent2 />
                            </div>
                            : ''
                        }
                        <iframe src={termAndConditionURl} title="Disclosure" className='modalIframe' ></iframe>
                    </Modal.Body>
                    {/* Model footer */}
                    <Modal.Footer>
                        <button className="gi-next-btn" type="button" onClick={this.onHandleClose}>{IntlMessages('button_close')}</button>
                    </Modal.Footer>
                </Modal>

                {/* modal open when user click privacy policy*/}
                <Modal show={setPrivacyPolicyModal} onHide={this.onHandleClose} centered size="lg">
                    {/* Model header */}
                    <Modal.Header closeButton>
                        <Modal.Title>{IntlMessages('privacy_policy')}</Modal.Title>
                    </Modal.Header>
                    {/* Model body */}
                    <Modal.Body className='modalBody'>
                        {loadingIframe
                            ? <div className='d-flex justify-content-center'>
                                <LoadingComponent2 />
                            </div>
                            : ''
                        }
                        <iframe src={privacyPolicyUrl} title="TaxPayer" className='modalIframe' ></iframe>
                    </Modal.Body>
                    {/* Model footer */}
                    <Modal.Footer>
                        <button className="gi-next-btn" type="button" onClick={this.onHandleClose}>{IntlMessages('button_close')}</button>
                    </Modal.Footer>
                </Modal>

                {/* model open when user click on tax payer modal */}
                <Modal show={setTaxPayerModal} onHide={this.onHandleClose} centered size="lg" >
                    {/* Model header */}
                    <Modal.Header closeButton>
                        <Modal.Title> {taxDisclosure} {IntlMessages('complete_application_t7')}</Modal.Title>
                    </Modal.Header>
                    {/* Model body */}
                    <Modal.Body className='modalBody'>
                        {loadingIframe
                            ? <div className='d-flex justify-content-center'>
                                <LoadingComponent2 />
                            </div>
                            : ''
                        }
                        <iframe src={taxDisclosureURL} title="TaxPayer" className='modalIframe' ></iframe>
                    </Modal.Body>
                    {/* Model footer */}
                    <Modal.Footer>
                        <button className="gi-next-btn" type="button" onClick={this.onHandleClose}>{IntlMessages('button_close')}</button>
                    </Modal.Footer>
                </Modal>

                {/* modal open when user click privacy policy giraffe*/}
                <Modal show={setPrivacyPolicyModalGiraffe} onHide={this.onHandleClose} centered size="lg">
                    {/* Model header */}
                    <Modal.Header closeButton>
                        <Modal.Title>{IntlMessages('privacy_policy')}</Modal.Title>
                    </Modal.Header>
                    {/* Model body */}
                    <Modal.Body className='giraffe-policy'>
                        <div className='giraffe-policy-main'>
                            <p><strong>{IntlMessages('privacy_policy_t1')}&nbsp;</strong></p>
                            <p>
                                <strong>{IntlMessages('privacy_policy')}</strong>
                            </p>
                            <p>
                                <span>{IntlMessages('privacy_policy_t2')}</span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t3')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t4')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t4')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t6')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t7')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t8')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t9')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t10')}
                                </span>
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        {IntlMessages('privacy_policy_t11')}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        {IntlMessages('privacy_policy_t12')}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        {IntlMessages('privacy_policy_t13')}
                                    </span>
                                </li>
                            </ul>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t14')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t15')}
                                </span>
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        {IntlMessages('privacy_policy_t16')}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        {IntlMessages('privacy_policy_t17')}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        {IntlMessages('privacy_policy_t18')}
                                    </span>
                                </li>
                            </ul>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t19')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t20')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t21')}</strong>
                            </h4>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t22')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t23')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t24')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t25')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t26')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t27')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t28')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t29')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t30')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t31')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t32')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t33')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t34')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t35')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t36')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t37')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t38')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t39')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t40')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t41')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t42')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t43')}&nbsp;
                                </span>
                                <span>
                                    {IntlMessages('privacy_policy_t44')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>
                                    {IntlMessages('privacy_policy_t45')}&nbsp;
                                </strong>
                                <span>
                                    {IntlMessages('privacy_policy_t46')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t47')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t48')}&nbsp;
                                </span>
                                <span>
                                    {IntlMessages('privacy_policy_t49')}&nbsp;
                                </span>
                                <a
                                    href="mailto:josh@giraffeinvest.com">
                                    <span>{IntlMessages('privacy_policy_t50')}&nbsp;</span>
                                </a>
                                <span>
                                    .
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>
                                    {IntlMessages('privacy_policy_t51')}
                                </strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t52')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t53')}</strong>
                            </h4>
                            <p>
                                <strong>
                                    {IntlMessages('privacy_policy_t54')}&nbsp;
                                </strong>
                                <span>
                                    {IntlMessages('privacy_policy_t55')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t56')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t57')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <strong>{IntlMessages('privacy_policy_t58')}&nbsp;</strong>
                                <span>
                                    {IntlMessages('privacy_policy_t59')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t60')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t61')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t62')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t63')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t64')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t65')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <h4>
                                <strong>{IntlMessages('privacy_policy_t66')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('privacy_policy_t67')}&nbsp;
                                </span>
                                <span>
                                    {IntlMessages('privacy_policy_t49')}&nbsp;
                                </span>
                                <a href="mailto:legal@giraffeinvest.com">
                                    <span>
                                        {IntlMessages('privacy_policy_t50')}&nbsp;
                                    </span>
                                </a>
                                <span>
                                    .
                                </span>
                            </p>
                        </div>
                    </Modal.Body>
                    {/* Model footer */}
                    <Modal.Footer>
                        <button className="gi-next-btn" type="button" onClick={this.onHandleClose}>{IntlMessages('button_close')}</button>
                    </Modal.Footer>
                </Modal>

                {/* modal open when user click Terms and condition giraffe */}
                <Modal show={setTAndCModalGiraffe} onHide={this.onHandleClose} centered size="lg">
                    {/* Model header */}
                    <Modal.Header closeButton>
                        <Modal.Title>{IntlMessages('t_and_c')}</Modal.Title>
                    </Modal.Header>
                    {/* Model body */}
                    <Modal.Body className='giraffe-policy'>
                        <div className='giraffe-policy-main'>
                            <p>
                                <strong>
                                    {IntlMessages('t_and_c_t1')}
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    {IntlMessages('t_and_c_t2')}
                                </strong>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t3')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t4')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t5')}&nbsp;
                                </span>
                                <span>
                                    {IntlMessages('t_and_c_t6')}&nbsp;
                                </span>
                                <span>
                                    {IntlMessages('t_and_c_t7')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t8')}&nbsp;
                                </span>
                                <span>
                                    {IntlMessages('t_and_c_t9')}&nbsp;
                                </span>
                                <span>
                                    {IntlMessages('t_and_c_t10')}&nbsp;
                                </span>
                                <a href="https://legal.drivewealth.com/terms-of-use?lang=en_US">
                                    <span>
                                        {IntlMessages('t_and_c_t11')}
                                    </span>
                                </a>
                                <span>
                                    ,
                                </span>
                                <a href="https://legal.drivewealth.com/privacy-policy">
                                    <span>
                                        &nbsp;{IntlMessages('t_and_c_t12')}&nbsp;
                                    </span>
                                </a>
                                <span>
                                    {IntlMessages('t_and_c_t13')}&nbsp;
                                </span>
                                <a href="https://legal.drivewealth.com/customer-account-agreement?lang=en_US">
                                    <span>
                                        {IntlMessages('t_and_c_t14')}
                                    </span>
                                </a>
                                <span>
                                    .
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t15')}&nbsp;
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t16')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t17')}
                                </span>
                            </p>
                            <p>
                                <span>&nbsp;</span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t18')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t19')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t20')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t21')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t22')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t23')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t24')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t25')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t26')}
                                </span>
                            </p>
                            <br />
                            <p>
                                <span>&nbsp;</span>
                            </p>

                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t27')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t28')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t29')}
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <strong>
                                    <em>{IntlMessages('t_and_c_t30')}</em>
                                </strong>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t31')}
                                </span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t32')}
                                </span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t33')}
                                </span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t34')}
                                </span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t35')}
                                </span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t36')}
                                </span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t37')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>
                                    {IntlMessages('t_and_c_t38')}
                                </strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t39')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>
                                    {IntlMessages('t_and_c_t40')}
                                </strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t41')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t42')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t43')}
                                </span>
                            </p>
                            <p>
                                &nbsp;
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t44')}
                                </span>
                            </p>
                            <p>
                                <span>&nbsp;</span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t45')}
                                </span>
                            </p>
                            <p>
                                <span>&nbsp;</span>
                            </p>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t46')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t47')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t48')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t49')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t50')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t51')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t52')}
                                </span>
                            </p>

                            <p> &nbsp;</p>

                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t53')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t54')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t55')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <strong>{IntlMessages('t_and_c_t56')}</strong>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t57')}
                                </span>
                            </p>
                            <br />
                            <h4>
                                <span>{IntlMessages('t_and_c_t58')}</span>
                            </h4>
                            <p>
                                <span>
                                    {IntlMessages('t_and_c_t59')}
                                </span>
                            </p>
                        </div>
                    </Modal.Body>
                    {/* Model footer */}
                    <Modal.Footer>
                        <button className="gi-next-btn" type="button" onClick={this.onHandleClose}>{IntlMessages('button_close')}</button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

export default withAuth0(CompleteDetailsPage)